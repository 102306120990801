import React, { useState, memo, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button, Badge } from "@mui/material";
import { CheckCircle, CheckBox } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useDragScroll } from "hooks";
import { LocalDbManagement } from "services/local.service";
import { AttachmentUtil, FileUtil, toCamel } from "utils";
import SettingTitle from "./SettingTitle";

const SettingAppLogo = ({ currentBranchId, onChange }) => {
  const classes = useStyles();
  const { t: getHomeLabel } = useTranslation(LangConstant.NS_HOME);
  const settingObjContent = getHomeLabel("OBJ_SETTING_APP", {
    returnObjects: true,
  });
  const [ref] = useDragScroll();

  const [selectedBranchId, setSelectedBranchId] = useState();
  const [branchList, setBranchList] = useState([]);

  const handleSelectLogoFunc = id => () => {
    setSelectedBranchId(id);
    onChange(id);
  };

  useEffect(() => {
    if (!currentBranchId) return;

    const branches = LocalDbManagement.find({
      state: [SystemConstant.STATE.active, SystemConstant.STATE.inactive],
    });
    if (Array.isArray(branches)) {
      const iconPathPromises = branches.map(item => {
        const branchItem = toCamel(item);
        return FileUtil.getUrlByLocalPath(AttachmentUtil.getAppIcon(branchItem.branchId));
      });

      Promise.all(iconPathPromises).then(iconList => {
        let branchesWithLogo = branches.map((item, index) => {
          const branchItem = toCamel(item);

          return { ...branchItem, logoPath: iconList[index] };
        });

        // Move selected branch to first array
        const selectedIndex = branchesWithLogo.findIndex(item => item.branchId === currentBranchId);
        if (selectedIndex !== 0) {
          branchesWithLogo.unshift(branchesWithLogo.splice(selectedIndex, 1)[0]);
        }

        setBranchList(branchesWithLogo);
      });
    }
  }, [currentBranchId]);

  return (
    <>
      <SettingTitle>{settingObjContent.appIcon}</SettingTitle>

      <Stack ref={ref} direction="row" flexWrap="nowrap" className={clsx("hidden-scrollbar", classes.logoList)}>
        {branchList.map(branchItem => (
          <AppIconItem
            key={branchItem.id}
            className={classes.logoItem}
            selectedBranchId={selectedBranchId}
            currentBranchId={currentBranchId}
            branchItem={branchItem}
            onClick={handleSelectLogoFunc(branchItem.branchId)}
          />
        ))}
      </Stack>
    </>
  );
};

SettingAppLogo.propTypes = {
  currentBranchId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(SettingAppLogo);

const AppIconItem = ({ selectedBranchId, currentBranchId, branchItem, ...otherProps }) => {
  const classes = useStyles();
  const { t: getHomeLabel } = useTranslation(LangConstant.NS_HOME);
  const settingObjContent = getHomeLabel("OBJ_SETTING_APP", {
    returnObjects: true,
  });

  const isUsingIcon = currentBranchId === branchItem.branchId;
  const isSelected = selectedBranchId === branchItem.branchId;

  return (
    <Box {...otherProps}>
      <Button
        variant="outlined"
        className={clsx(classes.logoImage, {
          [classes.notOutline]: isUsingIcon || !isSelected,
          [classes.hoverStyle]: !isUsingIcon,
        })}
      >
        <Badge
          badgeContent={<BadgeIcon isSelected={isSelected} isUsingIcon={isUsingIcon} />}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Box component="img" src={branchItem.logoPath} aria-label={`${branchItem.branchName} Logo`} />
        </Badge>
      </Button>

      <Typography
        component="p"
        variant="caption"
        color={isUsingIcon ? "inherit" : "transparent"}
        className="no-select-text"
      >
        {settingObjContent.selectedLogo}
      </Typography>
    </Box>
  );
};

const BadgeIcon = ({ isSelected, isUsingIcon }) => {
  switch (true) {
    case isUsingIcon:
      return <CheckCircle fontSize="12px" />;

    case isSelected:
      return <CheckBox fontSize="12px" />;

    default:
      return <Fragment />;
  }
};

const useStyles = makeStyles(theme => ({
  logoList: {
    width: "100%",
    overflowY: "hidden",
    overflowX: "auto",
    padding: theme.spacing(1, 3, 0, 3),
  },

  logoItem: {
    color: theme.palette.primary.main,
    textAlign: "center",

    "&:hover": {
      "& $hoverStyle": {
        border: `1px solid rgba(0, 143, 232, 0.5)`,
      },
    },
  },

  notOutline: {
    "&, &:hover": { borderColor: "transparent" },
  },

  hoverStyle: {
    // css is available when hover logoItem
  },

  logoImage: {
    padding: 4,
    margin: "0 16px",
    minWidth: 50,

    "& img": {
      maxWidth: "100%",
      height: 40,
      objectFit: "contain",
    },
  },
}));
