import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import { DrawerLayout } from "components";
import SelectingListContact from "../SelectingListContact";
import { useAccountList } from "hooks";

const AddingChannelMember = ({ onCreateChannel, ...otherProps }) => {
  const { accountList } = useAccountList();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const [isShow, setIsShow] = useState(false);

  const onCreateControlFuc = useCallback(open => () => setIsShow(open), []);

  return (
    <>
      <Button variant="contained" fullWidth size="large" onClick={onCreateControlFuc(true)} {...otherProps}>
        {chatObjContent.btnContinue}
      </Button>

      <DrawerLayout open={isShow} onClose={onCreateControlFuc(false)} title={chatObjContent.titleChanel}>
        <SelectingListContact
          data={accountList}
          onComplete={onCreateChannel}
          minimumLimit={SystemConstant.MINIMUM_SELECT_CONTACT}
        />
        {/*creating a channel need at lease 3 member including you */}
      </DrawerLayout>
    </>
  );
};

AddingChannelMember.propTypes = {
  onCreateChannel: PropTypes.func.isRequired,
};

AddingChannelMember.defaultProps = {};

export default AddingChannelMember;
