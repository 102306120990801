import React, { Fragment, memo, useState } from "react";
import clsx from "clsx";
import StringFormat from "string-format";
import { makeStyles } from "@mui/styles";
import { FormatConstant, KeyConstant, SystemConstant } from "const";
import { isJSONString, toCamel, uuid } from "utils";
import { Edit, MoreHoriz, TagFaces } from "@mui/icons-material";
import { FullEmojiMenu } from "components";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import EmojiMenu from "./EmojiMenu";
import { StorageUtil } from "utils";
import { saveMessageInQueue } from "../ConversationContext";
import ChatActionMenu from "./ChatActionMenu";
import { getInteractor } from "services/local.service";

const ChatAction = ({ message, isShow, onEditMessage }) => {
  const classes = useStyles();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isMine = message.senderId === accountId;

  const [reactionList, setReactionList] = useState([]);
  const [menuAnchor, setMenuAnchorEl] = useState();
  const [emojiAnchorEl, setEmojiAnchorEl] = useState();
  const [isShowFullEmoji, setIsShowFullEmoji] = useState(false);

  const handleShowAction = event => setMenuAnchorEl(event.currentTarget);

  const handleClickEditing = () => {
    setMenuAnchorEl();
    onEditMessage();
  };

  const handleShowShortenEmoji = event => setEmojiAnchorEl(event.target);

  const handleCloseEmoji = () => {
    setEmojiAnchorEl(null);
    setIsShowFullEmoji(false);
  };

  const handleShowFullEmoji = () => setIsShowFullEmoji(true);

  const handleSelectEmoji = value => {
    setEmojiAnchorEl(null);
    setIsShowFullEmoji(false);

    let newReactionList;
    let existedReaction = reactionList.filter(react => react.content === value && react.senderId === accountId);
    let currentAccountReactionIndex = reactionList.findIndex(reaction => reaction.senderId === accountId);

    if (existedReaction.length > 0) {
      newReactionList = reactionList.filter((react, index) => index !== currentAccountReactionIndex);
      setReactionList(newReactionList);
      saveMessageInQueue(SystemConstant.SEND_TYPE.deleteReaction, value, message.sourceId);
    } else {
      const currentAccount = toCamel(getInteractor().LocalAccountService.get(accountId));
      const newReaction = {
        id: uuid(),
        sourceId: uuid(),
        parentId: message.sourceId,
        threadId: null,
        sendType: SystemConstant.SEND_TYPE.reaction,
        accountId: accountId,
        content: value,
        mentions: null,
        senderId: accountId,
        groupId: message.groupId,
        created: Date.now(),
        accountName: currentAccount?.name || "No name",
        accountAvatarId: currentAccount?.avatarId || null,
      };

      if (currentAccountReactionIndex >= 0) {
        newReactionList = reactionList.map((react, index) =>
          index === currentAccountReactionIndex ? newReaction : react,
        );
      } else {
        newReactionList = [...reactionList, newReaction];
      }

      setReactionList(newReactionList);
      saveMessageInQueue(SystemConstant.SEND_TYPE.reaction, value, message.sourceId);
    }
  };

  if (!isShow) return <Fragment />;

  return (
    <>
      <Box
        className={clsx(classes.menuAndTimer, {
          [classes.menuAndTimerReceived]: !isMine,
          [classes.biggerMargin]: isJSONString(message.content),
        })}
      >
        <Stack direction={isMine ? "row" : "row-reverse"}>
          <IconButton
            className={classes.chatMenuButton}
            onClick={handleShowShortenEmoji}
            id={StringFormat(FormatConstant.FM_OPEN_EMOJI_BUTTON_ID, message.id)}
          >
            <TagFaces className={classes.chatMenuIcon} />
          </IconButton>

          <IconButton className={classes.chatMenuButton} onClick={handleShowAction}>
            <MoreHoriz className={classes.chatMenuIcon} />
          </IconButton>
        </Stack>

        <EmojiMenu
          open={Boolean(emojiAnchorEl) && !isShowFullEmoji}
          anchorEl={emojiAnchorEl}
          onClose={handleCloseEmoji}
          onOpenFullEmojiMenu={handleShowFullEmoji}
          onSelectEmoji={handleSelectEmoji}
        />

        <FullEmojiMenu
          open={Boolean(emojiAnchorEl) && isShowFullEmoji}
          onClose={handleCloseEmoji}
          anchorEl={emojiAnchorEl}
          onSelectEmoji={handleSelectEmoji}
        />

        <Stack direction={isMine ? "row" : "row-reverse"} className={classes.messageSentTimeWrapper}>
          <Typography component="span" className={clsx("medium-sm-txt", classes.messageSentTime)}>
            {message.sentTime}
          </Typography>
          {message.isEdited && <Edit className={classes.editedMessage} />}
        </Stack>
      </Box>

      <ChatActionMenu
        menuAnchor={menuAnchor}
        message={message}
        onEditMessage={handleClickEditing}
        onClose={() => setMenuAnchorEl()}
      />
    </>
  );
};

export default memo(ChatAction);

const useStyles = makeStyles(theme => ({
  messageSentTimeWrapper: {
    position: "absolute",
    top: -4,
    display: "flex",
    alignItems: "center",
  },

  messageSentTime: {
    color: "#666666",
    flexShrink: 0,
    marginLeft: 4,
    lineHeight: "16px",
  },

  editedMessage: {
    color: theme.palette.grey[200],
    width: 12,
    height: 16,
    marginLeft: 4,
  },

  menuAndTimer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    minWidth: 12,
    marginLeft: 10,
    marginRight: 10,
    position: "relative",
  },

  biggerMargin: {
    marginLeft: 30,
  },

  menuAndTimerReceived: {
    alignItems: "flex-start",
    bottom: 2,
  },

  chatMenuIcon: {
    width: 20,
    height: 20,
  },

  chatMenuButton: {
    width: 24,
    height: 24,
    minHeight: 10,
    minWidth: 10,
    padding: 4,
  },

  extraInfo: {
    position: "absolute",
    bottom: -10,
    left: 80,
    display: "flex",
    flexDirection: "row-reverse",
  },
}));
