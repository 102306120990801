import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import { Downloading, DownloadDone } from "@mui/icons-material";
import clsx from "clsx";
import { convertFileSizeUnit, downloadFile, getFileColor, getFirstLetter, StorageUtil } from "utils";
import { KeyConstant, LangConstant } from "const";
import { FilePreviewIcon } from "icons";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem.helper";
import { useMessageFile } from "hooks";

const FilePreview = ({ message }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const { mediaData } = useMessageFile(message);

  const [isAbleDownload, setIsAbleDownload] = useState(false);

  const handleDownloadFile = () => {
    if (isAbleDownload) {
      downloadFile(mediaData.fileName, mediaData.mediaPath, mediaData.mimeType);
    } else {
      alert(getLabel(LangConstant.TXT_FILE_NOT_FOUND));
    }
  };

  useEffect(() => {
    setIsAbleDownload(Boolean(mediaData && mediaData.mediaPath));
  }, [mediaData]);

  const isMine = message.senderId === accountId;
  const msgCreatedTime = getCreatedTimeText(message);
  const filePreviewIconColor = mediaData.fileName ? getFileColor(mediaData.fileName) : "";

  return (
    <Stack direction="row" spacing={2}>
      {!isMine && (
        <Avatar
          className={clsx(!message.isAvatar && messengerClasses.hiddenAvatar)}
          alt="chatting-avatar"
          src={message.avatar}
        >
          {getFirstLetter(message?.senderName)}
        </Avatar>
      )}

      <Box>
        {msgCreatedTime && <Typography className={messengerClasses.createdTimeText}>{msgCreatedTime}</Typography>}

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          className={clsx(
            messengerClasses.messengerRoot,
            messengerClasses.spacing,
            classes.contentContainer,
            isMine && messengerClasses.myMessage,
          )}
        >
          <Avatar className={classes.filePreviewIcon}>
            <FilePreviewIcon color={filePreviewIconColor} />
          </Avatar>

          <Box flex={1}>
            <Typography variant="subtitle2" color="inherit" className="ellipsis">
              {mediaData.fileName}
            </Typography>

            <Typography variant="caption" color="inherit" className="ellipsis">
              {convertFileSizeUnit(mediaData.size)}
            </Typography>
          </Box>

          <IconButton color={isMine ? "inherit" : "primary"} onClick={handleDownloadFile}>
            {isAbleDownload ? <DownloadDone /> : <Downloading />}
          </IconButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default memo(FilePreview);

const useStyles = makeStyles(theme => ({
  contentContainer: {
    borderRadius: 18,
    paddingTop: 8,
    paddingBottom: 8,
  },

  filePreviewIcon: {
    width: 48,
    height: 48,
    backgroundColor: theme.palette.white,
  },
}));
