export class Entity{
  public constructor(){

  }

   toJson() {
    // Note this.active will not be listed in keys since it's declared, but not defined
    return  JSON.parse(JSON.stringify(this));

  }
}