import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "const";
import { getCommonLang } from "utils/lang.utils";

import { ConversationSelectors } from "redux-store";

const UploadDocument = ({ file, onClose, onConfirm }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const { processStatus } = useSelector(ConversationSelectors.getUploadingAttachment);
  const isFailedUpload = AppConstant.PROCESS_STATUS.error === processStatus;

  return (
    <>
      <Typography className={"semiBold-lg-txt"}>{getLabel(LangConstant.TXT_SEND_FILE_CONFIRM_TEXT)}</Typography>
      <Typography className={clsx(classes.uploadConfirmText, "medium-md-txt")}>"{file?.name}"</Typography>

      {isFailedUpload && (
        <Typography className={clsx(classes.uploadFailed, "small-md-txt")}>
          {getLabel(LangConstant.TXT_UPLOAD_FAILED)}
        </Typography>
      )}

      <Box className={classes.actionContainer}>
        <Button className={classes.actionBtn} onClick={onClose}>
          {getCommonLang(LangConstant.TXT_CANCEL)}
        </Button>
        <Button onClick={() => onConfirm()} className={classes.actionBtn}>
          {getCommonLang(LangConstant.TXT_CONFIRM)}
        </Button>
      </Box>
    </>
  );
};

UploadDocument.propTypes = { file: PropTypes.any.isRequired, onConfirm: PropTypes.func.isRequired };

export default UploadDocument;

const useStyles = makeStyles({
  actionContainer: {
    display: "flex",
    marginTop: 16,
    width: "100%",
    justifyContent: "flex-end",
  },

  actionBtn: {
    textTransform: "none",
  },

  uploadConfirmText: {
    fontStyle: "italic",
    marginTop: 5,
  },

  uploadFailed: {
    color: "red",
    marginTop: 10,
  },
});
