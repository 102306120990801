import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Popover, Stack, Typography } from "@mui/material";
import { Article, Collections } from "@mui/icons-material";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { HandleUploadingInput } from "components";
import UploadFileDialog from "../UploadFileDialog";

const SendMessageFile = ({ anchorEl, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [uploadFile, setUploadFile] = useState(null);
  const [isMediaType, setIsMediaType] = useState(false);

  const handleChangeUploadType = isUploadMedia => () => setIsMediaType(isUploadMedia);

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      setUploadFile(file);
    }
    onClose();
  };

  useEffect(() => {
    if (anchorEl) {
      setUploadFile(null);
      setIsMediaType(false);
    }
  }, [anchorEl]);

  useEffect(() => {
    const handlePaste = event => {
      var items = event.clipboardData || event.originalEvent.clipboardData;
      let files = items?.files;

      Array.from(files).forEach(item => {
        if (item.type.split("/")[0] === "image") {
          setUploadFile(item);
        }
      });
    };
    window.addEventListener("paste", handlePaste);

    return () => window.removeEventListener("paste", handlePaste);
  }, []);

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={onClose}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        classes={{ paper: classes.wrapIconGird }}
      >
        <Stack direction="row" spacing={2}>
          <Box onClick={handleChangeUploadType(false)}>
            <HandleUploadingInput
              accept="*"
              className="hidden"
              id="icon-button-document"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="icon-button-document">
              <IconButton className={clsx(classes.wrapIcon)} component="span">
                <Article className={classes.iconPopup} />
              </IconButton>
              <Typography className={classes.textPopup} variant="body2">
                {getLabel(LangConstant.TXT_POPUP_DOCUMENT)}
              </Typography>
            </label>
          </Box>
          <Box onClick={handleChangeUploadType(true)}>
            <HandleUploadingInput
              accept={AppConstant.ACCEPTABLE_IMAGE_TYPES}
              className="hidden"
              id="icon-button-gallery"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="icon-button-gallery">
              <IconButton className={classes.wrapIconGallery} component="span">
                <Collections className={classes.iconPopup} />
              </IconButton>
              <Typography className={classes.textPopup} variant="body2">
                {getLabel(LangConstant.TXT_POPUP_GALLERY)}
              </Typography>
            </label>
          </Box>
        </Stack>
      </Popover>

      {Boolean(uploadFile) && (
        <UploadFileDialog isMediaType={isMediaType} file={uploadFile} onClose={() => setUploadFile(null)} />
      )}
    </>
  );
};

export default memo(SendMessageFile);

const useStyles = makeStyles(theme => ({
  iconPopup: {
    margin: 5,
    color: theme.palette.white,
  },

  textPopup: {
    color: "#1E272E",
  },

  wrapIcon: {
    backgroundColor: "#7F66FF",
  },

  wrapIconGallery: {
    backgroundColor: "#CA60F7",
  },

  wrapIconGird: {
    width: "fit-content",
    height: "fit-content",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#F1F3F6",
    padding: 25,
    borderRadius: 24,
  },
}));
