import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";

export const Processing = ({ isShow }) => {
  const classes = useStyles();

  return (
    <Backdrop open={isShow} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: theme.palette.white,
  },
}));
Processing.propTypes = { isShow: PropTypes.bool };
Processing.defaultProps = { isShow: false };

const SYSTEM_PROCESSING_TYPE = {
  none: "none",
  synchronize: "synchronize",
  switchBranch: "switch_branch",
};

export const SwitchBranchProcessing = () => {
  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);

  const [selectedBranch, setSelectedBranch] = useState({});
  const [processingType, setProcessingType] = useState(SYSTEM_PROCESSING_TYPE.none);

  // Handling when user switch branch
  useEffect(() => {
    const isChangingBranch = selectedServerRedux.id && selectedServerRedux.id !== selectedBranch.id;
    if (processingType === SYSTEM_PROCESSING_TYPE.none && isChangingBranch) {
      setSelectedBranch(selectedServerRedux);
      setProcessingType(SYSTEM_PROCESSING_TYPE.switchBranch);

      setTimeout(() => {
        setProcessingType(SYSTEM_PROCESSING_TYPE.none);
      }, 2000);
    }
  }, [selectedServerRedux]);

  return <Processing isShow={processingType !== SYSTEM_PROCESSING_TYPE.none} />;
};
