import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DrawerLayout } from "components";
import { getNSLang } from "utils/lang.utils";
import { LangConstant, SystemConstant } from "const";
import PropTypes from "prop-types";
import SelectingListContact from "../AddingChat/SelectingListContact";
import { useAccountList, useContactList } from "hooks";
import { GroupInfoActions } from "redux-store";
import { getSavedServer } from "utils/view.utils";

const AddingGroupMembers = ({ open, onClose, data }) => {
  const dispatch = useDispatch();
  const { contactList } = useContactList();
  const { accountList } = useAccountList();

  const [listAddGroupMember, setListAddGroupMember] = useState([]);

  const currentBranchId = getSavedServer().id;
  const isGlobal = currentBranchId === SystemConstant.GLOBAL_BRANCH_ID;

  const handleAddingMember = selectedContact => {
    const memberIds = selectedContact.map(item => {
      if (isGlobal) return item.contactId; // Global server: addMemberList is contactList => get contactId
      else return item.id; // Branch server: addMemberList is accountList => get id
    });
    dispatch(
      GroupInfoActions.addMemberGroup({
        groupId: data.id,
        memberIds: memberIds,
        memberInviteType: SystemConstant.ROLE_OBJ.member,
      }),
    );
    onClose();
  };

  useEffect(() => {
    const memberList = Array.isArray(data.groupMembers) ? data.groupMembers : [];
    const filterMemberGroup = memberList.map(item => item.id);
    let listAdd = [];
    if (isGlobal) {
      listAdd = contactList.filter(item => !filterMemberGroup.includes(item.contactId));
    } else {
      listAdd = accountList.filter(item => !filterMemberGroup.includes(item.id));
    }
    setListAddGroupMember(listAdd);
  }, [contactList, accountList]);

  return (
    <DrawerLayout
      open={open}
      onClose={onClose}
      anchor={"right"}
      hideBackdrop={true}
      title={getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_ADD_NEW_MEMBER)}
    >
      <SelectingListContact data={listAddGroupMember} onComplete={handleAddingMember} />
    </DrawerLayout>
  );
};

AddingGroupMembers.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,

  onClose: PropTypes.func,
};

AddingGroupMembers.defaultProps = {
  open: false,
  data: {},

  onClose: () => {},
};

export default AddingGroupMembers;
