import { useState, useCallback, useEffect } from "react";

const useDragScroll = () => {
  const [node, setNode] = useState();

  const ref = useCallback(nodeEle => {
    setNode(nodeEle);
  }, []);

  const handleMouseDown = useCallback(
    event => {
      if (!node) {
        return;
      }
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: event.clientX,
        y: event.clientY,
      };

      const handleMouseMove = event => {
        const dx = event.clientX - startPos.x;
        const dy = event.clientY - startPos.y;
        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
        updateCursor(node);
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        resetCursor(node);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [node],
  );

  const handleTouchStart = useCallback(
    event => {
      if (!node) {
        return;
      }
      const touch = event.touches[0];
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: touch.clientX,
        y: touch.clientY,
      };

      const handleTouchMove = event => {
        const touch = event.touches[0];
        const dx = touch.clientX - startPos.x;
        const dy = touch.clientY - startPos.y;
        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
        updateCursor(node);
      };

      const handleTouchEnd = () => {
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
        resetCursor(node);
      };

      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    },
    [node],
  );

  const updateCursor = element => {
    element.style.cursor = "grabbing";
    element.style.userSelect = "none";
  };

  const resetCursor = element => {
    element.style.cursor = "grab";
    element.style.removeProperty("user-select");
  };

  useEffect(() => {
    if (!node) {
      return;
    }
    node.addEventListener("mousedown", handleMouseDown);
    node.addEventListener("touchstart", handleTouchStart);
    return () => {
      node.removeEventListener("mousedown", handleMouseDown);
      node.removeEventListener("touchstart", handleTouchStart);
    };
  }, [node]);

  return [ref];
};

export default useDragScroll;
