import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import { RestoreActions } from "redux-store/restore.redux";
import { deviceFetching } from "./account-device.saga";
import { getInteractor } from "services/local.service";
import { AttachmentUtil, CryptoUtil, appendBuffer, toCamel, toSnake, uuid } from "utils";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { IGNORE_MESSAGE } from "./saga.helper";

export function* changeDeviceRole(action) {
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateDevice, { change_master_f: 1 });
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        RestoreActions.restoreSet({
          changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.success,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.fail,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    RestoreActions.restoreSet({
      changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.fail,
    });
  }
}

export function* verifyChangeDeviceRole(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).requestVerifyChangeDeviceRole, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.success,
        }),
      );

      yield deviceFetching(prefixKey);

      StorageUtil.setItem(KeyConstant.KEY_VERIFY_RETRIES, 0, prefixKey);
    } else if (response.status === ApiConstant.STT_FORBIDDEN) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.limitResend,
        }),
      );
    } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.wrongOtp,
        }),
      );
    } else if (response.status === ApiConstant.STT_OTP_EXPIRED) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.expiredOtp,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.systemError,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      RestoreActions.restoreSet({
        verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.systemError,
      }),
    );
  }
}

// Get file info
export function* restore(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).requestGetBackup, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      const backupInfo = toCamel(response.data);
      if (backupInfo && Object.keys(backupInfo).length > 0) {
        yield put(
          RestoreActions.restoreSet({
            backupInfo: backupInfo,
            restoreStatus: SystemConstant.RESTORE_STATUS.getFileInfo,
          }),
        );
      } else {
        yield put(
          RestoreActions.restoreSet({
            backupInfo: backupInfo,
            restoreStatus: SystemConstant.RESTORE_STATUS.noBackupFile,
          }),
        );
      }
    } else {
      throw new Error("An error occurred");
    }
  } catch (error) {
    console.log(error);
    yield put(
      RestoreActions.restoreSet({
        backupInfo: null,
        restoreStatus: SystemConstant.RESTORE_STATUS.error,
      }),
    );
  }
}

/**
 *
 * Check passCode
 * Get file
 * Decrypt data and migrate data
 */
export function* restoreToLocal(action) {
  try {
    const { passCode } = action.data;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);

    const {
      restoreRedux: { backupInfo },
    } = yield select();

    if (Boolean(backupInfo)) {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.inProgress,
        }),
      );

      let fileResponse = yield call(remoteApiFactory.getBranchApi(prefixKey).getAttachment, {
        attachment_id: backupInfo.attachmentId,
      });

      if (fileResponse.status === ApiConstant.STT_OK) {
        let decryptFilePath = "";
        try {
          const key0 = "" + passCode + accountId + backupInfo.createdTime;
          const key0Encrypt = yield CryptoUtil.encryptHmac256(key0);
          const keyEncryptFileInfo = yield CryptoUtil.decryptCBC(backupInfo.ckx, key0Encrypt);
          const keyInfoConvert = JSON.parse(keyEncryptFileInfo);

          const contentBuffer = new Uint8Array(fileResponse.data);
          const decryptFile = AttachmentUtil.getPrivateFilePath(
            backupInfo.attachmentId,
            backupInfo.attachmentId + ".txt",
          );
          decryptFilePath = yield CryptoUtil.decryptAES(
            contentBuffer,
            keyInfoConvert.key,
            keyInfoConvert.iv,
            keyInfoConvert.authtag,
            decryptFile,
          );
        } catch {
          throw new Error(AppConstant.PASSCODE_ERROR);
        }

        // read file restore and insert data to local db
        yield getInteractor(prefixKey).LocalRestoreService.restoreFromFile(decryptFilePath, deviceId);

        yield restoreThread(prefixKey, accountId);

        yield put(
          RestoreActions.restoreSet({
            restoreStatus: SystemConstant.RESTORE_STATUS.success,
          }),
        );
      } else {
        throw new Error();
      }
    }
  } catch (error) {
    console.log(error);
    if (error.message === AppConstant.PASSCODE_ERROR) {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.wrongPasscode,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.error,
        }),
      );
    }
  }
}

// Upload file backup to server
export function* backup(action) {
  try {
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const { passCode, createdTime } = action.data;

    yield put(
      RestoreActions.restoreSet({
        backupStatus: SystemConstant.RESTORE_STATUS.inProgress,
      }),
    );
    const file = yield getInteractor(prefixKey).LocalMessageService.getMessagesAndCallHistories(deviceId);

    if (file) {
      const fileMetadata = {
        file_name: file.name,
        size: file.size ? file.size + 2 : null,
        mime_type: file.type,
      };

      if (file.path) {
        const key = "" + passCode + accountId + createdTime;
        const keyEncrypt = yield CryptoUtil.encryptHmac256(key);

        const resultF = yield CryptoUtil.encryptAES(file.path);

        const aes_key_info = JSON.stringify({
          iv: resultF.iv,
          key: resultF.key,
          authtag: resultF.authtag,
        });

        const CKx = yield CryptoUtil.encryptCBC(aes_key_info, keyEncrypt);

        const attachmentId = uuid();

        const buffer = new ArrayBuffer(2);
        const fileContentBuffer = appendBuffer(resultF.encryptedData, buffer);

        let formData = new FormData();
        formData.append("file", new Blob([fileContentBuffer]));
        formData.append("metadata", JSON.stringify(fileMetadata));
        formData.append("attachment_id", attachmentId);

        const backUpFileInfo = {
          attachment_id: attachmentId,
          ckx: CKx,
          created_time: createdTime,
        };

        const responseUpload = yield call(remoteApiFactory.getBranchApi(prefixKey).requestBackupUpload, formData);
        if (responseUpload.status !== ApiConstant.STT_OK) {
          throw new Error();
        }

        const responseBackup = yield call(remoteApiFactory.getBranchApi(prefixKey).requestBackup, backUpFileInfo);
        if (responseBackup.status === ApiConstant.STT_FORBIDDEN) {
          throw new Error("FORBIDDEN");
        } else if (responseBackup.status !== ApiConstant.STT_OK) {
          throw new Error(responseBackup);
        }

        yield put(
          RestoreActions.restoreSet({
            fileSize: fileMetadata.size,
            backupStatus: SystemConstant.RESTORE_STATUS.success,
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
    if (error.message === "FORBIDDEN") {
      yield put(
        RestoreActions.restoreSet({
          backupStatus: SystemConstant.RESTORE_STATUS.losePermission,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          backupStatus: SystemConstant.RESTORE_STATUS.error,
        }),
      );
    }
  }
}

const restoreThread = async (prefixKey, accountId) => {
  const threadIds = await getInteractor(prefixKey).LocalMessageService.getThreadInfoFromMsg();

  let restoreThreads = [];
  for (let index = 0; index < threadIds.length; index++) {
    const threadId = threadIds[index].thread_id;

    const parentMessage = getInteractor(prefixKey).LocalMessageService.getMessageBySourceId(threadId)[0] || {};
    if (false === Boolean(parentMessage.id)) continue;
    const messagesInThread = getInteractor(prefixKey).LocalMessageService.getThreadMessage(threadId);
    const changeParentMsg = await getInteractor(prefixKey).LocalMessageService.getMessageWithParentId(threadId);
    const deleteMsgInThread = messagesInThread.filter(
      item => item.send_type === SystemConstant.SEND_TYPE.deleteMessage,
    );
    const normalMessagesInThread = messagesInThread.filter(
      item => item.parent_id === null && !IGNORE_MESSAGE.includes(item.send_type),
    );
    const lastMessage = messagesInThread[messagesInThread.length - 1] || {};
    const isMeReply =
      parentMessage.sender_id === accountId || messagesInThread.filter(item => item.sender_id === accountId).length > 0;

    const mentionArr = [parentMessage, ...messagesInThread].reduce((resultArr, item) => {
      if (Boolean(item.mentions)) {
        const mention = JSON.parse(item.mentions);
        return [...resultArr, ...mention];
      } else {
        return resultArr;
      }
    }, []);

    const isInvolved = isMeReply || mentionArr.includes(accountId);
    const totalReply = normalMessagesInThread.length - deleteMsgInThread.length;
    const isDeleteParent =
      changeParentMsg.filter(item => item.send_type === SystemConstant.SEND_TYPE.deleteMessage).length > 0;
    const isDelete = isDeleteParent || totalReply <= 0;

    const newThreadInfo = {
      id: uuid(),
      thread_id: threadId,
      total_reply: totalReply,
      total_unread: normalMessagesInThread.filter(item => item.status !== SystemConstant.MESSAGE_STATUS.read).length,
      involved_f: isInvolved ? SystemConstant.STATE.active : SystemConstant.STATE.inactive,
      thread_account_id: parentMessage.sender_id,
      thread_created: parentMessage.created,
      group_id: parentMessage.group_id,
      group_type: null,
      setting_type: null,
      thread_content: parentMessage.content, // TODO: original content of parentMessage
      thread_mentions: JSON.stringify(mentionArr),
      branch_id: parentMessage.branch_id,
      state: isDelete ? AppConstant.THREAD_STATE.inactive : AppConstant.THREAD_STATE.active,
      created: parentMessage.created,
      modified: lastMessage.created || 0,
    };

    restoreThreads.push(newThreadInfo);
  }

  await getInteractor(prefixKey).LocalThreadService.save(restoreThreads);
};
