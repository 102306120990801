import { AttachmentUtil, CryptoUtil, convertJSONObject, toCamel } from "utils";
import { remoteApiFactory } from "./remote.service";
import { ApiConstant } from "const";

const AttachmentQueue = {};
export const getAttachment = (data, prefixKey) => {
  if (!Object.keys(AttachmentQueue).includes(data.attachment_id)) {
    AttachmentQueue[data.attachment_id] = remoteApiFactory.getBranchApi(prefixKey).getAttachment(data);
  }
  return AttachmentQueue[data.attachment_id];
};

export const saveFileFromMessage = async (prefixKey, messages) => {
  const isValid = Array.isArray(messages) && messages.length > 0;
  if (!isValid) return true;

  const messageList = getNonExistentFiles(messages);

  if (Array.isArray(messageList) && messageList.length > 0) {
    const HANDLE_FILE_LIMIT = 10;
    let handlingMessageCount = 0;
    while (handlingMessageCount < messageList.length) {
      // Checking again if file is saved by other thread logic
      let handlingMessages = messageList.slice(handlingMessageCount, handlingMessageCount + HANDLE_FILE_LIMIT);
      handlingMessages = getNonExistentFiles(handlingMessages);
      handlingMessageCount += HANDLE_FILE_LIMIT;

      await Promise.all(
        handlingMessages.map(async message => {
          const fileObj = toCamel(convertJSONObject(message.content));
          const fileMetaData = fileObj.metaData || {};

          const responseData = await getAttachment({ attachment_id: fileObj.attachmentId }, prefixKey);
          if (responseData && responseData.status === ApiConstant.STT_OK && responseData.data) {
            const encryptedBytes = new Uint8Array(responseData.data);
            const decryptFile = AttachmentUtil.getPrivateFilePath(fileObj.attachmentId, fileMetaData.fileName);
            await CryptoUtil.decryptAES(
              encryptedBytes,
              fileObj.aesKeyInfo.key,
              fileObj.aesKeyInfo.iv,
              fileObj.aesKeyInfo.authtag,
              decryptFile,
            );
            return true;
          }

          return null;
        }),
      );
    }
  }
};

const getNonExistentFiles = messages =>
  toCamel(messages).filter(message => {
    const fileObj = toCamel(convertJSONObject(message.content));
    const fileMetaData = fileObj.metaData || {};
    const isExistFile = AttachmentUtil.exitsLocalFile(fileObj.attachmentId, fileMetaData.fileName);
    // Filter message dont save file at local
    return !isExistFile && fileObj.aesKeyInfo;
  });
