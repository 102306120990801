import { call, put } from "redux-saga/effects";
import { ApiConstant, KeyConstant } from "const";
import { toCamel, toSnake } from "utils";
import { ContactActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { getInteractor } from "../services/local.service";

export function* findContact(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

    const params = {
      paging: 0,
      asc: 1,
      offset: 0,
      limit: 1,
      sinceTime: 0,
      isCorrect: 1,
      ...data,
    };

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).getAccountList, toSnake(params));
    if (response.status === ApiConstant.STT_OK) {
      const findingAccount = Array.isArray(response.data?.data) ? response.data.data[0] : null;

      if (findingAccount && findingAccount.id) {
        const dataContact = yield getInteractor(prefixKey).LocalContactService.getContact(accountId, findingAccount.id);
        if (dataContact) {
          yield put(
            ContactActions.contactSuccess({
              existsInContactTable: toCamel({
                ...dataContact,
                phone: findingAccount.phone,
                avatar_id: findingAccount.avatar_id,
              }),
              idContactPhoneBook: dataContact.id,
            }),
          );
        } else {
          yield put(
            ContactActions.contactSuccess({
              notInContactTable: toCamel(findingAccount),
            }),
          );
        }
      } else {
        yield put(
          ContactActions.contactSuccess({
            noSearchResults: true,
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateContact(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateContact, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      const responseData = toCamel(response.data);
      getInteractor(prefixKey).LocalContactService.updateNameContact(responseData);
      yield put(
        ContactActions.contactSuccess({
          nameAfterChange: responseData.contactName,
          idContactPhoneBook: response.data.id,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addNewContact(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).addContact, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      getInteractor(prefixKey).LocalContactService.save([response.data]);
      yield put(
        ContactActions.contactSuccess({
          addNewContactResponse: toCamel(response.data),
          idContactPhoneBook: response.data.id,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* unfriendContact(action) {
  try {
    const { contactId } = action.data;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).deleteContact, contactId);
    if ([ApiConstant.STT_OK, ApiConstant.STT_NOT_FOUND].includes(response.status)) {
      const responseData = toCamel(response.data);

      getInteractor(prefixKey).LocalContactService.delete(responseData.id);
      yield put(
        ContactActions.contactSuccess({
          unfriendContactId: responseData.contactId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}
