import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import StringFormat from "string-format";
import { makeStyles } from "@mui/styles";
import { FormatConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { AttachmentUtil, convertString2JSON, isJSONString, isObjectNotEqual, toCamel, StorageUtil } from "utils";
import { isEqual } from "lodash";
import { formatSentTime } from "utils/date.utils";
import { getNSLang } from "utils/lang.utils";
import { notificationClear } from "utils/view.utils";
import { Replay, Error } from "@mui/icons-material";
import MessengerPeople from "./MessengerPeople";
import FilePreview from "./FilePreview";
import ImagePreview from "./ImagePreview";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import AudioChat from "./AudioChat";
import SeenMessage from "./SeenMessage";
import LineChat from "../LineChat";
import { getInteractor } from "services/local.service";
import { useConversationContext } from "../ConversationContext";
import ThreadFlow from "./ThreadFlow";
import ReactionFlow from "./ReactionFlow";
import ChatAction from "./ChatAction";

const ChatItem = ({ data, isThreadMode, isSearchMode, ...otherProps }) => {
  const classes = useStyles();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const isMine = data.senderId === accountId;
  const isSending = !Boolean(data.modified);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { groupDetail, clickMedia } = useConversationContext();
  const [isChannel, groupMembers] = useMemo(
    () => [groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel, groupDetail.groupMembers],
    [groupDetail],
  );

  const [message, setMessage] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reactionList, setReactionList] = useState([]);
  const [isShowSeenMembers, setIsShowSeenMembers] = useState(false);

  const onEnableEdit = () => setIsEditing(true);

  const onMouseEnter = () => setIsHovered(true);

  const onMouseLeave = () => setIsHovered(false);

  const onMediaClicked = useCallback(() => {
    if (isSending) return;

    clickMedia(message);
  }, [message, isSending]);

  const onResendMessage = event => {
    event.stopPropagation();
  };

  useEffect(() => {
    const isValid = Boolean(data && data.id);
    if (!isValid) return;

    let chattingMessage = { ...data };

    const childMessages = getInteractor().LocalMessageService.getChildMessages(chattingMessage.sourceId, [
      chattingMessage.sendType,
      SystemConstant.SEND_TYPE.groupMessage,
      SystemConstant.SEND_TYPE.message,
    ]);

    const hasEditedMessage = childMessages.length > 0;
    if (hasEditedMessage) {
      const editedMessage = childMessages[childMessages.length - 1];
      chattingMessage = {
        ...chattingMessage,
        mentions: editedMessage.mentions,
        content: editedMessage.content,
        created: editedMessage.created,
      };
    }

    const strMentionList =
      childMessages.length > 0 ? childMessages[childMessages.length - 1].mentions : chattingMessage.mentions;
    const mentionList = getMentionMembers(convertString2JSON(strMentionList, []));

    chattingMessage = {
      ...chattingMessage,
      sentTime: formatSentTime(chattingMessage.created),
      mentionList: mentionList,
      isEdited: hasEditedMessage,
    };

    // Sender data
    if (chattingMessage.senderId !== accountId) {
      const senderAccount = groupMembers.find(item => item.id === chattingMessage.senderId);
      if (senderAccount) {
        const avatarSrc = AttachmentUtil.getAvatarRemoteUrl(senderAccount.avatarId);
        chattingMessage = { ...chattingMessage, senderName: senderAccount.name, avatar: avatarSrc };
      } else {
        const sender = getInteractor().LocalAccountService.get(chattingMessage.senderId);
        chattingMessage = { ...chattingMessage, senderName: sender.name };
      }
    } else {
      // No display avatar/ name if owner message
      chattingMessage = { ...chattingMessage, senderName: "", avatar: "" };
    }

    // Handling reply message
    const [messageThreadTotal, unreadMsgThreadTotal] = countThreadMessage(chattingMessage.sourceId);
    chattingMessage = { ...chattingMessage, messageThreadTotal, unreadMsgThreadTotal };

    if (!isEqual(chattingMessage, message)) setMessage(chattingMessage);

    // Get all reaction of this message
    const msgReactionList = getReactionList(chattingMessage.sourceId);
    if (isObjectNotEqual(msgReactionList, reactionList)) setReactionList(msgReactionList);
  }, [data]);

  if (!Boolean(message.id && message.sourceId)) return <Fragment />;

  const isHavingExtraInfo = message.messageThreadTotal > 0 || reactionList.length > 0;
  const isMsgLeaveGroup = message.sendType === SystemConstant.SEND_TYPE.leaveGroup;
  const isSentByThisDevice = isMine && message.senderDeviceId === deviceId;
  const isSendFailed = !isSending && message.state === SystemConstant.STATE.inactive && isSentByThisDevice;

  let statusSendingText;
  if (!isSending && isSentByThisDevice && !isSendFailed && !isMsgLeaveGroup) {
    const messageOptions = convertString2JSON(message.options, null);
    statusSendingText = getLabel(
      messageOptions && messageOptions.seen_members?.length > 0
        ? LangConstant.TXT_SEEN
        : messageOptions && messageOptions.received_members?.length > 0
        ? LangConstant.TXT_RECEIVED
        : LangConstant.TXT_SENT,
    );
  }

  return (
    <>
      <Box
        className={clsx(
          classes.chatItemRoot,
          isMine && classes.chatItemRootFromMe,
          isHavingExtraInfo && classes.messageWithExtra,
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        id={StringFormat(FormatConstant.FM_CHAT_ITEM_ID, message.id)}
        {...otherProps}
      >
        <ChatAction
          isShow={isHovered && !isSearchMode && !isMsgLeaveGroup && !isEditing}
          message={message}
          onEditMessage={onEnableEdit}
        />

        <Stack
          direction="row"
          justifyContent="flex-end"
          className={classes.messageContent}
          onClick={() => setIsShowSeenMembers(!isShowSeenMembers)}
        >
          {isSendFailed && (
            <IconButton color="error" className={classes.chatMenuButton} onClick={onResendMessage}>
              <Replay />
            </IconButton>
          )}
          {[SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video].includes(message.sendType) &&
          isJSONString(message.content) ? (
            <ImagePreview message={message} isMine={isMine} onClick={onMediaClicked} />
          ) : message.sendType === SystemConstant.SEND_TYPE.file ? (
            <FilePreview message={message} />
          ) : message.sendType === SystemConstant.SEND_TYPE.audio ? (
            <AudioChat message={message} />
          ) : (
            message.sendType !== SystemConstant.SEND_TYPE.leaveGroup && (
              <MessengerPeople message={message} isMine={isMine} isEditing={isEditing} setIsEditing={setIsEditing} />
            )
          )}
        </Stack>

        {!isEditing && (
          <Stack direction="row" row={1} className={isMine ? classes.extraInfoMine : classes.extraInfo}>
            <ReactionFlow isShow={reactionList.length > 0} reactionList={reactionList} />

            <ThreadFlow
              isShow={message.messageThreadTotal > 0 && !isThreadMode}
              isMine={isMine}
              parentMessageId={message.sourceId}
              messageThreadTotal={message.messageThreadTotal}
              unreadMsgThreadTotal={message.unreadMsgThreadTotal}
            />
          </Stack>
        )}

        {isMsgLeaveGroup && <LeaveGroupMessage message={message} isChannel={isChannel} />}
      </Box>

      <SeenMessage data={message.seenMembers} isShow={isShowSeenMembers} />

      {isSending && <Typography className={classes.sentStatus}>{getLabel(LangConstant.TXT_SENDING)}</Typography>}

      {statusSendingText && (
        <Typography className={clsx(classes.sentStatus, !isHovered && classes.sentStatusHidden)}>
          {statusSendingText}
        </Typography>
      )}

      {isSendFailed && (
        <Typography className={clsx(classes.sentStatus, classes.failedStatus)}>
          {getLabel(LangConstant.TXT_SEND_FAILED)} <Error />
        </Typography>
      )}
    </>
  );
};

export default memo(ChatItem);

const getMentionMembers = ids => getInteractor().LocalAccountService.getAccountByIds(ids || []);

const getReactionList = parentMessageId => {
  try {
    const msgReactionList = toCamel(
      getInteractor().LocalMessageService.getChildMessages(parentMessageId, [
        SystemConstant.SEND_TYPE.reaction,
        SystemConstant.SEND_TYPE.deleteReaction,
      ]),
    ).sort((current, prev) => current.created - prev.created);
    const handleReactionObj = msgReactionList.reduce((newReactList, react) => {
      const account = react.senderId;
      newReactList[account] = (newReactList[account] || []).concat(react);

      return newReactList;
    }, {});
    const finalReactionArr = Object.values(handleReactionObj)
      .map(data => (data.length > 0 ? data[data.length - 1] : data[0]))
      .filter(react => react.sendType === SystemConstant.SEND_TYPE.reaction);
    return finalReactionArr;
  } catch (error) {
    console.log(error);
  }
  return [];
};

const countThreadMessage = parentMessageId => {
  try {
    const threadMessageArray = toCamel(getInteractor().LocalMessageService.getThreadMessage(parentMessageId)).filter(
      item =>
        !(
          Boolean(item.parentId) &&
          (item.sendType === SystemConstant.SEND_TYPE.message ||
            item.sendType === SystemConstant.SEND_TYPE.groupMessage)
        ) &&
        item.sendType !== SystemConstant.SEND_TYPE.reaction &&
        item.sendType !== SystemConstant.SEND_TYPE.deleteMessage &&
        getInteractor().LocalMessageService.getChildMessages(item.sourceId, SystemConstant.SEND_TYPE.deleteMessage)
          .length === 0,
    );

    if (threadMessageArray.length > 0) {
      const unreadThreadMsg = getInteractor().LocalMessageService.getUnreadInThread(parentMessageId);
      const allNotSeen = notificationClear(unreadThreadMsg).allNotSeen;

      return [threadMessageArray.length, Object.keys(allNotSeen).length];
    }
  } catch (error) {
    console.log(error);
  }

  return [0, 0];
};

const LeaveGroupMessage = ({ message, isChannel }) => {
  const [leaveGroupObj, setLeaveGroupObj] = useState({
    senderName: "",
    accountRemovedName: "",
  });

  useEffect(() => {
    if (message.sendType === SystemConstant.SEND_TYPE.leaveGroup) {
      const senderId = message.senderId;
      const removeAccountId = message.content;

      if (senderId && removeAccountId) {
        const sender = getInteractor().LocalAccountService.get(senderId);
        const removedAccount =
          senderId !== removeAccountId ? getInteractor().LocalAccountService.get(removeAccountId) : {};

        setLeaveGroupObj({
          senderName: sender.name,
          accountRemovedName: removedAccount.name,
        });
      }
    }
  }, [message]);

  return (
    <LineChat
      data={getNSLang(
        LangConstant.NS_HOME,
        message.senderId !== message.content
          ? LangConstant.FM_GROUP_REMOVE_MEMBER
          : isChannel
          ? LangConstant.FM_CHANNEL_NOTIFICATION
          : LangConstant.FM_GROUP_NOTIFICATION,
        {
          accountRemove: leaveGroupObj.accountRemovedName,
          senderName: leaveGroupObj.senderName,
          accountName: leaveGroupObj.senderName,
        },
      )}
    />
  );
};

const useStyles = makeStyles({
  chatItemRoot: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    paddingTop: 8,
    paddingBottom: 0,
  },

  chatItemRootFromMe: {
    flexDirection: "row",
  },

  messageContent: {
    maxWidth: "50%",
    cursor: "pointer",
  },

  chatMenuButton: {
    width: 24,
    height: 24,
    minHeight: 10,
    minWidth: 10,
    padding: 4,
  },

  extraInfo: {
    position: "absolute",
    bottom: -10,
    left: 80,
    display: "flex",
    flexDirection: "row-reverse",
  },

  extraInfoMine: {
    position: "absolute",
    bottom: -10,
    right: 30,
    display: "flex",
  },

  messageWithExtra: {
    marginBottom: 10,
  },

  sentStatus: {
    textAlign: "end",
    padding: "0 28px",
    fontSize: 9,
    fontWeight: 450,
    opacity: 1,
    transition: "opacity 0.2s ease-in-out",
  },

  sentStatusHidden: {
    opacity: 0,
  },

  failedStatus: {
    fontSize: 12,
    fontWeight: 400,
    color: "red",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "&>svg": {
      fontSize: 18,
      marginLeft: 2,
    },
  },
});
