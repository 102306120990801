import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { getPrefixKey, isArrayNotEquals, toCamel, uuid } from "utils";
import { BranchSelectors } from "redux-store";
import { BGlobalServerImage } from "theme/images";
import { StorageUtil } from "utils";
import { LocalDbManagement, getInteractor } from "services/local.service";
import { CommonBranchInfoService } from "services";

const useServerList = () => {
  const fetchBranchTimestamp = useSelector(BranchSelectors.getFetchBranchTimestamp);

  const [globalServer, setGlobalServer] = useState({}); // Global server
  const [branchList, setBranchList] = useState([]); // Private server

  const getServerList = async () => {
    let dbManagementList = toCamel(LocalDbManagement.getAllBranch());
    if (Array.isArray(dbManagementList) && dbManagementList?.length === 0) return;

    const globalServerDB = dbManagementList.find(item => item.branchId === SystemConstant.GLOBAL_BRANCH_ID);
    if (false === globalServerDB) {
      const globalServer = await getGlobalServer();
      dbManagementList.push(globalServer);
    }

    const activeOwnerList = dbManagementList
      .filter(item => item.state === SystemConstant.STATE.active)
      .map(item => item.ownerName);

    const activeBranchList = [];
    const inactiveBranchList = [];
    const suggestionBranchList = [];

    dbManagementList.forEach(item => {
      const isLoggedInBranch = item.accountId && item.branchId && item.state !== SystemConstant.STATE.suggested;
      if (isLoggedInBranch) {
        const prefixKey = getPrefixKey(item.accountId, item.branchId);
        const branchAccount = getInteractor(prefixKey).LocalBranchAccountService.getBranchAccount(
          item.accountId,
          item.branchId,
        );

        if (branchAccount && STATE_BRANCH_ACCOUNT_SHOW.includes(branchAccount.state)) {
          const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};
          if (item.state === SystemConstant.STATE.active) {
            activeBranchList.push(getBranchInfo(item, branchInfo));
          } else {
            inactiveBranchList.push(getBranchInfo(item, branchInfo));
          }
        }
      } else if (activeOwnerList.includes(item.ownerName)) {
        suggestionBranchList.push(getBranchInfo(item));
      }
    });

    const tmpBranchList = activeBranchList.concat(inactiveBranchList, suggestionBranchList);

    const globalServer = tmpBranchList.find(item => item.type === SystemConstant.SERVER_TYPE.server);
    const tmpBranchServerList = tmpBranchList.filter(item => item.type === SystemConstant.SERVER_TYPE.branch);

    setGlobalServer(globalServer || {});
    if (isArrayNotEquals(tmpBranchServerList, branchList)) {
      setBranchList(tmpBranchServerList);
    }
  };

  useEffect(() => {
    getServerList();
  }, [fetchBranchTimestamp]);

  return { globalServer, branchList };
};

export default useServerList;

const STATE_BRANCH_ACCOUNT_SHOW = [SystemConstant.BRANCH_STATE.active, SystemConstant.BRANCH_STATE.pending];

const getBranchInfo = (dbManagementInfo, storageInfo = {}) => {
  const avatarUrl = CommonBranchInfoService.getBranchAvatarUrl(
    dbManagementInfo.branchDomain,
    dbManagementInfo.branchId,
  );
  return {
    id: dbManagementInfo.branchId,
    accountId: dbManagementInfo.accountId,
    loginState: dbManagementInfo.state,
    phone: dbManagementInfo.ownerName,
    name: dbManagementInfo.branchName,
    domain: dbManagementInfo.branchDomain,
    type:
      dbManagementInfo.branchId === SystemConstant.GLOBAL_BRANCH_ID
        ? SystemConstant.SERVER_TYPE.server
        : SystemConstant.SERVER_TYPE.branch,
    ...storageInfo,
    branchIcon: avatarUrl || BGlobalServerImage,
  };
};

const getGlobalServer = async () => {
  const globalDomain = ApiConstant.BASE_URL.split("/")[2];
  const globalId = SystemConstant.GLOBAL_BRANCH_ID;
  const globalCommonInfo = (await CommonBranchInfoService.getBranchWithoutToken(globalDomain, globalId)) || {};

  const globalServer = {
    id: uuid(),
    account_id: StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID),
    branch_id: globalId,
    owner_name: StorageUtil.getItem(KeyConstant.KEY_PHONE),
    state: SystemConstant.STATE.suggested,
    options: null,
    token: null,
    branch_name: globalCommonInfo.name || AppConstant.GLOBAL_BRANCH_NAME,
    branch_domain: globalDomain,
    logo_app: globalCommonInfo.attachment_id || "",
    created: Date.now(),
    modified: Date.now(),
  };
  LocalDbManagement.save([globalServer]);
  return toCamel(globalServer);
};
