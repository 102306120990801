import React, { memo } from "react";
import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import { SystemConstant } from "const";
import AvatarChannel from "./AvatarChannel";
import AvatarConversation from "./AvatarConversation";

const AppAvatar = ({ group, src, ...otherProps }) => {
  const isPrivate = Boolean(group?.privateF);

  switch (true) {
    case group?.groupType === SystemConstant.GROUP_CHAT_TYPE.channel:
      return <AvatarChannel isPrivate={isPrivate} name={group?.name} {...otherProps} />;

    case Boolean(src && src.length > 0):
      return <Avatar sx={{ width: "100%", height: "100%" }} src={src} {...otherProps} />;

    default:
      break;
  }

  return <AvatarConversation memberArray={group?.groupMembers} {...otherProps} />;
};

AppAvatar.propTypes = {
  type: PropTypes.oneOf([
    SystemConstant.GROUP_CHAT_TYPE.personal,
    SystemConstant.GROUP_CHAT_TYPE.group,
    SystemConstant.GROUP_CHAT_TYPE.channel,
  ]),
  group: PropTypes.shape({
    groupType: PropTypes.number,
    groupName: PropTypes.string,
    groupMembers: PropTypes.array,
    privateF: PropTypes.number,
  }),
  ...AvatarChannel.propTypes,
  ...AvatarConversation.propTypes,
};
AppAvatar.defaultProps = {};

export default memo(AppAvatar);
