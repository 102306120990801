import { ApiConstant, AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { call, delay, put, select } from "redux-saga/effects";
import {
  compareObject,
  convertString2JSON,
  deepCloneJsonObject,
  isJSONString,
  isLoginBranch,
  isObjectNotEqual,
  removeDuplicateInArray,
  toCamel,
  uuid,
} from "utils";
import { LocalAppNotificationService, getInteractor } from "services/local.service";
import {
  formatPagingParams,
  ARR_NOTICE_NORMAL,
  ARR_NOTICE_SPECIAL,
  checkCurrentBranchByPrefix,
  DISPLAY_MSG_TYPES,
} from "./saga.helper";
import { deviceFetching } from "./account-device.saga";
import { getLabel } from "language";
import { updateMessageStatus } from "./conversation-message.saga";
import { getNSLang } from "utils/lang.utils";
import { ErrorMessageRetry } from "data/ErrorMessageRetry";
import { ErrorMessageSendNull } from "data/ErrorMessageSendNull";
import {
  CallingActions,
  ConversationActions,
  ConversationSelectors,
  GroupInfoActions,
  SystemActions,
  SystemSelectors,
} from "redux-store";
import { saveKeysOfDevice } from "./account-key.saga";
import { getBranches } from "./branch.saga";
import { updateThread } from "./thread.saga";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { replaceId2Name } from "utils/view.utils";
import { synchGroupMember } from "./synchronize.saga";
import { saveFileFromMessage } from "services/attachment.service";

const RETRY_AFTER_5_MINUTE = 5 * 60000;
export const DELAY_SYNCH_MSG_AFTER_5_SECOND = 5000;

export function* synchMessage(action) {
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  let isSynchronizing = yield select(SystemSelectors.isSystemSynchronizing);
  let isLogin = isLoginBranch();

  // Pending util isSynchronizing = false
  while (!isLogin || isSynchronizing || window.isStopSynchronize) {
    yield delay(AppConstant.DEBOUNCE_TIME);
    isSynchronizing = yield select(SystemSelectors.isSystemSynchronizing);
    isLogin = isLoginBranch();
  }

  try {
    yield call(getLastMessages, prefixKey);
    yield put(CallingActions.getCallHistory(prefixKey));
  } catch (error) {
    console.error(error);
  }

  yield delay(DELAY_SYNCH_MSG_AFTER_5_SECOND);
  yield call(synchMessage);
}

export function* getLastMessages(prefixKey) {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
  if (false === Boolean(prefixKey || accountId || deviceId)) return;

  const {
    LocalAccountGroupService,
    LocalAccountService,
    LocalContactService,
    LocalDeviceService,
    LocalGroupService,
    LocalGroupSettingService,
    LocalMessageService,
    LocalMsgErrorSendNullService,
    LocalNotificationService,
    LocalCipherService,
  } = getInteractor(prefixKey);

  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  if (isCurrentBranch) {
    yield put(
      SystemActions.systemSet({
        isSynchMessage: true,
      }),
    );
  }
  let isNeedUpdateUI = false;

  const selectedGroupId = yield select(ConversationSelectors.getSelectedGroupId);

  try {
    const lastMessage = LocalMessageService.getLastMessageByDevice(deviceId);
    const time2FetchMessage = lastMessage ? lastMessage.created + 1 : 0;

    const params = {
      ...formatPagingParams({
        sinceTime: time2FetchMessage,
        isOnline: true,
      }),
    };

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).getMessageList, params);
    const data = Array.isArray(response.data?.data) ? response.data.data : [];
    let notificationArr = Array.isArray(response.data?.notifications) ? response.data.notifications : [];

    if (response.status === ApiConstant.STT_OK && (data.length > 0 || notificationArr.length > 0)) {
      const responseData = data.sort(function (a, b) {
        if (a.created < b.created) return -1;
        if (a.created > b.created) return 1;
        return 0;
      });

      notificationArr = notificationArr.map(item => ({
        ...item,
        from_group_id: null,
      }));
      let deviceIds = notificationArr
        .filter(item => item.type !== SystemConstant.NotifyType.SMARTOTP)
        .reduce(function (map, obj) {
          map.push({ deviceId: obj.from_device_id, accountId: obj.from_account_id });
          return map;
        }, []);
      for (let noticeIndex = 0; noticeIndex < notificationArr.length; noticeIndex++) {
        const notification = notificationArr[noticeIndex];
        const localNotification = yield LocalNotificationService.get(notification.id);

        if (
          !localNotification ||
          localNotification.modified < notification.modified ||
          localNotification.status < notification.status
        ) {
          if (notification.type === SystemConstant.NotifyType.DEVICE_UPDATE) {
            const ownerId = notification.from_account_id;
            const deviceId = notification.from_device_id;

            let currentDevice = LocalDeviceService.get(deviceId);
            if (!currentDevice) {
              yield deviceFetching(prefixKey, ownerId, deviceId);
              currentDevice = LocalDeviceService.get(deviceId);
            }
            if (currentDevice) {
              yield saveKeysOfDevice(prefixKey, {
                accountId: ownerId,
                deviceId,
              });
            }
          } else if (notification.type === SystemConstant.NotifyType.GROUP_UPDATE) {
            if (notification.content) {
              yield synchGroupMember(prefixKey, [notification.content]);
            }

            const createdMessage = yield select(state => state.callingRedux.createdMessage);
            const options = notification.options;
            if (options && options !== "") {
              const optionsJson = JSON.parse(options);
              if (optionsJson && optionsJson.is_remove && optionsJson.is_remove === accountId) {
                const accountGroups = LocalAccountGroupService.findByGroupId(notification.content);
                const accountGroup = accountGroups.find(accountItem => accountItem.account_id === accountId);
                if (Boolean(accountGroup)) {
                  accountGroup.state = SystemConstant.STATE.inactive;
                  yield LocalAccountGroupService.save([{ ...accountGroup }]);

                  const currentGroupId = yield select(ConversationSelectors.getSelectedGroupId);
                  if (currentGroupId === notification.content) {
                    yield put(
                      ConversationActions.setSelectGroupId({
                        threadingId: null,
                        selectedGroupId: null,
                      }),
                    );
                  }
                }

                if (Boolean(createdMessage) && notification.content === createdMessage.groupId) {
                  yield put(
                    CallingActions.callingSet({
                      removedFromGroup: notification.content,
                    }),
                  );
                }
              }

              if (Boolean(createdMessage) && optionsJson.groupId === createdMessage.groupId) {
                if (Boolean(optionsJson.is_leave_call_f)) {
                  const leaveAccountId = optionsJson.account_id;
                  yield put(
                    CallingActions.callingSet({
                      memberChange: {
                        time: Date.now(),
                        accountId: leaveAccountId,
                      },
                    }),
                  );
                }

                if (Boolean(optionsJson.end_call_f)) {
                  yield put(
                    CallingActions.callingSet({
                      isNotifyEnded: {
                        groupId: optionsJson.group_id,
                        roomId: optionsJson.room_id,
                      },
                    }),
                  );
                }
              }
            }

            if (isCurrentBranch) {
              yield put(
                GroupInfoActions.groupInfoSet({
                  updatingGroupData: {
                    modified: new Date().getTime(),
                    id: notification.content,
                  },
                }),
              );
            }
          } else if (notification.type === SystemConstant.NotifyType.KEY_UPDATE) {
            const accountId = notification.from_account_id;
            const deviceId = notification.from_device_id;
            //TODO remove refactor duplicate code
            let currentDevice = LocalDeviceService.get(deviceId);
            if (!currentDevice) {
              yield deviceFetching(prefixKey, accountId, deviceId);
              currentDevice = LocalDeviceService.get(deviceId);
            }
            yield saveKeysOfDevice(prefixKey, {
              accountId,
              deviceId,
            });
          } else if (
            notification.type === SystemConstant.NotifyType.SMARTOTP &&
            notification.status !== SystemConstant.NOTIFICATION_STATUS.read
          ) {
            LocalAppNotificationService.showNotification(getLabel(LangConstant.TXT_APP_NAME), {
              content: `Smart-OTP: ${notification.content}`,
              prefixKey,
            });
          } else if (
            isCurrentBranch &&
            notification.type === SystemConstant.NotifyType.INVITE &&
            notification.status !== SystemConstant.NOTIFICATION_STATUS.read
          ) {
            yield put(
              SystemActions.systemSet({
                newNotification: toCamel(notification),
              }),
            );
          } else if (
            notification.type === SystemConstant.NotifyType.OWNER_NOTIFICATION &&
            notification.status !== SystemConstant.NOTIFICATION_STATUS.read
          ) {
            LocalAppNotificationService.showNotification(getLabel(LangConstant.TXT_APP_NAME), {
              content: `Thông báo: ${notification.content}`,
              prefixKey,
            });
          } else if (notification.type === SystemConstant.NotifyType.USER_CHANGE) {
            yield getBranches(prefixKey);
          }
        }

        if (isObjectNotEqual(localNotification, notification)) {
          yield LocalNotificationService.save([notification]);
        }
      }

      let fetchSuccess = false;
      const newMsgSourceIds = [];
      const normalMessageIds = responseData.map(msg => msg.source_id);
      const localMesArrIds = LocalMessageService.getMessageBySourceId(normalMessageIds).map(msg => msg.source_id);
      data.forEach(d => {
        deviceIds.push({ deviceId: d.sender_device_id, accountId: d.sender_id });
      });
      deviceIds = removeDuplicateInArray(deviceIds);
      for (let deviceIndex = 0; deviceIndex < deviceIds.length; deviceIndex++) {
        const deviceFrom = deviceIds[deviceIndex];
        let deviceLocal = LocalDeviceService.get(deviceFrom.deviceId);
        const deviceLocalByMac = LocalDeviceService.getDeviceByMac(deviceFrom.deviceId);
        if (deviceLocalByMac && !deviceLocal) deviceLocal = deviceLocalByMac;
        if (!deviceLocal) {
          yield deviceFetching(prefixKey, deviceFrom.accountId, deviceFrom.deviceId);
          deviceLocal = LocalDeviceService.get(deviceFrom.deviceId);
        }

        if (deviceLocal && deviceLocal.key_f !== SystemConstant.DEVICE_KEY_STATE.correct) {
          yield saveKeysOfDevice(prefixKey, {
            accountId: deviceLocal.account_id,
            deviceId: deviceLocal.id,
          });
        }
      }
      for (let mesIndex = 0; mesIndex < responseData.length; mesIndex++) {
        const mes = responseData[mesIndex];
        const local = yield LocalMessageService.get(mes.id);
        if (mes.send_type === SystemConstant.SEND_TYPE.restoreData) {
          continue;
        }

        let isNotify = true;

        if (mes.parent_id && !mes.thread_id) {
          const sourceId = mes.parent_id;
          const sourceMessage = LocalMessageService.getMessageBySourceId(sourceId)[0] || {};
          if (
            sourceMessage &&
            Object.keys(sourceMessage).length !== 0 &&
            mes.send_type !== SystemConstant.SEND_TYPE.deleteMessage
          )
            isNotify = true;
          else isNotify = false;
        }

        let group = LocalGroupService.get(mes.group_id);
        if (!group.id) {
          try {
            const groupResponse = yield call(
              remoteApiFactory.getBranchApi(prefixKey).getConversationList,
              formatPagingParams({ groupId: mes.group_id }),
            );
            if (groupResponse.status === ApiConstant.STT_OK) {
              const groupList = groupResponse.data.data;
              yield LocalGroupService.save(groupList);
              yield synchGroupMember(
                prefixKey,
                groupList.map(item => item.id),
              );
            }
            group = LocalGroupService.get(mes.group_id);
          } catch (error) {
            console.log(error);
            return;
          }
        }
        const groupType = group?.group_type;

        const parentMessage = yield select(state => state.callingRedux.createdMessage);
        const oldOffers = yield select(state => state.callingRedux.offerMessage);
        const missingConnection = yield select(state => state.callingRedux.missingConnection);
        const acceptWithId = yield select(state => state.callingRedux.acceptWithId);

        if (
          !local &&
          (mes.send_type === SystemConstant.SEND_TYPE.senderKeyDeliveryError ||
            mes.send_type === SystemConstant.SEND_TYPE.keyError)
        ) {
          yield LocalCipherService.deleteSession(mes.sender_id, mes.sender_device_id, mes.group_id);
          let decryptE2EMsg = null;
          try {
            decryptE2EMsg = yield LocalCipherService.decryptE2EMessage(
              mes.sender_id,
              mes.sender_device_id,
              mes.group_id,
              mes.content,
            );
          } catch (e) {
            console.log(e);
          }
          if (mes.send_type === SystemConstant.SEND_TYPE.keyError) {
            try {
              if (decryptE2EMsg != null && group) {
                if (groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
                  yield catchResend(
                    prefixKey,
                    mes.sender_id,
                    mes.sender_device_id,
                    mes.source_id,
                    mes.branch_id,
                    groupType,
                    mes.group_id,
                  );
                } else {
                  yield sendOneMessageSenderKeyDistributionMessage(prefixKey, group, mes);
                  yield catchResend(
                    prefixKey,
                    mes.sender_id,
                    mes.sender_device_id,
                    mes.source_id,
                    mes.branch_id,
                    groupType,
                    mes.group_id,
                  );
                }
              } else {
                console.log(
                  "MESSAGE HANDLER ERR1 " +
                    " group_id: " +
                    mes.group_id +
                    " send_type " +
                    mes.send_type +
                    "sender " +
                    mes.sender_device_id +
                    " res " +
                    mes.device_id,
                );
              }
            } catch (e) {
              console.log(e);
              console.log(
                "MESSAGE HANDLER ERR2 " +
                  " group_id: " +
                  mes.group_id +
                  " send_type " +
                  mes.send_type +
                  "sender " +
                  mes.sender_device_id +
                  " res " +
                  mes.device_id,
              );
            }
          }
          if (mes.send_type === SystemConstant.SEND_TYPE.senderKeyDeliveryError && group) {
            if (groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
              yield catchSendErrorMessage(
                prefixKey,
                mes.sender_id,
                mes.sender_device_id,
                mes.source_id,
                mes.branch_id,
                group,
                SystemConstant.SEND_TYPE.keyError,
              );
            } else {
              yield sendOneMessageSenderKeyDistributionMessage(prefixKey, group, mes);
            }
          }

          isNeedUpdateUI = yield LocalMessageService.saveFromRemote([mes]) || isNeedUpdateUI;
        } else if (!local && mes.send_type === SystemConstant.SEND_TYPE.senderKey) {
          const isStored = yield LocalCipherService.storeDistributionKey(
            mes.sender_id,
            mes.sender_device_id,
            mes.group_id,
            mes.content,
          );
          if (!isStored) {
            const checkError = LocalMsgErrorSendNullService.findByDeviceIdAndGroupIdAndType(
              mes.sender_device_id,
              mes.group_id,
              0,
            );
            const timeRetry = new Date().getTime() - RETRY_AFTER_5_MINUTE;
            const isError =
              checkError == null ||
              (checkError.created < timeRetry && !checkError.modified) ||
              (checkError.modified != null && checkError.modified !== 0 && checkError.modified < timeRetry);

            const sourceMessage = LocalMessageService.getMessageBySourceId(mes.source_id)[0];
            if (isError && group && (!sourceMessage || sourceMessage.send_type !== mes.send_type)) {
              yield LocalCipherService.deleteSession(mes.sender_id, mes.sender_device_id, mes.group_id);
              if (groupType !== SystemConstant.GROUP_CHAT_TYPE.personal)
                yield sendOneMessageSenderKeyDistributionMessage(prefixKey, group, mes);
              yield catchSendErrorMessage(
                prefixKey,
                mes.sender_id,
                mes.sender_device_id,
                mes.source_id,
                mes.branch_id,
                group,
                SystemConstant.SEND_TYPE.senderKeyDeliveryError,
              );
            }
          }
          isNeedUpdateUI = yield LocalMessageService.saveFromRemote([mes]) || isNeedUpdateUI;
        } else {
          try {
            let checkSenderIdBlock = LocalContactService.getContact(mes.account_id, mes.sender_id);
            if (mes.account_id === mes.sender_id) {
              checkSenderIdBlock = null;
            }
            const device = LocalDeviceService.get(mes.sender_device_id);
            let options = null;
            if (device && device.options && isJSONString(device.options)) {
              options = JSON.parse(device.options);
            }
            const encryption_type = options?.encryption_type || SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION;
            if (local) {
              const clone = deepCloneJsonObject(mes);
              clone.content = local.content;

              // TODO: hotfix performance render MessengerChat
              const { created: cloneCreated, state: incomeState, ...incomeMessage } = mes;
              const { created: localCreated, state: localState, ...localMessage } = local;
              const isDiffContent = false === compareObject(incomeMessage, localMessage);

              if (isDiffContent) {
                fetchSuccess = true;
                const newMessage = {
                  ...clone,
                  send_type: local.send_type,
                  content: local.content,
                  created: localCreated,
                  state: localState,
                };
                const isNewRemoteMessage = yield LocalMessageService.saveFromRemote([newMessage]);
                isNeedUpdateUI = isNewRemoteMessage || isNeedUpdateUI;

                if (
                  isCurrentBranch &&
                  isNewRemoteMessage &&
                  selectedGroupId === newMessage.group_id &&
                  DISPLAY_MSG_TYPES.includes(newMessage.send_type) &&
                  local.state !== SystemConstant.STATE.inactive
                ) {
                  yield put(ConversationActions.setNewRemoteMessage(toCamel(newMessage)));
                }
              }
            } else {
              try {
                //TODO refactor duplicate code
                let device = LocalDeviceService.get(mes.sender_device_id);
                if (!device) {
                  yield deviceFetching(prefixKey, mes.sender_id, mes.sender_device_id);
                  device = LocalDeviceService.get(mes.sender_device_id);
                }
                if (device?.key_f !== SystemConstant.DEVICE_KEY_STATE.correct) {
                  yield saveKeysOfDevice(prefixKey, {
                    accountId: mes.sender_id,
                    deviceId: mes.sender_device_id,
                  });
                }
              } catch (e) {
                console.log(e);
              }
              fetchSuccess = true;
              if (checkSenderIdBlock && checkSenderIdBlock.status === SystemConstant.CONTACT_STATUS.block) {
                mes.status = 4;
              }
              let resDecrypt = null;
              if (encryption_type === SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION) {
                if (groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
                  resDecrypt = yield LocalCipherService.decryptE2EMessage(
                    mes.sender_id,
                    mes.sender_device_id,
                    mes.group_id,
                    mes.content,
                  );
                  // const res = mes.content;
                } else {
                  // const res = mes.content;
                  resDecrypt = yield LocalCipherService.decryptE2EEMessage(
                    mes.sender_id,
                    mes.sender_device_id,
                    mes.group_id,
                    mes.content,
                  );
                }
              } else {
                resDecrypt = mes.content;
              }

              if (resDecrypt === null) {
                const checkError = LocalMsgErrorSendNullService.findByDeviceIdAndGroupIdAndType(
                  mes.sender_device_id,
                  mes.group_id,
                  0,
                );
                const timeRetry = new Date().getTime() - RETRY_AFTER_5_MINUTE;
                const isError =
                  checkError == null ||
                  (checkError.created < timeRetry && !checkError.modified) ||
                  (checkError.modified != null && checkError.modified !== 0 && checkError.modified < timeRetry);
                const sourceMessage = LocalMessageService.getMessageBySourceId(mes.source_id)[0];

                if (isError && group && (!sourceMessage || sourceMessage.send_type !== mes.send_type)) {
                  yield LocalCipherService.deleteSession(mes.sender_id, mes.sender_device_id, mes.group_id);
                  if (groupType !== SystemConstant.GROUP_CHAT_TYPE.personal)
                    yield sendOneMessageSenderKeyDistributionMessage(prefixKey, group, mes);
                  yield catchSendErrorMessage(
                    prefixKey,
                    mes.sender_id,
                    mes.sender_device_id,
                    mes.source_id,
                    mes.branch_id,
                    group,
                    SystemConstant.SEND_TYPE.keyError,
                  );
                }
                mes.send_type = SystemConstant.SEND_TYPE.keyError;
                isNeedUpdateUI = yield LocalMessageService.saveFromRemote([mes]) || isNeedUpdateUI;
              } else {
                mes.content = resDecrypt;
                if (selectedGroupId === mes.group_id && DISPLAY_MSG_TYPES.includes(mes.send_type)) {
                  if (isCurrentBranch && isNeedUpdateUI) {
                    yield put(ConversationActions.setNewRemoteMessage(toCamel(mes)));
                  }
                }

                if (
                  selectedGroupId !== mes.group_id &&
                  mes.sender_id !== accountId &&
                  SystemConstant.ARR_CALLING_TYPES.includes(mes.send_type) &&
                  mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.missed &&
                  isNotify
                ) {
                  // Check if is missing call message then create a new push notification
                  // Get group members
                  const senderName = LocalAccountService.getAccountName(mes.sender_id);

                  LocalAppNotificationService.showNotification(senderName, {
                    content: getNSLang(LangConstant.NS_CALLING, LangConstant.FM_MISSED_CALL, {
                      first: "Bạn",
                      second: senderName,
                    }),
                    groupId: mes.group_id,
                    prefixKey,
                  });
                }
                const checkError = LocalMsgErrorSendNullService.findByDeviceIdAndGroupIdAndType(
                  mes.sender_device_id,
                  mes.group_id,
                  0,
                );
                // Nếu hợp lệ xoá nó đi
                if (checkError != null)
                  LocalMsgErrorSendNullService.deleteByGroupIdAndDeviceIdAndType(mes.group_id, mes.sender_device_id, 0);

                if (newMsgSourceIds.includes(mes.source_id) || localMesArrIds.includes(mes.source_id)) {
                  mes.send_type = SystemConstant.SEND_TYPE.keyError;
                } else {
                  newMsgSourceIds.push(mes.source_id);
                }
                isNeedUpdateUI = yield LocalMessageService.saveFromRemote([mes]) || isNeedUpdateUI;

                if (mes.thread_id && !local) {
                  isNotify = yield updateThread(prefixKey, { ...mes }, isNotify);
                }
              }

              console.log("new message", mes);
              if (false === LocalGroupSettingService.isMutedNotify(mes.group_id)) {
                const branchId = mes.branch_id;
                if (
                  !(
                    branchId === SystemConstant.GLOBAL_BRANCH_ID &&
                    checkSenderIdBlock &&
                    checkSenderIdBlock.status === SystemConstant.CONTACT_STATUS.block
                  )
                ) {
                  // Get group detail
                  let callingGroupDetail = yield LocalGroupService.get(mes.group_id);
                  const isPersonal = callingGroupDetail.group_type === SystemConstant.GROUP_CHAT_TYPE.personal;
                  let groupName = "";
                  const accountGroupList = LocalAccountGroupService.findByGroupId(group.id);
                  const filteredArray = toCamel(accountGroupList)
                    .filter(s => s.state !== SystemConstant.STATE.inactive)
                    .map(r => r.accountId);

                  // Get group members
                  const groupMembers = yield LocalAccountService.getAccountByIds(filteredArray);

                  // User/group is not blocked or has notification turned off_
                  //_so continue to process notification of all kinds
                  if (mes && SystemConstant.ARR_CALLING_TYPES.includes(mes.send_type)) {
                    // Handle Do not open notification window when sync message
                    const messageContent = convertString2JSON(mes.content);

                    if (mes.parent_id) {
                      yield put(CallingActions.getCallHistory(prefixKey));
                      // Handle child message and dispatch respective events
                      const ARR_CALLING_GROUP = [
                        SystemConstant.SEND_TYPE.groupVideoCall,
                        SystemConstant.SEND_TYPE.conference,
                        SystemConstant.SEND_TYPE.groupCall,
                      ];
                      const ARR_CALLING_PERSONAL = [
                        SystemConstant.SEND_TYPE.personalCall,
                        SystemConstant.SEND_TYPE.personalVideoCall,
                      ];
                      const ARR_CALLING_VIDEO = [
                        SystemConstant.SEND_TYPE.groupVideoCall,
                        SystemConstant.SEND_TYPE.conference,
                        SystemConstant.SEND_TYPE.personalVideoCall,
                      ];

                      const isGroupCall = ARR_CALLING_GROUP.includes(mes.send_type);
                      const isPersonalCall = ARR_CALLING_PERSONAL.includes(mes.send_type);
                      const isVideoCall =
                        ARR_CALLING_VIDEO.includes(parentMessage[mes.group_id]?.sendType) ||
                        ARR_CALLING_VIDEO.includes(mes.send_type);

                      if (
                        parentMessage &&
                        parentMessage[mes.group_id] &&
                        (parentMessage[mes.group_id].parentId === mes.parent_id ||
                          parentMessage[mes.group_id].sourceId === mes.parent_id)
                      ) {
                        yield put(
                          CallingActions.callingSet({
                            isVideoCall: isVideoCall,
                          }),
                        );

                        if (mes.send_type === SystemConstant.SEND_TYPE.reconnect) {
                          console.log("reconnecting");

                          // parentMessage always be in camel format
                          if (
                            (messageContent.send_to_account_id === accountId &&
                              parentMessage[mes.group_id].sendType === SystemConstant.SEND_TYPE.groupVideoCall) ||
                            parentMessage[mes.group_id].sendType === SystemConstant.SEND_TYPE.groupCall ||
                            parentMessage[mes.group_id].sendType === SystemConstant.SEND_TYPE.conference
                          ) {
                            if (Boolean(messageContent.type === "offer")) {
                              yield put(
                                CallingActions.callingSet({
                                  offerMessage: {
                                    ...oldOffers,
                                    [mes.sender_id]: mes,
                                  },
                                  offerMes: mes,
                                  reconnectMessage: toCamel(mes),
                                  memberChange: {
                                    time: Date.now(),
                                    accountId: mes.sender_id,
                                  },
                                }),
                              );
                            }
                          } else if (
                            parentMessage[mes.group_id].sendType === SystemConstant.SEND_TYPE.personalCall ||
                            parentMessage[mes.group_id].sendType === SystemConstant.SEND_TYPE.personalVideoCall
                          ) {
                            yield put(
                              CallingActions.callingSet({
                                reconnectMessage: toCamel(mes),
                                acceptMessage: messageContent.type === "answer" ? toCamel(mes) : null,
                                personalRequestMessage: messageContent.type === "offer" ? toCamel(mes) : null,
                              }),
                            );
                          }
                        }

                        if (
                          mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.waiting ||
                          mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.accept
                        ) {
                          console.log("waiting or accept");

                          if (isGroupCall) {
                            const isMsgFromAnotherDevice =
                              mes.sender_id === accountId && mes.sender_device_id !== deviceId;
                            // accept group call in another device
                            if (
                              mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.accept &&
                              isMsgFromAnotherDevice
                            ) {
                              yield put(
                                CallingActions.callingSet({
                                  isInAnotherCall: true,
                                }),
                              );
                            }
                            // When receiver accepted the call, set acceptMessage and isCallAccepted = true in Call component
                            if (
                              mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.waiting &&
                              !Boolean(messageContent.send_to_account_id)
                            ) {
                              console.log("Another user end the call");

                              if (
                                isMsgFromAnotherDevice &&
                                mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.waiting
                              ) {
                                yield put(
                                  CallingActions.onCallCheck({ accountId: accountId, isEndCall: true }, prefixKey),
                                );
                              }
                              yield put(
                                CallingActions.callingSet({
                                  memberChange: {
                                    time: Date.now(),
                                    accountId: mes.sender_id,
                                  },
                                }),
                              );
                            } else if (messageContent.send_to_account_id === accountId) {
                              console.log({
                                key: "to me",
                                messageContent,
                              });

                              if (Boolean(messageContent.type === "offer")) {
                                console.log({
                                  type: messageContent.type,
                                  senderId: mes.sender_id,
                                  time: mes.created,
                                });

                                yield put(
                                  CallingActions.callingSet({
                                    offerMes: mes,
                                    offerMessage: {
                                      ...oldOffers,
                                      [mes.sender_id]: mes,
                                    },
                                  }),
                                );
                              } else if (Boolean(messageContent.type === "answer")) {
                                console.log({
                                  type: messageContent.type,
                                  senderId: mes.sender_id,
                                  time: mes.created,
                                });

                                yield put(
                                  CallingActions.callingSet({
                                    acceptTimeStamp: [mes.created],
                                    acceptWithId: {
                                      ...acceptWithId,
                                      [mes.sender_id]: {
                                        ...mes,
                                      },
                                    },
                                  }),
                                );
                              }
                            } else if (
                              messageContent.send_to_account_id !== accountId &&
                              Boolean(messageContent.type === "answer")
                            ) {
                              console.log({
                                key: "another user's answer",
                                to: messageContent.send_to_account_id,
                                from: mes.sender_id,
                              });

                              yield put(CallingActions.getCallHistory(prefixKey));
                              yield put(
                                CallingActions.callingSet({
                                  missingConnection: {
                                    ...missingConnection,
                                    [mes.sender_id]: {
                                      ...mes,
                                    },
                                  },
                                }),
                              );
                            }
                          } else if (isPersonalCall) {
                            // Personal call using jitsi: receiver accept call => set isCallAccepted = true (don't need to check peer connection)
                            yield put(
                              CallingActions.callingSet({
                                acceptMessage:
                                  mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.end ? null : toCamel(mes),
                                isCallAccepted: true, // remove if don't use jitsi
                              }),
                            );
                          }
                        }

                        if (mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.reject) {
                          // When receiver rejected the call, caller close calling component and reset redux states
                          console.log("Receiver rejected the call");

                          yield put(CallingActions.callingSet({ isCallRejected: true }));
                        }

                        if (mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.end && mes.sender_id !== accountId) {
                          // When the call ended, reset redux state
                          console.log("The call ended");
                          yield put(CallingActions.callingSet({ isCallEnded: mes.group_id }));
                        }

                        if (
                          mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.missed &&
                          mes.group_id === callingGroupDetail.id
                        ) {
                          // When receiver missed the call, caller close call component
                          console.log("Receiver missed the call", { callingGroupDetail });
                          yield put(
                            CallingActions.callingSet({
                              isCallMissed: callingGroupDetail?.group_type === SystemConstant.GROUP_CHAT_TYPE.personal,
                            }),
                          );
                        }

                        if (mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.inAnotherCall) {
                          // When receiver rejected the call, caller close calling component and reset redux states
                          console.log("Picked at another device");
                          yield put(
                            CallingActions.callingSet({
                              isInAnotherCall: true,
                            }),
                          );
                        }
                      } else if (
                        mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.waiting ||
                        mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.accept
                      ) {
                        console.log("dont have parentMessage");
                        if (isGroupCall) {
                          const isMsgFromAnotherDevice =
                            mes.sender_id === accountId && mes.sender_device_id !== deviceId;
                          // accept group call in another device
                          if (mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.accept && isMsgFromAnotherDevice) {
                            yield put(
                              CallingActions.callingSet({
                                isInAnotherCall: true,
                              }),
                            );
                          }

                          if (
                            mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.waiting &&
                            !Boolean(messageContent.send_to_account_id) &&
                            isMsgFromAnotherDevice
                          ) {
                            console.log("Another user end the call - no parentMessage");

                            yield put(CallingActions.onCallCheck({ accountId: accountId, isEndCall: true }, prefixKey));
                          }
                        }
                      }
                    } else if (mes.call_status === SystemConstant.MESSAGE_CALL_STATUS.inAnotherCall) {
                      yield put(CallingActions.callingSet({ isInAnotherCall: true }));
                    } else {
                      // Handle open calling notification dialog
                      const openNotificationStatus = [
                        SystemConstant.MESSAGE_CALL_STATUS.accept,
                        SystemConstant.MESSAGE_CALL_STATUS.waiting,
                      ];

                      yield put(
                        CallingActions.callingSet({
                          isVideoCall:
                            mes.send_type === SystemConstant.SEND_TYPE.groupVideoCall ||
                            mes.send_type === SystemConstant.SEND_TYPE.conference ||
                            mes.send_type === SystemConstant.SEND_TYPE.personalVideoCall,

                          isReceiver: true,
                        }),
                      );

                      if (openNotificationStatus.includes(mes.call_status) && mes.sender_id !== accountId) {
                        const isOpenCallingDialog = yield select(state => state.callingRedux.isOpenCallingDialog);
                        if (!isOpenCallingDialog) {
                          // Handle open calling notification dialog
                          // Get group name
                          if (
                            !isPersonal &&
                            isJSONString(callingGroupDetail.name) &&
                            JSON.parse(callingGroupDetail.name).name
                          ) {
                            groupName = JSON.parse(callingGroupDetail.name).name;
                          } else if (callingGroupDetail.group_type === SystemConstant.GROUP_CHAT_TYPE.personal) {
                            const other = groupMembers?.find(member => member.id !== accountId);
                            groupName = other ? (other.name ? other.name : other.phone) : "No Name";
                          } else {
                            const groupNameArr = groupMembers.map(item => item.name);
                            groupName = groupNameArr.join(", ");
                          }

                          callingGroupDetail = {
                            ...callingGroupDetail,
                            groupMembers: isPersonal
                              ? groupMembers.filter(item => item.id !== accountId)
                              : groupMembers,
                            name: groupName,
                            room_id: messageContent.room_id,
                            prefixKey,
                          };
                          const createdMessage = yield select(state => state.callingRedux.createdMessage);
                          const isShowCallDialog =
                            mes.sender_id !== accountId && Date.now() - mes.created < AppConstant.CALL_WAITING_TIME;

                          if (isShowCallDialog) {
                            yield put(
                              CallingActions.callingSet({
                                isOpenCallingDialog: AppConstant.CALLING_STATUS.notStart,
                                createdMessage: {
                                  ...createdMessage,
                                  [mes.group_id]: toCamel(mes),
                                },
                                callingGroupDetail: toCamel({ ...callingGroupDetail, room_id: messageContent.room_id }),
                                message: toCamel(mes),
                                personalRequestMessage: toCamel(mes),
                              }),
                            );
                          }
                        }
                      }
                    }
                  }

                  if (selectedGroupId !== mes.group_id) {
                    if (mes.sender_id !== accountId && isNotify) {
                      const senderName = LocalAccountService.getAccountName(mes.sender_id);
                      // const savedMsg = LocalMessageService.getMessageBySourceId(mes.source_id);
                      if (
                        ARR_NOTICE_NORMAL.includes(mes.send_type) &&
                        groupMembers.length > 0 /*  && !Boolean(savedMsg) */
                      ) {
                        LocalAppNotificationService.showNotification(senderName, {
                          content: replaceId2Name(mes.content, groupMembers, false),
                          groupId: mes.group_id,
                          prefixKey,
                        });
                      } else if (ARR_NOTICE_SPECIAL.includes(mes.send_type)) {
                        const multimedia = getLabel(LangConstant.OBJ_SEND_MES_TYPE, { returnObjects: true });
                        LocalAppNotificationService.showNotification(senderName, {
                          content: multimedia[mes.send_type],
                          groupId: mes.group_id,
                          prefixKey,
                        });
                      }
                    }
                  } else {
                    if (
                      isCurrentBranch &&
                      DISPLAY_MSG_TYPES.includes(mes.send_type) &&
                      selectedGroupId === mes.group_id
                    ) {
                      yield put(ConversationActions.setNewRemoteMessage(toCamel(mes)));
                    }
                  }
                }
              }
            }
          } catch (e) {
            console.error({ e });
            console.trace({ e });
            console.log({ e }, { mes });
            isNeedUpdateUI = yield LocalMessageService.saveFromRemote([mes]) || isNeedUpdateUI;
          }
        }
      }

      const tmpNormalList = responseData.filter(
        mes =>
          mes.send_type !== SystemConstant.SEND_TYPE.senderKey &&
          mes.send_type !== SystemConstant.SEND_TYPE.keyError &&
          mes.sender_id !== accountId &&
          mes.status !== SystemConstant.MESSAGE_STATUS.read &&
          mes.status !== SystemConstant.MESSAGE_STATUS.received,
      );

      if (tmpNormalList.length > 0) {
        yield updateMessageStatus(
          {
            data: {
              messageIds: tmpNormalList.map(mes => mes.id),
              status: SystemConstant.MESSAGE_STATUS.received,
            },
          },
          prefixKey,
        );
      }
      if (isCurrentBranch && fetchSuccess && isNeedUpdateUI) {
        yield put(
          ConversationActions.conversationSet({
            isUpdateViewMode: Date.now(),
          }),
        );
      }

      saveFileFromMessage(prefixKey, responseData);
    }
  } catch (error) {
    console.error(error);
  }

  if (isCurrentBranch) {
    yield put(
      SystemActions.systemSet({
        isSynchMessage: false,
      }),
    );
  }
}

async function catchResend(prefixKey, accountId, deviceId, sourceId, branchId, groupType, groupId) {
  const message = getInteractor(prefixKey).LocalMessageService.getMessageBySourceId(sourceId)[0] || {};
  let retryRow = getInteractor(prefixKey).LocalMsgErrorResendService.findByDeviceIdAndGroupIdAndSourceId(
    deviceId,
    groupId,
    sourceId,
  );

  try {
    if (message && Object.keys(message).length > 0) {
      if (retryRow == null) {
        const test = {
          id: uuid(),
          source_id: sourceId,
          device_id: deviceId,
          group_id: groupId,
          retry: 0,
          state: SystemConstant.STATE.active,
          options: null,
          created: Date.now(),
          modified: Date.now(),
          branch_id: message.branch_id,
        };
        const tmpRetry = new ErrorMessageRetry(test);
        retryRow = tmpRetry.toJson();
        await getInteractor(prefixKey).LocalMsgErrorResendService.save([retryRow]);
        let count = 0;
        let offset = 0;
        while (count % 999 === 0) {
          let reSendList = await getInteractor(prefixKey).LocalMessageService.findNextMyMessage(
            message.device_id,
            message.group_id,
            message.created,
            999,
            false,
          );
          count += reSendList.length;
          if (offset === 0) reSendList = [message].concat(reSendList);
          offset += 999;
          for (let i = 0; i < reSendList.length; i++) {
            const it = reSendList[i];
            if (groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
              await resendE2EMessage(prefixKey, accountId, deviceId, sourceId, branchId, it);
            } else {
              await resendE2EEMessage(prefixKey, accountId, deviceId, sourceId, branchId, it, groupType);
            }
          }
          if (reSendList.length === 0 || (reSendList.length === 1 && count === 0)) break;
        }
      }
    } else if (retryRow && retryRow.retry < 3) {
      let count = 0;
      let offset = 0;
      while (count % 999 === 0) {
        let reSendList = await getInteractor(prefixKey).LocalMessageService.findNextMyMessage(
          message.device_id,
          message.group_id,
          message.created,
          999,
          false,
        );
        count += reSendList.length;
        if (offset === 0) reSendList = [message].concat(reSendList);
        offset += 999;
        for (let msgIndex = 0; msgIndex < reSendList.length; msgIndex++) {
          const it = reSendList[msgIndex];
          if (groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
            await resendE2EMessage(prefixKey, accountId, deviceId, sourceId, branchId, it);
          } else {
            await resendE2EEMessage(prefixKey, accountId, deviceId, sourceId, branchId, it, groupType);
          }
        }
        if (reSendList.length === 0 || (reSendList.length === 1 && count === 0)) break;
      }
    }
    return null;
  } finally {
    if (retryRow != null) {
      if (retryRow.retry >= 3) {
        retryRow.state = SystemConstant.STATE.inactive;
        //
      } else {
        retryRow.modified = new Date().getTime();
        retryRow.retry = retryRow.retry + 1;
      }
      await getInteractor(prefixKey).LocalMsgErrorResendService.save([retryRow]);
    }
  }
}

function* sendOneMessageSenderKeyDistributionMessage(prefixKey, group, message) {
  const { sender_id, sender_device_id } = message || {};
  const groupId = group?.id || uuid();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
  const senderKeyMessage = yield getInteractor(prefixKey).LocalCipherService.generateDistributionKey(
    accountId,
    deviceId,
    groupId,
  );
  const contentEncryption = yield getInteractor(prefixKey).LocalCipherService.encryptE2EMessage(
    sender_id,
    sender_device_id,
    groupId,
    senderKeyMessage,
  );
  if (contentEncryption) {
    const id = uuid();
    const jsonOption = {};
    jsonOption.encryption_f = 1;
    const sendOneDistribution = {
      messageId: id,
      sendToDeviceId: sender_device_id,
      sendToAccountId: sender_id,
      content: contentEncryption,
      options: null,
      status: SystemConstant.MESSAGE_STATUS.send,
      sendType: SystemConstant.SEND_TYPE.senderKey,
      mentions: null,
      encryption_type: SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION,
    };
    const newMessage = {
      groupId: group.id,
      sourceId: uuid(),
      groupType: group.group_type,
      sendType: SystemConstant.SEND_TYPE.senderKey,
      created: Date.now(),
      messages: [sendOneDistribution],
      branchId: group.branch_id,
      parentId: null,
      threadId: null,
    };
    const marks = [
      {
        group_id: group.id,
        account_id: sendOneDistribution.sendToAccountId,
        device_id: sendOneDistribution.sendToDeviceId,
      },
    ];
    yield getInteractor(prefixKey).LocalSenderKeySharedService.save(marks);

    yield getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: newMessage.sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: newMessage.branchId,
        group_id: newMessage.groupId,
      },
    ]);
  }
}

async function resendE2EMessage(prefixKey, accountId, deviceId, sourceId, branchId, message) {
  if (message) {
    const jsonOption = {};
    jsonOption.encryption_f = 1;
    const id = uuid();

    const text = await getInteractor(prefixKey).LocalCipherService.encryptE2EMessage(
      accountId,
      deviceId,
      message.group_id,
      message.content,
    );
    // const text = content;
    if (text === null) return null;
    let callInfo = null;
    try {
      if (SystemConstant.ARR_CALLING_TYPES.includes(message.send_type)) callInfo = JSON.parse(message.content);
    } catch (e) {
      console.log(e);
    }

    const resendMessage = {
      messageId: id,
      sendToDeviceId: deviceId,
      sendToAccountId: accountId,
      content: text,
      options: JSON.stringify(jsonOption),
      status: SystemConstant.MESSAGE_STATUS.send,
      sendType: message.send_type,
      mentions: message.mentions,
      encryption_type: SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION,
    };
    const memberIdArr = await getInteractor(prefixKey).LocalAccountGroupService.find({
      group_id: message.group_id,
      state: 1,
    });
    let deviceList = null;
    if (memberIdArr && memberIdArr.length > 0) {
      const devices = getInteractor(prefixKey).LocalDeviceService.getAllByAccountList(
        memberIdArr.map(member => member.account_id),
      );
      deviceList = devices.filter(s => s.state === SystemConstant.STATE.active);
    }

    let deviceListIds = [deviceId];
    if (deviceList && deviceList.length > 0) {
      deviceListIds = deviceList.map(d => d.id);
    }
    const newMessage = {
      isSendingKey: false,
      groupId: message.group_id,
      sourceId: message.source_id,
      groupType: message.group_type,
      sendType: message.send_type,
      created: Date.now(),
      messages: [resendMessage],
      branchId: branchId,
      parentId: message.parent_id,
      threadId: message.thread_id,
      deviceList: deviceListIds,
      roomId: callInfo?.room_id,
      messageId: id,
    };
    message.id = id;
    message.send_type = SystemConstant.SEND_TYPE.keyError;
    message.options = JSON.stringify(jsonOption);
    await getInteractor(prefixKey).LocalMessageService.save([message]);
    await getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: newMessage.sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: newMessage.branchId,
        group_id: newMessage.groupId,
      },
    ]);
  }
  return null;
}

async function catchSendErrorMessage(prefixKey, accountId, deviceId, sourceId, branchId, group, sendType) {
  // Kiểm tra xem đã từng gửi báo error tới tin nhắn này với thiết bị này chưa
  let sendNullRow = getInteractor(prefixKey).LocalMsgErrorSendNullService.findByDeviceIdAndGroupIdAndType(
    deviceId,
    group?.id,
    0,
  );
  // Nếu đã gửi và vẫn chưa xử lý được thành công + sau 10 phút thì xóa và làm lại từ đầu
  if (sendNullRow && sendNullRow.retry >= 3 && sendNullRow.created <= Date.now() - 2 * RETRY_AFTER_5_MINUTE) {
    await getInteractor(prefixKey).LocalMsgErrorSendNullService.deleteByGroupIdAndDeviceIdAndType(
      group.id,
      deviceId,
      0,
    );
    sendNullRow = null;
  }

  const timeRetry = new Date().getTime() - RETRY_AFTER_5_MINUTE;
  try {
    if (sendNullRow == null) {
      // Nếu chưa từng tạo record
      const tmpSendNull = new ErrorMessageSendNull({
        id: uuid(),
        type: 0,
        device_id: deviceId,
        group_id: group?.id,
        retry: 0,
        state: SystemConstant.STATE.active,
        options: null,
        created: new Date().getTime(),
        modified: null,
        branch_id: branchId,
      });
      sendNullRow = tmpSendNull.toJson();
      await getInteractor(prefixKey).LocalMsgErrorSendNullService.save([sendNullRow]);

      await sendNullMessage(prefixKey, accountId, deviceId, sendType, sourceId, group);
    } else if (
      sendNullRow.retry < 3 &&
      (sendNullRow.created < timeRetry ||
        (sendNullRow.modified != null && sendNullRow.modified !== 0 && sendNullRow.modified < timeRetry))
    ) {
      // Cho phép gửi sendNull báo lỗi tới 3 lần với 1 tin nhắn
      sendNullRow.retry = sendNullRow.retry + 1;
      sendNullRow.modified = new Date().getTime();
      await sendNullMessage(prefixKey, accountId, deviceId, sendType, sourceId, group);
    }
    return null;
  } finally {
    if (sendNullRow != null) {
      if (sendNullRow.retry >= 3) {
        // Nếu sau 3 lần không hồi phục được thông báo với người dùng là thiết bị hỏng khoá
        sendNullRow.state = SystemConstant.STATE.inactive;
        if (StorageUtil.getItem(KeyConstant.KEY_ERROR_TIME_WARNING, prefixKey) === null) {
          LocalAppNotificationService.showNotification("Trios", {
            content: "Thiết bị lỗi khoá vui lòng đăng xuất và đăng nhập lại.",
            groupId: group?.id,
          });
        }
        await getInteractor(prefixKey).LocalMsgErrorSendNullService.deleteByGroupIdAndDeviceIdAndType(
          group.id,
          deviceId,
          0,
        );
      } else {
        await getInteractor(prefixKey).LocalMsgErrorSendNullService.save([sendNullRow]);
      }
    }
  }
}

async function sendNullMessage(prefixKey, accountId, deviceId, sendType, sourceId, group) {
  const nullContent = Math.random().toString();
  const contentEncryption = await getInteractor(prefixKey).LocalCipherService.encryptE2EMessage(
    accountId,
    deviceId,
    group.id,
    nullContent,
  );
  const id = uuid();
  // const text = content;
  if (contentEncryption === null) return null;
  const jsonOption = {};
  jsonOption.encryption_f = 1;
  const resendMessage = {
    messageId: id,
    sendToDeviceId: deviceId,
    sendToAccountId: accountId,
    content: contentEncryption,
    options: JSON.stringify(jsonOption),
    status: SystemConstant.MESSAGE_STATUS.send,
    sendType: sendType,
    mentions: null,
  };
  const newMessage = {
    isSendingKey: false,
    groupId: group.id,
    sourceId: sourceId,
    groupType: group.group_type,
    sendType: sendType,
    created: Date.now(),
    messages: [resendMessage],
    branchId: group.branch_id,
    parentId: null,
    threadId: null,
    deviceList: [deviceId],
    messageId: id,
  };
  await getInteractor(prefixKey).LocalApiCallService.save([
    {
      id: uuid(),
      task: `${AppConstant.TASK_MESSAGE_SEND}`,
      original_uid: sourceId,
      query: "",
      content: JSON.stringify({ data: newMessage }),
      original_content: JSON.stringify({ data: newMessage }),
      created: new Date().getTime(),
      retry: 0,
      branch_id: newMessage.branchId,
      group_id: newMessage.groupId,
    },
  ]);
  return null;
}

async function resendE2EEMessage(prefixKey, accountId, deviceId, sourceId, branchId, message, groupType) {
  if (message) {
    const text = await getInteractor(prefixKey).LocalCipherService.encryptE2EEMessage(
      message.account_id,
      message.device_id,
      message.group_id,
      message.content,
    );
    const jsonOption = {};
    jsonOption.encryption_f = 1;
    const id = uuid();

    // const text = content;
    if (text === null) return null;
    let callInfo = null;
    if (SystemConstant.ARR_CALLING_TYPES.includes(message.send_type) && isJSONString(message.content)) {
      callInfo = JSON.parse(message.content);
    }
    const resendMessage = {
      messageId: id,
      sendToDeviceId: deviceId,
      sendToAccountId: accountId,
      content: text,
      options: message.options,
      status: SystemConstant.MESSAGE_STATUS.send,
      sendType: message.send_type,
      mentions: message.mentions,
    };

    const newMessage = {
      isSendingKey: false,
      groupId: message.group_id,
      sourceId: message.source_id,
      groupType: groupType,
      sendType: message.send_type,
      created: Date.now(),
      messages: [resendMessage],
      branchId: branchId,
      parentId: message.parent_id,
      threadId: message.thread_id,
      deviceList: [deviceId],
      roomId: callInfo?.room_id,
      messageId: id,
    };
    message.id = id;
    message.options = JSON.stringify(jsonOption);
    message.send_type = SystemConstant.SEND_TYPE.keyError;
    await getInteractor(prefixKey).LocalMessageService.save([message]);
    await getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: AppConstant.TASK_MESSAGE_SEND,
        original_uid: newMessage.sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: newMessage.branchId,
        group_id: newMessage.groupId,
      },
    ]);
  }
  return null;
}
