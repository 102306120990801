import React, { useEffect } from "react";
import { AppConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import { ConversationActions, ConversationSelectors } from "redux-store";

const HandlingUnreadMessage = () => {
  const dispatch = useDispatch();
  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);
  const threadingId = useSelector(ConversationSelectors.getThreadingId);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);

  useEffect(() => {
    const markMsgTimeout = setTimeout(() => {
      if (selectedGroupId || threadingId) {
        dispatch(ConversationActions.markReadMessageInGroup(selectedGroupId, threadingId));
      }
    }, AppConstant.DEBOUNCE_TIME);

    return () => clearTimeout(markMsgTimeout);
  }, [isUpdateViewMode, selectedGroupId, threadingId]);

  return null;
};

export default HandlingUnreadMessage;
