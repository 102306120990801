import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { AvatarInfo, DrawerLayout } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { getSavedServer } from "utils/view.utils";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const ChooseAdminDrawer = ({ open, dataAdmin, onClose, groupDetail }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const dispatch = useDispatch();

  const [admin, setAdmin] = useState({});

  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  let savedServer = getSavedServer();

  const onChooseNewAdmin = item => {
    setAdmin(item);

    dispatch(
      ConversationActions.sendMessage({
        groupId: groupDetail.id,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: accountId,
        parentId: null,
        deviceList: [],
        branchId: savedServer?.id,
        mentionIdsArr: [],
        threadId: null,
        removingId: accountId,
        adminId: admin.id,
      }),
    );

    onClose();
  };

  const arrayAdmin = (dataAdmin || []).filter(item => item.id !== accountId);

  return (
    <DrawerLayout
      onClose={onClose}
      open={open}
      anchor={"right"}
      hideBackdrop={false}
      title={getLabel(LangConstant.TXT_CHOOSE_NEW_ADMIN)}
    >
      <Box className={classes.root}>
        <Typography className={clsx(classes.listTitle, "semiBold-sm-txt")}>
          {getLabel(LangConstant.TXT_CHOOSE_NEW_ADMIN_MESSAGE)}
        </Typography>
        <Box className={classes.memberList}>
          {arrayAdmin.map((item, index) => (
            <AvatarInfo
              key={`${item.avatarId}-${index}`}
              avatar={{ avatarId: item.avatarId, name: item.name }}
              title={item.name}
              onClick={() => onChooseNewAdmin(item)}
              button={true}
            />
          ))}
        </Box>
      </Box>
    </DrawerLayout>
  );
};

ChooseAdminDrawer.propTypes = {};

export default ChooseAdminDrawer;

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },

  listTitle: {
    color: theme.palette.text.secondary,
    lineHeight: "20px",
    padding: "0 16px",
  },
}));
