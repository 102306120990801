import { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant } from "const";
import { SendInputIcon } from "icons";
import { Attachment } from "@mui/icons-material";
import PeopleTagList from "./PeopleTagList";
import SendMessageFile from "./SendMessageFile";
import FullEmojiLayout from "./FullEmojiLayout";
import PastePopper from "./PastePopper";
import { getAllLetterIndex, removeDuplicateInArray, textNormalize } from "utils";
import clsx from "clsx";
import { StorageUtil } from "utils";
import { useConversationContext } from "../ConversationContext";
import { replaceName2Id } from "utils/view.utils";
import useInputEvent, { getCursorPosition } from "hooks/useInputEvent";

const MessengerChatInput = ({ customStyle, tagListStyle, placeholder, onSendMessage, isAllowAttach = true }) => {
  const classes = useStyles();
  const { inputRef, insertContentInput, updateContentInput } = useInputEvent();
  const mentionIdListRef = useRef([]);
  const { groupDetail } = useConversationContext();

  const [isShowTagList, setIsShowTagList] = useState(false);
  const [filteredGroupMembers, setFilteredGroupMembers] = useState([]);
  const [pasterAnchorEl, setPasterAnchorEl] = useState(null);
  const [attachmentAnchorEl, setAttachmentAnchorEl] = useState(null);
  const [isShowUploadIcon, setIsShowUploadIcon] = useState(isAllowAttach);

  const handleUpdateMessageContent = content => {
    updateContentInput(content);
    setIsShowUploadIcon(Boolean(isAllowAttach && false === Boolean(content?.trim())));
  };

  const handleResetInput = () => {
    mentionIdListRef.current = [];
    handleUpdateMessageContent("");

    handleCloseTagList();
    setFilteredGroupMembers(groupDetail.groupMembers || []);
    setPasterAnchorEl(null);
    setAttachmentAnchorEl(null);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 32) {
      // Space
      handleCloseTagList();
    } else if (event.keyCode === 13) {
      if (event.shiftKey) {
        if (event.type === "keypress" && inputRef.current) {
          // ShiftEnter
          handleUpdateMessageContent(inputRef.current.value + "\n");
        }
      } else if (inputRef.current.value?.trim()) {
        // Enter
        handleButtonSend(event);
      }
    }
  };

  const handlePasterAnchor = isShow => () => setPasterAnchorEl(isShow ? inputRef.current : null);

  const handleChangeInput = event => {
    const inputValue = event.target.value;
    const cursorIndex = getCursorPosition(inputRef.current);
    const previousCharacter = inputValue[cursorIndex - 1];

    if (!inputValue) {
      handleResetInput();
    } else if (
      cursorIndex > 0 &&
      mentionIdListRef.current.length < groupDetail.groupMembers.length &&
      getLastWord(inputValue).startsWith("@")
    ) {
      const mentionIndexes = getAllLetterIndex(inputValue, "@");
      const currentIndexes = mentionIndexes.filter(item => item < cursorIndex);

      // Filter member by name
      if (false === [" ", "\n"].includes(previousCharacter) && currentIndexes.length > 0) {
        const nearestIndex = Math.max(...currentIndexes);

        const value2Search = inputValue.slice(nearestIndex + 1, cursorIndex);
        const filteredArray = getFilterArray(groupDetail.groupMembers, value2Search).filter(
          item => !mentionIdListRef.current.includes(item.id),
        );

        setFilteredGroupMembers(filteredArray);
        setIsShowTagList(filteredArray.length > 0);
      }
    } else {
      handleCloseTagList();
    }

    const contentMention = replaceName2Id(inputValue, groupDetail.groupMembers);

    const mentionArr = groupDetail.groupMembers?.reduce((results, member) => {
      const mention = "@{" + member.id + "}";
      if (contentMention.includes(mention)) {
        results = [...results, member.id];
      }
      return results;
    }, []);

    mentionIdListRef.current = removeDuplicateInArray(mentionArr);

    handleUpdateMessageContent(inputValue);
  };

  const handleCloseTagList = () => setIsShowTagList(false);

  const handleSelectTag = person => {
    const isValid = person && person.id;
    if (!isValid) return;
    mentionIdListRef.current = [...mentionIdListRef.current, person.id];

    // Replace mention name by searching value
    const cursorIndex = getCursorPosition(inputRef.current);
    const preContent = inputRef.current.value.slice(0, cursorIndex);
    const arrContent = preContent.split(" ");
    arrContent.pop();

    const content = arrContent.join(" ") + ` @${person.name || ""} ` + inputRef.current.value.slice(cursorIndex);

    handleUpdateMessageContent(content);
    handleCloseTagList();
  };

  const handleAttachmentAnchor = event => setAttachmentAnchorEl(event?.currentTarget || null);

  const handleButtonSend = event => {
    event.preventDefault();

    onSendMessage(replaceName2Id(inputRef.current.value?.trim(), groupDetail.groupMembers), mentionIdListRef.current);
    handleResetInput();
  };

  const handlePasteTagMessage = pasteContent => {
    insertContentInput(pasteContent)
    const contentMention = replaceName2Id(pasteContent, groupDetail.groupMembers);
    const mentionArr = groupDetail.groupMembers?.reduce((results, member) => {
      const mention = "@{" + member.id + "}";
      if (contentMention.includes(mention)) {
        results = [...results, member.id];
      }
      return results;
    }, []);

    const currentTagList = mentionIdListRef.current;

    mentionIdListRef.current = removeDuplicateInArray(currentTagList.concat(mentionArr));
  };

  useEffect(() => {
    if (groupDetail) handleResetInput();
  }, [groupDetail]);

  return (
    <Box className={clsx(classes.inputType, customStyle)}>
      <TextField
        autoFocus
        multiline
        minRows={1}
        maxRows={5}
        onKeyDown={handleKeyDown}
        className={classes.input}
        onChange={handleChangeInput}
        placeholder={placeholder}
        inputRef={inputRef}
        InputProps={{
          onContextMenu: handlePasterAnchor(true),
          classes: {
            notchedOutline: classes.noBorder,
          },
          startAdornment: (
            <InputAdornment position="start">
              <FullEmojiLayout onSelect={insertContentInput} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Box>
                {isShowUploadIcon ? (
                  <IconButton className={classes.attachFileIcon} onClick={handleAttachmentAnchor}>
                    <Attachment variant="contained" color="action" />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleButtonSend}>
                    <SendInputIcon />
                  </IconButton>
                )}
              </Box>
            </InputAdornment>
          ),
        }}
      />

      <PeopleTagList
        isShow={isShowTagList}
        data={filteredGroupMembers}
        offsetWidth={0}
        tagListStyle={tagListStyle}
        onClick={handleSelectTag}
        onClose={handleCloseTagList}
      />

      <PastePopper
        anchorEl={pasterAnchorEl}
        onPaste={handlePasteTagMessage}
        onClose={handlePasterAnchor(false)}
      />

      {isAllowAttach && <SendMessageFile anchorEl={attachmentAnchorEl} onClose={() => handleAttachmentAnchor()} />}
    </Box>
  );
};

MessengerChatInput.propTypes = {
  placeholder: PropTypes.string,
  isAllowAttach: PropTypes.bool,

  onSendMessage: PropTypes.func,
  onInputChange: PropTypes.func,
};

MessengerChatInput.defaultProps = {
  placeholder: "",
  isAllowAttach: true,

  onSendMessage: () => {},
  onInputChange: () => {},
};

export default memo(MessengerChatInput);

export const getFilterArray = (memberArray, search) => {
  let filterItems = [];
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  if (memberArray && memberArray.length > 0) {
    filterItems = memberArray.filter(
      member => member.id !== accountId && textNormalize(member.name).includes(textNormalize(search)),
    );
  }
  return filterItems;
};

const getLastWord = inputString => {
  const wordsArray = inputString.split(" ");
  return wordsArray[wordsArray.length - 1];
};

const useStyles = makeStyles(theme => ({
  noBorder: {
    border: "none",
  },

  inputType: {
    width: "100%",
    position: "relative",
    backgroundColor: theme.palette.white,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },

  input: {
    width: "100%",
    backgroundColor: "#F3F3F5",
    borderRadius: 10,
    zIndex: 10,
  },

  attachFileIcon: {
    transform: "rotate(-45deg)",
  },
}));
