import { useEffect, useState } from "react";
import { ApiConstant, SystemConstant } from "const";
import { AttachmentUtil, CryptoUtil, convertJSONObject, convertString2JSON, isString, toCamel } from "utils";
import { AttachmentService } from "services";

const useMessageFile = message => {
  const [attachmentId, setAttachmentId] = useState();
  const [caption, setCaption] = useState("");
  const [mediaData, setMediaData] = useState({
    mediaPath: null,
    mimeType: null,
    fileName: "",
    size: null,
  }); // Structure: {fileName, mediaPath, mimeType, size}

  const handleUpdateMediaData = async (attachmentId, metaData) => {
    const mediaPath = await AttachmentUtil.imageFile2Src(
      attachmentId,
      SystemConstant.GROUP_CHAT_TYPE.group,
      metaData.mimeType,
      metaData.fileName,
    );

    setMediaData({
      mediaPath: mediaPath,
      mimeType: metaData.mimeType,
      fileName: metaData.fileName,
      size: metaData.size,
    });
  };

  useEffect(() => {
    if (message) {
      const fileObj = toCamel(convertJSONObject(convertString2JSON(message.content)));
      const fileMetaData = fileObj.metaData || {};

      if (fileObj.attachmentId && fileObj.attachmentId !== attachmentId) {
        setAttachmentId(fileObj.attachmentId);
        setCaption(String(fileObj.contentMessage || ""));

        const isExistFile = AttachmentUtil.exitsLocalFile(fileObj.attachmentId, fileMetaData.fileName);

        if (isExistFile) {
          handleUpdateMediaData(fileObj.attachmentId, fileMetaData);
        } else if (fileObj.aesKeyInfo) {
          saveFileFromServer(fileObj, fileMetaData, handleUpdateMediaData);
        } else {
          console.error("NullPointException - fileObj.aesKeyInfo");
        }
      }
    }
  }, []);

  return { caption, mediaData };
};

export default useMessageFile;

export const saveFileFromServer = async (fileObj, fileMetaData, callback) => {
  const responseData = await AttachmentService.getAttachment({ attachment_id: fileObj.attachmentId });
  if (responseData && responseData.status === ApiConstant.STT_OK && responseData.data) {
    const encryptedBytes = new Uint8Array(responseData.data);
    const decryptFile = AttachmentUtil.getPrivateFilePath(fileObj.attachmentId, fileMetaData.fileName);
    const decryptFilePath = await CryptoUtil.decryptAES(
      encryptedBytes,
      fileObj.aesKeyInfo.key,
      fileObj.aesKeyInfo.iv,
      fileObj.aesKeyInfo.authtag,
      decryptFile,
    );

    if (isString(decryptFilePath) && callback) callback(fileObj.attachmentId, fileMetaData);
  } else {
    console.warn("Something wrong after calling", responseData);
  }
};
