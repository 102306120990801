import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppAvatar } from "components";
import { KeyConstant, LangConstant } from "const";
import { isGroupOrChannelType } from "pages/Call";
import { useEffect, useState } from "react";
import { AttachmentUtil, toCamel } from "utils";
import InfoIcon from "@mui/icons-material/Info";
import VideocamIcon from "@mui/icons-material/Videocam";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import InitGroupCallPopup from "../MessengerChat/InitGroupCallPopup";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { StorageUtil } from "utils";

const ConferenceItem = ({ data }) => {
  const classes = useStyles();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);

  const [userAvatar, setUserAvatar] = useState(null);
  const [memberLength, setMemberLength] = useState();
  const [isShowInit, setIsShowInit] = useState(false);

  const maxPersonNumber = toCamel(JSON.parse(selectedBranch.options))?.meetMaxCallPerson;

  const onShowInitConference = () => {
    setIsShowInit(true);
  };

  useEffect(() => {
    setMemberLength(data.groupMembers.length);
    let avatarId = isGroupOrChannelType(data?.groupType)
      ? data.avatarId
      : data.groupMembers.find(acc => acc.id !== accountId)?.avatarId;

    if (avatarId) {
      setUserAvatar(AttachmentUtil.getAvatarRemoteUrl(avatarId));
    } else {
      setUserAvatar(null);
    }
  }, [data]);

  return (
    <>
      <Box className={classes.item}>
        <Box className={classes.groupNameAndAvatar}>
          <Box className={classes.chatItemAvatar}>
            <AppAvatar
              group={{
                groupType: data.groupType,
                groupName: data.groupName,
                privateF: data.privateF,
                groupMembers: data.groupMembers,
              }}
              src={userAvatar}
            />
          </Box>
          <Typography className={clsx(classes.chatName, "ellipsis")}>{data.groupName}</Typography>
        </Box>

        <Box className={classes.actionButton}>
          {memberLength > maxPersonNumber && (
            <Tooltip title={getLabel(LangConstant.TXT_MEMBER_OVER_TOOLTIP)}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          <Button
            variant="contained"
            className={clsx(classes.callButton)}
            disabled={memberLength > maxPersonNumber || isInCall || !hasInternet}
            classes={{ root: classes.rootButton, disabled: classes.disabledButton }}
            onClick={onShowInitConference}
          >
            <VideocamIcon color="white" />
          </Button>
        </Box>
      </Box>

      {isShowInit && <InitGroupCallPopup open={isShowInit} onClose={() => setIsShowInit(false)} data={data} />}
    </>
  );
};

export default ConferenceItem;

const useStyles = makeStyles(theme => ({
  groupNameAndAvatar: {
    display: "flex",
    alignItems: "center",
    width: "70%",
  },

  chatItemAvatar: {
    marginBottom: 10,
    marginRight: 10,
    minWidth: 50,
    width: 50,
    height: 50,
  },

  actionButton: {
    display: "flex",
    alignItems: "center",
  },

  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  callButton: {
    marginLeft: 10,
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },

  rootButton: {
    padding: 0,
    minWidth: 42,
    minHeight: 30,
  },

  chatName: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    marginBottom: 2,
  },
}));
