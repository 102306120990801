import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import { useDeviceList } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertMillisecondsToDate } from "utils/date.utils";
import { getNSLang } from "utils/lang.utils";
import { convertFileSize } from "utils";

const BackupFileInfo = ({ backupDeviceId, backupTime, size }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { masterDeviceList, slaveDeviceList } = useDeviceList();

  const backupInfoObj = getLabel(LangConstant.OBJ_BACKUP_INFO, { returnObjects: true });
  const deviceTypeObjContent = getNSLang(LangConstant.NS_HOME, LangConstant.OBJ_DEVICE_TYPE_TEXT, {
    returnObjects: true,
  });

  const [deviceName, setDeviceName] = useState("");
  const [backupTimeConvert, setBackupTimeConvert] = useState("");
  const [backupSize, setBackupSize] = useState("");

  useEffect(() => {
    const backupTimeConvert = convertMillisecondsToDate(backupTime, "HH:mm - dd/MM/yyyy");
    const backupFileSize = convertFileSize(size);
    setBackupTimeConvert(backupTimeConvert);
    setBackupSize(backupFileSize);
  }, []);

  useEffect(() => {
    const deviceInfo = [...masterDeviceList, ...slaveDeviceList].filter(item => item.id === backupDeviceId)[0];
    if (deviceInfo && Object.keys(deviceInfo).length > 0) {
      const deviceName = JSON.parse(deviceInfo.options).device_name;
      const deviceType = parseInt(deviceInfo.type);
      let deviceTypeName = deviceTypeObjContent.unknown;

      switch (deviceType) {
        case SystemConstant.DEVICE_TYPE.android:
          deviceTypeName = deviceTypeObjContent.android;
          break;
        case SystemConstant.DEVICE_TYPE.ios:
          deviceTypeName = deviceTypeObjContent.ios;
          break;
        case SystemConstant.DEVICE_TYPE.window:
          deviceTypeName = deviceTypeObjContent.window;
          break;
        case SystemConstant.DEVICE_TYPE.linux:
          deviceTypeName = deviceTypeObjContent.linux;
          break;
        default:
          break;
      }
      setDeviceName(deviceName || deviceTypeName);
    } else {
      setDeviceName(deviceTypeObjContent.unknown);
    }
  }, [masterDeviceList, slaveDeviceList]);

  return (
    <Box>
      <Typography className={classes.backupInfo}>{backupInfoObj.title}</Typography>
      <Typography>
        {backupInfoObj.deviceBackupName}: {deviceName}
      </Typography>
      <Typography>
        {backupInfoObj.backupTime}: {backupTimeConvert}
      </Typography>
      <Typography>
        {backupInfoObj.size}: {backupSize}
      </Typography>
    </Box>
  );
};

export default BackupFileInfo;

const useStyles = makeStyles({
  backupInfo: {
    marginTop: 50,
    fontWeight: "bold",
  },
});
