import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { toCamel, toSnake } from "utils";
import { getInteractor } from "services/local.service";
import { isEqual } from "lodash";
const StorageUtil = window.electronUtils.storage;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  uploadMessageFile: ["data", "prefixKey"],
  updateMessageStatus: ["data", "prefixKey"],
  conversationSetLinkData: ["data", "prefixKey"],
  sendMessage: ["data", "prefixKey"],
  deleteMessageLocal: ["data", "prefixKey"], // Delete message of other account at local database
  setNewRemoteMessage: ["sendingMsg"],

  requestUploadImageCall: ["data", "prefixKey"],
  setSelectGroupId: ["data", "prefixKey"],
  checkBlockedContact: ["data", "prefixKey"], // data: {accountId: <Receiver's id>}
  markReadMessageInGroup: ["selectedGroupId", "threadingId"],

  conversationSuccess: ["data"],
  conversationFailure: ["error", "data"],
  conversationSet: ["data"],
});

export const ConversationTypes = Types;
export const ConversationActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const DEFAULT_ATTACHMENT = {
  processStatus: AppConstant.PROCESS_STATUS.nothing,
  attachmentId: null,
  sendType: null,
};
export const INITIAL_STATE = {
  ...REDUX_STATE,

  isSearchMode: false,
  isUpdateViewMode: 0,

  threadingId: null,
  selectedGroupId: null,

  uploadingAttachment: DEFAULT_ATTACHMENT,
  sendingMsg: null, // message synch from server
  sendingMessageLocal: {}, // message is sent by current device

  idGroupCallAvatar: null, // Id của cuộc gọi conference trong trường hợp init
  idAvatarEdit: null, // Id của cuộc gọi conference trong trường hợp còn lại
  linkData: {}, // Preview link message
  isAddConference: false, // True: User đang trong luồng taojh conference

  scrollToChildId: null, // id của message mà mình muốn nhảy đến
  blockedAccount: null, // Dành riêng cho trường hợp chat cá nhân và thằng đó bị blocked
  searchingMessage: {
    selectedMessageId: null,
    messageList: [],
    searchValue: "",
  },
};

/* ------------- Selector ------------- */
export const ConversationSelectors = {
  getSearchingMessage: state => state.conversationRedux.searchingMessage,
  getUploadingAttachment: state => state.conversationRedux.uploadingAttachment,
  getSelectedGroupId: state => state.conversationRedux.selectedGroupId,
  getThreadingId: state => state.conversationRedux.threadingId,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) => requestReducerFunc(state);

const uploadMessageFile = (state = INITIAL_STATE) =>
  request({
    ...state,
    uploadingAttachment: { ...DEFAULT_ATTACHMENT, processStatus: AppConstant.PROCESS_STATUS.calling },
  });

const sendMessage = (state = INITIAL_STATE, action) => {
  const { currentMessage } = action.data;
  const sendingMessageLocal = toCamel(currentMessage);
  const newState = {
    ...state,
    sendingMessageLocal: {},
    isSearchMode: false,
  };

  // If message has type that can be displayed on the list, update msg to state
  if (SystemConstant.ARR_MSG_SEND_TYPES.includes(sendingMessageLocal.sendType)) {
    if (!sendingMessageLocal.senderId) sendingMessageLocal.senderId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

    if (!sendingMessageLocal.created) sendingMessageLocal.created = Date.now();

    newState.sendingMessageLocal = sendingMessageLocal;
  }

  return newState;
};

// Delete message of other account
const deleteMessageLocal = (state = INITIAL_STATE, action) => {
  const { data, prefixKey } = action;
  const deleteMessage = {
    ...data,
    state: SystemConstant.STATE.inactive,
    modified: Date.now(),
  };
  getInteractor(prefixKey).LocalMessageService.save([toSnake(deleteMessage)]);

  return {
    ...state,
    sendingMessageLocal: toCamel(deleteMessage),
    isSearchMode: false,
  };
};

const setLinkData = (state = INITIAL_STATE, action) => ({ ...state, linkData: { ...state.linkData, ...action.data } });

const setSelectGroupId = (state = INITIAL_STATE, action) => {
  const { threadingId, scrollToChildId, ...otherData } = action.data || {};

  return {
    ...state,
    sendingMessage: {},
    sendingMsg: {},
    isSearchMode: false,
    scrollToChildId: scrollToChildId || null,
    threadingId: threadingId || null,
    ...otherData,
  };
};

const setNewRemoteMessage = (state = INITIAL_STATE, action) => {
  const newMessage = toCamel(action.sendingMsg) || {};
  const currentSendingMsg = toCamel(state.sendingMsg) || {};
  if (newMessage.groupId === state.selectedGroupId && !isEqual(newMessage, currentSendingMsg)) {
    return { ...state, sendingMsg: newMessage };
  }

  return state;
};

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.UPLOAD_MESSAGE_FILE]: uploadMessageFile,
  [Types.UPDATE_MESSAGE_STATUS]: request,

  [Types.SEND_MESSAGE]: sendMessage,
  [Types.DELETE_MESSAGE_LOCAL]: deleteMessageLocal,
  [Types.CONVERSATION_SET_LINK_DATA]: setLinkData,
  [Types.SET_NEW_REMOTE_MESSAGE]: setNewRemoteMessage,

  [Types.REQUEST_UPLOAD_IMAGE_CALL]: request,
  [Types.SET_SELECT_GROUP_ID]: setSelectGroupId,
  [Types.CHECK_BLOCKED_CONTACT]: request,
  [Types.MARK_READ_MESSAGE_IN_GROUP]: request,

  [Types.CONVERSATION_SET]: set,
  [Types.CONVERSATION_SUCCESS]: success,
  [Types.CONVERSATION_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const ConversationReducer = createReducer(INITIAL_STATE, HANDLERS);
