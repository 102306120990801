import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { Redo, Undo } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ROTATE_DEGREES = 90;
const PreviewImageWithAction = props => {
  const classes = useStyles();

  const [rotateDeg, setRotateDeg] = useState(0);

  const handleRotateLeft = () => setRotateDeg(preState => preState - ROTATE_DEGREES);

  const handleRotateRight = () => setRotateDeg(preState => preState + ROTATE_DEGREES);

  return (
    <Box className={classes.root}>
      <Box
        className={classes.imageRoot}
        sx={{
          transform: `rotate(${rotateDeg}deg)`,
        }}
      >
        <ZoomImage {...props} />
      </Box>

      <Stack direction="row" justifyContent="center" className={classes.actionRoot}>
        <IconButton onClick={handleRotateLeft}>
          <Undo />
        </IconButton>
        <IconButton onClick={handleRotateRight}>
          <Redo />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default PreviewImageWithAction;

const ZoomImage = props => {
  const { src, alt } = props;
  const zoomFactor = 5;

  const [container, setContainer] = useState(null);

  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const [imageNaturalWidth, setImageNaturalWidth] = useState(0);
  const [imageNaturalHeight, setImageNaturalHeight] = useState(0);

  const imageScale = useMemo(() => {
    if (imageNaturalWidth === 0 || imageNaturalHeight === 0) return 0;
    const scale = Math.min(containerWidth / imageNaturalWidth, containerHeight / imageNaturalHeight);

    return scale || 0.5;
  }, [containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerWidth(rect.width);
      setContainerHeight(rect.height);
    } else {
      setContainerWidth(0);
      setContainerHeight(0);
    }
  }, [container]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setImageNaturalWidth(image.naturalWidth);
      setImageNaturalHeight(image.naturalHeight);
    };
    image.src = src;
  }, [src]);

  const fullPercentStyle = {
    width: "100%",
    height: "100%",
  };

  const isRectangle = imageNaturalWidth / imageNaturalHeight > 1.2;

  return (
    <Box sx={fullPercentStyle} ref={el => setContainer(el)}>
      {imageScale > 0 && (
        <TransformWrapper
          key={`${containerWidth}x${containerHeight}`}
          initialScale={isRectangle ? 1 : imageScale}
          minScale={imageScale}
          maxScale={imageScale * zoomFactor}
          centerOnInit
        >
          <TransformComponent wrapperStyle={fullPercentStyle}>
            <img alt={alt} src={src} />
          </TransformComponent>
        </TransformWrapper>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },

  imageRoot: {
    width: "70vw",
    height: "calc(100% - 56px)",
    backgroundColor: "transparent",
    margin: "auto",
  },

  actionRoot: { position: "absolute", width: "100%", paddingTop: 16 },
});
