import { Entity } from "./Entity"
interface IErrorMessageRetry {
  id: string;
  source_id: string;
  device_id: string;
  group_id: string;
  retry: number;
  state: number;
  options: number;
  created: number;
  modified: number;
  branch_id: string;
}


export class ErrorMessageRetry  extends Entity implements IErrorMessageRetry {

  public constructor({id, source_id, device_id, group_id, retry, state, options, created, modified, branch_id}: IErrorMessageRetry) {
    super();
    this.id = id;
    this.source_id = source_id;
    this.device_id = device_id;
    this.group_id = group_id;
    this.retry = retry;
    this.state = state;
    this.options = options;
    this.created = created;
    this.modified = modified;
    this.branch_id = branch_id;

  }



  branch_id: string;
  created: number;
  device_id: string;
  group_id: string;
  id: string;
  modified: number;
  options: number;
  retry: number;
  source_id: string;
  state: number;


}