import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Error, Phone, VideoCall, VideocamOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CallingActions } from "redux-store/calling.redux";
import ConversationAction from "redux-store/conversation.redux";
import { AppAvatar } from "components";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import GroupInfo from "../GroupInfo";
import SearchPopup from "./SearchPopup";
import { AttachmentUtil, toCamel, uuid } from "utils";
import { getNSLang } from "utils/lang.utils";
import { getSavedServer } from "utils/view.utils";
import InitGroupCallPopup from "./InitGroupCallPopup";
import { isGroupOrChannelType } from "pages/Call";
import { useBlockedAccountStatus, useOnlineGroup } from "hooks";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import { useConversationContext } from "./ConversationContext";
import { BranchSelectors, GroupInfoSelectors } from "redux-store";

const TitleChat = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchAnchorRef = useRef(null);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { isOnline } = useOnlineGroup();
  const { isBlockedAccount, isBlockedByAccount } = useBlockedAccountStatus();
  const {
    groupDetail: contextGroupDetail,
    store: { isInactive },
  } = useConversationContext();

  const serverOptions = useSelector(BranchSelectors.getServerOptions);
  const isFetchHistorySuccess = useSelector(state => state.callingRedux.isFetchHistorySuccess);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const demandingVoiceCall = useSelector(state => state.conversationRedux.demandingVoiceCall);
  const demandingVideoCall = useSelector(state => state.conversationRedux.demandingVideoCall);
  const createdMessage = useSelector(state => state.callingRedux.createdMessage);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);
  const isSearchMode = useSelector(state => state.conversationRedux.isSearchMode);
  const updatingGroupData = useSelector(GroupInfoSelectors.getUpdatingGroup);

  const [isShowGroupDetail, setIsShowGroupDetail] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [callingDetail, setCallingDetail] = useState(null);
  const [userAvatar, setUserAvatar] = useState("");
  const [openInitGroupCall, setOpenInitGroupCall] = useState(false);
  const [isOpenSearchPopup, setIsOpenSearchPopup] = useState(false);
  const [isAdminGroup, setIsAdminGroup] = useState(false);
  const [groupDetail, setGroupDetail] = useState({});

  const onOpenGroupInfoDrawer = () => {
    setIsShowGroupDetail(true);
  };

  // start conference call
  const onStartConference = () => {
    setOpenInitGroupCall(true);
  };

  // start audio call
  // call api to check receiver is in another call or not
  const onOpenAudioCallWindow = () => {
    onSendEndLastCall();
    dispatch(
      CallingActions.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: toCamel({ ...groupDetail, room_id: uuid() }),
        isReceiver: false,
      }),
    );
    dispatch(
      CallingActions.onCallCheck({ accountId: groupDetail.groupMembers[0].id }, StorageUtil.getCurrentPrefixKey()),
    );
  };

  // start video call
  // call api to check receiver is in another call or not
  const onOpenVideoCallWindow = () => {
    onSendEndLastCall();
    dispatch(
      CallingActions.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: toCamel({ ...groupDetail, room_id: uuid() }),
        isVideoCall: true,
        isReceiver: false,
      }),
    );

    dispatch(
      CallingActions.onCallCheck({ accountId: groupDetail.groupMembers[0].id }, StorageUtil.getCurrentPrefixKey()),
    );
  };

  // TODO: check condition to end last call
  const onJoinCurrentCall = () => {
    let lastCall = toCamel(getInteractor().LocalCallHistoryService.getLastByGroupId(groupDetail.id));
    const isAnotherCall =
      lastCall &&
      lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
      JSON.parse(lastCall.callingMembers).includes(deviceId);

    if (isAnotherCall) {
      onSendEndLastCall();
    } else {
      if (lastCall && lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling) {
        dispatch(CallingActions.getCallHistory(StorageUtil.getCurrentPrefixKey()));
        let roomId = lastCall.roomId;
        // join current conference call
        dispatch(
          CallingActions.callingSet({
            isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
            callingGroupDetail: toCamel({ ...groupDetail, roomId: roomId }),
            isReceiver: true,
            createdMessage: {
              ...createdMessage,
              [groupDetail.id]: callingDetail,
            },
            isVideoCall: callingDetail.sendType === SystemConstant.SEND_TYPE.groupVideoCall,
          }),
        );

        dispatch(CallingActions.onCallCheck({ accountId: accountId }, StorageUtil.getCurrentPrefixKey()));
      } else {
        dispatch(
          CallingActions.callingSet({
            isCallEnded: groupDetail.id,
          }),
        );
      }
    }
  };

  const onSendEndLastCall = () => {
    // If current user with current device is in another call, send end message to that group
    let lastCall = toCamel(getInteractor().LocalCallHistoryService.getLastByGroupId(groupDetail.id));

    let savedServer = getSavedServer();

    const callMessage = toCamel(getInteractor().LocalMessageService.getMessageBySourceId(lastCall.sourceId)[0] || {});

    if (
      lastCall &&
      lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
      JSON.parse(lastCall.callingMembers).includes(deviceId)
    ) {
      let endContent = JSON.stringify({
        room_id: lastCall.roomId,
      });

      let groupMembers = groupDetail.groupMembers;
      let memberIdArray = groupMembers.map(member => member.id) || [];

      let deviceList = getInteractor().LocalDeviceService.getAllByAccountList(memberIdArray);
      console.log("Sending end message from chat title", { callMessage });

      dispatch(
        ConversationAction.sendMessage({
          groupId: groupDetail.id,
          sendType: callMessage.sendType,
          content: endContent,
          parentId: callMessage.parentId,
          deviceList: [...deviceList],
          branchId: savedServer?.id,
          mentionIdsArr: [],
          threadId: null,
          callStatus:
            groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal
              ? SystemConstant.MESSAGE_CALL_STATUS.end
              : SystemConstant.MESSAGE_CALL_STATUS.waiting,
          roomId: lastCall.roomId,
          isReceiver: true,
        }),
      );
    }
  };

  const onClosePopupInit = () => {
    setOpenInitGroupCall(false);
  };

  useEffect(() => {
    if (groupDetail && groupDetail.id && isFetchHistorySuccess) {
      dispatch(
        CallingActions.callingSet({
          isFetchHistorySuccess: false,
        }),
      );

      let lastCall = toCamel(getInteractor().LocalCallHistoryService.getLastByGroupId(groupDetail.id));
      if (lastCall && lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling && !isInAnotherCall) {
        // let lastCallOption = lastCall.options ? toCamel(JSON.parse(lastCall.options)) : {};

        const parentMessage = toCamel(getInteractor().LocalMessageService.getMessageBySourceId(lastCall.sourceId)[0]);
        if (
          parentMessage &&
          Object.keys(parentMessage).length > 0 &&
          (!Boolean(callingDetail) || parentMessage.sourceId !== callingDetail.sourceId)
        ) {
          setCallingDetail(parentMessage);
        }
      } else {
        setCallingDetail(null);
      }
    }
  }, [groupDetail, isFetchHistorySuccess, isInAnotherCall]);

  useEffect(() => {
    if (demandingVideoCall && demandingVideoCall === groupDetail.id) {
      dispatch(
        ConversationAction.conversationSet({
          demandingVideoCall: null,
        }),
      );
      onOpenVideoCallWindow();
    }
  }, [demandingVideoCall, groupDetail]);

  useEffect(() => {
    if (demandingVoiceCall && demandingVoiceCall === groupDetail.id) {
      dispatch(
        ConversationAction.conversationSet({
          demandingVoiceCall: null,
        }),
      );
      onOpenAudioCallWindow();
    }
  }, [demandingVoiceCall, groupDetail]);

  useEffect(() => {
    const isValidData = groupDetail && groupDetail.id;
    if (false === isValidData) return;

    const isGlobalServer = getSavedServer().type === SystemConstant.SERVER_TYPE.server;

    // Set members of group
    const groupMemberList = groupDetail.groupMembers || [];
    if (groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal && isGlobalServer) {
      const filterMemberList = groupMemberList.filter(item => item.id !== accountId);
      setGroupMembers(filterMemberList);
    } else {
      setGroupMembers(groupMemberList);
    }

    // Set avatarPath of group
    if (groupDetail.avatarId) {
      setUserAvatar(AttachmentUtil.getAvatarRemoteUrl(groupDetail.avatarId));
    } else {
      setUserAvatar();
    }

    const findingItem = toCamel(getInteractor().LocalAccountGroupService.findByGroupId(groupDetail.id)).find(
      item => item.accountId === accountId && item.type === SystemConstant.ROLE_OBJ.admin,
    );
    setIsAdminGroup(Boolean(findingItem));
  }, [groupDetail]);

  useEffect(() => {
    setIsOpenSearchPopup(isSearchMode);
  }, [isSearchMode]);

  const isGroupOrChannel = isGroupOrChannelType(groupDetail.groupType);
  const isShowGroupDrawer = useMemo(
    () => Boolean(isShowGroupDetail && groupDetail && groupDetail.id),
    [isShowGroupDetail],
  ); // TODO: Fix rendering many time

  useEffect(() => {
    if (updatingGroupData.id === groupDetail.id) setGroupDetail(state => ({ ...state, ...updatingGroupData }));
  }, [updatingGroupData]);

  useEffect(() => {
    setGroupDetail(contextGroupDetail);
  }, [contextGroupDetail]);

  return (
    <>
      <Box className={classes.wapFlex}>
        <Box className={classes.wrapHeader} ref={searchAnchorRef}>
          <Box className={classes.flexStart}>
            <Box className={classes.nameAndAvatar}>
              <Box className={classes.avatarAndStatus}>
                <AppAvatar
                  group={{
                    groupType: groupDetail.groupType,
                    groupName: groupDetail.groupName,
                    privateF: groupDetail.privateF,
                    groupMembers: groupMembers,
                  }}
                  src={userAvatar}
                />
                <Box
                  className={clsx(isGroupOrChannel && "hidden", isOnline ? classes.onlineStatus : classes.offline)}
                />
              </Box>
              <Box className={classes.groupChatName}>
                <Typography variant="subtitle2" className={clsx(classes.titleChat, "ellipsis")}>
                  {groupDetail.groupName}
                </Typography>
                {groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group ? (
                  <Box className={clsx(classes.groupChat, "regular-sm-txt")}>{getLabel(LangConstant.TXT_GROUP)}</Box>
                ) : (
                  groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel && (
                    <Box className={clsx(classes.channelText, "regular-sm-txt")}>
                      {getLabel(LangConstant.TXT_CHANNEL)}
                    </Box>
                  )
                )}
              </Box>
            </Box>
            <Box className={classes.titleActionContainer}>
              {!isInactive &&
                (callingDetail && isGroupOrChannelType(groupDetail?.groupType) ? (
                  <Button
                    className={clsx(classes.joinCallBtn, "semiBold-sm-txt")}
                    variant="contained"
                    onClick={onJoinCurrentCall}
                    disabled={isInCall}
                  >
                    <Phone className={classes.phoneIcon} />
                    {getNSLang(LangConstant.NS_CALLING, LangConstant.TXT_JOIN_CALL)}
                  </Button>
                ) : hasInternet && isGroupOrChannel ? (
                  <IconButton
                    onClick={onStartConference}
                    disabled={
                      groupDetail.groupMembers.length > serverOptions.meetMaxCallPerson || isInCall || isInactive
                    }
                    className={classes.infoButton}
                  >
                    <VideocamOutlined color="inherit" className={classes.iconBtn} />
                  </IconButton>
                ) : hasInternet && !(isBlockedAccount || isBlockedByAccount) ? (
                  <>
                    <IconButton
                      onClick={onOpenAudioCallWindow}
                      className={classes.infoButton}
                      disabled={isInCall || isInactive}
                    >
                      <Phone color="inherit" />
                    </IconButton>

                    <IconButton
                      onClick={onOpenVideoCallWindow}
                      className={classes.infoButton}
                      disabled={isInCall || isInactive}
                    >
                      <VideoCall color="inherit" className={classes.videoCallIcon} />
                    </IconButton>
                  </>
                ) : (
                  !hasInternet && (
                    <>
                      <Tooltip title={getLabel(LangConstant.TXT_NO_INTERNET)}>
                        <span>
                          {isGroupOrChannel ? (
                            <>
                              <IconButton onClick={onStartConference} className={classes.infoButton} disabled={true}>
                                <VideocamOutlined color="inherit" className={classes.iconBtn} />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                onClick={onOpenAudioCallWindow}
                                className={classes.infoButton}
                                disabled={true}
                              >
                                <Phone color="inherit" />
                              </IconButton>
                              <IconButton
                                onClick={onOpenVideoCallWindow}
                                className={classes.infoButton}
                                disabled={true}
                              >
                                <VideoCall color="inherit" className={classes.videoCallIcon} />
                              </IconButton>
                            </>
                          )}
                        </span>
                      </Tooltip>
                    </>
                  )
                ))}

              <IconButton onClick={onOpenGroupInfoDrawer} className={classes.infoButton}>
                <Error color="action" />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <SearchPopup
          open={isOpenSearchPopup}
          anchor={searchAnchorRef.current}
          onClose={() => setIsOpenSearchPopup(false)}
        />
        <GroupInfo
          open={isShowGroupDrawer}
          data={groupDetail}
          onClose={() => setIsShowGroupDetail(false)}
          onOpenSearchPopup={() => setIsOpenSearchPopup(true)}
          isAdminGroup={isAdminGroup}
          isInactive={isInactive}
        />
      </Box>
      {openInitGroupCall && (
        <InitGroupCallPopup open={openInitGroupCall} onClose={onClosePopupInit} data={groupDetail} />
      )}
    </>
  );
};

export default memo(TitleChat);

const useStyles = makeStyles(theme => ({
  wapFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    backgroundColor: theme.palette.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  flexStart: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },

  avatarAndStatus: {
    display: "flex",
    position: "relative",
    width: 50,
    height: 50,
    minWidth: 50,
  },

  titleChat: {
    color: theme.palette.black,
    position: "relative",
  },

  onlineStatus: {
    position: "absolute",
    width: 10,
    height: 10,
    backgroundColor: "#2CC84A",
    borderRadius: "50%",
    bottom: 7,
    right: 0,
    border: "1px solid white",
  },

  offline: {
    position: "absolute",
    width: 10,
    height: 10,
    backgroundColor: theme.palette.divider,
    borderRadius: "50%",
    bottom: 7,
    right: 0,
    border: `1px solid ${theme.palette.grey[300]}`,
  },

  wrapHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 36,
    paddingBottom: 15,
  },

  groupChatName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: 10,
  },

  nameAndAvatar: {
    display: "flex",
    maxWidth: "70%",
  },

  infoButton: {
    width: "fit-content",
    height: "fit-content",
  },

  groupChat: {
    bottom: 0,
    color: theme.palette.grey[500],
    width: "100%",
  },

  channelText: {
    position: "absolute",
    bottom: 0,
    right: "-80%",
    color: theme.palette.grey[500],
  },

  videoCallIcon: {
    fontSize: 24,
  },

  joinCallBtn: {
    backgroundColor: "#2CC84A",
    textTransform: "none",
    color: "white",
    borderRadius: 26,
    padding: "6px 8px",
    height: 28,
    minHeight: "unset",
    marginLeft: 10,
    width: 95,
  },

  phoneIcon: {
    fontSize: 20,
    marginRight: 5,
  },

  titleActionContainer: {
    display: "flex",
    alignItems: "center",
  },

  iconBtn: {
    fontSize: 26,
  },
}));
