import { AppConstant } from "const";
import { useDispatch } from "react-redux";
import { SystemActions } from "redux-store";
import { StorageUtil } from "utils";

const useSynchronize = () => {
  const dispatch = useDispatch();

  const synchronize = () => {
    dispatch(SystemActions.synchronizeData());
    dispatch(SystemActions.synchMessage());
  };

  // Only be used in Home page
  const synchronizeWhenOpenApp = () => {
    synchronize();

    const synchPeriodInterval = setInterval(() => {
      if (navigator.onLine) {
        dispatch(SystemActions.periodicSynchronize(StorageUtil.getCurrentPrefixKey()));
      }
    }, AppConstant.SYNCHRONIZE_PERIOD_TIME);

    return synchPeriodInterval;
  };

  return { synchronize, synchronizeWhenOpenApp };
};

export default useSynchronize;
