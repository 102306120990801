import { StorageUtil } from "utils";
import { KeyConstant, SystemConstant } from "../const";

export class TriosRtcPeerConnection extends RTCPeerConnection {
  iceCandidateComplete: boolean = false;
  iceCandidateHandShake: boolean = false;
  channel: RTCDataChannel | null = null;
  candidateArray = [];
  finishGatheringIce: boolean = false;
  isForceGathering: boolean = false;
  callBackForceGathering: Function | null = null;

  sendEventFinishCompleteGatherIceCandidate() {
    console.log("sendEventFinishCompleteGatherIceCandidate");
    console.log(this);
    console.log(this.connectionState);
    if (this.connectionState === "connected") {
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      let message = `${SystemConstant.SHARE_ICE} ${accountId} ${JSON.stringify(this.candidateArray)}`;
      if (this.channel) {
        console.log(message);
        this.iceCandidateHandShake = true;
        this.channel.send(message);
      }
    }
  }

  handlerGathering(callBackSendMessageCall: Function) {
    let countIce = 0;
    let inter = setInterval(() => {
      let hasTurn = Boolean(
        this.candidateArray?.some(function (c: RTCIceCandidate) {
          return Boolean(c.candidate?.includes("typ relay"));
        }),
      );
      let hasStun = Boolean(
        this.candidateArray?.some(function (c: RTCIceCandidate) {
          return Boolean(c.candidate?.includes("typ srflx"));
        }),
      );
      let isFullOptions = hasTurn && hasStun;
      if (this.finishGatheringIce) {
        if (this.callBackForceGathering) this.callBackForceGathering();
        clearInterval(inter);
      } else if (this.isForceGathering) {
        // force case
        callBackSendMessageCall();
        if (this.callBackForceGathering) this.callBackForceGathering();
        clearInterval(inter);
      } else if (isFullOptions) {
        // support all stun and turn
        callBackSendMessageCall();
        if (this.callBackForceGathering) this.callBackForceGathering();
        clearInterval(inter);
      } else {
        countIce++;
        if (countIce > 30) {
          if (this.callBackForceGathering) this.callBackForceGathering();
          clearInterval(inter);
        } else if (
          !this.finishGatheringIce &&
          this.candidateArray?.length >= SystemConstant.MIN_ICE_CANDIDATE &&
          hasTurn
        ) {
          callBackSendMessageCall();
          if (this.callBackForceGathering) this.callBackForceGathering();
          clearInterval(inter);
        }
      }
    }, SystemConstant.TIME_RETRY_ICE);
  }

  forceGathering(callbackForce: Function) {
    if (!this.finishGatheringIce) {
      this.callBackForceGathering = callbackForce;
      this.isForceGathering = true;
    } else {
      callbackForce();
      this.isForceGathering = true;
    }
  }
}
