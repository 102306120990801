import { call, put } from "redux-saga/effects";
import { StorageUtil, toCamel, toSnake } from "utils";
import { getInteractor } from "services/local.service";
import { ApiConstant, KeyConstant } from "const";
import { checkCurrentBranchByPrefix, formatArray2Key, formatPagingParams } from "./saga.helper";
import { AccountActions, ConversationActions, ProfileActions } from "redux-store";
import { remoteApiFactory } from "services/remote.service";

export function* getAccountList(action) {
  let errorReason;
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const apiPayload = formatPagingParams({ limit: 10, filter: JSON.stringify(data) });

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).getAccountList, apiPayload);
    const responseData = response?.data?.data;
    if (response.status === ApiConstant.STT_OK && Array.isArray(responseData)) {
      yield put(
        AccountActions.accountSuccess({
          accountList: toCamel(responseData) || [],
        }),
      );
    } else {
      errorReason = response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (errorReason) yield put(AccountActions.accountFailure(errorReason));
}

export function* saveAccountListByIds(prefixKey, data) {
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  let errorReason;

  try {
    const param = toSnake(data);

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).getAccountByIds, formatArray2Key(param));
    const responseData = response.data;

    if (response.status === ApiConstant.STT_OK && Array.isArray(response.data)) {
      getInteractor(prefixKey).LocalAccountService.save(responseData);
      const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
      const isUpdateCurrentAccount = responseData.find(item => item.id === currentAccountId);

      if (isCurrentBranch && isUpdateCurrentAccount) {
        yield put(
          ProfileActions.profileSet({
            updatedProfile: new Date().getTime(),
          }),
        );
      }
    } else {
      errorReason = response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (isCurrentBranch && errorReason) yield put(AccountActions.accountFailure(errorReason));
}

export function* blockUser(action) {
  let errorReason;
  try {
    const { blockedAccountId } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).blockAccount, {
      blocked_account_id: blockedAccountId,
    });
    if (response.status === ApiConstant.STT_OK) {
      getInteractor(prefixKey).LocalContactService.blockContact(blockedAccountId);

      yield put(
        AccountActions.accountSuccess({
          blockAccountId: blockedAccountId,
        }),
      );

      yield put(
        ConversationActions.checkBlockedContact({
          accountId: blockedAccountId,
        }),
      );
    } else {
      errorReason = response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (errorReason) yield put(AccountActions.accountFailure(errorReason));
}

export function* unblockUser(action) {
  let errorReason;
  try {
    const { blockedAccountId } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).unblockAccount, {
      blocked_account_id: blockedAccountId,
    });
    if (response.status === ApiConstant.STT_OK) {
      yield getInteractor(prefixKey).LocalContactService.unblockContact(blockedAccountId);

      yield put(
        AccountActions.accountSuccess({
          blockAccountId: blockedAccountId,
        }),
      );

      yield put(
        ConversationActions.checkBlockedContact({
          accountId: blockedAccountId,
        }),
      );
    } else {
      errorReason = response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (errorReason) yield put(AccountActions.accountFailure(errorReason));
}
