import { KeyConstant, SystemConstant, FormatConstant, LangConstant, AppConstant } from "const";
import { convertMillisecondsToDate } from "./date.utils";
import { getLabel } from "language";
import parsePhoneNumber from "libphonenumber-js";
import { isEqual } from "lodash";
import { filesize } from "filesize";
import { LocalDbManagement, getInteractor } from "services/local.service";
import { camelizeKeys, decamelizeKeys } from "humps";
import { getLoginBranchInfo } from "./view.utils";
import store, { BranchActions } from "redux-store";

// Electron util
const ElectronCommonUtil = window.electronUtils.common;
export const StorageUtil = {
  ...window.electronUtils.storage,
  setCommonKey: window.electronUtils.storage.setCommonKey,
  getCommonKey: window.electronUtils.storage.getCommonKey,
  removeCommonKey: window.electronUtils.storage.removeCommonKey,
  setItem: window.electronUtils.storage.setItem,
  getItem: window.electronUtils.storage.getItem,
  removeItem: window.electronUtils.storage.removeItem,
  getAllStoreKeys: window.electronUtils.storage.getAllStoreKeys,
};
export const CryptoUtil = window.electronUtils.crypto;
export const FileUtil = window.electronUtils.file;
export const AttachmentUtil = window.electronUtils.attachment;

export const uuid = ElectronCommonUtil.uuid;
export const getPrefixKey = ElectronCommonUtil.getPrefixKey;
export const highlightString = ElectronCommonUtil.highlightString;
export const removeVietnameseTones = ElectronCommonUtil.removeVietnameseTones;

export const setLoginData = (camelResponse, phoneNumber) => {
  const prefixKey = getPrefixKey(camelResponse.accountId, camelResponse.branchId);
  const isValid =
    Object.keys(camelResponse).length > 0 && phoneNumber && prefixKey && false === prefixKey.includes("undefined");
  if (!isValid) throw new Error("CAN NOT LOGIN - Invalid data");

  // Update domain after login
  const tmpDomain = getLoginBranchInfo().domain;
  StorageUtil.setCommonKey(KeyConstant.KEY_CURRENT_DOMAIN, tmpDomain);
  const branchInfo = {
    id: camelResponse.branchId,
    domain: tmpDomain,
  };

  // Storage branch data
  StorageUtil.setCommonKey(KeyConstant.KEY_PREFIX, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_BRANCH_INFO, branchInfo, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_TOKEN, camelResponse.accessToken, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_ACCOUNT_ID, camelResponse.accountId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_BRANCH_ID, camelResponse.branchId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_DEVICE_ID, camelResponse.deviceId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_PHONE, phoneNumber, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_LOGIN_TIME, new Date().getTime(), prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_MEET_TOKEN, camelResponse.meetToken, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_EXPIRED_TIME, camelResponse.expiredTime, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_REFRESH_EXPIRED_TIME, camelResponse.refreshExpiredTime, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_REFRESH_TOKEN, camelResponse.refreshToken, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_SCOPES, camelResponse.scopes, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, false, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_TRIOS_ACCESS, camelResponse.triosAccess, prefixKey);

  // Copy key to branch data
  const identityPublicKey = StorageUtil.getCommonKey(KeyConstant.KEY_IDENTITY_PUBLIC_KEY);
  const identityPrivateKey = StorageUtil.getCommonKey(KeyConstant.KEY_IDENTITY_PRIVATE_KEY);
  const activeSignedPrekeyId = StorageUtil.getCommonKey(KeyConstant.KEY_ACTIVE_SIGNED_PREKEY_ID);
  const nextSignedPrekeyId = StorageUtil.getCommonKey(KeyConstant.KEY_NEXT_SIGNED_PREKEY_ID);
  const nextPrekeyId = StorageUtil.getCommonKey(KeyConstant.KEY_NEXT_PREKEY_ID);

  StorageUtil.setItem(KeyConstant.KEY_IDENTITY_PUBLIC_KEY, identityPublicKey, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_IDENTITY_PRIVATE_KEY, identityPrivateKey, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_ACTIVE_SIGNED_PREKEY_ID, activeSignedPrekeyId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_NEXT_SIGNED_PREKEY_ID, nextSignedPrekeyId, prefixKey);
  StorageUtil.setItem(KeyConstant.KEY_NEXT_PREKEY_ID, nextPrekeyId, prefixKey);

  // Set selected branch in storage
  StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, {
    id: camelResponse.branchId,
    accountId: camelResponse.accountId,
    phone: phoneNumber,
    loginState: SystemConstant.STATE.active,
    ...toCamel(branchInfo),
  });
};

export const handlingLogoutBranch = (prefixKey, url = null) => {
  const activeBranchList = LocalDbManagement.find({ state: SystemConstant.STATE.active });
  if (activeBranchList && activeBranchList.length === 0) handlingLogoutAll();
  if (url) {
    const branch = activeBranchList.find(item => url.includes(item.branch_domain));
    if (branch) {
      prefixKey = getPrefixKey(branch.account_id, branch.branch_id);
    }
  }

  const [accountId, branchId] = prefixKey.split("_");
  if (false === Boolean(accountId && branchId)) return;
  getInteractor(prefixKey).LocalInitDataService.deleteTable();
  const triosManagement = activeBranchList.find(item => item.account_id === accountId && item.branch_id === branchId);
  if (triosManagement) {
    LocalDbManagement.save([
      {
        ...triosManagement,
        state: SystemConstant.STATE.inactive,
        modified: new Date().getTime(),
      },
    ]);
  }

  store.dispatch(
    BranchActions.branchSuccess({
      fetchBranchTimestamp: new Date().getTime(),
    }),
  );
  
  StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, false, prefixKey);
  const logoutBranchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey);
  if (branchId !== SystemConstant.GLOBAL_BRANCH_ID) {
    StorageUtil.setCommonKey(KeyConstant.KEY_DEFAULT_BRANCH, { ...logoutBranchInfo, isDeepLink: false });
  }
  StorageUtil.removeCommonKey(prefixKey);

  if (activeBranchList.length === 1) handlingLogoutAll();
};

export const handlingLogoutAll = () => {
  const commonKey = [
    KeyConstant.KEY_IDENTITY_PUBLIC_KEY,
    KeyConstant.KEY_IDENTITY_PRIVATE_KEY,
    KeyConstant.KEY_ACTIVE_SIGNED_PREKEY_ID,
    KeyConstant.KEY_NEXT_SIGNED_PREKEY_ID,
    KeyConstant.KEY_NEXT_PREKEY_ID,
    KeyConstant.KEY_IS_VALID_KEYS,
    KeyConstant.KEY_DEFAULT_BRANCH,
  ];

  const storageAllKey = StorageUtil.getAllStoreKeys();
  storageAllKey.forEach(item => {
    if (false === commonKey.includes(item)) {
      StorageUtil.removeCommonKey(item);
    }
  });

  window.location.reload();
};

// Logout branch and return if having logged in branch
export const hasMoreLoggedInBranch = () => {
  const currentPrefixKey = StorageUtil.getCommonKey(KeyConstant.KEY_PREFIX);
  const currentBranchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, currentPrefixKey);
  const activeDBList = LocalDbManagement.find({ state: SystemConstant.STATE.active }); // list branch is logging in
  const activeDB = activeDBList.find(item => item.branch_id !== currentBranchId);
  handlingLogoutBranch(currentPrefixKey);

  if (activeDB) {
    const prefixKey = getPrefixKey(activeDB.account_id, activeDB.branch_id);
    const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey);

    StorageUtil.setCommonKey(KeyConstant.KEY_PREFIX, prefixKey);
    StorageUtil.setCommonKey(KeyConstant.KEY_CURRENT_DOMAIN, branchInfo.domain);
    StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, branchInfo);

    return true;
  }

  return false;
};

export const toCamel = obj => camelizeKeys(obj) || {};

export const toSnake = obj => decamelizeKeys(obj) || {};

export const isArray = array => array && Array.isArray(array);

export const isString = str => typeof str === "string";

export const formatPagination = data => {
  return {
    paging: data?.paging || 1,
    page: data?.page || 1,
    size: data?.size || 10,
    asc: data?.asc || 1,
  };
};

export const createParams = data => {
  let defaultParam = formatPagination();
  return {
    ...defaultParam,
    ...data,
  };
};

export const validatePhone = content => {
  const phoneNumber = parsePhoneNumber(content, "VN");
  return (
    phoneNumber &&
    phoneNumber.isValid() &&
    phoneNumber.nationalNumber.length >= 9 &&
    phoneNumber.nationalNumber.length <= 10
  );
};

export const validateEmail = content => {
  /* eslint-disable no-useless-escape */
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return content && regex.test(String(content).replace(/\s+/g, "").toLowerCase());
};

export const objectToJSON = data => JSON.stringify(data);

export const convertHex2rgba = (hex, alpha = 1) => {
  try {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  } catch (error) {
    return "";
  }
};

export const formatLimitNumber = number => {
  if (!number) return 0;

  let result = "";
  if (0 <= number && number < 10) result = "0" + number;
  if (10 <= number && number < 100) result = `${number}`;
  if (number > 99) result = "99+";

  return result;
};

export const isVideo = type => type && type.includes("video");

export const isAudio = type => type && type.includes("audio");

export const isImage = type => type && type.includes("image");

export const removeDuplicateInArray = arr => {
  const tmpArray = new Set(arr);
  return [...tmpArray];
};

export const isArrayNotEquals = (firstArray, secondArray) =>
  !firstArray ||
  !secondArray ||
  (Array.isArray(firstArray) && JSON.stringify(firstArray) !== JSON.stringify(secondArray));

export const isObjectNotEqual = (firstObject, secondObject) => !isEqual(firstObject, secondObject);

export const isCompareDataArray = (firstArray, secondArray) => {
  let isValid = firstArray && secondArray && Array.isArray(firstArray) && Array.isArray(secondArray);
  if (!isValid) return firstArray === secondArray;
  let sortingFirstArray = JSON.stringify(firstArray.sort());
  let sortingSecondArray = JSON.stringify(secondArray.sort());
  return sortingFirstArray === sortingSecondArray;
};

export const returnOsType = () => {
  let type = SystemConstant.DEVICE_TYPE.other;
  if ((navigator.userAgent.indexOf("Win") || navigator.userAgent.indexOf("Windows")) !== -1)
    type = SystemConstant.DEVICE_TYPE.window;
  else if (navigator.userAgent.indexOf("Mac") !== -1) type = SystemConstant.DEVICE_TYPE.mac;
  else if (navigator.userAgent.indexOf("Linux") !== -1) type = SystemConstant.DEVICE_TYPE.linux;
  else if (navigator.userAgent.indexOf("Android") !== -1) type = SystemConstant.DEVICE_TYPE.android;
  else if (navigator.userAgent.indexOf("like Mac") !== -1) type = SystemConstant.DEVICE_TYPE.ios;
  else type = SystemConstant.DEVICE_TYPE.other;
  return type;
};

export const addUrlParam = (basePath, key, val) => {
  let newParam = key + "=" + val;
  let queryParams = "?" + newParam;

  // If the "search" string exists, then build params from it
  if (basePath && basePath.includes("?")) {
    // Try to replace an exist instance
    queryParams = queryParams.replaceAll("?", "&");
    // If nothing was replaced, then add the new param to the end
    if (queryParams === basePath) {
      queryParams += "&" + newParam;
    }
  }

  return basePath + queryParams;
};

export const deepCloneJsonObject = json => {
  if (!json) return null;
  return JSON.parse(JSON.stringify(json));
};

export const sortArrayObject = (array, field = "created") =>
  array.sort((first, second) => (first[field] > second[field] ? 1 : second[field] > first[field] ? -1 : 0));

export const sortArrayObjectReserved = (array, field = "created") =>
  array.sort((first, second) => (first[field] < second[field] ? 1 : second[field] < first[field] ? -1 : 0));

export const convertString2JSON = (string, defaultValue) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return defaultValue || string;
  }
  return JSON.parse(string);
};

export const removeDuplicatesWithKey = (array, key) => {
  let lookup = new Set();
  return array.filter(obj => !Boolean(obj) || (!lookup.has(obj[key]) && lookup.add(obj[key])));
};

export const randomKeyGenerator = (length = 8) => {
  let characters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+=/";
  let result = "";
  for (let i = length; i > 0; --i) result += characters[Math.round(Math.random() * (characters.length - 1))];
  return result;
};

export const isJSONString = string => {
  try {
    let result = JSON.parse(string);
    if (typeof result === "object") return true;
  } catch (error) {
    return false;
  }
};

export const convertJSONObject = object => {
  if (isJSONString(object)) object = convertString2JSON(object);

  if (typeof object === "object") {
    Object.entries(object).forEach(entries => {
      object[entries[0]] = convertString2JSON(entries[1]);
      let childValue = object[entries[0]];

      if (childValue && typeof childValue === "object") {
        Object.entries(childValue).forEach(childEntries => {
          childValue[childEntries[0]] = convertString2JSON(childEntries[1]);
        });
      }
      object[entries[0]] = childValue;
    });
  }

  return object;
};

export const getCreateTimeObject = data => {
  let newDateTimeData = {};
  if (data) {
    let today = new Date().setUTCHours(0, 0, 0, 0);
    data = data.sort((a, b) => b.created - a.created);
    data.forEach(element => {
      let time =
        element.created > today
          ? getLabel(LangConstant.TXT_TODAY)
          : convertMillisecondsToDate(element.created, FormatConstant.FM_GALLERY_TIME);
      if (!newDateTimeData[time]) {
        newDateTimeData[time] = [];
      }
      newDateTimeData[time].push(element);
    });
  }

  return newDateTimeData;
};

export const convertFileSizeUnit = (size = 0) => {
  let result = "";

  if (size >= 1000 * 1000 * 1000) {
    let gb = (size / (1000 * 1000 * 1000)).toFixed(3);
    result = `${gb} GB`;
  } else if (size >= 1000 * 1000) {
    let mb = (size / (1000 * 1000)).toFixed(3);
    result = `${mb} MB`;
  } else if (size >= 1000) {
    let kb = (size / 1000).toFixed(3);
    result = `${kb} KB`;
  } else if (size > 0) {
    result = `${size} byte`;
  } else {
    result = "0 byte";
  }

  return result;
};

export const getFileColor = fileName => {
  let result = "#799098";
  if (false === Boolean(fileName)) return result;
  switch (true) {
    case fileName.endsWith(".doc"):
    case fileName.endsWith(".docs"):
    case fileName.endsWith(".docx"):
      result = "#2A65C0";
      break;

    case fileName.endsWith(".xlsx"):
    case fileName.endsWith(".xls"):
      result = "#229C5B";
      break;

    case fileName.endsWith(".pptx"):
    case fileName.endsWith(".pptm"):
    case fileName.endsWith(".ppt"):
      result = "#E85332";
      break;

    case fileName.endsWith(".pdf"):
      result = "#F40F02";
      break;

    default:
      result = "#799098";
      break;
  }

  return result;
};

export const appendBuffer = (buffer1, buffer2) => {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp;
};

export const getAllIndexOfKeyword = (keyword, string) => {
  let startingIndices = [];
  let indexOccurence = string.indexOf(keyword, 0);
  while (indexOccurence >= 0) {
    startingIndices.push(indexOccurence);
    indexOccurence = string.indexOf(keyword, indexOccurence + 1);
  }

  return startingIndices;
};

export const formatStringWithKeyword = (keyword, string) => {
  let result = "";
  let tmpContent = string.toLowerCase();
  let tmpKeyword = keyword.toLowerCase();
  let keywordIndexArr = getAllIndexOfKeyword(tmpKeyword, tmpContent);
  if (string.length > 100 && keywordIndexArr.length > 1) {
    result = `${string.substr(0, 32)} ... ${string.substr(-32)}`;
  } else if (string.length > 100 && keywordIndexArr.length === 1) {
    let index = tmpContent.indexOf(tmpKeyword) > 32 ? 32 : tmpContent.indexOf(tmpKeyword);
    let lastIndex =
      string.length - tmpContent.indexOf(tmpKeyword) - keyword.length > 32
        ? 32
        : string.length - tmpContent.indexOf(tmpKeyword) - keyword.length - 12;
    result = `${string.substr(0, index)} ... ${keyword} ... ${string.substr(0 - lastIndex)}`;
  } else {
    result = string;
  }

  return result;
};

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(84|)?(\d{9})$/);
  if (match) {
    return "(+" + match[1] + ")" + match[2];
  }
  return null;
};

export const formatEmoij = hex => {
  let hexArray = hex.split(/[ \-]/gm);

  return hexArray.join("");
};

export const convertHexToEmoji = hex => {
  let rawHexArray = [];
  try {
    const hexArray = hex.split(";").filter(value => Boolean(value?.trim()));
    rawHexArray = hexArray.map(h => h.replace(/(&#x)+/gm, "0x"));

    return rawHexArray.length === 2
      ? String.fromCodePoint(rawHexArray[0], rawHexArray[1])
      : String.fromCodePoint(rawHexArray[0]);
  } catch (error) {
    console.log(rawHexArray, error);
  }
  return "";
};

export const swapArrayElements = (array, firstElementIndex, secondElementIndex) => {
  let tmpArray = [...array];
  let temp = tmpArray[firstElementIndex];
  tmpArray[firstElementIndex] = tmpArray[secondElementIndex];
  tmpArray[secondElementIndex] = temp;

  return tmpArray;
};

let newReg = `(http|https){1}://([-a-zA-Z0-9@:%_\+.~#?&//=]*)`;

export const isExternalLink = data => {
  const regex = new RegExp(newReg);
  return regex.test(data);
};

export const getExternalLinkFromString = data => {
  if (data) {
    const regex = new RegExp(newReg);

    const urlArray = data.match(regex);

    return urlArray && urlArray.length > 0 ? urlArray[0] : null;
  }

  return null;
};

export const textNormalize = str => {
  return ElectronCommonUtil.removeVietnameseTones(str).toLowerCase();
};

export const getAllLetterIndex = (string, letter) => {
  if (string.length === 0 || !letter) return [];

  let result = [];
  for (let index = 0; index < string.length; index++) {
    if (string[index] === letter) result.push(index);
  }

  return result;
};

export const downloadFile = (fileName, mediaPath, mimeType = "") => {
  let downloadFileType = mimeType;
  let downloadFileName = fileName;
  let downloadFileLink = mediaPath;

  const isNotSupportVideo = mimeType.includes("video") && !AppConstant.SUPPORT_VIDEO_TYPES.includes(mimeType);

  if (isNotSupportVideo) {
    downloadFileType = AppConstant.DEFAULT_VIDEO_TYPE;
    downloadFileName = window.electronUtils.file.renameExtensionFile(fileName);
  }

  if (downloadFileLink.includes("base64")) {
    downloadFileLink = getUrlFromBase64(downloadFileLink, downloadFileType);
  }

  const element = document.createElement("a");
  element.setAttribute("href", downloadFileLink);
  element.setAttribute("download", downloadFileName);
  element.setAttribute("type", downloadFileType);
  element.click();
  element.remove();

  return true;
};

const getUrlFromBase64 = (base64, mimeType) => {
  const base64Data = base64.split(";base64,")[1];
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  return URL.createObjectURL(blob);
};

export const removeUndefinedValueInArray = arr => {
  const filtered = arr.filter(el => el !== null && el !== undefined);
  return filtered;
};

export const copyImageToClipboard = async base64 => {
  base64 = base64.replace("data:image/jpeg;base64", "data:image/png;base64");
  let image = await fetch(base64);
  let blob = await image.blob();
  let data = [new window.ClipboardItem({ "image/png": blob })];

  await navigator.clipboard.write(data);
};

export const compareObject = (obj1, obj2) => {
  if (Object.keys(obj1).length === 0 || Object.keys(obj2).length === 0) return false;

  let isEqual = true;

  Object.keys(obj1).forEach(key => {
    if (obj2[key] === undefined || JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      isEqual = false;
    }
  });

  return isEqual;
};

export const getMaxObjectWithKey = (array, key = "created", min = false) => {
  if (!array || !array.length) return {};

  let resultItem = array.reduce(function (a, b) {
    if (min) {
      return a[key] < b[key] ? a : b;
    } else {
      return a[key] > b[key] ? a : b;
    }
  });

  return resultItem || {};
};

export const convertFileSize = size => {
  if (size) {
    try {
      return filesize(size, { round: 2, standard: "jedec" });
    } catch (error) {
      console.log(error);
    }
  }

  return "0 B";
};

export const getFirstLetter = text => text?.charAt(0) || "";

export const isLoginBranch = branch => {
  branch = branch || StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER) || {};
  if (false === Boolean(branch.accountId && branch.id)) return false;
  const prefixKey = getPrefixKey(branch.accountId, branch.id);
  return Boolean(StorageUtil.getItem(KeyConstant.KEY_TOKEN, prefixKey));
};
