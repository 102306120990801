import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyConstant, LangConstant, PathConstant } from "const";
import { ConfirmDialog } from "components";
import { useDispatch } from "react-redux";
import { DeviceActions } from "redux-store";
import { getCommonLang } from "utils/lang.utils";
import { useDeviceList } from "hooks";
import ChooseDeviceDrawer from "./DeviceManagement/ChooseDeviceDrawer";
import { StorageUtil, hasMoreLoggedInBranch } from "utils";
import AccountItem from "./AccountItem";
import { useHistory } from "react-router-dom";

const AccountLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const { isMaster, slaveDeviceList } = useDeviceList();

  const [isConfirmLogout, setIsConfirmLogout] = useState(false);
  const [isOpenChooseDevice, setIsOpenChooseDevice] = useState(false);

  const handleShowConfirm = () => setIsConfirmLogout(true);

  const handleChooseDevice = () => {
    setIsConfirmLogout(false);
    setIsOpenChooseDevice(true);
  };

  const handleCancelLogout = () => {
    setIsConfirmLogout(false);
  };

  const handleLogoutSlave = () => {
    dispatch(
      DeviceActions.deviceLogout({
        slaveDeviceId: [deviceId],
      }),
    );
    const hasMoreBranch = hasMoreLoggedInBranch();
    if (hasMoreBranch) {
      history.replace(PathConstant.CHOOSE_BRANCH_PAGE);
    } else {
      history.replace(PathConstant.LOGIN);
    }
  };

  const hasSlave = slaveDeviceList.length > 0;
  const title = isMaster && hasSlave ? getCommonLang(LangConstant.TXT_CONFIRM_LOGOUT_MASTER) : accountObjContent.logout;
  const content = getCommonLang(
    isMaster
      ? hasSlave
        ? LangConstant.TXT_CONTENT_LOGOUT_MASTER
        : LangConstant.TXT_LOGOUT_MASTER_WITHOUT_SLAVE
      : LangConstant.TXT_CONTENT_LOGOUT_SLAVE,
  );

  const submitProps =
    isMaster && hasSlave
      ? {
          submitText: getCommonLang(LangConstant.TXT_CHOOSE_DEVICE),
          onClick: handleChooseDevice,
        }
      : {
          submitText: accountObjContent.logout,
          onClick: handleLogoutSlave,
        };

  return (
    <>
      <AccountItem text={accountObjContent.logout} onClick={handleShowConfirm} />

      {isConfirmLogout && (
        <ConfirmDialog
          open
          title={title}
          content={content}
          submitProps={submitProps}
          cancelProps={{ onClick: handleCancelLogout }}
        />
      )}
      {isOpenChooseDevice && <ChooseDeviceDrawer open onClose={() => setIsOpenChooseDevice(false)} />}
    </>
  );
};

AccountLogout.propTypes = {};

export default AccountLogout;
