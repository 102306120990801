import { AppConstant, KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { StorageUtil, toSnake, uuid } from "utils";
import { validateInvolved, validateMentions } from "utils/view.utils";
import { IGNORE_MESSAGE } from "./saga.helper";

export function* updateThread(prefixKey, mes, isNotify = false, isMyMessage = false) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();

  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

    const NORMAL_MESSAGE = [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage];

    const threadInfo = yield getInteractor(prefixKey).LocalThreadService.getByThreadId(mes.thread_id);
    const parentMessage = yield getInteractor(prefixKey).LocalMessageService.getMessageBySourceId(mes.thread_id)[0] ||
      {};
    if (false === Boolean(parentMessage.id)) return;
    const isDeleteMessage = mes.send_type === SystemConstant.SEND_TYPE.deleteMessage;
    const isMessageEdit = NORMAL_MESSAGE.includes(mes.send_type) && Boolean(mes.parent_id);
    const isIgnoreMessage = IGNORE_MESSAGE.includes(mes.send_type);
    const isNewUnread =
      mes.status !== SystemConstant.MESSAGE_STATUS.read &&
      mes.sender_id !== accountId &&
      !Boolean(mes.parent_id) &&
      !isIgnoreMessage;

    const threadMentions = validateMentions(threadInfo, mes.mentions, parentMessage.mentions);
    const isInvolved = validateInvolved(mes, threadMentions) ? 1 : 0;

    // update thread info
    if (Boolean(threadInfo)) {
      const updateTotalReply =
        isMessageEdit || isIgnoreMessage
          ? threadInfo.total_reply
          : isDeleteMessage
          ? threadInfo.total_reply - 1
          : threadInfo.total_reply + 1;

      const updateThreadInfo = {
        totalReply: updateTotalReply,
        totalUnread: isMyMessage ? 0 : isNewUnread ? threadInfo.total_unread + 1 : threadInfo.total_unread,
        involvedF: threadInfo.involved_f || isInvolved,
        state: updateTotalReply > 0 ? AppConstant.THREAD_STATE.active : AppConstant.THREAD_STATE.inactive,
        modified: new Date().getTime(),
        threadMentions: JSON.stringify(threadMentions),
        settingType: threadInfo.setting_type,
      };

      yield getInteractor(prefixKey).LocalThreadService.update(toSnake(updateThreadInfo), mes.thread_id);
    } else if (!Boolean(threadInfo) && !isIgnoreMessage) {
      const createdTime = new Date().getTime();

      const newThreadInfo = {
        id: uuid(),
        threadId: mes.thread_id,
        groupId: mes.group_id,
        totalReply: 1,
        totalUnread: isMyMessage ? 0 : isNewUnread ? 1 : 0,
        involvedF: isInvolved,
        state: AppConstant.THREAD_STATE.active,
        created: createdTime,
        modified: createdTime,
        threadAccountId: parentMessage.sender_id,
        threadCreated: parentMessage.created,
        threadContent: parentMessage.content,
        threadMentions: JSON.stringify(threadMentions),
        branchId: mes.branch_id,
        groupType: null,
        settingType: null,
      };

      yield getInteractor(prefixKey).LocalThreadService.save([toSnake(newThreadInfo)]);
    }

    // delete parent message
    if (mes.parent_id && !Boolean(mes.thread_id) && mes.send_type === SystemConstant.SEND_TYPE.deleteMessage) {
      const threadInfo = yield getInteractor(prefixKey).LocalThreadService.getByThreadId(mes.parent_id);
      if (threadInfo) {
        const updateThreadInfo = {
          state: AppConstant.THREAD_STATE.inactive,
          modified: new Date().getTime(),
        };

        yield getInteractor(prefixKey).LocalThreadService.update(toSnake(updateThreadInfo), mes.parent_id);
      }
    }

    if (threadInfo?.involved_f || Boolean(isInvolved)) {
      isNotify = true;
    } else {
      isNotify = false;
    }

    return isNotify;
  } catch (error) {
    console.log(error);
    return isNotify;
  }
}
