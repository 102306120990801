import { AppConstant, KeyConstant } from "const";
import store, { ConversationActions, SystemActions } from "redux-store";
import { CommonBranchInfoService } from "services";
import { AttachmentUtil, FileUtil, StorageUtil, isLoginBranch } from "utils";
import { changeBranchServer } from "utils/view.utils";

// Connect to DB
export const getInteractor = (prefixKey = "") => {
  const interactor = window.interactor.getInstance(prefixKey);
  return {
    // Independent - the data is saved in branch's database (format name: <accountId_branchId>.db)
    LocalAccountService: interactor.account,
    LocalAccountKeyService: interactor.accountKey,
    LocalGroupService: interactor.group,
    LocalGroupSettingService: interactor.groupSetting,
    LocalSettingService: interactor.setting,
    LocalBranchAccountService: interactor.branchAccount,
    LocalBranchService: interactor.branch,
    LocalContactService: interactor.contact,
    LocalMessageService: interactor.message,
    LocalDeviceService: interactor.device,
    LocalAccountGroupService: interactor.accountGroup,
    LocalInitDataService: interactor.initData,

    LocalNotificationService: interactor.notification,
    LocalSenderKeySharedService: interactor.senderKeyShared,
    LocalEmojiService: interactor.emoji,
    LocalCallHistoryService: interactor.callHistory,
    LocalMsgErrorResendService: interactor.messageErrorReSend,
    LocalMsgErrorSendNullService: interactor.messageErrorSendNull,
    LocalThreadService: interactor.thread,
    LocalRestoreService: interactor.restore,
    LocalApiCallService: interactor.apiCall,

    LocalCipherService: interactor.cipher,
  };
};

export const getCommonInteractor = () => window.interactor.getCommonInteractor();

// Common - logic is not related prefixKey (common function or query data in trios.db)
export const LocalDbManagement = getCommonInteractor().dbManagement;
export const LocalKeyActionService = getCommonInteractor().keyAction;

export const LocalEventService = {
  setLogoApp: branchId => {
    // Emit event to electron main
    window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.setLogoApp, branchId);

    const localLogoPath = AttachmentUtil.getAppIcon(branchId);
    return FileUtil.getUrlByLocalPath(localLogoPath);
  },

  showApplication: () => window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.showApplication),
};

export const LocalAppNotificationService = {
  setBadgeCount: count => {
    window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.setBadgeCount, count || 0);
  },

  showNotification: (title, noticeData) => {
    const { content, groupId, messageId, prefixKey } = noticeData;
    store.dispatch(
      SystemActions.systemSet({
        newNotification: { groupId, messageId, prefixKey },
      }),
    );

    const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};
    const branchLogo =
      branchInfo.domain && branchInfo.id
        ? CommonBranchInfoService.getBranchAvatarUrl(branchInfo.domain, branchInfo.id)
        : "";

    if (!window.Notification) {
      console.warn("Browser does not support notifications.");
    } else {
      createNotification(
        title,
        {
          icon: branchLogo,
          body: content,
        },
        () => {
          LocalEventService.showApplication();
          if (isLoginBranch(branchInfo)) {
            // Switch to branch
            changeBranchServer(branchInfo);
            // Go to message by groupId/ messageId
            store.dispatch(
              ConversationActions.setSelectGroupId({
                selectedGroupId: groupId,
                threadingId: null,
              }),
            );

            store.dispatch(
              ConversationActions.conversationSet({
                scrollToChildId: messageId,
              }),
            );
          }
        },
      );
    }
  },
};

const createNotification = async (title, notificationOptions = {}, onClick) => {
  let notice;
  // Check if permission is already granted
  if (Notification.permission === "granted") {
    // Show notification here
    notice = new Notification(title, notificationOptions);
  } else {
    try {
      // Request permission from user
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // Show notification here
        notice = new Notification(title, notificationOptions);
      } else {
        console.error("User blocked notifications.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (onClick) notice.onclick = onClick;

  return notice;
};
