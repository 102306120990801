import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { convertFileSizeUnit, convertJSONObject, convertString2JSON, getFileColor, toCamel } from "utils";
import { FilePreviewIcon } from "../../../../icons";

const DataFile = ({ data }) => {
  const classes = useStyles();

  const [fileObj, setFileObj] = useState({});

  useEffect(() => {
    if (data) {
      let fileObj = toCamel(convertJSONObject(convertString2JSON(data.content)));
      setFileObj(fileObj);
    }
  }, []);

  return (
    <Box className={classes.userAvatarAndName}>
      <FilePreviewIcon color={getFileColor(fileObj?.metaData?.fileName)} />
      <Box className={classes.customerDetailBox}>
        <Box className={classes.boxBodyUserInfo}>
          <Typography variant="body1" className={clsx(classes.typographyName, "semiBold-sm-txt")}>
            {fileObj?.metaData?.fileName || ""}
          </Typography>
        </Box>
        <Box className={classes.boxRating}>
          <Typography variant="body2" className={clsx(classes.customerDetail, "regular-sm-txt")}>
            {convertFileSizeUnit(fileObj?.metaData?.size || 0)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DataFile;

const useStyles = makeStyles(theme => ({
  cover: {
    width: 25,
    height: 30,
  },
  userAvatarAndName: {
    width: "100%",
    display: "flex",
    paddingTop: 20,
  },
  customerDetailBox: {
    width: "100%",
    marginLeft: 16,
    minWidth: "max-content",
  },
  typographyName: {
    lineHeight: "18px",
    height: "fit-content",
    width: "95%",
    color: theme.palette.primary.main,
  },
  customerDetail: {
    lineHeight: "18px",
    height: "fit-content",
    paddingTop: 3,
    color: "#1E272E",
  },
}));
