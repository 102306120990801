import { SystemConstant } from "const";
import { all, call, delay } from "redux-saga/effects";
import { getPrefixKey } from "utils";
import { LocalDbManagement } from "services/local.service";
import { StorageUtil } from "utils";
import { DELAY_SYNCH_MSG_AFTER_5_SECOND, getLastMessages } from "./synch-message.saga";

export function* synchMessageBranches() {
  let activeBranchList = [];
  const currentPrefixKey = StorageUtil.getCurrentPrefixKey();
  try {
    if (navigator.onLine) {
      // Get all branches except the current branch
      const branchList = LocalDbManagement.find({ state: SystemConstant.STATE.active });
      activeBranchList = branchList
        .map(item => ({
          ...item,
          prefixKey: getPrefixKey(item.account_id, item.branch_id),
        }))
        .filter(item => item.prefixKey !== currentPrefixKey);

      if (activeBranchList.length > 0) {
        yield all(activeBranchList.map(item => call(getLastMessages, item.prefixKey)));
      }
    }

    // TODO: Need to optimize loop this function when there are not another active branch
    yield delay(
      activeBranchList.length > 0 ? DELAY_SYNCH_MSG_AFTER_5_SECOND * 1.2 : DELAY_SYNCH_MSG_AFTER_5_SECOND * 12,
    );
    yield call(synchMessageBranches);
  } catch (error) {
    console.error(error);
  }
}
