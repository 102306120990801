import { makeStyles } from "@mui/styles";

export const getCreatedTimeText = message => {
  let msgCreatedTime;
  if (message && message.isAvatar) {
    if (message.senderName && message.sentTime) {
      msgCreatedTime = `${message.senderName}, ${message.sentTime}`;
    } else if (message.senderName) {
      msgCreatedTime = message.senderName;
    } else if (message.sentTime) {
      msgCreatedTime = message.sentTime;
    }
  }

  return msgCreatedTime;
};

export const useMessageStyle = makeStyles(theme => ({
  messengerRoot: {
    width: "fit-content",
    borderRadius: 20,
    backgroundColor: "#F1F3F6",
    overflow: "hidden",
    color: theme.palette.black,
  },

  myMessage: {
    backgroundColor: "#008FE8",
    color: "white",
  },

  spacing: {
    padding: "12px 16px",
  },

  hiddenAvatar: {
    "& img": { display: "none" },
  },

  createdTimeText: {
    fontSize: 12,
    fontWeight: 550,
    marginBottom: 6,
  },
}));
