import { useEffect, useState } from "react";
import { KeyConstant, SystemConstant } from "const";
import { isArray, toCamel } from "utils";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "../services/local.service";

const useContactList = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID);
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);

  const unfriendContactId = useSelector(state => state.contactRedux.unfriendContactId);
  const addNewContact = useSelector(state => state.contactRedux.addNewContactResponse);

  const [isFetching, setIsFetching] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [searchingName, setSearchingName] = useState("");

  const handleDataFromResponse = response => {
    if (response && isArray(response)) {
      response = toCamel(response).map(contact => ({
        ...contact,
        name: contact.contactName || contact.accountName || "",
      }));

      if (selectedBranch.type === SystemConstant.SERVER_TYPE.branch) {
        // Remove login account
        response = response.filter(accountItem => accountItem.id !== accountId);
      }

      const sortContactList = sortBy(response, [contact => contact.name.toLowerCase().trim()]);
      setContactList(sortContactList);
    } else {
      console.error("useContactList - Response is not array");
    }
  };

  /**
   * In Branch Mode
   * contactList = distinct(contact + personalChat)
   *
   * In Global Mode
   * contactList = contact
   *
   */
  const getAllContactByName = async (name = "") => {
    setIsFetching(true);
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    name = name ? name.trim() : "";
    setSearchingName(name);
    let contactList = await getInteractor(prefixKey).LocalContactService.searchContactList(name);
    const contactListIds = contactList.map(item => item.contact_id);

    // If branch server => add account inbox
    if (selectedBranch.type === SystemConstant.SERVER_TYPE.branch) {
      const personalMsgList = await getInteractor(prefixKey).LocalGroupService.searchGroupByCondition("", {
        group_type: SystemConstant.GROUP_CHAT_TYPE.personal,
      });
      const inboxAccountList = [];
      personalMsgList.forEach(item => {
        const group = toCamel(item);
        if (group.groupMembers[0]) {
          inboxAccountList.push(group.groupMembers[0]); // Ensure that member is valid data
        }
      });
      const inboxAccountIdList = inboxAccountList.map(item => item.id);
      const branchAccountList = getInteractor(prefixKey).LocalBranchAccountService.find({
        account_id: inboxAccountIdList,
        state: SystemConstant.STATE.active,
        branch_id: branchId,
      });

      contactList = branchAccountList.reduce((results, item) => {
        const accountDetails = inboxAccountList.find(account => account.id === item.account_id) || {};
        if (false === contactListIds.includes(item.account_id)) {
          contactListIds.push(item.account_id);
          results.push({
            ...item,
            account_name: accountDetails.name,
            phone: accountDetails.phone,
            avatar_id: accountDetails.avatarId,
            contact_id: item.account_id,
          });
        }

        return results;
      }, contactList || []);
    }

    handleDataFromResponse(contactList);
    setIsFetching(false);
  };

  useEffect(() => {
    if (isFetching) return;

    getAllContactByName(searchingName);
  }, [selectedBranch, unfriendContactId, addNewContact]);

  return { isFetching, contactList };
};

export default useContactList;
