import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dialog, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { getInteractor } from "services/local.service";
import OTPVerify from "components/OTPVerify";
import ClearIcon from "@mui/icons-material/Clear";
import RestoreAction from "redux-store/restore.redux";
import { toSnake } from "utils";
import SuccessDialog from "components/dialog/SuccessDialog";
import { NoticeDialog } from "components";
import { StorageUtil } from "utils";

const OTPChangeRole = ({ open, onClose, slaveDeviceId, onResendOtp, onLogout, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const dispatch = useDispatch();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const accountDetails = getInteractor().LocalAccountService.get(accountId);

  const verifyStatus = useSelector(state => state.restoreRedux.verifyStatus);

  const [retries, setRetries] = useState(1);
  const [errorContent, setErrorContent] = useState(null);
  const [otpValues, setOtpValues] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [countDownTime, setCountDownTime] = useState(new Date().getTime());
  const [isBlocked, setIsBlocked] = useState(false);

  // Send request to verify otp
  const handleVerify = () => {
    dispatch(
      RestoreAction.verifyChangeDeviceRole(
        toSnake({
          code: otpValues,
          slaveDeviceId: slaveDeviceId,
          type: 1,
        }),
      ),
    );
  };

  const handleSuccess = () => {
    if (Boolean(onLogout)) onLogout();
    handleCancel();
  };

  const handleCancel = () => {
    setRetries(1);
    setErrorContent(null);
    setIsSuccess(false);
    dispatch(
      RestoreAction.restoreSet({
        verifyStatus: AppConstant.VERIFY_OTP_ERROR_TYPE.notStart,
      }),
    );
    onClose();
  };

  useEffect(() => {
    let successTimeout;

    switch (verifyStatus) {
      case AppConstant.VERIFY_OTP_ERROR_TYPE.success:
        setErrorContent(null);
        setIsSuccess(true);
        successTimeout = setTimeout(() => {
          handleSuccess();
        }, 3000);
        break;
      case AppConstant.VERIFY_OTP_ERROR_TYPE.wrongOtp:
        if (retries < AppConstant.MAX_RETRY_TIMES) {
          setErrorContent(
            getNSLang(LangConstant.NS_LOGIN, LangConstant.TXT_OTP_ERROR_MESSAGE, {
              tryTimes: AppConstant.MAX_RETRY_TIMES - retries,
            }),
          );
          setRetries(state => state + 1);
        } else {
          const blockTimestamp = new Date().getTime();
          StorageUtil.setItem(KeyConstant.KEY_DISABLE_CHANGE_ROLE, blockTimestamp);
          setErrorContent(getCommonLang(LangConstant.TXT_BLOCK_CHANGE_DEVICE_ROLE));
          setCountDownTime(blockTimestamp);
          setIsBlocked(true);
        }
        break;
      case AppConstant.VERIFY_OTP_ERROR_TYPE.expiredOtp:
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, LangConstant.TXT_OTP_EXPIRED));
        break;
      case AppConstant.VERIFY_OTP_ERROR_TYPE.limitResend:
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, LangConstant.TXT_TRY_AGAIN_LATER));
        break;
      case AppConstant.VERIFY_OTP_ERROR_TYPE.systemError:
        setErrorContent(getCommonLang(LangConstant.TXT_SERVER_ERROR));
        break;
      default:
        setErrorContent(null);
    }

    return () => successTimeout && clearTimeout(successTimeout);
  }, [verifyStatus]);

  useEffect(() => {
    let countDownInterval = null;
    const blockTimestamp = StorageUtil.getItem(KeyConstant.KEY_DISABLE_CHANGE_ROLE);
    const unblockTimestamp = Number(blockTimestamp) + 300000;
    let time = unblockTimestamp - countDownTime;

    if (time > 0) {
      setIsBlocked(true);
      countDownInterval = setInterval(() => {
        if (time > 0) {
          setErrorContent(getCommonLang(LangConstant.TXT_BLOCK_CHANGE_DEVICE_ROLE));
          time = time - 1000;
        } else {
          setIsBlocked(false);
          setErrorContent("");
          clearInterval(countDownInterval);
        }
      }, 1000);
    } else {
      setIsBlocked(false);
      setErrorContent(null);
    }

    return () => {
      if (countDownInterval) clearInterval(countDownInterval);
    };
  }, [countDownTime]);

  return (
    <>
      <Dialog open={open} onClose={handleCancel} classes={{ paper: classes.paper }} {...otherProps}>
        <IconButton className={classes.btnClose} onClick={onClose}>
          <ClearIcon />
        </IconButton>
        <OTPVerify
          TitleProps={{
            title: getLabel(LangConstant.TXT_CHANGE_ROLE_OTP_TITLE),
            content: getLabel(LangConstant.FM_CHANGE_ROLE_OTP_CONTENT, {
              phone: accountDetails ? accountDetails.phone : "",
            }),
          }}
          InputProps={{ errorContent: errorContent, onChange: setOtpValues }}
          ActionProps={{
            onResendOtp: onResendOtp,
            className: isBlocked ? classes.noneDisplayOtpActions : classes.displayOtpActions,
          }}
        />
        <Box>
          <Button
            fullWidth
            variant="contained"
            className={classes.btnAction}
            onClick={handleVerify}
            disabled={otpValues.length < SystemConstant.DEFAULT_OTP_LENGTH || isBlocked}
          >
            {getLabel(LangConstant.TXT_VERIFY)}
          </Button>
          <Button fullWidth onClick={handleCancel} className={classes.btnAction}>
            {getNSLang(LangConstant.NS_COMMON, LangConstant.TXT_CANCEL)}
          </Button>
        </Box>
      </Dialog>

      {isSuccess && (
        <SuccessDialog
          open
          onClose={handleSuccess}
          title={getCommonLang(LangConstant.TXT_CHOOSE_DEVICE_SUCCESS)}
          content={Boolean(onLogout) ? getCommonLang(LangConstant.TXT_LOGOUT_LATER) : ""}
          hideBackdrop={true}
        />
      )}

      {isBlocked && (
        <NoticeDialog
          open
          title={getCommonLang(LangConstant.TXT_NOTIFICATION)}
          content={getCommonLang(LangConstant.TXT_BLOCK_CHANGE_DEVICE_ROLE)}
          submitProps={{
            submitText: getCommonLang(LangConstant.TXT_GOT_IT),
            onClick: () => {
              setIsBlocked(false);
              handleCancel();
            },
            className: classes.confirmBtn,
          }}
        />
      )}
    </>
  );
};

export default OTPChangeRole;

const useStyles = makeStyles({
  paper: {
    padding: 50,
    width: 430,
  },

  btnClose: {
    width: "fit-content",
    position: "absolute",
    right: 10,
    top: 20,
  },

  btnAction: {
    marginTop: 8,
    minHeight: 40,
    fontSize: 14,
  },

  noneDisplayOtpActions: {
    display: "none",
  },

  confirmBtn: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#d7221f",
    },
  },
});
