import { Provider } from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider, CssBaseline, useMediaQuery } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import theme from "theme/material";
import "emoji-mart/css/emoji-mart.css";
import "react-phone-input-2/lib/style.css";
import "react-multi-carousel/lib/styles.css";
import store from "./redux-store";
import "./language";
import "./theme/styles";
import { PathConstant } from "const";
import AuthenticationRoute from "components/AuthenticationRoute";
import { HomePage, LoginPage, NotFoundPage, WarningDevicePage } from "pages";
import { CreateName } from "pages/LoginPage/components";
import { HandleThirdToken, HandleAppDialog } from "components";
import ChooseBranchPage from "pages/ChooseBranchPage";
const log = require("electron-log/renderer");
// console.log = log.log;
// console.warn = log.warn;
// console.info = log.info;
// console.error = log.error;
const App = () => {
  let isMobileUI = useMediaQuery(theme.breakpoints.down("xs"));
  let isMobile = detectCallingNotiWindow() ? false : detectMobile() || isMobileUI;

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HandleThirdToken />
          <HandleAppDialog />

          <HashRouter>
            <Switch>
              {isMobile && <Route component={WarningDevicePage} />}
              <Route exact path={PathConstant.CHOOSE_BRANCH_PAGE} component={ChooseBranchPage} />
              <Route exact path={PathConstant.LOGIN} component={LoginPage} />
              <Route exact path={PathConstant.CREATE_NAME} component={CreateName} />
              <AuthenticationRoute path={PathConstant.ROOT} component={HomePage} />
              <Route exact path={PathConstant.NOT_FOUND} component={NotFoundPage} />
              <Redirect to={PathConstant.NOT_FOUND} />
            </Switch>
          </HashRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;

const detectMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
};

const detectCallingNotiWindow = () => {
  const regex = new RegExp(`#${PathConstant.CALLING_NOTIFICATION}`);
  const hash = window.location.hash;

  return regex.test(hash);
};
