import { Avatar, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { getInteractor } from "services/local.service";
import { AttachmentUtil, getFirstLetter } from "utils";
import { convertMillisecondsToDate } from "utils/date.utils";
import ChatTypography from "../../MessengerChat/ChatItem/ChatTypography";

const MessageThreadItem = ({ data }) => {
  const classes = useStyles();

  const [accountName, setAccountName] = useState("");
  const [accountAvatar, setAccountAvatar] = useState("");

  useEffect(() => {
    if (data) {
      const account = getInteractor().LocalAccountService.get(data.senderId);
      setAccountName(account?.name);

      setAccountAvatar(AttachmentUtil.getAvatarRemoteUrl(account?.avatar_id));
    }
  }, [data]);

  return (
    <>
      {data.senderId && (
        <Box className={classes.component}>
          <Avatar className={classes.accountAvatar} src={accountAvatar}>
            {getFirstLetter(accountName)}
          </Avatar>
          <Box className={classes.content}>
            <Box className={classes.contentTitle}>
              <Typography>{accountName}</Typography>
              <Typography className={classes.textColor}>
                {convertMillisecondsToDate(data.created, "HH:mm - dd/MM/yyyy")}
              </Typography>
            </Box>
            <ChatTypography
              className={clsx(classes.messageContent, classes.textColor, "ellipsis")}
              mentionList={data.groupMembers}
              messageContent={data.content}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default MessageThreadItem;

const useStyles = makeStyles(() => ({
  component: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },

  accountAvatar: {
    marginRight: 10,
  },

  content: {
    width: "100%",
  },

  contentTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  textColor: {
    color: "#7F7F80",
    fontSize: 14,
  },

  messageContent: {},

  timeSend: {},
}));
