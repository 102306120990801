import React, { useState, useEffect, Fragment } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BranchSelectors, ProfileSelectors } from "redux-store";
import { isObjectNotEqual } from "utils";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { AvatarInfo, DrawerLayout } from "components";
import AccountCoverView from "./AccountCoverView";
import YourProfile from "./YourProfile";
import YourDevice from "./DeviceManagement/YourDevice";
import { useAccount } from "hooks";
import SettingApp from "./SettingApp";
import { BackupData, RestoreData } from "./BackupRestore";
import ChangingStatus from "./ChangingStatus";
import AccountLogout from "./AccountLogout";
import NotificationBadge from "../Notification/NotificationBadge";

const Account = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });

  const { currentAccount, getAccount } = useAccount();
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const updateProfile = useSelector(ProfileSelectors.getUpdateProfile);

  const [isShow, setIsShow] = useState(false);
  const [accountDetail, setAccountDetail] = useState({});

  const handleChangeAccount = account => setAccountDetail(preAccount => ({ ...preAccount, ...account }));

  useEffect(() => {
    if (currentAccount?.id && isObjectNotEqual(currentAccount, accountDetail)) {
      setAccountDetail(currentAccount);
    }
  }, [currentAccount]);

  useEffect(() => {
    getAccount();
    setAccountDetail({});
  }, [selectedBranch]);

  useEffect(() => {
    getAccount();
  }, [updateProfile]);

  if (Object.values(accountDetail).length === 0) return <Fragment />;

  const isActive = accountDetail.status === SystemConstant.STATE.active;
  return (
    <>
      <Box className={classes.avatarBox}>
        <AvatarInfo
          avatar={{
            name: accountDetail.name,
            avatarId: accountDetail.avatarId,
          }}
          title={accountDetail.name}
          size={AppConstant.SIZE_OBJ.large}
          onClick={() => setIsShow(true)}
        />
        <Box className={classes.notiBox}>
          <NotificationBadge />
        </Box>
      </Box>

      <DrawerLayout open={isShow} onClose={() => setIsShow(false)} title={accountObjContent.title}>
        <AccountCoverView
          isActive={isActive}
          avatarUrl={accountDetail.avatarUrl}
          backgroundUrl={accountDetail.backgroundUrl}
          name={accountDetail.name}
        />

        <ChangingStatus accountDetail={accountDetail} onChange={handleChangeAccount} />
        <YourProfile accountDetail={accountDetail} onChange={handleChangeAccount} />
        <YourDevice />
        <SettingApp />
        <BackupData />
        <RestoreData />
        <AccountLogout />
      </DrawerLayout>
    </>
  );
};

Account.propTypes = {};

export default Account;

const useStyles = makeStyles({
  avatarBox: {
    display: "flex",
    paddingRight: 16,
    alignItems: "center",
    "& *": { cursor: "pointer" },
  },

  logoutButton: {
    width: 40,
    height: 40,
  },

  notiBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutText: {
    fontSize: 15,
    fontWeight: 400,
    color: "#1E272E",
  },
});
