import { useSynchronize } from "hooks";
import store, { ConversationActions } from "redux-store";
import { useEffect } from "react";
import { AppConstant } from "const";
import { SAGA_REDUCERS } from "sagas/saga.helper";

const HandleAppEvents = () => {
  const { synchronizeWhenOpenApp } = useSynchronize();

  useEffect(() => {
    window.onmessage = handleApplicationEvents;
    const synchPeriodInterval = synchronizeWhenOpenApp();
    // Get Mapping branches base on active branch list at local DB
    store.dispatch(SAGA_REDUCERS.getBranchMapping());

    return () => clearInterval(synchPeriodInterval);
  }, []);

  return null;
};

export default HandleAppEvents;

const handleApplicationEvents = async (event, msgEvent) => {
  if (event.source === window) {
    if (event.data === AppConstant.EVENT_RECEIVED_URL_METADATA) {
      const [port] = event.ports;
      port.onmessage = event => {
        store.dispatch(
          ConversationActions.conversationSetLinkData({
            [event.data.url]: {
              messageId: event.data.messageId,
              metaData: event.data.metaData,
            },
          }),
        );
      };
    }
  }
};
