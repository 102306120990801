import React, { memo } from "react";
import { Avatar, Box, Card, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AccountStatus } from "components";
import { getFirstLetter } from "utils";

const AccountCoverView = ({ isActive, avatarUrl, backgroundUrl, name }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Card className={classes.coverBox} elevation={0}>
        {backgroundUrl ? (
          <CardMedia
            component="img"
            alt=""
            className={backgroundUrl ? classes.cover : classes.coverDefault}
            src={backgroundUrl}
          />
        ) : (
          <Box className={classes.coverDefault} />
        )}
        <Box className={classes.avatarBox}>
          <Avatar className={classes.avatar} src={avatarUrl}>
            {getFirstLetter(name)}
          </Avatar>
        </Box>
      </Card>
      <Typography className={classes.name}>{name}</Typography>
      <Box>
        <AccountStatus isActive={isActive} />
      </Box>
    </Box>
  );
};

export default memo(AccountCoverView);

const useStyles = makeStyles({
  root: {
    "& > *": { textAlign: "center" },
  },

  coverBox: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  coverDefault: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  avatarBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },

  avatar: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    fontSize: 48,
  },

  name: {
    fontWeight: 700,
    fontSize: 15,
  },
});
