import React, { memo, useEffect, useState } from "react";
import clsx from "clsx";
import StringFormat from "string-format";
import PropTypes from "prop-types";
import { Box, CardMedia, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, FormatConstant, ImageConstant } from "const";
import { getExternalLinkFromString } from "utils";
import { useSelector } from "react-redux";

const LinkPreview = ({ message }) => {
  const classes = useStyles();
  const previewUrl = getExternalLinkFromString(message?.content);
  const linkData = useSelector(state => state.conversationRedux.linkData);

  const [data, setData] = useState({
    title: "",
    description: "",
    image: "",
    url: "",
  });

  const handleClickExternalLink = event => {
    event.preventDefault();
    if (event.currentTarget?.href) window.handleExternalLink.openExternalLink(event.currentTarget.href);
  };

  useEffect(() => {
    const id = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, message.id);
    const elements = document.querySelectorAll(`#${id} a`);
    if (elements) {
      for (const element of elements) {
        element.addEventListener("click", handleClickExternalLink);
      }
    }

    return () => {
      if (elements) {
        for (const element of elements) {
          element.removeEventListener("click", handleClickExternalLink);
        }
      }
    };
  }, [message]);

  useEffect(() => {
    if (linkData[previewUrl]) {
      const dataMessageLink = linkData[previewUrl].metaData;

      setData({
        ...dataMessageLink,
        image: dataMessageLink.image || ImageConstant.NoFileImage,
      });
    } else {
      const fetchMetaTimeout = setTimeout(() => {
        window.getLinkMetaData.fetch({
          url: previewUrl,
          messageId: message.id,
        });
      }, AppConstant.DEBOUNCE_TIME);

      return () => clearTimeout(fetchMetaTimeout);
    }
  }, [linkData, previewUrl]);

  return (
    <Link href={previewUrl} className={clsx(classes.previewRoot)}>
      <Box>
        <CardMedia
          component="img"
          className={clsx(classes.previewImage, {
            [classes.skeletonLoading]: !Boolean(data.image),
          })}
          image={data.image}
        />

        {data.title && (
          <Typography className={clsx("bold-md-txt ellipsis", classes.previewTitle)}>{data.title}</Typography>
        )}

        {data.description && (
          <Typography className={clsx("regular-sm-txt ellipsis", classes.previewDescribe)}>
            {data.description}
          </Typography>
        )}
      </Box>
    </Link>
  );
};

export default memo(LinkPreview);

LinkPreview.propTypes = {
  message: PropTypes.object,

  classes: PropTypes.shape({
    root: PropTypes.string,
    message: PropTypes.string,
    preview: PropTypes.string,
    image: PropTypes.string,
    skeletonLoading: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
  }),
};

LinkPreview.defaultProps = {
  message: {},

  classes: {
    root: "",
    message: "",
    preview: "",
    image: "",
    skeletonLoading: "",
    title: "",
    desc: "",
  },
};

const useStyles = makeStyles(theme => ({
  previewRoot: {
    backgroundColor: theme.palette.grey[100],
    textDecoration: "none",
    paddingBottom: 12,

    "&:hover": {
      textDecoration: "none",
    },
  },

  previewImage: {
    width: "100%",
    height: "100%",
    maxHeight: "200px",
    minHeight: "120px",
    backgroundSize: "cover",
    objectFit: "cover",
  },

  skeletonLoading: {
    background: `linear-gradient(to right, 
      rgba(255,255,255,0),
      rgba(255,255,255,0.2),
      rgba(255,255,255,0.4),
      rgba(255,255,255,0.55),
      rgba(255,255,255,0.6),
      rgba(255,255,255,0.4),
      rgba(255,255,255,0.2),
      rgba(255,255,255,0))`,
    backgroundSize: "200% 100%",
    animation: `$loading 3000ms linear infinite`,
  },

  previewTitle: {
    padding: "8px 16px 4px",
    color: theme.palette.black,
    WebkitLineClamp: 2,
  },

  previewDescribe: {
    padding: "0 16px",
    color: theme.palette.grey[600],
    WebkitLineClamp: 3,
  },

  "@keyframes loading": {
    to: {
      backgroundPositionX: "-200%",
    },
  },
}));
