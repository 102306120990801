import { call, put } from "redux-saga/effects";
import {
  AttachmentUtil,
  getPrefixKey,
  removeDuplicateInArray,
  removeDuplicatesWithKey,
  toCamel,
  toSnake,
  uuid,
} from "utils";
import { getInteractor, LocalDbManagement } from "services/local.service";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { saveAccountListByIds } from "./account.saga";
import { BranchActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { checkCurrentBranchByPrefix } from "./saga.helper";

export function* getBranches(prefixKey) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  const savedServer = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};

  try {
    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).getBranchList);
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data;

      if (Array.isArray(responseData) && responseData.length > 0) {
        yield getInteractor(prefixKey).LocalBranchService.save(responseData);
        saveFileFromBranchList(prefixKey, responseData);

        const currentBranchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
        const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
        const dbManagement =
          LocalDbManagement.find({ account_id: currentAccountId, branch_id: currentBranchId })[0] || {};
        const branchDataResponse = responseData.find(item => item.id === currentBranchId);
        const isValidData = currentAccountId && dbManagement.owner_name && branchDataResponse;
        let selectedBranch = {
          account_id: currentAccountId,
          phone: dbManagement.owner_name,
          login_state: dbManagement.state,
        };
        selectedBranch = toCamel({ ...selectedBranch, ...branchDataResponse });

        // Select branch
        const isNeedUpdate = !savedServer || JSON.stringify(savedServer) !== JSON.stringify(selectedBranch);
        if (isValidData && isNeedUpdate) {
          StorageUtil.setItem(KeyConstant.KEY_BRANCH_INFO, selectedBranch, prefixKey);
          StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, selectedBranch);
          if (isCurrentBranch) {
            yield put(BranchActions.updateSelectedBranch(selectedBranch));
          }

          LocalDbManagement.save([
            {
              ...dbManagement,
              branch_name: branchDataResponse.name,
              logo_app: branchDataResponse.attachment_id,
              modified: new Date().getTime(),
            },
          ]);

          if (isCurrentBranch) {
            yield put(
              BranchActions.branchSuccess({
                fetchBranchTimestamp: new Date().getTime(),
              }),
            );
          }

          return true;
        } else if (!branchDataResponse?.id) {
          console.warn("NOT FOUND BRANCH ON SERVER");
        }

        yield call(getBranchAccount, prefixKey);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

const saveFileFromBranchList = (prefixKey, branchArr) => {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const tmpArr = toCamel(branchArr);

  if (Array.isArray(tmpArr)) {
    tmpArr.forEach(branch => {
      if (branch.attachmentId) {
        const fileName = branch.attachmentId + ".png";
        if (!AttachmentUtil.exitsLocalFile(branch.attachmentId, fileName)) {
          const response = remoteApiFactory
            .getBranchApi(prefixKey)
            .getAttachment({ attachment_id: branch.attachmentId });
          try {
            response.then(responseData => {
              const unit8String = new Uint8Array(responseData.data);
              if (responseData.status === ApiConstant.STT_OK) {
                AttachmentUtil.savePublicFile(unit8String, branch.attachmentId, fileName);
              }
            });
          } catch (error) {
            console.log({ error });
          }
        }
      }
    });
  }
};

export function* getBranchAccount(prefixKey) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  const savedServer = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};

  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

    const branchAccount = getInteractor(prefixKey).LocalBranchService.getActiveBranch();
    const currentBranches = getInteractor(prefixKey).LocalBranchService.getAll();
    const idsArr = branchAccount.map(item => ({
      branchId: item.id,
      lastTime: item.created + 1,
    }));

    const getBranchAccountResponse = yield call(
      remoteApiFactory.getBranchApi(prefixKey).getBranchAccount,
      JSON.stringify(toSnake(idsArr)),
    );

    if (getBranchAccountResponse.status === ApiConstant.STT_OK) {
      const branchAccountArray = getBranchAccountResponse.data;
      const camelAccountArr = toCamel(branchAccountArray);
      const accountIdArr = camelAccountArr.map(item => item.accountId);
      const firstActiveBranch = branchAccountArray.find(
        item2 => item2.state === SystemConstant.BRANCH_STATE.active && item2.account_id === accountId,
      );
      const selectedBranch = [...branchAccount, ...currentBranches].find(
        item => item.id === firstActiveBranch?.branch_id,
      );

      selectedBranch.account_id = accountId;

      const isNeedUpdate = !savedServer || Object.keys(savedServer).length !== Object.keys(selectedBranch || {}).length;

      if (isNeedUpdate) {
        if (isCurrentBranch && selectedBranch) {
          StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, toCamel(selectedBranch));
          yield put(BranchActions.updateSelectedBranch(toCamel(selectedBranch)));
        } else {
          return;
        }
      }

      // Check if current account status in any branch !== 1 (active) -> remove that branch from selected server list
      const inactiveBranchList = branchAccountArray.filter(
        item =>
          item.status === SystemConstant.STATUS.inactive &&
          item.state !== SystemConstant.STATE.inactive &&
          item.account_id === accountId,
      );

      if (inactiveBranchList.length > 0) {
        const inactiveBranchId = removeDuplicatesWithKey(inactiveBranchList, "branch_id").map(item => item.branch_id);
        if (isCurrentBranch) {
          yield put(
            BranchActions.branchSet({
              inactiveBranchArray: inactiveBranchId,
            }),
          );
        }
      }

      yield saveAccountListByIds(prefixKey, {
        account_ids: removeDuplicateInArray(accountIdArr),
      });

      getInteractor(prefixKey).LocalBranchAccountService.save(branchAccountArray);
    }
  } catch (error) {
    console.log(error);
  }
}

export const getBranchMapping = async () => {
  try {
    const localActiveServer = LocalDbManagement.find({ state: SystemConstant.STATE.active });

    for (let index = 0; index < localActiveServer.length; index++) {
      const item = localActiveServer[index];
      const result = await syncBranch(item);
      if (!result) console.log("ERROR_MAPPING_BRANCH", item);
    }
  } catch (error) {
    console.log({ error });
  }
};

const syncBranch = async branch => {
  try {
    const prefixKey = getPrefixKey(branch.account_id, branch.branch_id);
    const response = await remoteApiFactory.getBranchApi(prefixKey).syncBranchMapping({
      owner_name: branch.owner_name,
    });
    const branchMapping = response.data;
    if (response.status === ApiConstant.STT_OK && Array.isArray(branchMapping)) {
      const localBranch = LocalDbManagement.getAll();
      const localBranchId = localBranch.map(item => item.branch_id);
      const suggestBranch = branchMapping.filter(item => false === localBranchId.includes(item.branch_id));
      const suggestBranch2SaveLocal = suggestBranch.map(item => ({
        id: uuid(),
        account_id: "",
        branch_id: item.branch_id,
        owner_name: item.owner_name,
        state: SystemConstant.STATE.suggested,
        options: null,
        token: null,
        branch_name: item.branch_name,
        branch_domain: item.branch_domain,
        logo_app: item.branch_logo,
        created: Date.now(),
        modified: Date.now(),
      }));

      LocalDbManagement.save(suggestBranch2SaveLocal);

      // Update to server
      const localServerByPhone = localBranch.filter(item => item.owner_name === branch.owner_name);
      if (localServerByPhone.length > branchMapping.length) {
        const branchMappingId = branchMapping.map(item => item.branch_id);
        const updateBranches = localServerByPhone.filter(item => false === branchMappingId.includes(item.branch_id));

        const dataToUpdate = updateBranches.map(item => ({
          owner_name: item.owner_name,
          branch_id: item.branch_id,
          branch_domain: item.branch_domain,
          branch_logo: item.logo_app,
          branch_name: item.branch_name,
        }));

        await remoteApiFactory.getBranchApi(prefixKey).createBranchMapping({
          account_branch_mappings: JSON.stringify(dataToUpdate),
        });
      }
      return true;
    }
  } catch (error) {
    console.log({ error });
  }

  return false;
};
