import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, CardMedia, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getCreateTimeObject } from "utils";
import { ImageConstant, LangConstant } from "const";
import NoData from "./NoData";
import DataMedia from "./DataMedia";
import { CarouselMedia } from "components";

const Media = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [dateTimeData, setDateTimeData] = useState({});
  const [isShowCarousel, setIsShowCarousel] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);

  const onMediaClick = clickedItem => {
    setIsShowCarousel(true);
    setClickedIndex(data.findIndex(item => clickedItem.id === item.id));
  };

  useEffect(() => {
    setDateTimeData(getCreateTimeObject(data));
  }, [data]);

  return (
    <Box className={classes.root}>
      <CarouselMedia
        data={data}
        open={isShowCarousel}
        onClose={() => setIsShowCarousel(false)}
        initialIndex={clickedIndex}
      />
      {dateTimeData && Object.keys(dateTimeData).length > 0 ? (
        Object.keys(dateTimeData).map(key => (
          <Grid key={key} className={classes.mediaContainer}>
            <Typography className={classes.titleMedia} variant="subtitle2">
              {key}
            </Typography>
            <Grid key={key} className={classes.paddingMedia} container spacing={2}>
              {dateTimeData[key].map((dataMap, index) => (
                <DataMedia key={index} data={dataMap} onClick={() => onMediaClick(dataMap)} />
              ))}
            </Grid>
          </Grid>
        ))
      ) : (
        <NoData
          media={<CardMedia component="img" alt="cover" className={classes.cover} src={ImageConstant.NoMediaImage} />}
          textOne={getLabel(LangConstant.TXT_CONTENT_NO_MEDIA)}
          textTwo={getLabel(LangConstant.TXT_NO_MEDIA)}
        />
      )}
    </Box>
  );
};

Media.propTypes = {
  name: PropTypes.array,
};

Media.defaultProps = {
  data: [],
};

export default Media;

const useStyles = makeStyles({
  root: {
    marginTop: 14,
  },
  cover: {
    position: "relative",
    marginBottom: 12,
    height: "auto",
    maxWidth: "100%",
    width: "unset",
  },
  titleMedia: {
    marginBottom: 30,
    paddingLeft: 14,
  },
  paddingMedia: {
    padding: "0 16px 0 28px",
  },
  mediaContainer: {
    marginBottom: 14,
  },
});
