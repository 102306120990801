import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Button, CardMedia, Container } from "@mui/material";
import { AppConstant, ImageConstant, KeyConstant, PathConstant, SystemConstant } from "const";
import { LoginForm, OTPAuth } from "./components";
import { useHandleBranchInvite } from "pages/HomePage/HandlingParallel/HandlingBranchInvite";
import { StorageUtil } from "utils";
import { ArrowBack } from "@mui/icons-material";
import clsx from "clsx";
import { getSavedServer } from "utils/view.utils";
import { AuthActions, AuthSelectors, BranchActions, BranchSelectors } from "redux-store";
import { LocalKeyActionService } from "services/local.service";

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useHandleBranchInvite();
  const currentServer = getSavedServer();

  const tmpLoginBranch = useSelector(BranchSelectors.getTmpLoginBranch);
  const authLoginStatus = useSelector(AuthSelectors.authLoginStatus);

  const [isHavePhone, setIsHavePhone] = useState(false);
  const [loginBranchInfo, setLoginBranchInfo] = useState({});
  const [isShowBackBtn, setIsShowBackBtn] = useState(false);

  const handleBackToBranch = () => {
    if (currentServer.id) {
      dispatch(AuthActions.authReset());
      history.replace(PathConstant.ROOT);
    } else {
      StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, {});
      setIsHavePhone(false);
      dispatch(AuthActions.authReset());
      dispatch(
        BranchActions.branchSet({
          loginBranch: {},
        }),
      );
    }
  };

  useEffect(() => {
    if (authLoginStatus === AppConstant.VERIFY_OTP_ERROR_TYPE.success) setIsHavePhone(true);
  }, [authLoginStatus]);

  useEffect(() => {
    const defaultBranch = StorageUtil.getCommonKey(KeyConstant.KEY_DEFAULT_BRANCH) || {};
    StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, defaultBranch);
    dispatch(
      BranchActions.branchSet({
        loginBranch: defaultBranch,
      }),
    );

    return () => {
      setIsHavePhone(false);
      setIsShowBackBtn(false);
    };
  }, []);

  useEffect(() => {
    const isLoginBranch = tmpLoginBranch.id && tmpLoginBranch.id !== SystemConstant.GLOBAL_BRANCH_ID;
    setIsShowBackBtn(currentServer.id || isLoginBranch);
    const isAnotherBranch = loginBranchInfo.id !== tmpLoginBranch.id || loginBranchInfo.phone !== tmpLoginBranch.phone;
    if (tmpLoginBranch.isDeepLink && tmpLoginBranch.phone && isAnotherBranch) {
      LocalKeyActionService.getInitLoginData().then(initLoginData => {
        dispatch(
          AuthActions.requestLogin({
            ...initLoginData,
            phone: tmpLoginBranch.phone,
            regionCode: AppConstant.LOCAL_PHONE_CODE,
          }),
        );
      });
      setIsHavePhone(true);
      setLoginBranchInfo(tmpLoginBranch);
    }
  }, [tmpLoginBranch]);

  return (
    <Container
      component="main"
      maxWidth="xl"
      classes={{
        root: classes.container,
        maxWidthXl: classes.container,
      }}
    >
      <Box className={classes.root}>
        <Box className={classes.inputComponent}>
          {isHavePhone ? <OTPAuth onBack={handleBackToBranch} /> : <LoginForm />}
          {isShowBackBtn && (
            <Button
              fullWidth
              variant="text"
              startIcon={<ArrowBack />}
              className={clsx("semiBold-lg-txt", classes.continueButton)}
              onClick={handleBackToBranch}
            >
              {currentServer.name || AppConstant.GLOBAL_BRANCH_NAME}
            </Button>
          )}
        </Box>
        <Box className={classes.imageContainer}>
          <CardMedia component="img" src={ImageConstant.ImageLogin} className={classes.loginWelcomeImage} />
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    padding: 0,
    position: "relative",
    zIndex: 10000,
  },

  root: {
    display: "flex",
    height: "100%",
  },

  imageContainer: {
    width: "70%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "60%",
  },

  backIcon: {
    position: "absolute",
    marginTop: 20,
    marginLeft: 20,
    color: "#424242",
  },

  inputComponent: {
    width: "30%",
    height: "100vh",
    minWidth: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 50px",
    [theme.breakpoints.up(1500)]: {
      width: AppConstant.SIDEBAR_WIDTH.large,
    },
  },

  continueButton: {
    marginTop: 15,
    width: "100%",
    padding: "14px 0",
  },
}));
