import GlobalApi, { createApiWithBranch } from "./config/api.config";
import { ApiConstant, AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import QueryString from "qs";
import { StorageUtil, getPrefixKey, handlingLogoutBranch, setLoginData, toCamel } from "utils";
import { getLabel } from "language";
import { CommonBranchInfoService } from "services";
import { BGlobalServerImage } from "theme/images";
import { changeBranchServer } from "utils/view.utils";
import store, { BranchActions } from "redux-store";
const LocalDbManagement = window.interactor.getCommonInteractor().dbManagement;

class RemoteApiFactory {
  constructor() {
    /**
     * Ex: {
     *  <accountId_GLOBAL_ID>: <API config for global server> - global branch
     *  <accountId_branchId>: <API config for branch server>
     */
    this.instances = {};

    // Init data from storage
    this.initFromDB();
  }

  initFromDB = () => {
    try {
      const activeBranchList = LocalDbManagement.find({ state: SystemConstant.STATE.active });

      activeBranchList.forEach(item => {
        const branch = toCamel(item);
        const prefixKey = getPrefixKey(branch.accountId, branch.branchId);
        const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};
        const token = StorageUtil.getItem(KeyConstant.KEY_TOKEN, prefixKey);
        if (branchInfo.domain) {
          this.instances[prefixKey] = new RemoteApi(prefixKey, branchInfo.domain, token);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  getBranchApi = (prefixKey, domain) => {
    if (!prefixKey) {
      // Return api's current branch if prefix is not exist
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID);
      prefixKey = getPrefixKey(accountId, branchId);
    }

    if (!prefixKey || prefixKey.includes("undefined"))
      throw new Error(`CAN NOT INIT API - Invalid prefixKey - ${prefixKey}`);

    const storageData = StorageUtil.getCommonKey(prefixKey) || {};
    const branchInfo = storageData[KeyConstant.KEY_BRANCH_INFO] || {};
    const branchDomain = branchInfo.domain || domain;

    if (!this.instances[prefixKey]) {
      this.instances[prefixKey] = new RemoteApi(prefixKey, branchDomain, storageData[KeyConstant.KEY_TOKEN]);
    }
    return this.instances[prefixKey];
  };
}

// Only create RemoteApi api object when there are accountId/ branchId
class RemoteApi {
  constructor(prefixKey, domain, token) {
    if (!domain) throw new Error(`CAN NOT INIT API - Invalid domain - ${domain}`);

    this.prefixKey = prefixKey;
    this.api = createApiWithBranch(domain);
    this.configApi();
    this.setToken(token);
  }

  configApi = () => {
    this.api.addAsyncResponseTransform(async response => {
      if (response.status === ApiConstant.STT_UNAUTHORIZED && response.config.url !== ApiConstant.POST_REFRESH_TOKEN) {
        const newResponse = await this.handleRefreshToken(response);
        response = { ...newResponse };
      }
    });
  };

  setToken = token => {
    if (token) {
      this.api.setHeader("Authorization", `Bearer ${token}`);
    }
  };

  handleLogout = url => {
    handlingLogoutBranch("", url);

    const activeBranchList = LocalDbManagement.find({ state: SystemConstant.STATE.active });
    let dbManagementInfo = activeBranchList.find(item => false === url.includes(item.branch_domain));

    if (dbManagementInfo) {
      dbManagementInfo = toCamel(dbManagementInfo);
      const avatarUrl = CommonBranchInfoService.getBranchAvatarUrl(
        dbManagementInfo.branchDomain,
        dbManagementInfo.branchId,
      );
      const selectBranch = {
        id: dbManagementInfo.branchId,
        accountId: dbManagementInfo.accountId,
        loginState: dbManagementInfo.state,
        phone: dbManagementInfo.ownerName,
        name: dbManagementInfo.branchName,
        domain: dbManagementInfo.branchDomain,
        type:
          dbManagementInfo.branchId === SystemConstant.GLOBAL_BRANCH_ID
            ? SystemConstant.SERVER_TYPE.server
            : SystemConstant.SERVER_TYPE.branch,
        branchIcon: avatarUrl || BGlobalServerImage,
      };

      changeBranchServer(selectBranch);
      window.location.reload();
    }
  };

  handleRefreshToken = async (response, retry = 1) => {
    // Logout if more than number retry refresh token
    const currentDomain = StorageUtil.getCommonKey(KeyConstant.KEY_CURRENT_DOMAIN);

    if (retry > 3) {
      const isCurrentBranch = this.api.getBaseURL().includes(currentDomain);
      if (isCurrentBranch) {
        window.dispatchEvent(
          new CustomEvent(AppConstant.NOTICE_EVENT_NAME, {
            detail: {
              content: getLabel(LangConstant.TXT_SESSION_EXPIRED),
              callback: () => this.handleLogout(this.api.getBaseURL()),
            },
          }),
        );
      } else {
        handlingLogoutBranch("", this.api.getBaseURL());
        store.dispatch(
          BranchActions.branchSet({
            fetchBranchTimestamp: new Date().getTime(),
          }),
        );
      }
      return response;
    }

    const access_token = StorageUtil.getItem(KeyConstant.KEY_TOKEN, this.prefixKey);
    const refresh_token = StorageUtil.getItem(KeyConstant.KEY_REFRESH_TOKEN, this.prefixKey);
    const phone = StorageUtil.getItem(KeyConstant.KEY_PHONE, this.prefixKey);

    const refreshResponse = await createApiWithBranch(null, this.api.getBaseURL()).post(
      ApiConstant.POST_REFRESH_TOKEN,
      QueryString.stringify({ access_token, refresh_token }),
    );

    let retryResponse = { ...response };
    if (refreshResponse.status === ApiConstant.STT_OK) {
      const responseData = toCamel(refreshResponse);
      setLoginData(responseData.data, phone);
      this.setToken(responseData.accessToken);

      const newApiConfig = { ...response.config };
      retryResponse = await createApiWithBranch(null, this.api.getBaseURL()).any(newApiConfig); // Retry calling API after refresh token
    } else if (
      retryResponse.status !== ApiConstant.STT_MAINTAIN_1 ||
      retryResponse.status !== ApiConstant.STT_MAINTAIN_2 ||
      retryResponse.status !== ApiConstant.STT_MAINTAIN_3
    ) {
      retryResponse = await this.handleRefreshToken(response, retry + 1);
    } else if (
      retry >= 3 &&
      (retryResponse.status === ApiConstant.STT_UNAUTHORIZED || retryResponse.status === ApiConstant.STT_FORBIDDEN)
    ) {
      handlingLogoutBranch("", retryResponse.config.url);
    }

    return retryResponse;
  };

  // ------------------------ Auth Api ------------------------
  verify = async data => {
    const response = await this.api.post(ApiConstant.POST_VERIFY, QueryString.stringify(data, { skipNulls: true }));
    if (response.status === ApiConstant.STT_OK && response.data) {
      this.setToken(response.data[KeyConstant.KEY_TOKEN]);

      return toCamel(response.data);
    }

    return {};
  };

  refresh = data => this.api.post(ApiConstant.POST_REFRESH_TOKEN, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Account Api --------------------------
  updateAccount = data =>
    this.api.post(
      ApiConstant.POST_UPDATE_ACCOUNT,
      QueryString.stringify(data, {
        skipNulls: true,
      }),
    );

  getAccountByIds = data =>
    this.api.post(ApiConstant.POST_GET_ACCOUNT, QueryString.stringify(data, { skipNulls: true }));

  getAccountList = data => this.api.get(ApiConstant.GET_ACCOUNT_LIST, data);

  uploadFileAccount = data =>
    this.api.post(ApiConstant.POST_UPLOAD_FILE_ACCOUNT, data, {
      headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
    });

  blockAccount = data =>
    this.api.post(ApiConstant.POST_BlOCK_ACCOUNT, QueryString.stringify(data, { skipNulls: true }));

  unblockAccount = data =>
    this.api.post(ApiConstant.POST_UNBLOCK_USER, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Account Device Api ---------------------
  getDevice = data => this.api.post(ApiConstant.POST_GET_DEVICE, QueryString.stringify(data, { skipNulls: true }));

  deleteDevice = data =>
    this.api.post(ApiConstant.POST_DELETE_DEVICE, QueryString.stringify(data, { skipNulls: true }));

  updateDevice = data =>
    this.api.post(ApiConstant.POST_UPDATE_DEVICE, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Account Key Api ------------------------
  uploadKeys = data =>
    this.api.post(ApiConstant.POST_UPLOAD_KEY_LIST, QueryString.stringify(data, { skipNulls: true }));

  getKeys = data => this.api.post(ApiConstant.POST_GET_KEY, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Account Contact Api --------------------
  getContactList = data => this.api.get(ApiConstant.GET_CONTACT_LIST, data);

  getContact = (account_id, contact_id) => this.api.get(ApiConstant.GET_CONTACT, { account_id, contact_id });

  updateContact = data =>
    this.api.post(ApiConstant.POST_UPDATE_CONTACT, QueryString.stringify(data, { skipNulls: true }));

  addContact = data => this.api.post(ApiConstant.POST_ADD_CONTACT, QueryString.stringify(data, { skipNulls: true }));

  deleteContact = contact_id => this.api.post(ApiConstant.POST_DELETE_CONTACT, QueryString.stringify({ contact_id }));

  // ------------------------ Branch Api -----------------------------
  getBranchList = () => this.api.post(ApiConstant.POST_GET_BRANCH_LIST);

  getBranchAccount = branch_ids => this.api.post(ApiConstant.POST_GET_BRANCH_ACCOUNT, { branch_ids });

  updateBranch = data =>
    this.api.post(ApiConstant.POST_UPDATE_BRANCH_ACCOUNT, QueryString.stringify(data, { skipNulls: true }));

  syncBranchMapping = data =>
    this.api.get(ApiConstant.GET_BRANCH_MAPPING, data, {
      headers: { "trios-access": StorageUtil.getItem(KeyConstant.KEY_TRIOS_ACCESS) },
    });

  createBranchMapping = data =>
    this.api.post(ApiConstant.POST_BRANCH_MAPPING, QueryString.stringify(data), {
      headers: { "trios-access": StorageUtil.getItem(KeyConstant.KEY_TRIOS_ACCESS) },
    });

  // ----- [Conversation] Group Api - Include channel, group, personal -----
  addConversation = data =>
    this.api.post(ApiConstant.POST_CREATE_CONVERSATION, QueryString.stringify(data, { skipNulls: true }));
  getConversationList = data => this.api.get(ApiConstant.GET_CONVERSATION_LIST, data);

  getConversationMember = data => this.api.post(ApiConstant.POST_GET_CONVERSATION_MEMBER, QueryString.stringify(data));
  updateConversation = data =>
    this.api.post(ApiConstant.POST_UPDATE_CONVERSATION, QueryString.stringify(data, { skipNulls: true }));

  deleteConversation = data =>
    this.api.post(ApiConstant.POST_DELETE_CONVERSATION, QueryString.stringify(data, { skipNulls: true }));

  addConversationMembers = data =>
    this.api.post(ApiConstant.POST_ADD_MEMBERS, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ ATTACHMENT Api ------------------------
  getAttachment = data =>
    this.api.get(ApiConstant.GET_ATTACHMENT, data, {
      responseType: "arraybuffer",
    });

  // ------------------------ Call Api ------------------------------
  getCallingStatus = data => this.api.get(ApiConstant.GET_CALL_STATUS, data);

  getCallHistory = data => this.api.get(ApiConstant.GET_CALL_HISTORY, data);

  updateCallHistory = data =>
    this.api.post(ApiConstant.POST_UPDATE_CALL_HISTORY, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Message Api ---------------------------
  sendMessage = data => this.api.post(ApiConstant.POST_SEND_MESSAGE, QueryString.stringify(data, { skipNulls: true }));

  sendMessageFile = data =>
    this.api.post(ApiConstant.POST_SEND_MESSAGE_FILE, data, {
      headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
    });

  getMessageList = data => this.api.get(ApiConstant.GET_MESSAGE_LIST, data);

  updateMessageStatus = data =>
    this.api.post(ApiConstant.POST_UPDATE_MESSAGE_STT, QueryString.stringify(data, { skipNulls: true }));

  // ---------------------- Backup/ Restore data ---------------------
  requestVerifyChangeDeviceRole = data =>
    this.api.post(ApiConstant.POST_VERIFY_OTP_CHANGE_DEVICE_ROLE, QueryString.stringify(data, { skipNulls: true }));

  requestBackupUpload = (data, branchId) =>
    this.api.post(ApiConstant.POST_BACKUP_UPLOAD, data, {
      headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
    });

  requestBackup = (data, branchId) =>
    this.api.post(ApiConstant.POST_BACKUP, QueryString.stringify(data, { skipNulls: true }));

  requestGetBackup = data => this.api.get(ApiConstant.GET_BACKUP_INFO, data);

  // ------------------------ JitsiMeet -------------------------------
  postMeetToken = data => this.api.post(ApiConstant.POST_MEET_TOKEN, QueryString.stringify(data, { skipNulls: true }));

  // ------------------------ Other Api -------------------------------
  getEmoji = data => this.api.get(ApiConstant.GET_EMOJI, data);

  // ------------------------ ThirdParty Api --------------------------
  createThirdPartyToken = service_id =>
    this.api.post(ApiConstant.POST_CREATE_THIRD_TOKEN, QueryString.stringify({ service_id }));

  // ------------------------ Checking Server Status --------------------------
  checkingServerStatus = () => this.api.get(ApiConstant.GET_SERVER_TIME, {}, { timeout: 2000 });
}

export const remoteApiFactory = new RemoteApiFactory();
