/**
 * Export an instance of api client
 */

import { ApiConstant, FormatConstant, KeyConstant, SystemConstant } from "const";
import apisauce from "apisauce";
import StringFormat from "string-format";
import { getLoginBranchInfo } from "utils/view.utils";
const StorageUtil = window.electronUtils.storage;

export const API_CONFIG = {
  baseURL: ApiConstant.BASE_URL,
  headers: {
    ...ApiConstant.HEADER_DEFAULT,
    Authorization: "Bearer " + StorageUtil.getItem(KeyConstant.KEY_TOKEN),
  },
  timeout: ApiConstant.TIMEOUT,
};
const GlobalApi = apisauce.create(API_CONFIG);

export const createApiWithBranch = (domain, url) => {
  let baseUrl = url;
  if (!baseUrl) baseUrl = domain ? StringFormat(FormatConstant.FM_BASE_URL, domain) : ApiConstant.BASE_URL;

  const branchApi = apisauce.create({ ...API_CONFIG, baseURL: baseUrl });

  return branchApi;
};

export const createApiWithoutToken = domain => {
  let customUrl = domain || getLoginBranchInfo().domain;

  if (false === Boolean(customUrl)) {
    customUrl = ApiConstant.BASE_URL.split("/")[2];
    StorageUtil.setCommonKey(KeyConstant.KEY_TMP_BRANCH, { id: SystemConstant.GLOBAL_BRANCH_ID, domain: customUrl }); // base_url domain
  }

  GlobalApi.setBaseURL(StringFormat(FormatConstant.FM_BASE_URL, customUrl));

  return GlobalApi;
};

export default GlobalApi;
