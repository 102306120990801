import React, { memo, useCallback, useState } from "react";
import { CardMedia, Fab } from "@mui/material";
import { LangConstant, AppConstant, ImageConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import AddingLayout from "./AddingLayout";
import AddingMessageTab from "./AddingMessageTab";
import AddingChannelTab from "./AddingChannelTab";
import AddingChatTab from "./AddingChatTab";
import { Tabs } from "@mui/base";
import { getSavedServer } from "utils/view.utils";

const AddingChat = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const [isShow, setIsShow] = useState(false);

  const onCreateControlFuc = useCallback(open => () => setIsShow(open), []);

  return (
    <>
      <Fab color="primary" aria-label="add" className={classes.btnAdding} onClick={onCreateControlFuc(true)}>
        <CardMedia component="img" src={ImageConstant.AddChat} className={classes.logoImg} />
      </Fab>
      <AddingLayout open={isShow} onClose={onCreateControlFuc(false)} title={chatObjContent.title}>
        <Tabs defaultValue={AppConstant.CHATTING_TAB_MODE.message} className={classes.root}>
          {getSavedServer()?.type === SystemConstant.SERVER_TYPE.branch ? <AddingChatTab /> : <></>}
          <AddingMessageTab onCloseDrawer={onCreateControlFuc(false)} />
          <AddingChannelTab onCloseDrawer={onCreateControlFuc(false)} />
        </Tabs>
      </AddingLayout>
    </>
  );
};

export default memo(AddingChat);

const useStyles = makeStyles({
  btnAdding: {
    position: "absolute",
    bottom: 72,
    right: 16,
  },
  root: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  logoImg: {
    width: 20,
    height: 20,
  },
});
