import { useEffect, useState } from "react";
import { toCamel } from "utils";
import { useSelector } from "react-redux";
import { ConversationSelectors } from "redux-store";
import { SystemConstant } from "const";
import { getInteractor } from "services/local.service";

const STATUS_CHECKING_INTERVAL = 5000;
const useOnlineGroup = () => {
  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const groupDetail = toCamel(getInteractor().LocalGroupService.get(selectedGroupId));
    const isChatWithPersonal =
      Array.isArray(groupDetail.groupMembers) &&
      groupDetail.groupMembers.length > 0 &&
      groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    const personalAccountId = isChatWithPersonal ? groupDetail.groupMembers[0].id : null;

    if (personalAccountId) {
      const checkOnlineInterval = setInterval(() => {
        setIsOnline(getInteractor().LocalDeviceService.isOnlineAccount(personalAccountId));
      }, STATUS_CHECKING_INTERVAL);

      return () => clearInterval(checkOnlineInterval);
    }
  }, [selectedGroupId]);

  return { isOnline };
};

export default useOnlineGroup;
