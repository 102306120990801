import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getCreateTimeObject } from "utils";
import { ImageConstant, LangConstant } from "const";
import NoData from "./NoData";
import DataFile from "./DataFile";

const File = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [dateTimeData, setDateTimeData] = useState({});
  useEffect(() => {
    setDateTimeData(getCreateTimeObject(data));
  }, [data]);

  return (
    <Box className={classes.root}>
      {dateTimeData && Object.keys(dateTimeData).length > 0 ? (
        <>
          {Object.keys(dateTimeData).map((key, index) => (
            <Box key={index}>
              <Typography variant="subtitle2">{key}</Typography>
              {dateTimeData[key].map((dataMap, index) => (
                <DataFile data={dataMap} key={index} />
              ))}
            </Box>
          ))}
        </>
      ) : (
        <NoData
          media={<CardMedia component="img" alt="cover" src={ImageConstant.NoFileImage} />}
          textOne={getLabel(LangConstant.TXT_CONTENT_NO_FILE)}
          textTwo={getLabel(LangConstant.TXT_NO_FILE)}
        />
      )}
    </Box>
  );
};

File.propTypes = {
  data: PropTypes.array,
};

File.defaultProps = {
  data: [],
};

export default File;

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
  },
}));
