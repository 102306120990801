import { call, put, select } from "redux-saga/effects";
import { AttachmentService } from "services";
import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { AttachmentUtil, toCamel, toSnake, uuid } from "utils";
import { getInteractor } from "services/local.service";
import { ConversationActions, ConversationSelectors, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { synchGroupMember } from "./synchronize.saga";

export function* changeGroupPhoto(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const apiPayload = new FormData();
    apiPayload.append("file", data.upload, data.upload.path);

    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).uploadFileAccount, apiPayload);
    if (response.status === ApiConstant.STT_OK) {
      const imageId = response.data.id;
      yield call(
        remoteApiFactory.getBranchApi(prefixKey).updateConversation,
        toSnake({
          groupId: data.groupId,
          avatarId: imageId,
        }),
      );
      let fileName = imageId + AppConstant.FM_SAVED_FILE;
      if (!AttachmentUtil.exitsLocalFile(imageId, fileName)) {
        let attachmentResponse = yield call(AttachmentService.getAttachment, toSnake({ attachmentId: imageId }));
        if (attachmentResponse.status === ApiConstant.STT_OK) {
          let unit8String = new Uint8Array(attachmentResponse.data);
          yield AttachmentUtil.savePublicFile(unit8String, imageId, fileName);
          getInteractor(prefixKey).LocalGroupService.changPhotoGroup(data.groupId, imageId);

          yield put(
            GroupInfoActions.groupInfoSuccess({
              updatingGroupData: {
                id: data.groupId,
                avatarId: imageId,
              },
            }),
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* removeMemberGroup(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    let response = yield call(
      remoteApiFactory.getBranchApi(prefixKey).deleteConversation,
      toSnake({
        groupId: data.groupId,
        memberId: data.memberId,
      }),
    );
    if (response.status === ApiConstant.STT_OK) {
      getInteractor(prefixKey).LocalAccountGroupService.removeMemberGroup(data.groupId, data.memberId);
      let { groupName, groupMembers } = toCamel(getInteractor(prefixKey).LocalGroupService.get(data.groupId));
      yield put(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            name: groupName,
            groupName: groupName,
            groupMembers: groupMembers,
            modified: new Date().getTime(),
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addMemberGroup(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).addConversationMembers, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      if (Array.isArray(response.data)) {
        const saveData = response.data.map(item => ({
          id: item.id,
          account_id: item.account_id,
          group_id: item.group_id,
          state: item.state,
          options: item.details ? item.details : null,
          created: item.created,
          modified: item.modified,
          invite_by: item.invite_by,
          type: item.type,
        }));

        yield getInteractor(prefixKey).LocalAccountGroupService.save(saveData);
      }

      const { groupName, groupMembers } = toCamel(getInteractor(prefixKey).LocalGroupService.get(data.groupId));
      yield put(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            name: groupName,
            groupName: groupName,
            groupMembers: groupMembers,
            modified: new Date().getTime(),
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* muteGroupNotification(action) {
  const { data } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    const selectGroup = getInteractor(prefixKey).LocalGroupSettingService.getByGroupId(data.groupId);
    let newSetting;

    if (selectGroup && selectGroup.id) {
      newSetting = {
        ...selectGroup,
        state: data.status,
      };
    } else {
      newSetting = {
        id: uuid(),
        groupId: data.groupId,
        settingId: SystemConstant.SETTING_TYPE.notification,
        status: SystemConstant.STATUS.active,
        state: data.status,
        options: "",
        created: Date.now(),
        modified: 0,
      };
    }

    getInteractor(prefixKey).LocalGroupSettingService.save([toSnake(newSetting)]);
    yield put(
      GroupInfoActions.groupInfoSuccess({
        updatingGroupData: {
          id: data.groupId,
          modified: Date.now(),
        },
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* addAdmin(action) {
  const { data } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      getInteractor(prefixKey).LocalAccountGroupService.memberToAdmin(response.data.group_id, response.data.account_id);
      yield put(
        GroupInfoActions.groupInfoSuccess({
          dataMemberToAdmin: toCamel(response).data,
        }),
      );
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* requestUploadImageCall(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const apiPayload = new FormData();
    apiPayload.append("file", data.upload, data.upload.path);
    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).uploadFileAccount, apiPayload);
    if (response.status === ApiConstant.STT_OK) {
      const avatarID = response.data.id;
      let fileName = data.upload.name;
      if (!AttachmentUtil.exitsLocalFile(avatarID, fileName)) {
        let attachmentResponse = yield call(
          AttachmentService.getAttachment,
          toSnake({
            attachmentId: avatarID,
          }),
        );
        if (attachmentResponse.status === ApiConstant.STT_OK) {
          let unit8String = new Uint8Array(attachmentResponse.data);
          try {
            AttachmentUtil.savePublicFile(unit8String, avatarID, fileName);
            if (data.initCall) {
              yield put(
                ConversationActions.conversationSet({
                  idGroupCallAvatar: avatarID,
                }),
              );
            } else {
              yield put(
                ConversationActions.conversationSet({
                  idAvatarEdit: avatarID,
                }),
              );
            }
          } catch (error) {
            console.log({ error });
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createGroup(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).addConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield getInteractor(prefixKey).LocalGroupService.save([
        {
          ...responseData,
          name: responseData.name,
        },
      ]);
      yield synchGroupMember(prefixKey, [responseData.id]);
      yield put(
        ConversationActions.conversationSet({
          demandingVoiceCall: Boolean(data.isDemandVoiceCall) ? responseData.id : null,
          demandingVideoCall: Boolean(data.isDemandVideoCall) ? responseData.id : null,
        }),
      );
      yield put(
        ConversationActions.setSelectGroupId({
          selectedGroupId: responseData.id,
        }),
      );

      yield put(
        GroupInfoActions.groupInfoSet({
          isCreateGroupSuccess: true,
          updatingGroupData: toCamel({
            ...responseData,
            name: responseData.name,
          }),
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteGroup(action) {
  try {
    const { data, isLeaveGroup } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).deleteConversation, toSnake(data));
    if (![ApiConstant.STT_OK, ApiConstant.STT_NOT_FOUND].includes(response.status)) {
      console.log("DELETE_GROUP_ERROR: ", response);
    }

    getInteractor(prefixKey).LocalMessageService.updateDeleteStateForMsg(data.groupId);

    // Remove group in local database
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const groupDetail = getInteractor(prefixKey).LocalGroupService.get(data.groupId);
    const savingGroup = { ...groupDetail, state: SystemConstant.STATE.inactive };
    const isPersonalConversation = groupDetail.group_type === SystemConstant.GROUP_CHAT_TYPE.personal;

    if (isPersonalConversation) {
      const options = groupDetail.options ? JSON.parse(groupDetail.options) : {};

      if (options.hidden && Array.isArray(options.hidden) && !options.hidden.includes(accountId)) {
        options.hidden.push(accountId);
      } else {
        options.hidden = [accountId];
      }
      savingGroup.options = JSON.stringify(options);
    } else {
      getInteractor(prefixKey).LocalAccountGroupService.removeMemberGroup(groupDetail.id, accountId);
    }
    yield getInteractor(prefixKey).LocalGroupService.save([savingGroup]);

    yield put(
      GroupInfoActions.groupInfoSuccess({
        deletedGroupId: data.groupId,
      }),
    );

    const selectedGroupId = yield select(ConversationSelectors.getSelectedGroupId);
    if (selectedGroupId === data.groupId) {
      yield put(
        ConversationActions.setSelectGroupId({
          threadingId: null,
          selectedGroupId: null,
          isLeavingGroup: Boolean(isLeaveGroup),
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateGroup(action) {
  try {
    const { data } = action;
    const groupName = JSON.parse(data.name).name;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      getInteractor(prefixKey).LocalGroupService.changeNameGroup(data.groupId, data.name);

      yield put(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            name: groupName,
            groupName: groupName,
            modified: new Date().getTime(),
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleSelectConversation(action) {
  try {
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const { selectedGroupId } = action.data;

    const selectedGroup = toCamel(getInteractor(prefixKey).LocalGroupService.get(selectedGroupId));

    const isChatWithPersonal =
      Array.isArray(selectedGroup.groupMembers) &&
      selectedGroup.groupMembers.length > 0 &&
      selectedGroup.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

    // Checking chatting person is block or not
    if (isChatWithPersonal) {
      const loginAccountId = localStorage.getItem(KeyConstant.KEY_ACCOUNT_ID); // loginAccountId: sender
      const otherPerson = selectedGroup.groupMembers.find(memberItem => memberItem.id !== loginAccountId);
      yield checkBlockedContact({
        data: {
          accountId: otherPerson.id,
        },
      });
    }

    // Checking file don't save at local (message file)
    const handleMessageList = getInteractor().LocalMessageService.getMediaMessages(selectedGroupId, [
      SystemConstant.SEND_TYPE.image,
      SystemConstant.SEND_TYPE.video,
      SystemConstant.SEND_TYPE.file,
    ]);
    if (Array.isArray(handleMessageList) && handleMessageList.length > 0) {
      AttachmentService.saveFileFromMessage(StorageUtil.getCurrentPrefixKey(), handleMessageList);
    }
  } catch (error) {
    console.log("handleSelectConversation", error);
  }
}

// If receiver block login account on server, update local database and display block sending message UI
// Else checking login account block receiver or not
export function* checkBlockedContact(action) {
  try {
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const loginAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey); // loginAccountId: sender
    const { accountId } = action.data; // account_id: receiver

    // Case 1: receiver block login account
    let blockedAccount = yield call(getBlockedContactOnServer, prefixKey, accountId);

    // Case 2: login account block receiver
    if (!blockedAccount) {
      const contactInDB = getInteractor(prefixKey).LocalContactService.getContact(loginAccountId, accountId);
      if (contactInDB && contactInDB.status === SystemConstant.CONTACT_STATUS.block) blockedAccount = contactInDB;
    }

    // And then, dispatch block status to view
    yield put(
      ConversationActions.conversationSet({
        blockedAccount: blockedAccount ? toCamel(blockedAccount) : {},
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

// Return data if checkingAccount block login user (current account)
const getBlockedContactOnServer = async (prefixKey, checkingAccountId) => {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();

  const loginAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const response = await remoteApiFactory.getBranchApi(prefixKey).getContact(checkingAccountId, loginAccountId);
  const contactOfReceiverServer = response.data;

  if (response.status === ApiConstant.STT_OK && contactOfReceiverServer?.id) {
    const contactOfReceiverLocal = getInteractor(prefixKey).LocalContactService.getContact(
      checkingAccountId,
      loginAccountId,
    );

    if (contactOfReceiverLocal?.id && contactOfReceiverLocal.status !== contactOfReceiverServer.status) {
      await getInteractor(prefixKey).LocalContactService.updateBeingBlockContact(
        contactOfReceiverServer.id,
        contactOfReceiverServer.status,
      );
    }

    if (contactOfReceiverServer.status === SystemConstant.CONTACT_STATUS.block) {
      // If receiver block login user, save data to DB
      await getInteractor(prefixKey).LocalContactService.save([contactOfReceiverServer]);

      return contactOfReceiverServer;
    }
  }

  return null;
};
