import { useEffect, useState } from "react";
import { AppConstant, KeyConstant } from "const";
import { AttachmentUtil, isObjectNotEqual, toCamel } from "utils";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import { getSavedServer } from "utils/view.utils";

const useAccount = () => {
  const [currentAccount, setCurrentAccount] = useState({});

  const getAccount = () => {
    const selectAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const selectBranchId = getSavedServer().id;
    if (!selectAccountId) return;

    const account = getInteractor().LocalAccountService.get(selectAccountId);
    const branchAccount = getInteractor().LocalBranchAccountService.getBranchAccount(selectAccountId, selectBranchId);
    const newAccount = account ? toCamel({ ...account, branchAccountState: branchAccount?.state }) : {};

    // Only update if account obj is changed
    if (newAccount.id && isObjectNotEqual(newAccount, currentAccount)) {
      newAccount.avatarUrl = newAccount.avatarId ? AttachmentUtil.getAvatarRemoteUrl(newAccount.avatarId) : null;
      newAccount.backgroundUrl = newAccount.backgroundId
        ? AttachmentUtil.getAvatarRemoteUrl(newAccount.backgroundId)
        : null;
      setCurrentAccount(newAccount);
    }
    return newAccount;
  };

  useEffect(() => {
    let intervalLimit = 0;
    const accountInterval = setInterval(() => {
      const selectAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      if (selectAccountId) {
        const newAccount = getAccount();
        if (newAccount && newAccount.id) clearInterval(accountInterval);
      }
      intervalLimit++;

      if (intervalLimit > 10) clearInterval(accountInterval);
    }, AppConstant.DEBOUNCE_TIME);
  }, []);

  return { currentAccount, getAccount };
};

export default useAccount;
