/**
 * Implement logic to support the view layer
 */

import { getPrefixKey, isExternalLink, isJSONString, removeVietnameseTones } from "utils";
import { KeyConstant, SystemConstant } from "const";
import { convertString2JSON, deepCloneJsonObject, removeDuplicateInArray, toCamel } from "./index";
import { getInteractor } from "services/local.service";
import store, { BranchActions, ConversationActions } from "redux-store";
import { CommonBranchInfoService } from "services";

const StorageUtil = window.electronUtils.storage;

export const showConfirmLeaveGroup = (data, setIsConfirmLeave, setIsChooseAdmin) => {
  const memberArray = data.groupMembers;
  const adminList = toCamel(getInteractor().LocalAccountGroupService.findByGroupId(data.id))
    .filter(item => item.type === SystemConstant.ROLE_OBJ.admin)
    .map(item => memberArray.find(member => member.id === item.accountId));

  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  if (adminList.find(element => element.id === currentAccountId)) {
    setIsChooseAdmin(true);
  } else {
    setIsConfirmLeave(true);
  }
};

export const getSavedServer = selectedBranch => {
  selectedBranch = selectedBranch?.id
    ? selectedBranch
    : StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER) || {};

  // Init branch Icon if it not exist
  if (false === Boolean(selectedBranch.branchIcon)) {
    const avatarPath = CommonBranchInfoService.getBranchAvatarUrl(selectedBranch.domain, selectedBranch.id);
    selectedBranch.branchIcon = avatarPath;
  }

  // Create serverOptions json object
  selectedBranch.serverOptions = getServerOptions(selectedBranch.options);

  return selectedBranch;
};

export const getLoginBranchInfo = () => StorageUtil.getCommonKey(KeyConstant.KEY_TMP_BRANCH) || {};

export const groupByByKey = (key, array) => {
  let list = array.filter(obj => {
    return obj[key];
  });
  let map = {};
  list.forEach(obj => {
    if (!map[obj[key]]) {
      map[obj[key]] = [];
    }
    map[obj[key]].push(obj);
  });
  return map;
};

const NORMAL_MESSAGE_SEND_TYPE = [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage];
export const notificationClear = result => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const mapByGroup = {};
  let clearList = [];
  let allNotSeen = {};
  result.forEach(obj => {
    if (!mapByGroup[obj.group_id]) {
      mapByGroup[obj.group_id] = [];
    }
    mapByGroup[obj.group_id].push(obj);
    if (!allNotSeen[obj.id]) {
      allNotSeen[obj.id] = obj;
    }
  });
  let groupDeleted =
    Object.keys(mapByGroup).length === 0
      ? []
      : getInteractor().LocalGroupService.getByGroupIds(Object.keys(mapByGroup));
  let groupIds = groupDeleted.map(gd => gd.id);
  let groupDeletedIds = groupDeleted
    .filter(
      gd => gd.state === SystemConstant.STATE.inactive || gd.account_group_state === SystemConstant.STATE.inactive,
    )
    .map(gd => gd.id);
  let diff = Object.keys(mapByGroup);
  diff = diff.filter(d => !groupIds.includes(d));
  diff = diff.concat(groupDeletedIds);
  diff.forEach(it => {
    mapByGroup[it].forEach(mes => {
      if (mes) {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      }
    });
  });

  let threadMap = groupByByKey("thread_id", Object.values(allNotSeen));
  let threadParentMessage = getInteractor()
    .LocalMessageService.getMessageBySourceId(Object.keys(threadMap))
    .map(it => it.source_id);
  threadParentMessage.forEach(tp => {
    delete threadMap[tp];
  });
  Object.keys(threadMap).forEach(tm => {
    if (threadMap[tm]) {
      threadMap[tm].forEach(mes => {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      });
    }
  });
  let parentMap = groupByByKey("parent_id", Object.values(allNotSeen));
  let parentMessage = getInteractor()
    .LocalMessageService.getMessageBySourceId(Object.keys(parentMap))
    .map(it => it.source_id);
  parentMessage.forEach(tp => {
    delete parentMap[tp];
  });
  Object.keys(parentMap).forEach(tm => {
    if (parentMap[tm]) {
      parentMap[tm].forEach(mes => {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      });
    }
  });
  let clone = deepCloneJsonObject(Object.values(allNotSeen));
  clone.forEach(al => {
    if (
      al.send_type === SystemConstant.SEND_TYPE.deleteMessage ||
      al.send_type === SystemConstant.SEND_TYPE.deleteReaction ||
      (NORMAL_MESSAGE_SEND_TYPE.includes(al.send_type) && Boolean(al.parent_id)) || // edited message
      (SystemConstant.ARR_CALLING_TYPES.includes(al.send_type) &&
        al.call_status !== SystemConstant.MESSAGE_CALL_STATUS.missed) // notice call
    ) {
      delete allNotSeen[al.id];
      clearList.push(al);
    }
  });
  let cloneList = deepCloneJsonObject(Object.values(allNotSeen));
  cloneList.forEach(n => {
    try {
      let options = convertString2JSON(n.options);
      if (options.seen_members && options.seen_members.length > 0 && options.seen_members.includes(accountId)) {
        delete allNotSeen[n.id];
        clearList.push(n);
      }
    } catch (e) {
      console.log(e);
    }
  });
  clearList = clearList.filter(c => c.status !== SystemConstant.NOTIFICATION_STATUS.read);
  return { allNotSeen: allNotSeen, clearList: clearList };
};

// Return new mention arr in thread
export const validateMentions = (threadInfo, newMentions, parentMentions) => {
  const threadMentions = Boolean(threadInfo) ? JSON.parse(threadInfo.thread_mentions) : [];

  let newMentionArr = [];
  if (newMentions && Array.isArray(newMentions)) {
    newMentionArr = newMentions;
  } else if (newMentions) {
    newMentionArr = JSON.parse(newMentions);
  }
  const parentMentionArr = Boolean(parentMentions) ? JSON.parse(parentMentions) : [];
  return removeDuplicateInArray([...parentMentionArr, ...threadMentions, ...newMentionArr]);
};

export const validateInvolved = (message, mentionArr) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const parentMessage = getInteractor().LocalMessageService.getMessageBySourceId(message.thread_id)[0] || {};

  return parentMessage.sender_id === accountId || message.sender_id === accountId || mentionArr.includes(accountId);
};

export const isBranchServer = branchId => {
  return branchId !== SystemConstant.GLOBAL_BRANCH_ID;
};

export const MAX_CALL_PERSON = 20;
export const DEFAULT_SEVER_OPTIONS = {
  meetMaxCallPerson: MAX_CALL_PERSON,
};
export const getServerOptions = options => {
  const serverOptions =
    options && isJSONString(options)
      ? toCamel(convertString2JSON(options, DEFAULT_SEVER_OPTIONS))
      : DEFAULT_SEVER_OPTIONS;
  serverOptions.meetMaxCallPerson = serverOptions.meetMaxCallPerson || MAX_CALL_PERSON;

  return serverOptions;
};

export const changeBranchServer = selectedServer => {
  const prefixKey = getPrefixKey(selectedServer.accountId, selectedServer.id);

  StorageUtil.setCommonKey(KeyConstant.KEY_PREFIX, prefixKey);
  StorageUtil.setCommonKey(KeyConstant.KEY_CURRENT_DOMAIN, selectedServer.domain);
  StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, selectedServer);

  store.dispatch(BranchActions.updateSelectedBranch(selectedServer));
  store.dispatch(
    ConversationActions.setSelectGroupId({
      threadingId: null,
      selectedGroupId: null,
    }),
  );

  return getServerOptions(selectedServer.options);
};

export const PREFIX_MENTION = "@{";
export const SUFFIX_MENTION = "}";
export const replaceId2Name = (string, memberArr, isHighlight = true) => {
  if (string && !(memberArr && memberArr.length > 0)) return string;
  if (!(string && memberArr && memberArr.length > 0)) return "";
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  let tmpMemberArr = JSON.parse(JSON.stringify(memberArr));
  let tags = string?.match(/@{\w+}/gm) || [];
  let myAccount = getInteractor().LocalAccountService.get(accountId);
  if (myAccount) {
    tmpMemberArr.push(myAccount);
  }
  let messageContent = string;
  tags.forEach(tag => {
    let tagAccountId = tag.replace(PREFIX_MENTION, "").replace("}", "");
    let currentAccount = tmpMemberArr.find(item => item.id === tagAccountId);
    if (!currentAccount) {
      currentAccount = getInteractor().LocalAccountService.get(tagAccountId);
    }
    let tagAccountName = currentAccount?.name || "No name";
    messageContent = messageContent.replace(tag, isHighlight ? `<b>@${tagAccountName}</b>` : `@${tagAccountName}`);
  });

  return messageContent;
};

export const replaceName2Id = (text, memberArr) => {
  try {
    let result = String(text || "").trim();
    (memberArr || []).forEach(member => {
      let string2Replace = "@" + member.name;
      let string2Insert = PREFIX_MENTION + member.id + SUFFIX_MENTION;
      let index2Replace = result.indexOf(string2Replace);
      if (index2Replace >= 0) {
        result =
          result.slice(0, index2Replace) +
          string2Insert +
          result.slice(index2Replace + string2Replace.length, result.length);
      }
    });

    return result;
  } catch (error) {
    console.log(error);
  }

  return text;
};

export const getSearchResult = (searchValue, inputData = [], searchFields) => {
  if (!searchValue || searchValue === "" || false === Array.isArray(inputData)) return inputData;

  const filteredList = inputData.filter(item => {
    const field2RemoveVietnameseTones = searchFields.map(field => item[field] || "");
    return removeVietnameseTones(`${field2RemoveVietnameseTones.join(", ")}`)
      .toLowerCase()
      .includes(removeVietnameseTones(searchValue.toLowerCase()));
  });

  return filteredList;
};

// Handling send_type if it is missing
export const getSendType = (sendType, content, isPersonal) => {
  let newSendType = sendType;

  if (false === Object.values(SystemConstant.SEND_TYPE).includes(sendType)) {
    // Set the default send_type based on the current user is chatting in a group or with an individual
    newSendType = isPersonal ? SystemConstant.SEND_TYPE.message : SystemConstant.SEND_TYPE.groupMessage;
  }

  // Update sendType if message is text and contain url
  if (
    [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage].includes(newSendType) &&
    isExternalLink(content)
  ) {
    newSendType = SystemConstant.SEND_TYPE.link;
  }

  return newSendType;
};
