import { call, fork, put } from "redux-saga/effects";
import { AttachmentService } from "services";
import { AttachmentUtil, toCamel } from "utils";
import { getInteractor, LocalKeyActionService } from "services/local.service";
import { getBranches } from "./branch.saga";
import { formatArray2Key, formatPagingParams } from "./saga.helper";
import { deviceFetching } from "./account-device.saga";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { SystemActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { saveKeysByAccountIds } from "./account-key.saga";

// Synch data when user open app
export function* synchronizeData(action) {
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
  yield put(
    SystemActions.systemSet({
      isSynchronizing: true,
    }),
  );
  yield fork(synchEmoji, prefixKey);
  yield call(getBranches, prefixKey);
  yield call(synchGroup, prefixKey);
  yield call(synchContact, prefixKey);
  yield call(deviceFetching, prefixKey);
  yield call(synchKey, prefixKey);

  try {
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const deviceName = StorageUtil.getCommonKey(KeyConstant.KEY_DEVICE_NAME, prefixKey);
    const version = window.env?.npm_package_version?.replace(".", "");
    const device = getInteractor(prefixKey).LocalDeviceService.get(deviceId);
    if (device) {
      let updateData = { device_name: deviceName };

      if (version && device.version !== version) {
        updateData = { ...updateData, version: parseInt(version) };
      }
      if (Object.keys(updateData).length > 0) {
        yield call(remoteApiFactory.getBranchApi(prefixKey).updateDevice, {
          ...updateData,
        });
      }
    }
  } catch (error) {
    console.log(error);
  }

  yield put(
    SystemActions.systemSet({
      isSynchronizing: false,
    }),
  );
}

// Synchronize data after AppConstant.SYNCHRONIZE_PERIOD_TIME
export function* periodicSynchronize(action) {
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  // Fork stop synchronize
  if (window.isStopSynchronize) return;

  yield call(getBranches, prefixKey);
  yield call(synchGroup, prefixKey);
  yield call(synchContact, prefixKey);
  yield call(deviceFetching, prefixKey);
}

export const synchGroupMember = async (prefixKey, groupIds) => {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const isValid = groupIds && Array.isArray(groupIds);
  if (!isValid) return;

  try {
    let paramsObject = {};
    paramsObject = {
      ...formatPagingParams(formatArray2Key({ groupIds: groupIds })),
    };

    let response = await remoteApiFactory.getBranchApi(prefixKey).getConversationMember(paramsObject);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;

      let arrayAccountGroup = [];
      let accountArr = [];

      Object.entries(responseData).forEach(pair =>
        pair[1].forEach(item2 => {
          accountArr.push(item2);
          arrayAccountGroup.push({
            ...item2,
            id: pair[0] + item2.id,
            account_id: item2.id,
            group_id: pair[0],
          });
        }),
      );

      // Change this to get key by group members
      let memberIds = arrayAccountGroup.map(item => item.id);
      await saveKeysByAccountIds(prefixKey, memberIds);

      await getInteractor(prefixKey).LocalAccountService.save(accountArr);
      await getInteractor(prefixKey).LocalAccountGroupService.save(
        arrayAccountGroup.map(account => ({
          id: account.id,
          account_id: account.account_id,
          group_id: account.group_id,
          state: account.group_state,
          options: account.options,
          created: account.created,
          modified: account.modified,
          invite_by: account.invite_by,
          type: account.type,
        })),
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const synchGroup = async prefixKey => {
  try {
    // Synch group
    const groupResponse = await remoteApiFactory.getBranchApi(prefixKey).getConversationList(formatPagingParams());
    if (groupResponse.status === ApiConstant.STT_OK) {
      const groupList = groupResponse.data.data;
      await getInteractor(prefixKey).LocalGroupService.save(groupList);

      await synchGroupMember(
        prefixKey,
        groupList.map(item => item.id),
      );

      const groupListConvert = toCamel(groupList);
      groupListConvert.forEach(item => {
        if (item.groupType === SystemConstant.GROUP_CHAT_TYPE.group && item.avatarId !== null) {
          const fileName = item.avatarId + ".png";
          if (!AttachmentUtil.exitsLocalFile(item.avatarId, fileName)) {
            const responseData = AttachmentService.getAttachment({
              attachment_id: item.avatarId,
            });
            try {
              responseData.then(res => {
                const unit8String = new Uint8Array(res.data);
                if (res.status === ApiConstant.STT_OK) {
                  AttachmentUtil.savePublicFile(unit8String, item.avatarId, fileName);
                }
              });
            } catch (error) {
              console.log({ error });
            }
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const synchContact = async prefixKey => {
  // Synch current account info
  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const accountResponse = await remoteApiFactory.getBranchApi(prefixKey).getAccountByIds({
      account_ids: [accountId],
    });

    if (accountResponse.status === ApiConstant.STT_OK && Array.isArray(accountResponse.data)) {
      getInteractor(prefixKey).LocalAccountService.save(accountResponse.data);
    }
  } catch (error) {
    console.log(error);
  }

  // Synch contact
  try {
    const getContactResponse = await remoteApiFactory.getBranchApi(prefixKey).getContactList({
      ...formatPagingParams(),
    });

    if (getContactResponse.status === ApiConstant.STT_OK) {
      const responseData = getContactResponse.data.data;
      getInteractor(prefixKey).LocalContactService.save(responseData);
    }
    if (
      StorageUtil.getItem(SystemConstant.KEY_ERROR_TIME_WARNING, prefixKey) &&
      Number(StorageUtil.getItem(SystemConstant.KEY_ERROR_TIME_WARNING, prefixKey)) <
        new Date().getTime() - 60000 * 60 * 24
    ) {
      StorageUtil.setItem(SystemConstant.KEY_ERROR_TIME_WARNING, new Date().getTime(), prefixKey);
    }
  } catch (error) {
    console.log(error);
  }
};

const synchKey = async prefixKey => {
  // Synch keys
  try {
    while (false === Boolean(StorageUtil.getCommonKey(KeyConstant.KEY_IS_VALID_KEYS))) {
      await LocalKeyActionService.generateAllKeys(prefixKey);
    }

    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const keys = LocalKeyActionService.getAllKeys(prefixKey);
    while (StorageUtil.getItem(KeyConstant.KEY_FIRST_UPLOAD_F, prefixKey) !== true) {
      const currentDevice = getInteractor(prefixKey).LocalDeviceService.get(deviceId);
      if (Boolean(currentDevice?.upload_key_f)) {
        // If device is uploaded key on server, update KEY_FIRST_UPLOAD_F
        StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, true, prefixKey);
      } else {
        // Else, uploading keys to server
        const uploadResponse = await remoteApiFactory.getBranchApi(prefixKey).uploadKeys(keys);
        if ([ApiConstant.STT_OK, ApiConstant.STT_CONFLICT].includes(uploadResponse.status)) {
          StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, true, prefixKey);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const synchEmoji = async prefixKey => {
  try {
    const limit = SystemConstant.EMOIJ_LIMIT_RECORD;
    const emoji = getInteractor(prefixKey).LocalEmojiService.getLastRecord();
    const sinceTime = emoji ? emoji.created + 1 : 0;
    let page = 1;

    let response = await getEmojiServer(prefixKey, {
      sinceTime: sinceTime,
      offset: page * limit - limit,
    });
    while (response.status === ApiConstant.STT_OK && response.data?.limit === response.data.numberOfElements) {
      page++;
      response = await getEmojiServer(prefixKey, {
        sinceTime: sinceTime,
        offset: page * limit - limit,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

async function getEmojiServer(prefixKey, conditionJson = {}) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();

  const params = formatPagingParams({
    // Default params
    orderBy: SystemConstant.EMOIJ_ORDER_BY_COLUMN,
    limit: SystemConstant.EMOIJ_LIMIT_RECORD,
    sinceTime: 0,
    offset: 0,

    // Overwrite
    ...conditionJson,
  });

  const response = await remoteApiFactory.getBranchApi(prefixKey).getEmoji(params);
  if (response.status === ApiConstant.STT_OK && response.data?.data) {
    getInteractor(prefixKey).LocalEmojiService.save(response.data.data);
  }

  return toCamel(response);
}
