import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { AttachmentUtil, getFirstLetter, toCamel } from "utils";

const AvatarConversation = ({ memberArray = [], classes }) => {
  const defaultClasses = useStyles();

  let AvatarComponent = SingleAvatar;
  switch (true) {
    case Boolean(memberArray?.length > 2):
      AvatarComponent = MultipleAvatar;
      break;

    case Boolean(memberArray?.length === 2):
      AvatarComponent = DoubleAvatar;
      break;

    default:
      break;
  }

  return (
    <Box className={defaultClasses.avatarRoot}>
      <AvatarComponent accounts={toCamel(memberArray)} classes={classes} />
    </Box>
  );
};

AvatarConversation.propTypes = {
  memberArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
};

const SingleAvatar = ({ accounts }) => {
  const classes = useStyles();

  const singleAccount = accounts[0] || {};
  const avatarUrl = AttachmentUtil.getAvatarRemoteUrl(singleAccount.avatarId);
  const firstLetter =
    singleAccount.name && isPossiblePhoneNumber(singleAccount.name) ? null : getFirstLetter(singleAccount.name);

  return (
    <Avatar alt={singleAccount.name} src={avatarUrl} className={classes.avatar}>
      {firstLetter}
    </Avatar>
  );
};

const DoubleAvatar = ({ accounts, classes }) => {
  const defaultClasses = useStyles();

  const [firstAccount, setFirstAccount] = useState({});
  const [secondAccount, setSecondAccount] = useState({});

  useEffect(() => {
    const firstAccount = accounts[0] || {};
    const secondAccount = accounts[1] || {};

    setFirstAccount({
      ...firstAccount,
      avatarUrl: AttachmentUtil.getAvatarRemoteUrl(firstAccount.avatarId),
      firstLetter: getFirstLetter(firstAccount.name),
    });
    setSecondAccount({
      ...secondAccount,
      avatarUrl: AttachmentUtil.getAvatarRemoteUrl(secondAccount.avatarId),
      firstLetter: getFirstLetter(secondAccount.name),
    });
  }, [accounts]);

  return (
    <Box className={defaultClasses.doubleAvtBox}>
      <Avatar
        alt={firstAccount.name}
        src={firstAccount.avatarUrl}
        className={clsx(
          defaultClasses.firstAvatarDouble,
          defaultClasses.multipleAvt,
          classes?.doubleAvt,
          "regular-sm-txt",
        )}
      >
        {firstAccount.firstLetter}
      </Avatar>
      <Avatar
        alt={secondAccount.name}
        src={secondAccount.avatarUrl}
        className={clsx(
          defaultClasses.secondAvatarDouble,
          defaultClasses.multipleAvt,
          classes?.doubleAvt,
          "regular-sm-txt",
        )}
      >
        {secondAccount.firstLetter}
      </Avatar>
    </Box>
  );
};

const MultipleAvatar = ({ accounts, classes }) => {
  const defaultClasses = useStyles();

  const [firstAccount, setFirstAccount] = useState({});
  const [secondAccount, setSecondAccount] = useState({});
  const [lastAccount, setLastAccount] = useState({});

  useEffect(() => {
    const firstAccount = accounts[0] || {};
    const secondAccount = accounts[1] || {};
    const lastAccount = accounts[accounts.length - 1] || {};

    setFirstAccount({
      ...firstAccount,
      avatarUrl: AttachmentUtil.getAvatarRemoteUrl(firstAccount.avatarId),
      firstLetter: getFirstLetter(firstAccount.name),
    });

    setSecondAccount({
      ...secondAccount,
      avatarUrl: AttachmentUtil.getAvatarRemoteUrl(secondAccount.avatarId),
      firstLetter: getFirstLetter(secondAccount.name),
    });

    setLastAccount({
      ...lastAccount,
      avatarUrl: AttachmentUtil.getAvatarRemoteUrl(lastAccount.avatarId),
      firstLetter: getFirstLetter(lastAccount.name),
    });
  }, [accounts]);

  const isOverThreeMembers = accounts.length > 3;

  return (
    <Box className={defaultClasses.groupAvatarBox}>
      <Avatar
        alt={firstAccount.name}
        src={firstAccount.avatarUrl}
        className={clsx(
          defaultClasses.firstAvatarGroup,
          defaultClasses.multipleAvt,
          classes?.individualAvt,
          "regular-sm-txt",
        )}
      >
        {firstAccount.firstLetter}
      </Avatar>
      <Avatar
        alt={secondAccount.name}
        src={secondAccount.avatarUrl}
        className={clsx(
          defaultClasses.secondAvatarGroup,
          defaultClasses.multipleAvt,
          classes?.individualAvt,
          "regular-sm-txt",
        )}
      >
        {secondAccount.firstAvatarGroup}
      </Avatar>
      <Avatar
        alt={lastAccount.name}
        src={isOverThreeMembers ? null : lastAccount.avatarUrl}
        className={clsx(
          defaultClasses.lastAvatarGroup,
          defaultClasses.multipleAvt,
          classes?.individualAvt,
          "regular-sm-txt",
        )}
      >
        {isOverThreeMembers ? `+${accounts.length - 2}` : lastAccount.firstLetter}
      </Avatar>
    </Box>
  );
};

export default AvatarConversation;

const useStyles = makeStyles(() => ({
  avatarRoot: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  avatar: {
    width: "100%",
    height: "100%",
  },

  groupAvatarBox: {
    position: "relative",
    width: "100%",
    height: "100%",
  },

  firstAvatarGroup: {
    position: "absolute",
    top: 0,
    marginLeft: " auto",
    marginRight: "auto",
    left: 0,
    right: 0,
  },

  secondAvatarGroup: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },

  lastAvatarGroup: {
    position: "absolute",
    bottom: 0,
    right: 0,
    lineHeight: "16px",
  },

  doubleAvtBox: {
    position: "relative",
    width: "100%",
    height: "100%",
  },

  firstAvatarDouble: {
    position: "absolute",
    marginBottom: "auto",
    marginTop: "auto",
    left: 0,
    top: 0,
    bottom: 0,
  },

  secondAvatarDouble: {
    position: "absolute",
    marginBottom: "auto",
    marginTop: "auto",
    right: 0,
    top: 0,
    bottom: 0,
  },

  multipleAvt: {
    width: "56%",
    height: "56%",
  },
}));
