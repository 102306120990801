import { useState, useEffect } from "react";
import { Box, Divider, IconButton, List, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { DrawerLayout, SearchBar } from "components";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AddingConferenceIcon } from "icons";
import AddingGroupLayout from "./AddingGroup/AddingGroupLayout";
import ConferenceItem from "./ConferenceItem";
import { ConversationActions } from "redux-store";
import { getInteractor } from "services/local.service";
import { toCamel } from "utils";

const AddingConferenceDrawer = ({ onClose, ...otherProps }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const [groupList, setGroupList] = useState([]);

  const handleSearch = value => {
    getInteractor()
      .LocalGroupService.searchGroupByCondition(value, {
        group_type: [SystemConstant.GROUP_CHAT_TYPE.channel, SystemConstant.GROUP_CHAT_TYPE.group],
      })
      .then(conversationList => {
        setGroupList(toCamel(conversationList));
      });
  };

  const handleStartConference = () => {
    dispatch(
      ConversationActions.conversationSet({
        isAddConference: true,
      }),
    );

    if (onClose) onClose();
  };

  useEffect(() => {
    handleSearch("");
  }, []);

  return (
    <DrawerLayout title={chatObjContent.titleConference} onClose={onClose} {...otherProps}>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={handleSearch} />
      </Box>

      <Box className={clsx(classes.selectedList, classes.rootSpace)}>
        <Box className={clsx(classes.infoBox)}>
          <Typography variant="subtitle2" className={classes.subTitle}>
            {getLabel(LangConstant.FM_GROUP_TAG, { number: groupList.length })}
          </Typography>
          <AddingGroupLayout onComplete={handleStartConference} DrawerProps={{ isConferenceFlow: true }}>
            <IconButton>
              <AddingConferenceIcon />
            </IconButton>
          </AddingGroupLayout>
        </Box>

        <List>
          {groupList.map((item, index) => (
            <Box key={index}>
              {index > 0 && <Divider key={index} variant="inset" component="li" />}
              <ConferenceItem key={item.id} data={item} />
            </Box>
          ))}
        </List>
      </Box>
    </DrawerLayout>
  );
};

AddingConferenceDrawer.propTypes = DrawerLayout.propTypes;

AddingConferenceDrawer.defaultProps = {};

export default AddingConferenceDrawer;

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  subTitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    textAlign: "left",
    marginTop: 10,
  },

  selectedList: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  infoBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
  },
}));
