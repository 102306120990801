import { Avatar, Badge, Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Close } from "@mui/icons-material";
import { AttachmentUtil } from "utils";

const ACSelectContact = ({ contact, onDelete }) => {
  const classes = useStyles();

  contact.avatarUrl = AttachmentUtil.getAvatarRemoteUrl(contact.avatarId);

  return (
    <Box className={classes.selectedItem} key={`${contact.phone}`}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          <IconButton
            className={classes.closeButton}
            onClick={() => onDelete(contact)}
            disableFocusRipple
            disableRipple
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        <Avatar alt={contact.phone} src={contact.avatarUrl} />
      </Badge>
      <Typography className={clsx(classes.selectContactName, "ellipsis")}>
        {contact.contactName || contact.name || contact.phone}
      </Typography>
    </Box>
  );
};

ACSelectContact.propTypes = {
  contact: PropTypes.object,
  onDelete: PropTypes.func,
};

ACSelectContact.defaultProps = {
  contact: {},
  onDelete: () => {},
};

export default ACSelectContact;

const useStyles = makeStyles(() => ({
  selectedItem: {
    width: 64,
    minWidth: 64,
    paddingTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  selectContactName: {
    fontSize: "12px",
  },

  closeButton: {
    fontSize: 14,
    padding: 1,
    color: "white",
    backgroundColor: "black",
  },
}));
