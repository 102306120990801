"use strict";
import * as ImageConstant from "../theme/images";
import * as SoundConstant from "../theme/sounds";
const deepCloneJsonObject = json => {
  if (!json) return null;
  return JSON.parse(JSON.stringify(json));
};
const SystemConstant = deepCloneJsonObject(window.TriosConstant.system);
const AppConstant = deepCloneJsonObject(window.TriosConstant.app);
const LangConstant = deepCloneJsonObject(window.TriosConstant.lang);
const ApiConstant = deepCloneJsonObject(window.TriosConstant.api);
const PathConstant = deepCloneJsonObject(window.TriosConstant.path);
const KeyConstant = deepCloneJsonObject(window.TriosConstant.key);
const FormatConstant = deepCloneJsonObject(window.TriosConstant.format);
const LinkConstant = deepCloneJsonObject(window.TriosConstant.link);

export {
  AppConstant,
  SystemConstant,
  LangConstant,
  ApiConstant,
  PathConstant,
  KeyConstant,
  FormatConstant,
  LinkConstant,
  ImageConstant,
  SoundConstant,
};
