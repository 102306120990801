import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Clear, Done } from "@mui/icons-material";
import { Avatar, Box, IconButton, Input, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { convertString2JSON, getFirstLetter, isExternalLink } from "utils";
import LinkPreview from "./LinkPreview";
import { saveMessageInQueue } from "../ConversationContext";
import ChatTypography from "./ChatTypography";
import { replaceId2Name, replaceName2Id } from "utils/view.utils";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem.helper";
import FullEmojiLayout from "../MessengerChatInput/FullEmojiLayout";
import { useInputEvent } from "hooks";

const MessengerPeople = ({ message, isMine, isEditing, setIsEditing }) => {
  const hasExternalLink = isExternalLink(message.content);
  const isAvatar = Boolean(message.isAvatar);

  return (
    <>
      {isMine ? (
        <MyMessage
          message={message}
          hasExternalLink={hasExternalLink}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      ) : (
        <OtherMessage message={message} hasExternalLink={hasExternalLink} isAvatar={isAvatar} />
      )}
    </>
  );
};

export default memo(MessengerPeople, (prev, next) => {
  if (prev.content !== next.content) {
    return prev.content === next.content;
  }
});

const MyMessage = ({ message, isEditing, hasExternalLink, setIsEditing }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();

  const [modifiedMessage, setModifiedMessage] = useState();
  const [defaultContent, setDefaultContent] = useState();
  const [messageContent, setMessageContent] = useState();

  const handleCancel = () => setIsEditing(false);

  const handleUpdateMessage = content => {
    if (content !== defaultContent) {
      saveMessageInQueue(
        null,
        replaceName2Id(content, message.mentionList),
        message.sourceId,
        convertString2JSON(message.mentions, []),
      );

      setDefaultContent(content);
      setMessageContent(content);
      setModifiedMessage(Date.now());
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (modifiedMessage && modifiedMessage > message.modified) return;

    const displayContent = replaceId2Name(message.content, message.mentionList, false);
    setDefaultContent(displayContent);
    setMessageContent(displayContent);
  }, [message.content]);

  return (
    <Stack
      direction="column"
      className={clsx(messengerClasses.messengerRoot, messengerClasses.myMessage, {
        [classes.previewLink]: hasExternalLink,
      })}
    >
      {isEditing ? (
        <EditMessageContent
          classes={classes}
          defaultContent={defaultContent}
          onSubmit={handleUpdateMessage}
          onCancel={handleCancel}
        />
      ) : (
        <>
          <ChatTypography
            messageContent={message.content}
            mentionList={message.mentionList}
            className={messengerClasses.spacing}
          />

          {hasExternalLink && (
            <LinkPreview
              message={{
                ...message,
                content: messageContent,
              }}
            />
          )}
        </>
      )}
    </Stack>
  );
};

const OtherMessage = ({ message, hasExternalLink, isAvatar }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const msgCreatedTime = getCreatedTimeText(message);

  return (
    <Stack direction="row" spacing={2}>
      <Avatar className={clsx(!isAvatar && messengerClasses.hiddenAvatar)} alt="chatting-avatar" src={message.avatar}>
        {getFirstLetter(message?.senderName)}
      </Avatar>

      <Box>
        {msgCreatedTime && <Typography className={messengerClasses.createdTimeText}>{msgCreatedTime}</Typography>}

        <Stack
          direction="column"
          className={clsx(messengerClasses.messengerRoot, {
            [classes.previewLink]: hasExternalLink,
          })}
        >
          <ChatTypography
            messageContent={message.content}
            mentionList={message.mentionList}
            className={clsx(messengerClasses.spacing)}
          />

          {hasExternalLink && <LinkPreview message={message} />}
        </Stack>
      </Box>
    </Stack>
  );
};

const EditMessageContent = ({ defaultContent, onSubmit, onCancel }) => {
  const classes = useStyles();
  const { inputRef, inputValue, insertContentInput, updateContentInput } = useInputEvent();

  const handleChangeContent = event => updateContentInput(event.target.value);

  const handleUpdateContent = value => insertContentInput(value);

  const handleSubmit = () => onSubmit(inputValue.trim());

  useEffect(() => {
    updateContentInput(defaultContent);
  }, []);

  return (
    <Box className={classes.editMessageRoot}>
      <Input
        classes={{
          root: classes.editMessageInputRoot,
          input: classes.editMessageInput,
        }}
        inputRef={inputRef}
        onChange={handleChangeContent}
        disableUnderline
        multiline
      />

      <Box className={classes.editActionBox}>
        <FullEmojiLayout onSelect={handleUpdateContent} />
        <Box>
          <IconButton onClick={onCancel}>
            <Clear />
          </IconButton>
          <IconButton color="primary" onClick={handleSubmit} disabled={inputValue === defaultContent}>
            <Done />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  previewLink: {
    width: 300,
    maxWidth: "100%",
  },

  editMessageRoot: {
    backgroundColor: "#F3F3F5",
  },

  editMessageInputRoot: {
    width: "100%",
    padding: "16px 30px",
  },

  editMessageInput: {
    width: 520,
    maxWidth: "100%",
  },

  editActionBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    paddingTop: 0,
  },
});
