import { ApiConstant } from "const";
import qs from "qs";
import { createApiWithoutToken } from "./config/api.config";
import { getLoginBranchInfo } from "utils/view.utils";

const getApiAuth = domain => createApiWithoutToken(domain || getLoginBranchInfo().domain);

export const login = (data, domain) =>
  getApiAuth(domain).post(ApiConstant.POST_LOGIN, qs.stringify(data, { skipNulls: true }));

export const verify = data => getApiAuth().post(ApiConstant.POST_VERIFY, qs.stringify(data, { skipNulls: true }));
