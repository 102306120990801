import { useEffect, useRef, useState } from "react";
import { Divider, List, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AvatarInfo, DrawerLayout, SearchBar } from "components";
import { KeyConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import clsx from "clsx";
import { getSavedServer, getSearchResult } from "utils/view.utils";
import { useAccountList, useContactList, useCreateConversation } from "hooks";
import { StorageUtil } from "utils";

const AddingPersonalDrawer = ({ onClose, ...otherProps }) => {
  const classes = useStyles();
  const searchingRef = useRef("");
  const { createWithPersonal } = useCreateConversation();
  const { accountList } = useAccountList();
  const { contactList } = useContactList();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const [viewingList, setViewingList] = useState([]);

  const onCreateNewHistoryKeyword = () => {
    const savedServer = getSavedServer();
    if (searchingRef.current) {
      let historyKeywords = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
      let historyKeywordsInServer = historyKeywords[savedServer?.id] || [];
      historyKeywordsInServer.unshift(searchingRef.current);
      StorageUtil.setItem(
        KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS,
        Object.assign(historyKeywords, { [savedServer?.id]: historyKeywordsInServer }),
      );
    }
  };

  const onStartPersonalChat = item => {
    onCreateNewHistoryKeyword();

    createWithPersonal(item);
    onClose();
  };

  const onSearchContact = searchingContent => {
    searchingRef.current = searchingContent?.trim() || "";

    if (searchingRef.current && searchingRef.current.length > 0) {
      const filteredList = getSearchResult(searchingRef.current, accountList, ["name", "contactName"]);

      setViewingList(filteredList);
    } else {
      setViewingList(contactList);
    }
  };

  useEffect(() => {
    if (!searchingRef.current) onSearchContact("");
  }, [contactList, accountList]);

  return (
    <DrawerLayout title={chatObjContent.title} onClose={onClose} {...otherProps}>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={onSearchContact} />
      </Box>

      <Box className={classes.selectedList}>
        <Typography variant="subtitle2" className={clsx(classes.subTitle, classes.rootSpace)}>
          {getLabel(LangConstant.FM_MEMBER, { number: viewingList.length })}
        </Typography>

        <List>
          {viewingList.map((contact, index) => (
            <Box key={`${contact.avatarId}-${index}`}>
              {index > 0 && <Divider variant="inset" component="li" />}
              <AvatarInfo
                avatar={{ avatarId: contact.avatarId, name: contact.contactName || contact.name || contact.phone }}
                title={contact.contactName || contact.name || contact.phone}
                onClick={() => onStartPersonalChat(contact)}
                button={true}
              />
            </Box>
          ))}
        </List>
      </Box>
    </DrawerLayout>
  );
};

AddingPersonalDrawer.propTypes = DrawerLayout.propTypes;

export default AddingPersonalDrawer;

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  subTitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    textAlign: "left",
    marginTop: 10,
  },

  selectedList: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));
