import { useCallback, useEffect, useRef, useState } from "react";
import { AppConstant, SystemConstant } from "const";
import { StorageUtil, toCamel } from "utils";
import { useSelector } from "react-redux";
import { BranchSelectors, GroupInfoSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { debounce } from "lodash";

export const useGroupByType = (groupTypes = Object.values(SystemConstant.GROUP_CHAT_TYPE)) => {
  const searchRef = useRef("");
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isSynchronizing = useSelector(state => state.systemRedux.isSynchronizing);
  const deletedGroupId = useSelector(state => state.groupInfoRedux.deletedGroupId);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const updatingGroupData = useSelector(GroupInfoSelectors.getUpdatingGroup);

  const [groupList, setGroupList] = useState([]);

  const searchConversation = async (searchValue = "") => {
    searchValue = searchValue ? searchValue.trim() : "";
    searchRef.current = searchValue;

    const conversationList = await getInteractor().LocalGroupService.searchGroupByCondition(searchValue, {
      group_type: groupTypes,
    });

    setGroupList(toCamel(conversationList));
  };

  const getGroupConversation = useCallback(
    debounce((searchValue = "") => searchConversation(searchValue), AppConstant.DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    // Not query data while synchronizing
    if (isSynchronizing) return;

    getGroupConversation(searchRef.current);
  }, [selectedBranch, isSynchronizing, isUpdateViewMode]);

  useEffect(() => {
    if (deletedGroupId && groupList.length > 0) {
      const deletedIndex = groupList.findIndex(item => item.id === deletedGroupId);
      if (deletedIndex >= 0) setGroupList(groupList.filter(item => item.id !== deletedGroupId));
    }
  }, [deletedGroupId]);

  useEffect(() => {
    if (updatingGroupData && updatingGroupData.id && groupList.length > 0) {
      const newGroupList = [...groupList];
      const selectedIndex = groupList.findIndex(item => item.id === updatingGroupData.id);
      if (selectedIndex >= 0) {
        // Move updating group to first
        newGroupList.splice(selectedIndex, 1);
        const newGroup = { ...groupList[selectedIndex], ...updatingGroupData };
        newGroupList.unshift(newGroup);
      } else {
        getInteractor(StorageUtil.getCurrentPrefixKey()).LocalGroupService.searchGroupByCondition("", {
          id: updatingGroupData.id,
          state: SystemConstant.STATE.active,
        }).then(results => {
          const newGroup = toCamel(results[0] || {})
          if (groupTypes.includes(newGroup.groupType)) {
            newGroupList.unshift(newGroup);
          }
        })
      }

      setGroupList(newGroupList);
    }
  }, [updatingGroupData]);

  return {
    groupList,
  };
};
