import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { AppConstant, KeyConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import OTPLocked from "./OTPLocked";
import OTPVerify from "components/OTPVerify";
import { AuthActions, AuthSelectors, BranchSelectors } from "redux-store";
import { StorageUtil } from "utils";
import { getLoginBranchInfo } from "utils/view.utils";
import BranchInfo from "components/BranchInfo";
import { LocalKeyActionService } from "services/local.service";
import OTPLoading from "./OTPLoading";

export const OTPAuth = ({ onBack }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const loginBranchInfo = getLoginBranchInfo();

  const phoneNumber = useSelector(state => state.authRedux.phoneNumber);
  const masterExisted = useSelector(state => state.authRedux.masterExisted);
  const isNew = useSelector(state => state.authRedux.isNew);
  const authLoginStatus = useSelector(AuthSelectors.authLoginStatus);
  const authVerifyStatus = useSelector(AuthSelectors.authVerifyStatus);
  const tmpLoginBranch = useSelector(BranchSelectors.getTmpLoginBranch);

  const [isLockedOTP, setIsLockedOTP] = useState(false);
  const [otpType, setOtpType] = useState(null);
  const [otpValues, setOtpValues] = useState("");
  const [errorContent, setErrorContent] = useState(null);
  const [isLoginBranch, setIsLoginBranch] = useState(false);
  const [retries, setRetries] = useState(1);
  const [isRequestLogin, setIsRequestLogin] = useState(true);
  const [headerContent, setHeaderContent] = useState("");

  const deviceName = StorageUtil.getCommonKey(KeyConstant.KEY_DEVICE_NAME);

  const checkBlockLogin = phone => {
    const loginBlockInfo = StorageUtil.getCommonKey(KeyConstant.KEY_LOGIN_BLOCK_INFO);
    if (loginBlockInfo) {
      const currentTimestamp = new Date().getTime();

      if (currentTimestamp - Number(loginBlockInfo[phone]) < 5 * 60 * 1000) {
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_OTP_LOCKED_FULL_MESSAGE"));
        return true;
      }
    }

    return false;
  };

  const handleContinue = event => {
    event.preventDefault();
    if (checkBlockLogin(loginBranchInfo.phone)) return;
    dispatch(
      AuthActions.requestVerify({
        device_name: deviceName,
        phone: loginBranchInfo.phone,
        code: otpValues,
      }),
    );
  };

  const handleResendOtp = async () => {
    setErrorContent(null);
    if (checkBlockLogin(loginBranchInfo.phone)) return;
    const initLoginData = await LocalKeyActionService.getInitLoginData();
    dispatch(
      AuthActions.requestLogin({
        ...initLoginData,
        phone: loginBranchInfo.phone,
        regionCode: AppConstant.LOCAL_PHONE_CODE,
        mode: otpType,
        check_master_f: false,
      }),
    );
  };

  const handleChangeTypeOfOTP = async newOtpType => {
    setOtpType(newOtpType);
    setHeaderContent(getOtpHeaderLang(loginBranchInfo.phone, newOtpType === SystemConstant.OTP_TYPE.smartOtp));
    if (checkBlockLogin(loginBranchInfo.phone)) return;
    const initLoginData = await LocalKeyActionService.getInitLoginData();
    dispatch(
      AuthActions.requestLogin({
        ...initLoginData,
        phone: loginBranchInfo.phone,
        regionCode: AppConstant.LOCAL_PHONE_CODE,
        mode: newOtpType,
        check_master_f: false,
      }),
    );
  };

  const handleCloseLockOtp = () => {
    setIsLockedOTP(false);
    setErrorContent(null);
    setOtpValues("");
    setRetries(1);
    dispatch(AuthActions.authReset());
  };

  useEffect(() => {
    return () => {
      setIsLockedOTP(false);
      setOtpValues("");
      setErrorContent(null);
      setIsLoginBranch(false);
      setRetries(1);
    };
  }, []);

  // Verify status
  useEffect(() => {
    switch (authVerifyStatus) {
      case AppConstant.VERIFY_OTP_ERROR_TYPE.success:
        if (isNew) {
          history.replace(PathConstant.CREATE_NAME);
        } else {
          history.replace(PathConstant.ROOT);
        }
        dispatch(AuthActions.authReset());
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.limitResend:
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_TRY_AGAIN_LATTER"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.wrongOtp:
        if (retries < AppConstant.MAX_RETRY_TIMES) {
          setErrorContent(
            getNSLang(LangConstant.NS_LOGIN, LangConstant.TXT_OTP_ERROR_MESSAGE, {
              tryTimes: AppConstant.MAX_RETRY_TIMES - retries,
            }),
          );
          setRetries(state => state + 1);
        } else {
          setIsLockedOTP(true);
          let blockInfo = StorageUtil.getCommonKey(KeyConstant.KEY_LOGIN_BLOCK_INFO)
            ? StorageUtil.getCommonKey(KeyConstant.KEY_LOGIN_BLOCK_INFO)
            : {};
          blockInfo[phoneNumber] = new Date().getTime();
          StorageUtil.setCommonKey(KeyConstant.KEY_LOGIN_BLOCK_INFO, blockInfo);
        }

        setErrorContent(
          getNSLang(LangConstant.NS_LOGIN, "TXT_OTP_ERROR_MESSAGE", {
            tryTimes: AppConstant.MAX_RETRY_TIMES - retries,
          }),
        );
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.expiredOtp:
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_OTP_EXPIRED"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.limitDevice:
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_LIMIT_DEVICE"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.block:
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_OTP_LOCKED_FULL_MESSAGE"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.systemError:
        setErrorContent(getCommonLang("TXT_SERVER_ERROR"));
        break;

      default:
        setErrorContent(null);
    }
  }, [authVerifyStatus]);

  // Auth status (case resend OTP)
  useEffect(() => {
    switch (authLoginStatus) {
      case AppConstant.VERIFY_OTP_ERROR_TYPE.success:
        setIsRequestLogin(false);
        if (otpType === null) {
          setOtpType(masterExisted ? SystemConstant.OTP_TYPE.smartOtp : SystemConstant.OTP_TYPE.smsOtp);
          setHeaderContent(getOtpHeaderLang(loginBranchInfo.phone, masterExisted));
        }
        setErrorContent(null);
        break;
      case AppConstant.VERIFY_OTP_ERROR_TYPE.limitResend:
        setIsRequestLogin(false);
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_INACTIVE_USER"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.block:
        setIsRequestLogin(false);
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_OTP_LOCKED_FULL_MESSAGE"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.notFoundPhone:
        setIsRequestLogin(false);
        setErrorContent(getNSLang(LangConstant.NS_LOGIN, "TXT_UNREGISTERED_MESSAGE"));
        break;

      case AppConstant.VERIFY_OTP_ERROR_TYPE.systemError:
        setIsRequestLogin(false);
        setErrorContent(getCommonLang("TXT_SERVER_ERROR"));
        break;

      default:
        setErrorContent(null);
    }
  }, [authLoginStatus]);

  useEffect(() => {
    setIsLoginBranch(tmpLoginBranch.id && tmpLoginBranch.id !== SystemConstant.GLOBAL_BRANCH_ID);
  }, [tmpLoginBranch]);

  useEffect(() => {
    if (authLoginStatus === AppConstant.VERIFY_OTP_ERROR_TYPE.notStart) {
      setIsRequestLogin(true);
    } else {
      setIsRequestLogin(false);
    }
  }, []);

  return isLockedOTP ? (
    <OTPLocked onClose={handleCloseLockOtp} />
  ) : (
    <Box className={classes.otpMainForm}>
      {isLoginBranch && (
        <BranchInfo
          title={getNSLang(LangConstant.NS_LOGIN, "TXT_LOGIN_WELCOME_TITLE")}
          subtitle={getNSLang(LangConstant.NS_LOGIN, "TXT_LOGIN_WELCOME_SUBTITLE")}
        />
      )}

      {isRequestLogin ? (
        <OTPLoading />
      ) : (
        <OTPVerify
          TitleProps={{
            title: getNSLang(LangConstant.NS_LOGIN, "TXT_LOGIN"),
            content: headerContent,
          }}
          InputProps={{
            errorContent: errorContent,
            onChange: setOtpValues,
          }}
          ActionProps={{
            otpType: otpType,

            onChangePhone: isLoginBranch ? null : onBack,
            onResendOtp: handleResendOtp,
            onChangeOtpType: handleChangeTypeOfOTP,
          }}
        />
      )}

      <Button
        variant="contained"
        className={clsx("semiBold-lg-txt", classes.continueButton)}
        disabled={otpValues.length < SystemConstant.DEFAULT_OTP_LENGTH}
        classes={{ disabled: classes.disabledButton }}
        onClick={handleContinue}
      >
        {getCommonLang(LangConstant.TXT_CONTINUE)}
      </Button>
    </Box>
  );
};

export default OTPAuth;

const getOtpHeaderLang = (phoneNumber, isSmartOTP) => {
  if (isSmartOTP) {
    return getNSLang(LangConstant.NS_LOGIN, "TXT_SENT_OTP_MESSAGE", { phone: phoneNumber });
  } else {
    return getNSLang(LangConstant.NS_LOGIN, "TXT_SENT_SMS_OTP_MESSAGE", { phone: phoneNumber });
  }
};

const useStyles = makeStyles(theme => ({
  otpMainForm: {
    backgroundColor: theme.palette.white,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    [theme.breakpoints.down("lg")]: {
      padding: "0 24px",
    },
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },

  continueButton: {
    marginTop: 15,
    width: "100%",
    padding: "14px 0",
  },
}));
