import React, { memo, useState } from "react";
import { Typography, Box, Button, OutlinedInput } from "@mui/material";
import { LangConstant, AppConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import PhoneInput from "react-phone-input-2";
import { ArrowDropDown } from "@mui/icons-material";
import { getNSLang } from "utils/lang.utils";
import clsx from "clsx";
import { DrawerLayout } from "components";

// TODO: Need to refactor
const AddingContactDrawer = ({ onSubmit, ...otherProps }) => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const contactObjContent = getLabel(LangConstant.OBJ_ADDING_CONTACT, { returnObjects: true });

  const [countryCode, setCountryCode] = useState(AppConstant.LOCAL_PHONE_CODE);
  const [phoneNumber, setPhoneNumber] = useState("");

  const onClickPhoneInput = () => {
    let phoneInput = document.getElementById(PHONE_CODE_BUTTON_ID);
    phoneInput.children[0].children[2].children[0].id = FLAG_BUTTON_ID;
    document.getElementById(FLAG_BUTTON_ID).click();
  };

  const onChangePhone = event => {
    const newPhone = event.target.value;
    const strPhoneNumber = newPhone.replace(/\D/g, "");

    setPhoneNumber(strPhoneNumber);
  };

  const onChangeCountry = strCountryCode => {
    setCountryCode(strCountryCode);
  };

  const searchContact = e => {
    e.preventDefault();
    onSubmit("+" + countryCode + phoneNumber);
  };

  return (
    <DrawerLayout title={contactObjContent.title} isPadding={true} {...otherProps}>
      <Typography className={classes.title}>{contactObjContent.phoneNumber}</Typography>
      <form onSubmit={searchContact}>
        <Box className={classes.phoneCodeAndInput}>
          <Box
            className={clsx(classes.phoneCodeButton, "no-select-text")}
            onClick={onClickPhoneInput}
            id={PHONE_CODE_BUTTON_ID}
          >
            <PhoneInput
              onChange={onChangeCountry}
              inputClass={classes.countryInput}
              buttonClass={classes.dropdownCountryButton}
              preferredCountries={[
                SystemConstant.COUNTRY.vietnam,
                SystemConstant.COUNTRY.british,
                SystemConstant.COUNTRY.america,
              ]}
            />
            {getNSLang(LangConstant.NS_LOGIN, LangConstant.FM_PHONE_CODE, { code: countryCode })}
            <ArrowDropDown />
          </Box>
          <OutlinedInput
            classes={{
              notchedOutline: classes.notchedOutline,
              focused: classes.notchedOutline,
              input: clsx(classes.phoneInput, "regular-md-txt"),
            }}
            value={phoneNumber}
            onChange={onChangePhone}
            inputMode="numeric"
            autoComplete="tel"
          />
        </Box>
        <Button
          className={classes.buttonSearch}
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={!Boolean(phoneNumber.length === 9)}
          onClick={searchContact}
        >
          {contactObjContent.search}
        </Button>
      </form>
    </DrawerLayout>
  );
};

const FLAG_BUTTON_ID = "flag-button";
const PHONE_CODE_BUTTON_ID = "phone-code-button";

AddingContactDrawer.propTypes = DrawerLayout.propTypes;

export default memo(AddingContactDrawer);

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    marginBottom: 12,
  },

  countryInput: {
    display: "none",
  },

  phoneCodeAndInput: {
    display: "flex",
    padding: "10px 0",
    backgroundColor: theme.palette.white,
    height: 42,
    border: "1px solid #F5F5F5",
    borderRadius: 8,
    width: "100%",
  },

  dropdownCountryButton: {
    border: "none !important",
    position: "relative !important",
    height: "100%",
    background: "#F7F7F8",
    "&>div": {
      padding: 0 + " !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#F7F7F8 !important",
    },
    "&>div>div": {
      position: "unset",
      margin: 0,
    },
    "&>div>div>div": {
      display: "none",
    },
  },

  phoneCodeButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: 10,
    borderRight: "1px solid #E4E4E4",
    "&>div": {
      width: 0,
    },
    "&>div>div>div": {
      display: "none",
    },
    "&>div>div>ul": {
      margin: 0 + " !important",
      top: 24,
      left: -10,
    },
  },

  buttonSearch: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 20,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.white,
    },
  },

  notchedOutline: {
    borderWidth: 0 + " !important",
  },

  phoneInput: {
    color: "#030229",
    height: 30,
    padding: 10,
    MozAppearance: "textfield",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
}));
