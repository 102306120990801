import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Close, Download, PlayArrow } from "@mui/icons-material";
import ScrollContainer from "react-indiana-drag-scroll";
import clsx from "clsx";
import { Avatar, CardActionArea, CardMedia, IconButton, Modal } from "@mui/material";
import { AttachmentUtil, downloadFile, isVideo } from "utils";
import AvatarInfo from "./AvatarInfo";
import AppCarousel from "./AppCarousel";
import { formatSentTime } from "utils/date.utils";
import { SystemConstant } from "const";
import PreviewImageWithAction from "./PreviewImageWithAction";

const CarouselMedia = ({ data, open, onClose, initialIndex, ...otherProps }) => {
  const classes = useStyles();

  const [list, setList] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);

  const onScrollAfterChanging = (_, { currentSlide }) => {
    setSlideIndex(currentSlide);
    stopVideo();
    scrollToThumbnail();
  };

  const handleDownload = () => {
    const media = list[slideIndex];
    downloadFile(media.content?.metaData.fileName, media.src, media.content?.metaData?.mimeType);
  };

  useEffect(() => {
    if (!Array.isArray(data)) return;

    if (data.length === 0) {
      setList([]);
    } else {
      Promise.all(
        data.map(media =>
          AttachmentUtil.imageFile2Src(
            media.content?.attachmentId,
            SystemConstant.GROUP_CHAT_TYPE.group,
            media.content?.metaData?.mimeType,
            media.content?.metaData?.fileName,
          ),
        ),
      ).then(listSrc => {
        const listItemWithSrc = listSrc.map((src, index) => ({
          ...data[index],
          src: src,
        }));

        setSlideIndex(initialIndex >= 0 && initialIndex < listItemWithSrc.length ? initialIndex : 0);
        setList(listItemWithSrc);
      });
    }
  }, [data]);

  useEffect(() => {
    setSlideIndex(initialIndex >= 0 && initialIndex < list.length ? initialIndex : 0);
  }, [initialIndex, open]);

  const isShowCarousel = list && list.length > 0 && open;
  return (
    isShowCarousel && (
      <Modal
        open={true}
        aria-labelledby="carouse-media-title"
        aria-describedby="carouse media"
        hideBackdrop
        {...otherProps}
      >
        <Box className={classes.root}>
          <AvatarInfo
            avatar={{
              name: list[slideIndex]?.senderName,
              avatarId: list[slideIndex]?.senderAvatar,
            }}
            title={list[slideIndex]?.senderName}
            secondTitle={formatSentTime(list[slideIndex]?.created)}
            endComponent={
              <>
                <IconButton disabled={!Boolean(list[slideIndex])} onClick={handleDownload}>
                  <Download />
                </IconButton>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </>
            }
            titleProps={{ className: classes.avatarTitle }}
          />
          <Box className={classes.main}>
            <AppCarousel
              swipeable={false}
              draggable={false}
              showDots={false}
              nextSlide={slideIndex}
              afterChange={onScrollAfterChanging}
            >
              {list.map((item, index) => (
                <Box key={index} className={classes.item}>
                  <MediaItem item={item} />
                </Box>
              ))}
            </AppCarousel>
          </Box>

          <ScrollContainer className={clsx(classes.thumbnailRoot, THUMBNAIL_LIST_CLASS_TRIGGER)}>
            {list.map((item, index) => (
              <ThumbnailItem
                key={index}
                data={item}
                active={index === slideIndex}
                onClick={() => setSlideIndex(index)}
              />
            ))}
          </ScrollContainer>
        </Box>
      </Modal>
    )
  );
};

CarouselMedia.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      accountId: PropTypes.string,
      branchId: PropTypes.string,
      deviceId: PropTypes.string,
      groupId: PropTypes.string,
      mentions: PropTypes.string,
      options: PropTypes.string,
      parentId: PropTypes.string,
      senderAvatar: PropTypes.string,
      senderDeviceId: PropTypes.string,
      sourceId: PropTypes.string,
      senderName: PropTypes.string,
      src: PropTypes.string,
      senderId: PropTypes.string,
      threadId: PropTypes.string,

      created: PropTypes.number,
      modified: PropTypes.number,
      sendType: PropTypes.number,
      state: PropTypes.number,
      status: PropTypes.number,

      content: PropTypes.shape({
        attachmentId: PropTypes.string,
        metaData: PropTypes.shape({
          fileName: PropTypes.string,
          mimeType: PropTypes.string,
        }),
      }),
    }),
  ),

  onClose: PropTypes.func,

  initialIndex: PropTypes.number,

  open: PropTypes.bool,
};
CarouselMedia.defaultProps = {
  data: [],

  onClose: () => {},

  initialIndex: 0,

  open: false,
};

export default CarouselMedia;

const THUMBNAIL_LIST_CLASS_TRIGGER = "thumbnailList";
const THUMBNAIL_CLASS_TRIGGER = "active";

const ThumbnailItem = ({ onClick, active, data }) => {
  const classes = useStyles();

  return (
    <CardActionArea
      className={clsx(classes.thumbnailItem, active && [classes.thumbnailItemActive, THUMBNAIL_CLASS_TRIGGER])}
      onClick={onClick}
    >
      <MediaItem item={data} width={108} height={108} isThumbnail={true} />
    </CardActionArea>
  );
};

const MediaItem = ({ item, isThumbnail, ...otherProps }) => {
  const classes = useStyles();
  const props = { ...otherProps };
  const isShowVideo = isVideo(item.content?.metaData?.mimeType);

  if (isShowVideo) {
    if (isThumbnail) {
      props.preload = "metadata";
    } else {
      props.controls = "controls";
      props.className = "video";
    }
  }

  return isShowVideo ? (
    <>
      <video src={item.src} controls {...props} />
      {isThumbnail && (
        <Avatar className={classes.thumbnailVideoPlay}>
          <PlayArrow />
        </Avatar>
      )}
    </>
  ) : isThumbnail ? (
    <CardMedia component="img" src={item.src} alt={item.name} {...otherProps} />
  ) : (
    <PreviewImageWithAction src={item.src} alt={item.name} />
  );
};

const stopVideo = () => {
  const arrVideoEl = document.getElementsByClassName("video");
  if (arrVideoEl) {
    for (let videoIndex = 0; videoIndex < arrVideoEl.length; videoIndex++) {
      arrVideoEl[videoIndex].load();
    }
  }
};

const scrollToThumbnail = () => {
  const thumbnailListEl = document.getElementsByClassName(THUMBNAIL_LIST_CLASS_TRIGGER)[0];
  const thumbnailActiveEl = document.getElementsByClassName(THUMBNAIL_CLASS_TRIGGER)[0];
  if (!thumbnailListEl || !thumbnailActiveEl) return; // Return if variable is null

  const halfWidthEl = thumbnailListEl.clientWidth / 2;
  if (thumbnailActiveEl.offsetLeft + 200 < thumbnailListEl.clientWidth) {
    thumbnailListEl.scrollLeft = 0;
  } else if (thumbnailListEl.scrollWidth - thumbnailActiveEl.offsetLeft < 200) {
    thumbnailListEl.scrollLeft = thumbnailListEl.scrollWidth;
  } else if (thumbnailActiveEl.offsetLeft < halfWidthEl) {
    thumbnailListEl.scrollLeft = thumbnailActiveEl.offsetLeft + halfWidthEl;
  } else thumbnailListEl.scrollLeft = thumbnailActiveEl.offsetLeft - halfWidthEl;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    background: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 16,

    "& *": { color: "white" },
  },

  avatarTitle: {
    fontSize: 15,

    "& + *": {
      fontWeight: 400,
      fontSize: 15,
    },
  },

  main: {
    flexGrow: 1,
    minHeight: 400,
    paddingLeft: 16,
    paddingRight: 16,
  },

  item: {
    width: "100%",
    height: "70vh",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",

    "& img, & video": {
      width: "auto",
      height: "100%",
      objectFit: "contain",
      borderRadius: 10,
    },
  },

  thumbnailRoot: {
    width: "calc(100% - 80px)",
    display: "inline-block",
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    marginLeft: 40,
    transition: "all 0.3s",
    willChange: "transform",
    cursor: "pointer",
    bottom: 16,

    [theme.breakpoints.up(1500)]: {
      bottom: 30,
    },
  },

  thumbnailItem: {
    display: "inline-block",
    width: 95,
    height: 95,
    margin: 6,
    borderRadius: 10,
    overflow: "hidden",

    [theme.breakpoints.up(1500)]: {
      width: 108,
      height: 108,
    },

    "& img, & video": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
  },

  thumbnailItemActive: {
    border: "3px solid #008FE8",
    boxSizing: "border-box",
  },

  thumbnailVideoPlay: {
    position: "absolute",
    top: 28,
    left: 28,
    width: 50,
    height: 50,
    background: "rgba(0, 0, 0, 0.4)",
    backdropFilter: "blur(10px)",
  },
}));
