import { AppConstant, KeyConstant } from "const";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notificationClear } from "utils/view.utils";
import { StorageUtil, isLoginBranch } from "utils";
import { BranchSelectors } from "redux-store";
import { getInteractor } from "services/local.service";

const useUnreadMessage = groupId => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);

  const [allUnreadNumber, setAllUnreadNumber] = useState(0);
  const [unreadInGroupNumber, setUnreadInGroupNumber] = useState(0);

  useEffect(() => {
    const fetchDataTimeout = setTimeout(() => {
      if (isLoginBranch(selectedBranch)) {
        const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
        const unreadMsg = getInteractor(prefixKey).LocalMessageService.findUnreadOrderByCreatedDesc(accountId);

        const allUnreadMessage = notificationClear(unreadMsg);
        const allMsgNotSeen = allUnreadMessage.allNotSeen;

        let tmpUnreadMsg = [];

        Object.values(allMsgNotSeen)
          .filter(item => item.branch_id === selectedBranch.id)
          .forEach(msg => {
            const currentGroup = getInteractor(prefixKey).LocalGroupService.get(msg.group_id);
            const options = currentGroup.options ? JSON.parse(currentGroup.options) : {};

            if (!(options.hidden && options.hidden.includes(accountId))) {
              tmpUnreadMsg = [...tmpUnreadMsg, msg];
            }
          });

        if (tmpUnreadMsg.length !== allUnreadNumber) setAllUnreadNumber(tmpUnreadMsg.length);

        if (groupId) {
          const unreadMsgInGroupLength = tmpUnreadMsg.filter(item => item.group_id === groupId).length;
          if (unreadMsgInGroupLength !== unreadInGroupNumber) setUnreadInGroupNumber(unreadMsgInGroupLength);
        }
      }
    }, AppConstant.DEBOUNCE_TIME);

    return () => clearTimeout(fetchDataTimeout);
  }, [isUpdateViewMode, selectedBranch, groupId]);

  return { allUnreadNumber, unreadInGroupNumber };
};

export default useUnreadMessage;
