import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { AttachmentUtil, downloadFile, isJSONString, toCamel } from "utils";
import { ContentCopy, Delete, Edit, Reply, Download } from "@mui/icons-material";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { ActionMenu } from "components";
import ConfirmDeleteMessagePopup from "./ConfirmDeteleMessagePopup";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { saveMessageInQueue, useConversationContext } from "../ConversationContext";
import { replaceId2Name } from "utils/view.utils";

const ChatActionMenu = ({ message, menuAnchor, isThreading, onEditMessage, onClose }) => {
  const dispatch = useDispatch();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const isMine = message.senderId === accountId;

  const { groupDetail } = useConversationContext();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  const handleReplyMessage = () => {
    dispatch(
      ConversationActions.setSelectGroupId({
        threadingId: message.sourceId,
      }),
    );
    onClose();
  };

  const handleDownLoadFile = async () => {
    if (MEDIA_TYPES.includes(message.sendType) && isJSONString(message.content)) {
      let content = toCamel(JSON.parse(message.content));
      let metaData = toCamel(JSON.parse(content.metaData));

      const mediaPath = await AttachmentUtil.imageFile2Src(
        content.attachmentId,
        SystemConstant.GROUP_CHAT_TYPE.group,
        metaData.mimeType,
        metaData.fileName,
      );

      if (metaData.fileName && mediaPath && metaData.mimeType) {
        return downloadFile(metaData.fileName, mediaPath, metaData.mimeType);
      }
    }

    // Show alert if app can not download file
    alert(getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_FILE_NOT_FOUND));
  };

  const handleCopyContent = item => {
    navigator?.clipboard.writeText(replaceId2Name(message.content, groupDetail.groupMembers, false));
    onClose();
  };
  const handleShowConfirmDialog = () => {
    setIsOpenConfirmDialog(true);
    setDeleteItem(message);
  };

  const handleCloseConfirmDialog = () => {
    setIsOpenConfirmDialog(false);
    onClose();
  };

  const handleDeleteMessage = () => {
    saveMessageInQueue(SystemConstant.SEND_TYPE.deleteMessage, deleteItem.id, deleteItem.sourceId);
    handleCloseConfirmDialog();
  };

  const handleDeleteMessageLocal = () => {
    dispatch(ConversationActions.deleteMessageLocal(message, StorageUtil.getCurrentPrefixKey()));
    handleCloseConfirmDialog();
  };

  const menuArray =
    isMine && message.sendType !== SystemConstant.SEND_TYPE.file
      ? [
          {
            onClick: handleShowConfirmDialog,
            icon: <Delete />,
            content: getCommonLang(LangConstant.TXT_DELETE),
          },
          {
            onClick: MEDIA_TYPES.includes(message.sendType) ? handleDownLoadFile : onEditMessage,
            icon: MEDIA_TYPES.includes(message.sendType) ? <Download /> : <Edit />,
            content: getLabel(
              MEDIA_TYPES.includes(message.sendType)
                ? getCommonLang(LangConstant.TXT_DOWNLOAD)
                : getCommonLang(LangConstant.TXT_EDIT),
            ),
          },
        ]
      : [
          {
            onClick: handleShowConfirmDialog,
            icon: <Delete />,
            content: getLabel(LangConstant.TXT_DELETE_FOR_YOU),
          },
          [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage].includes(message.sendType)
            ? {
                onClick: handleCopyContent,
                icon: <ContentCopy />,
                content: getCommonLang(LangConstant.TXT_COPY),
              }
            : null,
          MEDIA_TYPES.includes(message.sendType)
            ? {
                onClick: handleDownLoadFile,
                icon: <Download />,
                content: getCommonLang(LangConstant.TXT_DOWNLOAD),
              }
            : null,
        ].filter(item => Boolean(item && item.content));

  return (
    <>
      <ActionMenu
        contentArray={
          isThreading
            ? menuArray
            : [
                {
                  onClick: handleReplyMessage,
                  icon: <Reply />,
                  content: getCommonLang(LangConstant.TXT_REPLY),
                },
                ...menuArray,
              ]
        }
        onClose={onClose}
        anchorEl={menuAnchor}
        placement="top"
      />

      <ConfirmDeleteMessagePopup
        open={isOpenConfirmDialog}
        onCloseConfirmDialog={handleCloseConfirmDialog}
        onConfirmDelete={isMine ? handleDeleteMessage : handleDeleteMessageLocal}
      />
    </>
  );
};

export default memo(ChatActionMenu);

const MEDIA_TYPES = [SystemConstant.SEND_TYPE.audio, SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video];
