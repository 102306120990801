import { AppConstant, KeyConstant, SystemConstant } from "const";
import { uuid } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";
import { PRIVATE_FLAG_ID } from "pages/HomePage/components/AddingChat/AddingChannel/AddingChannelDrawer";
import { ConversationActions, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { useCallback } from "react";
import { debounce } from "lodash";

const useCreateConversation = () => {
  const dispatch = useDispatch();
  const isCreateGroupSuccess = useSelector(state => state.groupInfoRedux.isCreateGroupSuccess);
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const createConversation = (memberList = [], strGroupName, groupType, option = {}) => {
    if (isNil(groupType)) {
      console.log("createConversation - Need passing groupType");
      return;
    }

    const privateFlagInput = document.getElementById(PRIVATE_FLAG_ID);
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const memberIdList = memberList.map(item => item.contactId || item.id);

    // Adding current account_id if not exist
    if (false === memberList.includes(accountId)) memberIdList.push(accountId);

    let groupNameJson = {};
    if (strGroupName) {
      groupNameJson = { name: strGroupName };
    } else {
      groupNameJson = {
        ids: memberIdList.join(),
      };
    }

    const addingGroupData = {
      groupId: uuid(),
      groupType: groupType,
      groupName: JSON.stringify(groupNameJson),
      members: memberIdList,
      privateF: privateFlagInput?.checked ? 1 : 0,
      encryptionF: 1,

      ...option,
    };

    dispatch(GroupInfoActions.createGroup(addingGroupData));
  };

  const createGroup = (contactList = [], strGroupName, option = {}) =>
    createConversation(contactList, strGroupName, SystemConstant.GROUP_CHAT_TYPE.group, option);

  const createChannel = (contactList = [], strGroupName, option = {}) =>
    createConversation(contactList, strGroupName, SystemConstant.GROUP_CHAT_TYPE.channel, option);

  const createWithPersonal = useCallback(
    debounce((contact, option = {}) => {
      const group = getInteractor(prefixKey).LocalGroupService.findPersonalGroup(contact.contactId || contact.id);
      if (group && group.id) {
        dispatch(
          ConversationActions.setSelectGroupId({
            selectedGroupId: group.id,
          }),
        );
      } else {
        // Create a conversation if the user never chat before
        createConversation([contact], contact.contactName, SystemConstant.GROUP_CHAT_TYPE.personal, option);
      }
    }, AppConstant.DEBOUNCE_TIME),
    [prefixKey],
  );

  return { isCreateGroupSuccess, createGroup, createChannel, createWithPersonal };
};

export default useCreateConversation;
