import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";

const EmptySearchResultImage = ({ width, height, className }) => {
  return (
    <SvgIcon width={width} height={height} className={className} viewBox={`0 0 ${width} ${height}`}>
      <path d="M201 131.82H0V131.921H201V131.82Z" fill="#EBEBEB" />
      <path d="M34.4031 138.297H21.0889V138.398H34.4031V138.297Z" fill="#EBEBEB" />
      <path d="M72.2913 139.392H54.4023V139.493H72.2913V139.392Z" fill="#EBEBEB" />
      <path d="M63.1135 134.562H34.8047V134.662H63.1135V134.562Z" fill="#EBEBEB" />
      <path d="M180.86 135.238H175.759V135.338H180.86V135.238Z" fill="#EBEBEB" />
      <path d="M172.177 135.238H157.371V135.338H172.177V135.238Z" fill="#EBEBEB" />
      <path d="M123.949 136.937H111.438V137.037H123.949V136.937Z" fill="#EBEBEB" />
      <path
        d="M95.2739 113.866H17.6518C17.0437 113.865 16.4608 113.622 16.0312 113.191C15.6016 112.76 15.3604 112.176 15.3604 111.567V2.29854C15.3656 1.69309 15.6093 1.11418 16.0383 0.687561C16.4673 0.260938 17.0471 0.0210567 17.6518 0.0200195H95.2739C95.8827 0.0200195 96.4666 0.262198 96.8971 0.693279C97.3275 1.12436 97.5694 1.70903 97.5694 2.31867V111.567C97.5694 112.177 97.3275 112.761 96.8971 113.192C96.4666 113.623 95.8827 113.866 95.2739 113.866ZM17.6518 0.100533C17.0703 0.101599 16.5131 0.333644 16.1023 0.745735C15.6915 1.15783 15.4609 1.71629 15.4609 2.29854V111.567C15.4609 112.149 15.6915 112.708 16.1023 113.12C16.5131 113.532 17.0703 113.764 17.6518 113.765H95.2739C95.8558 113.764 96.4134 113.532 96.8248 113.12C97.2362 112.708 97.4678 112.15 97.4689 111.567V2.29854C97.4678 1.71592 97.2362 1.15747 96.8248 0.74549C96.4134 0.333514 95.8558 0.101597 95.2739 0.100533H17.6518Z"
        fill="#EBEBEB"
      />
      <path
        d="M182.23 113.866H104.604C103.996 113.865 103.412 113.622 102.982 113.191C102.552 112.76 102.31 112.176 102.309 111.567V2.29854C102.315 1.69273 102.559 1.11382 102.989 0.68731C103.419 0.260798 103.999 0.0210454 104.604 0.0200195H182.23C182.834 0.0221147 183.413 0.262464 183.841 0.688974C184.269 1.11548 184.512 1.69379 184.518 2.29854V111.567C184.518 112.175 184.277 112.759 183.848 113.189C183.419 113.62 182.838 113.863 182.23 113.866ZM104.604 0.100533C104.022 0.101597 103.465 0.333514 103.053 0.74549C102.642 1.15747 102.41 1.71592 102.409 2.29854V111.567C102.41 112.15 102.642 112.708 103.053 113.12C103.465 113.532 104.022 113.764 104.604 113.765H182.23C182.812 113.764 183.37 113.532 183.781 113.12C184.192 112.708 184.424 112.15 184.425 111.567V2.29854C184.424 1.71592 184.192 1.15747 183.781 0.74549C183.37 0.333514 182.812 0.101597 182.23 0.100533H104.604Z"
        fill="#EBEBEB"
      />
      <path d="M127.655 91.805V5.53516L21.7524 5.53516V91.805H127.655Z" fill="#EBEBEB" />
      <path d="M128.294 91.8091V5.53931H127.655V91.8091H128.294Z" fill="#E0E0E0" />
      <path d="M26.9745 86.5796L122.438 86.5796L122.437 10.7684L26.9745 10.7684L26.9745 86.5796Z" fill="#F5F5F5" />
      <path d="M70.4548 86.5797H83.2706L61.9204 10.7686H49.1006L70.4548 86.5797Z" fill="white" />
      <path d="M60.1637 86.5797H67.5766L46.2264 10.7686H38.8135L60.1637 86.5797Z" fill="white" />
      <path d="M101.244 86.5797H104.315L82.9648 10.7686H79.8936L101.244 86.5797Z" fill="white" />
      <path
        d="M171.204 128.511H59.4481C59.0639 128.513 58.6832 128.437 58.329 128.288C57.9748 128.138 57.6544 127.919 57.387 127.643C57.1196 127.366 56.9109 127.039 56.7732 126.679C56.6355 126.32 56.5718 125.937 56.5859 125.552L59.2311 45.53C59.264 44.7381 59.5994 43.9892 60.168 43.4378C60.7365 42.8864 61.4948 42.5747 62.2863 42.5672H174.066C174.451 42.5658 174.832 42.6423 175.186 42.7919C175.54 42.9416 175.86 43.1614 176.128 43.4381C176.395 43.7148 176.604 44.0427 176.741 44.4022C176.879 44.7617 176.943 45.1453 176.929 45.53L174.283 125.552C174.248 126.347 173.909 127.098 173.335 127.649C172.762 128.2 171.999 128.509 171.204 128.511Z"
        fill="#E0E0E0"
      />
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M170.291 128.511H58.5351C58.1508 128.513 57.7701 128.437 57.4159 128.288C57.0617 128.138 56.7413 127.919 56.4739 127.643C56.2066 127.366 55.9978 127.039 55.8601 126.679C55.7224 126.32 55.6587 125.937 55.6728 125.552L58.2898 45.53C58.3228 44.7381 58.6582 43.9892 59.2267 43.4378C59.7953 42.8864 60.5536 42.5747 61.345 42.5672H173.101C173.486 42.5658 173.866 42.6423 174.22 42.7919C174.575 42.9416 174.895 43.1614 175.162 43.4381C175.43 43.7148 175.638 44.0427 175.776 44.4022C175.914 44.7617 175.977 45.1453 175.963 45.53L173.318 125.552C173.284 126.338 172.951 127.082 172.389 127.631C171.827 128.181 171.077 128.495 170.291 128.511Z"
          fill="#FAFAFA"
        />
      </g>
      <path
        d="M168.84 128.366H33.865C33.1976 128.366 32.5439 128.555 31.9792 128.911C31.4145 129.268 30.9617 129.776 30.6731 130.379C30.5997 130.535 30.5671 130.708 30.5784 130.88C30.5896 131.053 30.6444 131.22 30.7376 131.365C30.8307 131.511 30.9592 131.63 31.1111 131.713C31.2629 131.795 31.4331 131.838 31.6057 131.836H171.1C171.273 131.838 171.443 131.796 171.596 131.714C171.748 131.632 171.877 131.513 171.971 131.367C172.064 131.221 172.12 131.054 172.131 130.881C172.143 130.709 172.11 130.536 172.036 130.379C171.747 129.776 171.294 129.267 170.729 128.91C170.163 128.554 169.509 128.365 168.84 128.366Z"
        fill="#E0E0E0"
      />
      <path
        opacity="0.6"
        d="M33.865 128.366H141.601C142.268 128.367 142.921 128.556 143.485 128.912C144.049 129.268 144.501 129.777 144.789 130.379C144.863 130.535 144.897 130.708 144.886 130.881C144.875 131.054 144.821 131.221 144.727 131.367C144.634 131.513 144.505 131.633 144.352 131.715C144.2 131.797 144.029 131.839 143.856 131.836H31.6057C31.4331 131.838 31.2629 131.795 31.1111 131.713C30.9592 131.63 30.8307 131.511 30.7376 131.365C30.6444 131.22 30.5896 131.053 30.5784 130.88C30.5671 130.708 30.5997 130.535 30.6731 130.379C30.9617 129.776 31.4145 129.268 31.9792 128.911C32.5439 128.555 33.1976 128.366 33.865 128.366Z"
        fill="#FAFAFA"
      />
      <path
        opacity="0.3"
        d="M98.2532 130.564H70.2298C69.8972 130.564 69.5782 130.696 69.3429 130.932C69.1077 131.167 68.9756 131.487 68.9756 131.82H99.5075C99.5075 131.487 99.3753 131.167 99.1401 130.932C98.9049 130.696 98.5859 130.564 98.2532 130.564Z"
        fill="#E0E0E0"
      />
      <path
        opacity="0.9"
        d="M58.3307 122.658L60.7869 48.4244C60.8004 48.1133 60.9333 47.8194 61.1581 47.6041C61.3828 47.3888 61.6819 47.2688 61.9929 47.2691H172.209C172.359 47.2684 172.507 47.2981 172.646 47.3565C172.784 47.4148 172.908 47.5006 173.012 47.6085C173.117 47.7165 173.198 47.8444 173.251 47.9847C173.304 48.1249 173.329 48.2745 173.323 48.4244L170.871 122.658C170.856 122.968 170.723 123.261 170.498 123.475C170.273 123.69 169.975 123.809 169.665 123.809H59.4442C59.2949 123.809 59.1471 123.779 59.0095 123.721C58.872 123.662 58.7476 123.577 58.6437 123.47C58.5399 123.362 58.4586 123.235 58.4048 123.095C58.351 122.956 58.3258 122.807 58.3307 122.658Z"
        fill="white"
      />
      <path
        d="M61.2524 119.498L63.5157 51.4881C63.5272 51.1125 63.6843 50.7562 63.9537 50.4947C64.2231 50.2332 64.5836 50.087 64.9588 50.0872H168.96C169.154 50.087 169.346 50.1261 169.525 50.202C169.703 50.2778 169.865 50.389 170 50.5288C170.134 50.6686 170.24 50.8341 170.309 51.0156C170.378 51.197 170.41 51.3906 170.403 51.5847L168.156 119.582C168.143 119.957 167.984 120.313 167.714 120.573C167.444 120.834 167.084 120.979 166.709 120.979H62.7117C62.5177 120.981 62.3253 120.944 62.1459 120.87C61.9666 120.796 61.8039 120.687 61.6677 120.549C61.5315 120.41 61.4245 120.246 61.3531 120.065C61.2818 119.885 61.2475 119.692 61.2524 119.498Z"
        fill="#F0F0F0"
      />
      <path d="M146.103 123.813H164.378L143.317 47.269H125.046L146.103 123.813Z" fill="white" />
      <path d="M134.465 123.813H141.038L119.981 47.269H113.404L134.465 123.813Z" fill="white" />
      <path d="M97.0471 123.813H103.624L82.563 47.269H75.9863L97.0471 123.813Z" fill="white" />
      <path
        opacity="0.4"
        d="M118.031 91.0805C118.023 91.3126 117.926 91.5326 117.759 91.6946C117.593 91.8566 117.371 91.9481 117.139 91.95C117.026 91.9512 116.914 91.9294 116.809 91.8857C116.705 91.8421 116.611 91.7776 116.532 91.6962C116.453 91.6148 116.392 91.5183 116.352 91.4124C116.312 91.3066 116.294 91.1936 116.299 91.0805C116.308 90.848 116.406 90.628 116.573 90.4661C116.74 90.3042 116.963 90.2128 117.195 90.211C117.308 90.2103 117.42 90.2326 117.523 90.2765C117.627 90.3204 117.721 90.3849 117.799 90.4662C117.878 90.5475 117.938 90.6439 117.978 90.7495C118.018 90.8551 118.036 90.9677 118.031 91.0805Z"
        fill="white"
      />
      <path
        d="M100.5 150C143.548 150 178.444 147.96 178.444 145.443C178.444 142.926 143.548 140.886 100.5 140.886C57.4533 140.886 22.5566 142.926 22.5566 145.443C22.5566 147.96 57.4533 150 100.5 150Z"
        fill="#F5F5F5"
      />
      <path
        opacity="0.5"
        d="M63.516 93.7254C57.2145 87.4154 52.9231 79.3759 51.1843 70.6235C49.4456 61.871 50.3377 52.7989 53.7478 44.5542C57.1579 36.3095 62.9329 29.2626 70.3424 24.3047C77.7519 19.3467 86.4631 16.7004 95.3745 16.7004C104.286 16.7004 112.997 19.3467 120.407 24.3047C127.816 29.2626 133.591 36.3095 137.001 44.5542C140.411 52.7989 141.303 61.871 139.565 70.6235C137.826 79.3759 133.535 87.4154 127.233 93.7254C118.783 102.185 107.323 106.938 95.3745 106.938C83.4257 106.938 71.9661 102.185 63.516 93.7254Z"
        fill="white"
      />
      <path
        d="M105.666 38.7309C107.096 36.7954 108.674 34.9743 110.385 33.2841C111.535 32.1529 115.173 29.7295 116.532 29.2585C117.593 28.9042 119.603 34.4073 119.587 37.8412C119.587 41.0617 118.405 50.6146 118.405 50.6146L105.666 38.7309Z"
        fill="#008FE8"
      />
      <path
        opacity="0.2"
        d="M108.206 42.0398C110.505 41.7299 113.806 37.3822 115.977 35.16C118.148 32.9378 118.389 36.3677 118.011 40.7597C117.818 42.9215 117.335 45.4858 116.644 46.6935C116.583 46.7859 116.501 46.8624 116.405 46.9168C116.309 46.9712 116.201 47.0018 116.091 47.0061C115.98 47.0104 115.87 46.9884 115.77 46.9417C115.67 46.895 115.582 46.8251 115.514 46.7378C113.578 44.5028 111.041 42.8721 108.206 42.0398Z"
        fill="white"
      />
      <path
        d="M121.207 101.064C120.353 101.633 119.483 102.17 118.599 102.674C110.442 107.324 101.08 109.417 91.7235 108.683C82.3666 107.95 73.4443 104.424 66.1094 98.5601C68.1194 95.1382 69.6751 92.3887 70.3585 90.8308C73.1725 84.3656 74.6479 83.4558 74.8408 77.8239C75.0338 72.192 72.3846 65.7187 71.6972 59.9661C76.276 57.7681 115.853 70.8716 115.853 70.8716C112.034 78.7377 118.373 84.7883 119.873 88.3631C121.682 92.6464 121.674 96.8693 121.207 101.064Z"
        fill="#008FE8"
      />
      <path
        d="M123.104 59.8292C123.687 60.204 124.158 60.7298 124.467 61.3509C124.467 61.3509 124.391 57.3253 122.212 54.1571C119.969 50.8842 118.284 49.8657 116.262 45.9367C114.192 41.911 109.428 37.044 102.924 36.5408C96.4195 36.0376 80.8702 35.1882 75.2301 45.1517C73.7331 47.7365 72.6583 50.5442 72.0463 53.4687C69.984 56.8261 68.4283 63.0175 68.5248 64.821C69.2521 63.9629 70.0982 63.2133 71.0373 62.5949C71.0373 62.5949 70.1408 68.1704 70.3981 71.1011C71.4528 69.8328 72.7569 68.7955 74.2291 68.0536C76.9266 72.8402 86.6188 79.7965 100.218 78.2869C111.31 77.0792 116.298 75.332 118.606 71.6647C119.451 70.2757 120.204 68.8328 120.861 67.3451C121.73 68.1975 122.392 69.2389 122.795 70.3885C123.134 68.4169 123.081 66.3975 122.638 64.4467C122.638 64.4467 123.735 65.5457 124.282 66.1576C124.29 63.6254 124.101 62.0152 123.104 59.8292Z"
        fill="#008FE8"
      />
      <path
        opacity="0.3"
        d="M91.5234 49.4189C93.0309 47.4704 96.0017 46.0011 98.6187 48.4406C99.475 49.2377 98.06 55.7915 91.5234 49.4189Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M116.323 48.7506C115.077 46.9994 112.809 45.8078 110.747 47.9937C109.774 49.0243 112.423 54.6884 116.323 48.7506Z"
        fill="black"
      />
      <path
        d="M110.188 52.8809C106.502 52.728 104.48 52.8045 104.821 54.8575C105.107 56.5684 108.918 58.4806 110.249 57.7399C112.246 56.6127 113.332 53.0098 110.188 52.8809Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M108.769 57.3534C105.111 56.3068 97.9717 55.2722 95.7567 59.2455C94.1045 62.2124 94.9527 65.2639 97.4732 67.4498C100.171 69.7968 104.432 72.0672 108.673 72.4416C114.602 72.9609 117.055 70.779 118.618 68.3958C120.954 64.8412 122.039 58.9557 117.505 57.2729C114.948 56.3229 111.326 56.8301 108.769 57.3534Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M118.591 102.646C110.005 107.545 100.092 109.601 90.2697 108.519C88.5637 103.288 88.4129 97.6724 89.8355 92.3565C92.3762 82.6184 100.69 84.8446 104.951 87.2278C107.913 85.855 112.122 84.4742 115.105 86.6763C118.47 89.1601 119.206 95.8588 118.591 102.646Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M105.152 63.5852C104.036 63.5605 102.932 63.3538 101.883 62.9733C101.814 62.9486 101.757 62.8977 101.725 62.8315C101.693 62.7652 101.687 62.689 101.711 62.619C101.722 62.5848 101.741 62.5533 101.765 62.5263C101.789 62.4992 101.818 62.4772 101.85 62.4615C101.883 62.4458 101.918 62.4366 101.954 62.4347C101.99 62.4327 102.026 62.4379 102.06 62.4499C104.308 63.2188 106.141 63.2269 107.507 62.4781C108.445 61.9475 109.142 61.0751 109.453 60.0426C109.466 59.9896 109.494 59.9417 109.534 59.905C109.574 59.8684 109.624 59.8448 109.678 59.8373C109.732 59.8275 109.788 59.8346 109.837 59.8577C109.887 59.8807 109.928 59.9186 109.956 59.9661C112.283 63.8509 114.82 62.5747 114.844 62.5627C114.876 62.5453 114.911 62.5345 114.948 62.5309C114.984 62.5274 115.021 62.5312 115.055 62.5421C115.09 62.5529 115.123 62.5707 115.151 62.5943C115.178 62.6179 115.201 62.6468 115.218 62.6794C115.235 62.7112 115.246 62.7463 115.249 62.7823C115.253 62.8184 115.249 62.8548 115.238 62.8894C115.227 62.924 115.209 62.956 115.186 62.9835C115.162 63.0111 115.134 63.0336 115.101 63.0498C115.073 63.0498 112.307 64.4628 109.791 60.731C109.382 61.6743 108.676 62.4577 107.781 62.9612C106.972 63.39 106.067 63.6048 105.152 63.5852Z"
        fill="black"
      />
      <path
        d="M85.1637 38.8596C82.6672 37.9699 80.6974 36.2228 77.8875 33.5135C75.7569 31.4564 74.0805 28.5016 72.155 28.3647C70.6475 28.256 69.4254 33.5095 70.2736 38.6019C70.9289 42.535 75.0976 52.2892 75.0976 52.2892L85.1637 38.8596Z"
        fill="#008FE8"
      />
      <path
        opacity="0.2"
        d="M80.8702 39.874C81.0026 39.7947 81.1122 39.6823 81.1883 39.5479C81.2644 39.4135 81.3044 39.2616 81.3044 39.1071C81.3044 38.9526 81.2644 38.8007 81.1883 38.6663C81.1122 38.5319 81.0026 38.4196 80.8702 38.3402C78.832 37.1124 76.3396 34.4877 74.8402 32.7043C72.8784 30.3694 72.2312 34.3991 73.0673 38.7106C73.4063 40.1872 73.9718 41.6022 74.7437 42.9053C74.8667 43.1212 75.0408 43.3034 75.2508 43.4359C75.4608 43.5684 75.7001 43.647 75.9477 43.6649C76.1952 43.6828 76.4433 43.6394 76.6701 43.5385C76.8969 43.4377 77.0955 43.2824 77.2481 43.0865C78.2589 41.812 79.4847 40.7248 80.8702 39.874Z"
        fill="white"
      />
      <path
        d="M119.189 45.5381L85.1963 46.2507C85.1495 46.2534 85.1027 46.2468 85.0585 46.2312C85.0142 46.2156 84.9736 46.1914 84.9388 46.1599C84.904 46.1285 84.8758 46.0904 84.8558 46.0479C84.8359 46.0054 84.8245 45.9594 84.8225 45.9125C84.8209 45.8673 84.8283 45.8221 84.8442 45.7797C84.8602 45.7373 84.8843 45.6985 84.9154 45.6656C84.9464 45.6326 84.9837 45.6062 85.025 45.5877C85.0663 45.5693 85.1109 45.5593 85.1561 45.5583L119.149 44.8457C119.241 44.8446 119.33 44.8792 119.396 44.9423C119.463 45.0054 119.503 45.092 119.507 45.1839C119.511 45.2729 119.481 45.3601 119.421 45.4265C119.362 45.4928 119.278 45.5329 119.189 45.5381Z"
        fill="white"
      />
      <path d="M89.5454 53.5411L80.7256 53.7262L80.416 45.6588L89.2359 45.4736L89.5454 53.5411Z" fill="white" />
      <path
        opacity="0.3"
        d="M85.908 49.6C85.9088 49.7841 85.855 49.9643 85.7535 50.1177C85.6519 50.2712 85.5072 50.391 85.3376 50.462C85.1679 50.533 84.9811 50.552 84.8007 50.5166C84.6203 50.4811 84.4545 50.3929 84.3242 50.263C84.1939 50.1331 84.1051 49.9674 84.0689 49.7869C84.0328 49.6064 84.0509 49.4192 84.1211 49.2491C84.1912 49.0789 84.3103 48.9334 84.4631 48.8311C84.6159 48.7287 84.7956 48.6741 84.9794 48.6741C85.1012 48.6736 85.2218 48.6971 85.3345 48.7434C85.4471 48.7897 85.5495 48.8578 85.6358 48.9438C85.7221 49.0299 85.7905 49.1321 85.8372 49.2447C85.884 49.3573 85.908 49.4781 85.908 49.6Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M89.5258 53.0622L101.039 52.8206C101.258 52.8144 101.474 52.7646 101.674 52.6742C101.874 52.5838 102.054 52.4544 102.204 52.2938C102.353 52.1331 102.469 51.9443 102.546 51.7382C102.622 51.5322 102.656 51.3131 102.647 51.0936L102.434 45.5422L89.2363 45.82L89.5258 53.0622Z"
        fill="white"
      />
      <path
        d="M72.6414 53.8954L80.7256 53.7264L80.4161 45.675L72.3761 45.8441C72.2121 45.8472 72.0503 45.8831 71.9004 45.9497C71.7505 46.0162 71.6153 46.1122 71.503 46.2318C71.3906 46.3515 71.3033 46.4924 71.2461 46.6464C71.1889 46.8004 71.1631 46.9642 71.1701 47.1283L71.3831 52.724C71.3998 53.0447 71.5404 53.3463 71.7753 53.565C72.0101 53.7836 72.3208 53.9021 72.6414 53.8954Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M72.6414 53.8954L80.7256 53.7264L80.4161 45.675L72.3761 45.8441C72.2121 45.8472 72.0503 45.8831 71.9004 45.9497C71.7505 46.0162 71.6153 46.1122 71.503 46.2318C71.3906 46.3515 71.3033 46.4924 71.2461 46.6464C71.1889 46.8004 71.1631 46.9642 71.1701 47.1283L71.3831 52.724C71.3998 53.0447 71.5404 53.3463 71.7753 53.565C72.0101 53.7836 72.3208 53.9021 72.6414 53.8954Z"
        fill="#008FE8"
      />
      <path
        d="M160.672 119.014C160.516 118.206 160.162 117.45 159.642 116.812C158.605 115.697 157.496 114.655 156.402 113.592C155.309 112.529 154.191 111.499 153.054 110.48C151.916 109.462 150.782 108.447 149.629 107.449C147.305 105.464 144.929 103.536 142.461 101.696C141.335 100.863 140.198 100.042 139.032 99.2444C139.307 98.4982 139.363 97.6886 139.193 96.9115C139.022 96.1345 138.633 95.4227 138.071 94.8605L136.379 93.1657C136.082 92.8689 135.744 92.6179 135.374 92.421C143.127 82.2591 146.734 69.5258 145.463 56.8005C144.192 44.0752 138.138 32.3096 128.528 23.8872C118.918 15.4647 106.47 11.0153 93.7059 11.4402C80.9422 11.8652 68.8169 17.1327 59.7866 26.1757C50.7563 35.2188 45.4961 47.3611 45.0718 60.1428C44.6474 72.9245 49.0906 85.3899 57.5012 95.0135C65.9118 104.637 77.6609 110.7 90.3683 111.973C103.076 113.246 115.791 109.634 125.939 101.869C126.133 102.24 126.382 102.58 126.678 102.876L128.375 104.574C128.937 105.136 129.648 105.524 130.424 105.695C131.199 105.865 132.007 105.81 132.753 105.537C133.557 106.7 134.361 107.839 135.201 108.966C137.038 111.438 138.964 113.821 140.941 116.144C141.942 117.299 142.951 118.455 143.972 119.578C144.994 120.701 146.031 121.824 147.08 122.927C148.129 124.03 149.182 125.133 150.296 126.176C150.936 126.693 151.692 127.045 152.499 127.203C153.612 127.413 154.758 127.369 155.852 127.074C156.988 126.795 158.027 126.21 158.855 125.382C159.683 124.554 160.268 123.514 160.547 122.376C160.841 121.279 160.883 120.13 160.672 119.014ZM62.2056 95.0256C55.6425 88.4562 51.1722 80.0854 49.3603 70.9716C47.5483 61.8579 48.4761 52.4108 52.0262 43.8251C55.5763 35.2395 61.5893 27.901 69.3047 22.7379C77.02 17.5747 86.0911 14.8189 95.3706 14.8189C104.65 14.8189 113.721 17.5747 121.437 22.7379C129.152 27.901 135.165 35.2395 138.715 43.8251C142.265 52.4108 143.193 61.8579 141.381 70.9716C139.569 80.0854 135.099 88.4562 128.536 95.0256C119.739 103.832 107.809 108.779 95.3706 108.779C82.9319 108.779 71.0024 103.832 62.2056 95.0256Z"
        fill="#407BFF"
      />
      <path
        opacity="0.2"
        d="M160.672 119.014C160.516 118.206 160.162 117.45 159.642 116.812C158.605 115.697 157.496 114.655 156.402 113.592C155.309 112.529 154.191 111.499 153.054 110.48C151.916 109.462 150.782 108.447 149.629 107.449C147.305 105.464 144.929 103.536 142.461 101.696C141.335 100.863 140.198 100.042 139.032 99.2444C139.307 98.4982 139.363 97.6886 139.193 96.9115C139.022 96.1345 138.633 95.4227 138.071 94.8605L136.379 93.1657C136.082 92.8689 135.744 92.6179 135.374 92.421C143.127 82.2591 146.734 69.5258 145.463 56.8005C144.192 44.0752 138.138 32.3096 128.528 23.8872C118.918 15.4647 106.47 11.0153 93.7059 11.4402C80.9422 11.8652 68.8169 17.1327 59.7866 26.1757C50.7563 35.2188 45.4961 47.3611 45.0718 60.1428C44.6474 72.9245 49.0906 85.3899 57.5012 95.0135C65.9118 104.637 77.6609 110.7 90.3683 111.973C103.076 113.246 115.791 109.634 125.939 101.869C126.133 102.24 126.382 102.58 126.678 102.876L128.375 104.574C128.937 105.136 129.648 105.524 130.424 105.695C131.199 105.865 132.007 105.81 132.753 105.537C133.557 106.7 134.361 107.839 135.201 108.966C137.038 111.438 138.964 113.821 140.941 116.144C141.942 117.299 142.951 118.455 143.972 119.578C144.994 120.701 146.031 121.824 147.08 122.927C148.129 124.03 149.182 125.133 150.296 126.176C150.936 126.693 151.692 127.045 152.499 127.203C153.612 127.413 154.758 127.369 155.852 127.074C156.988 126.795 158.027 126.21 158.855 125.382C159.683 124.554 160.268 123.514 160.547 122.376C160.841 121.279 160.883 120.13 160.672 119.014ZM62.2056 95.0256C55.6425 88.4562 51.1722 80.0854 49.3603 70.9716C47.5483 61.8579 48.4761 52.4108 52.0262 43.8251C55.5763 35.2395 61.5893 27.901 69.3047 22.7379C77.02 17.5747 86.0911 14.8189 95.3706 14.8189C104.65 14.8189 113.721 17.5747 121.437 22.7379C129.152 27.901 135.165 35.2395 138.715 43.8251C142.265 52.4108 143.193 61.8579 141.381 70.9716C139.569 80.0854 135.099 88.4562 128.536 95.0256C119.739 103.832 107.809 108.779 95.3706 108.779C82.9319 108.779 71.0024 103.832 62.2056 95.0256Z"
        fill="#008FE8"
      />
      <path
        opacity="0.3"
        d="M132.753 105.536L139.032 99.2444C139.032 99.2444 137.364 104.035 132.753 105.536Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default EmptySearchResultImage;

EmptySearchResultImage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,

  className: PropTypes.string,
};

EmptySearchResultImage.defaultProps = {
  width: 201,
  height: 150,

  className: "",
};
