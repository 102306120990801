import HandlingUnreadMessage from "./HandlingUnreadMessage";
import HandlingBlockAccount from "./HandlingBlockAccount";
import HandlingOnlineStatus from "./HandlingOnlineStatus";
import HandlingBranchInvite from "./HandlingBranchInvite";

import HandleAppEvents from "./HandleAppEvents";

const HandlingParallel = () => {
  return (
    <>
      <HandlingBlockAccount />
      <HandlingUnreadMessage />
      <HandlingOnlineStatus />
      <HandlingBranchInvite />
      <HandleAppEvents />
    </>
  );
};
export default HandlingParallel;
