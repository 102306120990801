import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ImageConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { DrawerLayout, NoticeDialog, Processing } from "components";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RestoreActions } from "redux-store";
import OTPInput from "components/OTPVerify/OTPInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import clsx from "clsx";
import BackupFileInfo from "./BackupFileInfo";
import { StorageUtil } from "utils";
import AccountItem from "../AccountItem";

const BackUpDrawer = () => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const backupStatus = useSelector(state => state.restoreRedux.backupStatus);
  const fileSize = useSelector(state => state.restoreRedux.fileSize);

  const [isShow, setIsShow] = useState(false);
  const [passCode, setPassCode] = useState("");
  const [errorContent, setErrorContent] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [backupTime, setBackupTime] = useState(null);
  const [isOpenLosePermission, setIsOpenLosePermission] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const handleSendRequest = () => {
    const backupTime = new Date().getTime();
    setBackupTime(backupTime);
    dispatch(
      RestoreActions.backup({
        passCode: passCode,
        createdTime: backupTime,
      }),
    );
  };

  const handleClose = () => {
    setPassCode("");
    setErrorContent(null);
    setInProgress(false);
    setIsSuccess(false);
    setIsOpenLosePermission(false);
    setIsShow(false);

    dispatch(
      RestoreActions.restoreSet({
        backupStatus: SystemConstant.RESTORE_STATUS.notStart,
      }),
    );
  };

  useEffect(() => {
    if (backupStatus === SystemConstant.RESTORE_STATUS.success) {
      setInProgress(false);
      setIsSuccess(true);
    } else if (backupStatus === SystemConstant.RESTORE_STATUS.error) {
      setInProgress(false);
      setErrorContent(getLabel(LangConstant.TXT_SERVER_ERROR));
    } else if (backupStatus === SystemConstant.RESTORE_STATUS.losePermission) {
      setInProgress(false);
      setIsOpenLosePermission(true);
    } else if (backupStatus === SystemConstant.RESTORE_STATUS.inProgress) {
      setInProgress(true);
    }
  }, [backupStatus]);

  return (
    <>
      <AccountItem
        text={getLabel(LangConstant.TXT_BACKUP)}
        onClick={() => {
          setIsShow(true);
        }}
      />
      <DrawerLayout open={isShow} onClose={handleClose} title={getLabel(LangConstant.TXT_BACKUP)}>
        {inProgress && <Processing isShow={true} />}
        <Box className={classes.root}>
          {isSuccess ? (
            <Box className={clsx(classes.component, classes.successComponent)}>
              <CheckCircleIcon className={classes.successIcon} />
              <Typography className={classes.successText}>{getLabel(LangConstant.TXT_BACKUP_SUCCESS)}</Typography>
              <BackupFileInfo backupDeviceId={deviceId} backupTime={backupTime} size={fileSize} />
            </Box>
          ) : (
            <Box className={classes.component}>
              <CardMedia component="img" alt="image key" src={ImageConstant.KeyImage} className={classes.keyImage} />
              <Typography className={classes.backupInstruction}>
                {getLabel(LangConstant.TXT_BACKUP_INSTRUCTION)}
              </Typography>
              <Box className={classes.inputBox}>
                <Typography className={classes.passcodeInstruction}>
                  {getLabel(LangConstant.TXT_PASSCODE_INSTRUCTION)}
                </Typography>
                <OTPInput onChange={setPassCode} errorContent={errorContent} />
              </Box>
            </Box>
          )}
          <Button
            variant="contained"
            fullWidth
            className={isSuccess ? classes.actionBtnSuccess : classes.actionButton}
            disabled={!isSuccess && passCode.length !== SystemConstant.DEFAULT_OTP_LENGTH}
            onClick={isSuccess ? handleClose : handleSendRequest}
          >
            {isSuccess ? getLabel(LangConstant.TXT_CLOSE) : getLabel(LangConstant.TXT_CREATE_PASSCODE)}
          </Button>
          {!isSuccess && (
            <Typography className={classes.warningText}>{getLabel(LangConstant.TXT_BACKUP_WARNING)}</Typography>
          )}

          {isOpenLosePermission && (
            <NoticeDialog
              open
              onClose={handleClose}
              title={getLabel(LangConstant.TXT_NOTIFICATION)}
              content={getLabel(LangConstant.TXT_LOSE_MASTER_PERMISSION)}
              submitProps={{
                submitText: getLabel(LangConstant.TXT_GOT_IT),
                onClick: handleClose,
                className: classes.notiBtn,
              }}
            />
          )}
        </Box>
      </DrawerLayout>
    </>
  );
};

export default BackUpDrawer;

const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  keyImage: {
    height: 140,
    objectFit: "contain",
    marginTop: 30,
  },

  component: {
    height: "75vh",
  },

  passcodeInstruction: {
    textAlign: "center",
    marginBottom: 10,
  },

  backupInstruction: {
    textAlign: "center",
    fontSize: 12,
  },

  inputBox: {
    height: 400,
    paddingTop: 80,
  },

  actionButton: {
    marginTop: 8,
    minHeight: 40,
    fontSize: 14,
    backgroundColor: "red",
    "&:disabled": {
      backgroundColor: "rgba(255, 0, 0, 0.12)",
    },
    "&:hover": {
      backgroundColor: "#d7221f",
    },
  },

  actionBtnSuccess: {
    marginTop: 8,
    minHeight: 40,
    fontSize: 14,
  },

  warningText: {
    marginTop: 10,
    textAlign: "left",
    fontSize: 12,
    color: "red",
  },

  successComponent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  successIcon: {
    fontSize: 60,
    color: "#0DB55A",
    marginTop: 50,
  },

  successText: {
    marginTop: 10,
    fontWeight: "bold",
  },

  notiBtn: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#d7221f",
    },
  },
});
