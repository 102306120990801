import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { call, put } from "redux-saga/effects";
import { StorageUtil, removeDuplicateInArray, toSnake } from "utils";
import { getInteractor } from "services/local.service";
import { DeviceActions } from "redux-store";
import { remoteApiFactory } from "services/remote.service";
import { checkCurrentBranchByPrefix } from "./saga.helper";

export function* logoutDevice(action) {
  const { data } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  yield put(
    DeviceActions.deviceSet({
      loggedOutDevice: [],
    }),
  );

  let errorReason;
  try {
    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateDevice, toSnake(data));
    if (response.status === ApiConstant.STT_OK && Array.isArray(response.data)) {
      const loggedOutDevice = response.data;
      if (isCurrentBranch) {
        yield put(
          DeviceActions.deviceSuccess({
            loggedOutDevice: loggedOutDevice,
          }),
        );

        yield deviceFetching(prefixKey, currentAccountId, loggedOutDevice[0]);
      }
    } else {
      errorReason = response.error || response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (isCurrentBranch && errorReason)
    yield put(DeviceActions.deviceFailure(errorReason, { fetchStatus: SystemConstant.REDUX_LIFECYCLE.fail }));
}

export function* deviceFetching(prefixKey, accountId, deviceId, listAccountId) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);

  try {
    if (isCurrentBranch) {
      yield put(
        DeviceActions.deviceSet({
          fetchStatus: SystemConstant.REDUX_LIFECYCLE.inProgress,
        }),
      );
    }
    let accountIds = [];
    let accounts = [];
    if (accountId != null) {
      accountIds.push(accountId);
    } else if (listAccountId != null) {
      accountIds = listAccountId;
    } else {
      accounts = yield getInteractor(prefixKey).LocalAccountService.getAllInBranch();
      accountIds = removeDuplicateInArray(accounts.map(item => item.id));
    }

    let cloneAccountIds = [...accountIds];
    const lastDeviceList = getInteractor(prefixKey).LocalDeviceService.getLastDevices(accountIds);
    // Logic foreach này để làm gì?
    lastDeviceList.forEach(last => {
      cloneAccountIds = cloneAccountIds.filter(accId => accId !== last.account_id);
    });
    const excludeAccount = cloneAccountIds.map(accId => ({ account_id: accId, last_time: 0 }));
    // Get all device base on account_id, last_time
    const deviceListResponse = yield call(remoteApiFactory.getBranchApi(prefixKey).getDevice, {
      account_time_ids: JSON.stringify([...lastDeviceList, ...excludeAccount]),
    });

    const deviceList = deviceListResponse.data?.data;
    if (deviceListResponse.status === ApiConstant.STT_OK && Array.isArray(deviceList)) {
      yield getInteractor(prefixKey).LocalDeviceService.save(deviceList);
    }

    if (accountId && deviceId) {
      const newDevice = getInteractor(prefixKey).LocalDeviceService.get(deviceId);

      // If device is not found, calling API to get detail
      if (!newDevice) {
        const deviceItemResponse = yield call(remoteApiFactory.getBranchApi(prefixKey).getDevice, {
          account_id: accountId,
          device_id: deviceId,
        });
        if (deviceItemResponse.status === ApiConstant.STT_OK) {
          const deviceList = deviceItemResponse.data.data; // Return array has only 1 item
          if (Array.isArray(deviceList)) {
            getInteractor(prefixKey).LocalDeviceService.save(deviceList);
          }
        } else throw deviceItemResponse;
      }
    }

    if (isCurrentBranch) {
      yield put(
        DeviceActions.deviceSuccess({
          fetchStatus: SystemConstant.REDUX_LIFECYCLE.success,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    if (isCurrentBranch)
      yield put(DeviceActions.deviceFailure(error, { fetchStatus: SystemConstant.REDUX_LIFECYCLE.fail }));
  }
}
