import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Create, Delete } from "@mui/icons-material";
import { KeyConstant, LangConstant } from "const";
import PopupChangeContactName from "../../AddingContact/PopupChangeContactName";
import { useDispatch, useSelector } from "react-redux";
import DialogConfirmLeave from "../../AddingContact/DialogConfirmLeave";
import { BranchSelectors, ContactActions } from "redux-store";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";

const PopupContact = ({ data, onOpenPopupContact }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);

  const [isPopUpChangeName, setIsPopUpChangeName] = useState(false);
  const [isPopUpUnfriendContact, setIsPopUpUnfriendContact] = useState(false);
  const [dataChangeName, setDataChangeName] = useState("");

  const onOpenPopupChangeName = () => {
    setIsPopUpChangeName(true);
  };

  const submitChangeName = e => {
    e.preventDefault();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const currentBranchServerId = StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER).id;
    const contact = getInteractor().LocalContactService.getContactByBranchId(
      data.contactId,
      accountId,
      currentBranchServerId,
    );

    if (contact) {
      dispatch(
        ContactActions.updateContact({
          id: data.id,
          contactName: dataChangeName,
        }),
      );
    } else {
      dispatch(
        ContactActions.addNewContact({
          phoneNumber: data.phone,
          contactName: dataChangeName,
        }),
      );
    }

    setIsPopUpChangeName(false);
    onOpenPopupContact();
  };

  const unfriendContactPopup = () => {
    setIsPopUpUnfriendContact(true);
  };

  const unfriendContact = () => {
    dispatch(
      ContactActions.unfriendContact({
        contactId: data.id,
      }),
    );
    onOpenPopupContact();
  };

  return (
    <>
      <Grid container spacing={1} className={classes.container}>
        {/* <Grid item>
        <Typography className="hidden" id="icon-button-document" />
        <MenuItem className={classes.styleButton}>
          <RemoveRedEye />
          <Typography className={classes.range}>{getLabel(LangConstant.TXT_VIEW_POPUP)}</Typography>
        </MenuItem>
      </Grid> */}
        <Grid item onClick={onOpenPopupChangeName}>
          <Typography className="hidden" id="icon-button-document" />
          <MenuItem className={classes.styleButton}>
            <Create />
            <Typography className={classes.range}>{getLabel(LangConstant.TXT_CHANGE_ALIAS)}</Typography>
          </MenuItem>
        </Grid>
        {false === isBranchServer && (
          <Grid item onClick={unfriendContactPopup}>
            <Typography className="hidden" id="icon-button-document" />
            <MenuItem className={classes.styleButton}>
              <Delete className={classes.colorIcon} />
              <Typography className={classes.rangeRed}>{getLabel(LangConstant.TXT_DELETE_POPUP)}</Typography>
            </MenuItem>
          </Grid>
        )}
      </Grid>

      <PopupChangeContactName
        open={isPopUpChangeName}
        onClose={() => {
          setIsPopUpChangeName(false);
        }}
        onChange={setDataChangeName}
        onSubmit={submitChangeName}
      />

      <DialogConfirmLeave
        open={isPopUpUnfriendContact}
        onClose={() => setIsPopUpUnfriendContact(false)}
        title={getLabel(LangConstant.TXT_DELETE_CONTACT)}
        content={getLabel(LangConstant.TXT_CONTENT_DELETE_CONTACT, { name: data.contactName })}
        submitProps={{ submitText: getLabel(LangConstant.TXT_DELETE_POPUP), onClick: unfriendContact }}
        cancelProps={{ onClick: () => setIsPopUpUnfriendContact(false) }}
      />
    </>
  );
};

export default PopupContact;

const useStyles = makeStyles(theme => ({
  range: {
    marginLeft: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rangeRed: {
    color: theme.palette.secondary.main,
    marginLeft: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  styleButton: {
    display: "flex",
    borderRadius: 10,
    "&::hover": {
      backgroundColor: "#F2F2F2",
    },
  },
  colorIcon: {
    color: "#FF2C28",
  },
}));
