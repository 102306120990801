import { AppConstant } from "const";
import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  callingSet: ["data"],
  callingReset: ["data", "prefixKey"],
  onCallCheck: ["data", "prefixKey"],
  getCallHistory: ["prefixKey"],
  updateCallHistory: ["data", "prefixKey"],
  getImageIncomingCall: ["data", "prefixKey"],

  onCheckReceiversStatus: ["prefixKey"],
});

export const CallingTypes = Types;
export const CallingActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  // Calling dialog management
  isOpenCallingDialog: AppConstant.CALLING_STATUS.notStart, // need to rename; use to check calling status
  isHideCallingDialog: false,

  // Calling events management
  isInCall: false, // check user in call or not. when user start/accept: isInCall = true
  isCallEnded: null, // groupId: call in group is end
  isCallAccepted: false, // cuộc gọi được nhận
  isCallMissed: false, // cuộc gọi nhỡ
  isCallRejected: false, // cuộc gọi bị reject
  isReceiver: false, // người dùng có phải là người nhận không. Accept/Join current call: isReceiver = true
  isFetching: false,
  isConnectSuccess: false,
  isRinging: false, // đang đổ chuông khi có cuộc gọi đến => hiển thị trạng thái cuộc gọi: Đang đổ chuông
  isInAnotherCall: false, // check user in call before start/join a call
  isCalleeInAnotherCall: false, // personal call: check người được gọi đang trong cuộc gọi khác, if true: thông báo người được gọi đang trong cuộc gọi khác => kết thúc cuộc gọi.
  isPingToCallee: false,
  isReceiving: false,
  isWaitingSoundActive: false,
  isConnecting: false,
  isFetchHistorySuccess: false,
  isVideoCall: false, // check call is video or not
  isRequestDeviceFail: false, // check required device: => bắt buộc phải có mic mới thực hiện được cuộc gọi
  isDisconnected: false,
  isNoInternetConnection: false,
  isSendMeetInfoSuccess: false,

  // Calling needed data
  message: null,
  callingGroupDetail: null,
  acceptMessage: null,
  callingParticipants: null,
  createdMessage: {},
  reconnectMessage: null,
  personalRequestMessage: null,
  offerMessage: {},
  acceptWithId: {},
  missingConnection: {},
  memberChange: {
    time: 0,
    accountId: null,
  },
  lastCallingCall: [],
  removedFromGroup: null,
  isNotifyEnded: null,

  error: null,
  groupCallOptions: {},
  waitingForCall: [],
};

/* ------------- Selector ------------- */
export const CallingSelectors = {};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
    error: null,
    isFetchHistorySuccess: false,
  };
};

const set = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.data,
  };
};

const reset = (state = INITIAL_STATE) => {
  return {
    ...INITIAL_STATE,
    createdMessage: state.createdMessage,
    isSendMeetInfoSuccess: false,
  };
};

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.CALLING_SET]: set,
  [Types.CALLING_RESET]: reset,
  [Types.ON_CALL_CHECK]: request,
  [Types.ON_CHECK_RECEIVERS_STATUS]: request,
  [Types.GET_CALL_HISTORY]: request,
  [Types.UPDATE_CALL_HISTORY]: request,
  [Types.GET_IMAGE_INCOMING_CALL]: request,
};

/* ------------- Hookup Reducers To Types ------------- */
export const CallingReducer = createReducer(INITIAL_STATE, HANDLERS);
