import CommonLang from "./common.lang";
import LoginLang from "./login.lang";
import NotFoundLang from "./not-found.lang";
import HomeLang from "./home.lang";
import HomeConversationLang from "./home-conversation.lang";
import CallingLang from "./calling.lang";

export default {
  common: CommonLang,
  login: LoginLang,
  notFound: NotFoundLang,
  home: HomeLang,
  homeConversation: HomeConversationLang,
  callingLang: CallingLang,
};
