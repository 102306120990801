import React from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { removeUndefinedValueInArray } from "utils";

const ActionMenu = ({ anchorEl, onClose, classes, contentArray, placement, ...otherProps }) => {
  const defaultClasses = useStyles();

  const anchorObject = () => {
    let anchorOrigin = {};
    let transformOrigin = {};

    switch (placement) {
      case "right":
        anchorOrigin = {
          vertical: "center",
          horizontal: "right",
        };
        transformOrigin = {
          vertical: "center",
          horizontal: "left",
        };
        break;

      case "left":
        anchorOrigin = {
          vertical: "center",
          horizontal: "left",
        };
        transformOrigin = {
          vertical: "center",
          horizontal: "right",
        };
        break;

      case "top":
        anchorOrigin = {
          vertical: "top",
          horizontal: "center",
        };
        transformOrigin = {
          vertical: "bottom",
          horizontal: "center",
        };
        break;

      case "bottom":
        anchorOrigin = {
          vertical: "bottom",
          horizontal: "center",
        };
        transformOrigin = {
          vertical: "top",
          horizontal: "center",
        };
        break;

      default:
        break;
    }

    return {
      anchorOrigin: anchorOrigin,
      transformOrigin: transformOrigin,
    };
  };

  return (
    <Menu
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={{
        root: clsx(defaultClasses.chatMenuRoot, classes.chatMenuRoot),
        paper: clsx(defaultClasses.chatMenuPaper, classes.chatMenuPaper),
        list: clsx(defaultClasses.chatMenuList, classes.chatMenuList),
      }}
      anchorOrigin={anchorObject().anchorOrigin}
      transformOrigin={anchorObject().transformOrigin}
      anchorEl={anchorEl}
      PaperProps={{
        elevation: 0,
        placement: placement,
      }}
      {...otherProps}
    >
      {removeUndefinedValueInArray(contentArray).map((item, index) => (
        <MenuItem
          className={clsx(defaultClasses.menuItem, classes.menuItem)}
          onClick={item.onClick}
          key={index}
          disabled={Boolean(item.disabled)}
        >
          {item.icon} {item.content}
        </MenuItem>
      ))}
    </Menu>
  );
};

ActionMenu.propTypes = {
  contentArray: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      icon: PropTypes.node,
      content: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,

  classes: PropTypes.shape({
    chatMenuRoot: PropTypes.string,
    chatMenuPaper: PropTypes.string,
    chatMenuList: PropTypes.string,
    menuItem: PropTypes.string,
  }),

  anchorEl: PropTypes.any,

  onClose: PropTypes.func,
};

ActionMenu.defaultProps = {
  classes: {
    chatMenuRoot: "",
    chatMenuPaper: "",
    chatMenuList: "",
    menuItem: "",
  },

  onClose: () => {},

  anchorEl: null,
};

const useStyles = makeStyles(theme => ({
  chatMenuRoot: {},

  chatMenuPaper: {
    borderRadius: 20,
    padding: "13px 10px",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    minWidth: 200,

    "&[placement='right']": {
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: "50%",
        left: -5,
        width: 20,
        height: 20,
        backgroundColor: theme.palette.white,
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },

    "&[placement='bottom']": {
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        width: 20,
        height: 20,
        backgroundColor: theme.palette.white,
        transform: "translate(-50%) rotate(45deg)",
        zIndex: 0,
        top: -5,
        left: "50%",
      },
    },

    "&[placement='left']": {
      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: "50%",
        right: -5,
        width: 20,
        height: 20,
        backgroundColor: theme.palette.white,
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },

    "&[placement='top']": {
      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        width: 20,
        height: 20,
        backgroundColor: theme.palette.white,
        transform: "translate(-50%) rotate(45deg)",
        zIndex: 0,
        bottom: -5,
        left: "50%",
      },
    },
  },

  chatMenuList: {},

  menuItem: {
    borderRadius: 10,
    padding: "10px 14px",
    "&::hover": {
      backgroundColor: "#F2F2F2",
    },

    "&>svg": {
      marginRight: 10,
    },

    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    color: "#1E272E",
  },

  leftArrow: {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: "10px solid blue",
    position: "absolute",
  },
}));

export default ActionMenu;
