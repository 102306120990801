import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, ListItem, ListItemAvatar, ListItemText, OutlinedInput, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DrawerLayout, IOSSwitch } from "components";
import { makeStyles } from "@mui/styles";
import { Grid3x3 } from "@mui/icons-material";
import { LangConstant, SystemConstant } from "const";
import { useCreateConversation } from "hooks";
import AddingChannelMember from "./AddingChannelMember";

const AddingChannelDrawer = ({ onComplete, ...otherProps }) => {
  const classes = useStyles();
  const { isCreateGroupSuccess, createChannel } = useCreateConversation();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const [channelName, setChannelName] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const onChangeName = useCallback(event => setChannelName(event.target.value), []);

  const onCreateChannel = memberList => {
    setIsFetching(true); // Start adding channel
    createChannel(memberList, channelName);
  };

  useEffect(() => {
    // callback when complete adding group
    if (isFetching && isCreateGroupSuccess) {
      if (onComplete) onComplete();
    }
  }, [isFetching, isCreateGroupSuccess]);

  return (
    <DrawerLayout title={chatObjContent.titleChanel} {...otherProps}>
      <Box className={classes.root}>
        <Typography variant="subtitle1" className={classes.title}>
          {chatObjContent.txtName}
        </Typography>
        <OutlinedInput
          fullWidth
          startAdornment={<Grid3x3 />}
          placeholder={chatObjContent.channelPlaceHolder}
          onChange={onChangeName}
          classes={{ root: classes.inputRoot }}
          inputProps={{ maxLength: SystemConstant.MAX_NAME_LENGTH }}
        />

        <Typography className={classes.title}>{chatObjContent.txtPrivacy}</Typography>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <Typography variant="body2" className={classes.privacy}>
                {chatObjContent.txtMakePrivacy}
              </Typography>
            }
            secondary={
              <Typography variant="body2" className={classes.privacyDescription}>
                {chatObjContent.txtPrivacyDescription}
              </Typography>
            }
          />
          <ListItemAvatar>
            <IOSSwitch id={PRIVATE_FLAG_ID} sx={{ m: 1 }} />
          </ListItemAvatar>
        </ListItem>

        <AddingChannelMember onCreateChannel={onCreateChannel} disabled={!Boolean(channelName)} />
      </Box>
    </DrawerLayout>
  );
};

AddingChannelDrawer.propTypes = {
  onComplete: PropTypes.func,
};

export default AddingChannelDrawer;

export const PRIVATE_FLAG_ID = "private-flag-switch";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  inputRoot: {
    borderColor: "#D4D5D8",
    borderRadius: 10,
    marginTop: 8,
    marginBottom: 16,

    "& input": {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 8,
      fontWeight: 400,
      fontSize: 12,
      color: "black",
    },
  },
  title: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  privacy: {
    fontSize: 15,
    color: "black",
    lineHeight: "20px",
  },
  privacyDescription: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 16,
    lineHeight: "20px",
  },
}));
