import { Fragment, useEffect, useState } from "react";
import { Box, CardMedia, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ImageConstant, KeyConstant, LangConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import QueryString from "qs";
import { remoteApiFactory } from "services/remote.service";
import { isLoginBranch, getPrefixKey } from "utils";
import { LocalDbManagement } from "../services/local.service";
import { SystemConstant } from "../const";

const HandleThirdToken = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const isLogin = isLoginBranch();

  const [isShow, setIsShow] = useState(false);
  const [deeplink, setDeeplink] = useState();
  const [titleLoading, setTitleLoading] = useState("");

  const handleCreateServiceToken = async () => {
    setIsShow(() => true);
    console.log(deeplink);
    const queryUrl = deeplink.substring(deeplink.indexOf("?") + 1);
    const eventParams = QueryString.parse(queryUrl) || {};

    if (eventParams.service_id && eventParams.callbackUrl) {
      setTitleLoading(
        getLabel(LangConstant.TXT_CREATE_THIRD_TOKEN) + " " + AppConstant.SERVICE_NAME[eventParams.service_id],
      );

      const csbBranch = LocalDbManagement.find({
        branch_id: "147cf16bac2511eca1b1069d4fcda551",
        state: SystemConstant.STATE.active,
      })[0];
      console.log(csbBranch);
      // Not found CSB branch, return error to GST
      if (!csbBranch || csbBranch.length === 0) {
        window.handleExternalLink.openExternalLink(`${eventParams.callbackUrl}`);
        setDeeplink();
        setIsShow(false);
        return;
      }

      const prefix = getPrefixKey(csbBranch.account_id, csbBranch.branch_id);
      const response = await remoteApiFactory.getBranchApi(prefix).createThirdPartyToken(eventParams.service_id);

      // Return token to service
      window.handleExternalLink.openExternalLink(`${eventParams.callbackUrl}&token=${response.data?.token || ""}`);
    } else if (eventParams.callbackUrl) {
      // TODO: Callback error to CSB
    }

    setDeeplink();
    setIsShow(false);
  };

  useEffect(() => {
    if (!window.electronEvent) return;
    window.electronEvent.addListener(KeyConstant.EVENT_KEY.createThirdToken, (_, payload) => {
      setDeeplink(payload);
    });
  }, []);

  useEffect(() => {
    if (deeplink) {
      handleCreateServiceToken();
    }
  }, [isLogin, deeplink]);

  return (
    <>
      {isShow ? (
        <Box className={classes.container}>
          <Box className={classes.loading}>
            <Typography variant="h5" className={classes.titleLoading}>
              {titleLoading}
            </Typography>
            <Box>
              <CircularProgress color="inherit" />
            </Box>
            <Typography className={classes.contentLoading}>{getLabel(LangConstant.TXT_WAIT_MINUTE)}</Typography>
          </Box>
          <Box className={classes.imageContainer}>
            <CardMedia component="img" src={ImageConstant.ImageLogin} className={classes.loginWelcomeImage} />
          </Box>
        </Box>
      ) : (
        <Fragment />
      )}
    </>
  );
};

export default HandleThirdToken;

const useStyles = makeStyles({
  container: {
    zIndex: 9999,
    backgroundColor: "#FAFAFA",
    position: "fixed",
    height: "100%",
    display: "flex",
  },

  loading: {
    width: "30%",
    textAlign: "center",
    margin: "auto 50px",
  },

  imageContainer: {
    width: "70%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "80%",
  },

  titleLoading: {
    fontWeight: 600,
    padding: 32,
    wordBreak: "break-word",
  },

  contentLoading: {
    padding: 32,
    wordBreak: "break-word",
  },
});
