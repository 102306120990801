import { SystemConstant } from "const";
import { StorageUtil, toSnake } from "utils";

export const ARR_NOTICE_NORMAL = [
  SystemConstant.SEND_TYPE.message,
  SystemConstant.SEND_TYPE.groupMessage,
  SystemConstant.SEND_TYPE.link,
];

export const ARR_NOTICE_SPECIAL = [
  SystemConstant.SEND_TYPE.image,
  SystemConstant.SEND_TYPE.file,
  SystemConstant.SEND_TYPE.audio,
  SystemConstant.SEND_TYPE.video,
];

export const DISPLAY_MSG_TYPES = [...SystemConstant.ARR_CALLING_TYPES, ...SystemConstant.ARR_MSG_SEND_TYPES];

export const IGNORE_MESSAGE = [
  SystemConstant.SEND_TYPE.keyError,
  SystemConstant.SEND_TYPE.senderKey,
  SystemConstant.SEND_TYPE.senderKeyDeliveryError,
  SystemConstant.SEND_TYPE.reaction,
];

export const isFileMessage = sendType =>
  [
    SystemConstant.SEND_TYPE.file,
    SystemConstant.SEND_TYPE.audio,
    SystemConstant.SEND_TYPE.image,
    SystemConstant.SEND_TYPE.video,
  ].includes(sendType);

export const formatPagingParams = newParamsObj => {
  let params = {
    paging: 1,
    orderBy: "",
    asc: 1,
    offset: 0,
    limit: 100,
    filter: "",
    groupId: null,
    sinceTime: 0,
  };
  params = {
    ...params,
    ...newParamsObj,
  };

  Object.entries(params).forEach(result => {
    if (result[1] === "" || result[1] === null || result[1] === undefined) {
      delete params[result[0]];
    }
  });

  return toSnake(params);
};

export const formatArray2Key = initialObject => {
  let result = {};
  if (!initialObject) return result;

  Object.entries(initialObject).forEach(pair => {
    if (Array.isArray(pair[1])) {
      pair[1].forEach((item, index) => {
        result[`${pair[0]}[${index}] `] = item;
      });
      delete result[pair[0]];
    }
  });

  return result;
};

export const checkCurrentBranchByPrefix = prefixKey => {
  const currentPrefixKey = StorageUtil.getCurrentPrefixKey();
  return prefixKey && prefixKey === currentPrefixKey;
};

// Define types to only mapping with saga logic (not mapping with redux)
export const SAGA_TYPES = {
  GET_BRANCH_MAPPING: "GET_BRANCH_MAPPING",
};

export const SAGA_REDUCERS = {
  getBranchMapping: () => ({
    type: SAGA_TYPES.GET_BRANCH_MAPPING,
  }),
};
