import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import { toCamel } from "utils";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { getInteractor } from "services/local.service";

const useAccountList = () => {
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const [accountList, setAccountList] = useState([]); // List accounts of current branch

  const getAccountList = (searchingValue = "") => {
    searchingValue = searchingValue ? searchingValue.trim() : "";
    const accountsInBranch = getInteractor().LocalAccountService.searchAccountList(selectedBranch.id, searchingValue);

    const sortedAccountList = sortBy(accountsInBranch, [account => account.name?.toLowerCase()?.trim()]);
    setAccountList(toCamel(sortedAccountList));
  };

  useEffect(() => {
    if (selectedBranch?.id) {
      getAccountList();
    }
  }, [selectedBranch]);

  return { accountList };
};

export default useAccountList;
