import React, { useState } from "react";
import PropTypes from "prop-types";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { AttachmentUtil, downloadFile } from "utils";
import { Avatar, Box, Button, CardMedia, IconButton, Popover, Typography, Stack } from "@mui/material";
import { Close, Delete, Download, Upgrade, Visibility } from "@mui/icons-material";
import { ConfirmDialog, HandleUploadingInput } from "components";
import { makeStyles } from "@mui/styles";

const UpdateImagePopover = ({
  open,
  anchorEl,
  imageId,
  name,
  isAvatar,
  onUpload,
  onDelete,
  onClose,
  ...otherProps
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const profileObjContent = getLabel(LangConstant.OBJ_ACCOUNT_PROFILE, { returnObjects: true });
  const imageUrl = imageId ? AttachmentUtil.getAvatarRemoteUrl(imageId) : null;

  const [isConfirmRemove, setIsConfirmRemove] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const handleUploadImage = event => {
    const file = event.target.files[0];
    onUpload(file);
    onClose();
  };

  const handlePreviewImage = () => {
    setIsPreview(true);
    document.getElementById(PREVIEW_IMAGE).style.width = "100%";
  };

  const handleClose = () => {
    document.getElementById(PREVIEW_IMAGE).style.width = "0%";
    setIsPreview(false);
    onClose();
  };

  const handleDownloadImage = async () => {
    const fileName = `${imageId}.png`;

    downloadFile(fileName, imageUrl);
    handleClose();
  };

  const handleDeleteImage = () => {
    setIsConfirmRemove(false);
    onDelete();
    handleClose();
  };

  const handleShowConfirmDelete = () => setIsConfirmRemove(true);

  const typeImageContent = getLabel(isAvatar ? "TXT_AVATAR" : "TXT_COVER");
  const isShowAction = open && !isPreview;
  return (
    <>
      {isShowAction && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={onClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          {...otherProps}
        >
          <Stack spacing={0.5} className={classes.container}>
            <Button
              fullWidth
              variant="text"
              component="label"
              htmlFor="upload_photo_id"
              className={classes.styleButton}
            >
              <Upgrade className={classes.iconBlack} />
              <Typography className={classes.range}>{profileObjContent.uploadPhoto}</Typography>

              <HandleUploadingInput
                accept={AppConstant.ACCEPTABLE_IMAGE_TYPES}
                className="hidden"
                id="upload_photo_id"
                type="file"
                onChange={handleUploadImage}
              />
            </Button>

            {imageUrl && (
              <>
                <Button
                  fullWidth
                  className={classes.styleButton}
                  startIcon={<Visibility className={classes.iconBlack} />}
                  onClick={handlePreviewImage}
                >
                  <Typography className={classes.range}>{profileObjContent.viewPhoto}</Typography>
                </Button>
                <Button
                  fullWidth
                  className={classes.styleButton}
                  startIcon={<Delete className={classes.iconBlack} />}
                  onClick={handleShowConfirmDelete}
                >
                  <Typography className={classes.range}>{profileObjContent.removePhoto}</Typography>
                </Button>
              </>
            )}
          </Stack>
        </Popover>
      )}

      <Box className={classes.boxOverPlay} id={PREVIEW_IMAGE}>
        <Box className={classes.containerBoxOverPlay}>
          <Box className={classes.containerOverPlay}>
            <Box className={classes.center}>
              <Avatar src={imageUrl} />
              <Typography className={classes.textOverPlay}>{name}</Typography>
              <Typography className={classes.textOverPlay}>({typeImageContent})</Typography>
            </Box>
            <Box className={classes.center}>
              {imageId && (
                <Box>
                  <IconButton onClick={handleShowConfirmDelete}>
                    <Delete className={classes.iconWhite} />
                  </IconButton>
                  <IconButton onClick={handleDownloadImage}>
                    <Download className={classes.iconWhite} />
                  </IconButton>
                </Box>
              )}

              <ConfirmDialog
                open={isConfirmRemove}
                onClose={() => setIsConfirmRemove(false)}
                title={getLabel(LangConstant.TXT_CONFIRM_REMOVE_PHOTO)}
                content={getLabel(LangConstant.TXT_SURE_REMOVE)}
                submitProps={{
                  submitText: getLabel(LangConstant.TXT_DELETE),
                  onClick: handleDeleteImage,
                }}
                cancelProps={{ onClick: () => setIsConfirmRemove(false) }}
              />
              <IconButton onClick={handleClose}>
                <Close className={classes.iconWhite} />
              </IconButton>
            </Box>
          </Box>

          {isAvatar ? (
            <Box className={classes.imageOverPlay}>
              <CardMedia component="img" alt="" className={classes.coverImage} src={imageUrl} />
            </Box>
          ) : (
            <Box className={classes.imageOverPlayBackground}>
              <CardMedia component="img" alt="" className={classes.coverBackground} src={imageUrl} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

UpdateImagePopover.propTypes = {
  imageId: PropTypes.string,
  name: PropTypes.string,
  isAvatar: PropTypes.bool,

  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
};
UpdateImagePopover.defaultProps = {
  isAvatar: true,
};

export default UpdateImagePopover;

const PREVIEW_IMAGE = "PREVIEW_IMAGE";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 15,
    borderRadius: 20,
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      content: '""',
      position: "absolute",
      top: -15,
      left: "calc(50% - 0px)",
      width: 15,
      height: 15,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      transform: "translate(-50%, 50%) rotate(315deg)",
      clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },

  range: {
    textTransform: "none",
  },

  container: {
    padding: 8,
    borderRadius: 8,
  },

  styleButton: {},

  coverBackground: {
    width: 797,
    height: 461,
    borderRadius: 10,
    objectFit: "contain",
  },

  boxOverPlay: {
    position: "fixed",
    zIndex: 9999,
    backgroundColor: "rgba(0,0,0, 0.9)",
    width: 0,
    height: "100%",
    overflowX: "hidden",
    top: 0,
    left: 0,
  },

  iconBlack: {
    color: theme.palette.black,
  },

  iconWhite: {
    color: theme.palette.white,
  },

  containerBoxOverPlay: {
    margin: "32px 15px",
  },

  textOverPlay: {
    fontSize: 15,
    fontWeight: 600,
    color: "white",
    paddingLeft: 16,
  },

  center: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  imageOverPlay: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
  },

  imageOverPlayBackground: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "8%",
  },

  containerOverPlay: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  coverImage: {
    maxWidth: 692,
    maxHeight: 692,
    borderRadius: 10,
    objectFit: "contain",
  },

  name: {
    fontWeight: 700,
    fontSize: 15,
  },
}));
