import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";
import { DEFAULT_SEVER_OPTIONS, getSavedServer } from "utils/view.utils";
import { SystemConstant } from "const";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getBranch: ["prefixKey"],
  updateSelectedBranch: ["selectedBranch", "prefixKey"],

  branchSuccess: ["data"],
  branchFailure: ["error", "data"],
  branchSet: ["data"],
});

export const BranchTypes = Types;
export const BranchActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  ...REDUX_STATE,

  fetchBranchTimestamp: 0,

  selectedBranch: getSavedServer(),
  defaultBranch: null,
  inactiveBranchArray: [],

  loginBranch: {},
};

/* ------------- Selector ------------- */
export const BranchSelectors = {
  isBranchServer: state => state.branchRedux.selectedBranch?.type === SystemConstant.SERVER_TYPE.branch,
  getFetchBranchTimestamp: state => state.branchRedux.fetchBranchTimestamp,
  getSelectedBranch: state => state.branchRedux.selectedBranch || {},
  getInactiveBranchArray: state => state.branchRedux.inactiveBranchArray || [],
  getDefaultBranch: state => state.branchRedux.defaultBranch,
  getTmpLoginBranch: state => state.branchRedux.loginBranch || {},
  getServerOptions: state => state.branchRedux.selectedBranch?.serverOptions || DEFAULT_SEVER_OPTIONS,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,
  });

const updateSelectedBranch = (state = INITIAL_STATE, action) => {
  const selectedBranch = action.selectedBranch || {};

  if (
    selectedBranch.id !== state.selectedBranch.id ||
    Object.keys(state.selectedBranch).length !== Object.keys(selectedBranch).length
  ) {
    const newBranchData = getSavedServer(selectedBranch);
    return requestReducerFunc({ ...state, selectedBranch: { ...state.selectedBranch, ...newBranchData } });
  }

  return state;
};

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_BRANCH]: request,
  [Types.UPDATE_SELECTED_BRANCH]: updateSelectedBranch,

  [Types.BRANCH_SET]: set,
  [Types.BRANCH_SUCCESS]: success,
  [Types.BRANCH_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const BranchReducer = createReducer(INITIAL_STATE, HANDLERS);
