import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { AppConstant, SystemConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import UploadDocument from "./UploadDocument";
import { saveMessageInQueue, useConversationContext } from "../ConversationContext";
import UploadMedia from "./UploadMedia";
import { ConversationActions } from "redux-store";
import { ConversationSelectors, DEFAULT_ATTACHMENT } from "redux-store/conversation.redux";
import { AttachmentUtil, CryptoUtil, appendBuffer, convertHex2rgba, isAudio, isImage, isVideo } from "utils";

const UploadFileDialog = ({ isMediaType, file, onClose }) => {
  const isUploadMedia = Boolean(file && isMediaType);
  const isUploadFile = Boolean(file && !isMediaType);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { groupDetail } = useConversationContext();
  const { processStatus, ...attachmentData } = useSelector(ConversationSelectors.getUploadingAttachment);

  const [messageFileContent, setMessageFileContent] = useState({});
  const [formData, setFormData] = useState(null);
  const [caption, setCaption] = useState();

  const handleCloseDialog = () => {
    dispatch(
      ConversationActions.conversationSet({
        uploadingAttachment: DEFAULT_ATTACHMENT,
      }),
    );

    setFormData(null);
    setMessageFileContent({});
    onClose();
  };

  const handleConfirm = msgContent => {
    if (formData) {
      if (msgContent) setCaption(msgContent);
      dispatch(
        ConversationActions.uploadMessageFile({
          upload: formData,
          sendType: getSendType(file.type),
        }),
      );
    }
  };

  const handleChangeFile = async uploadFile => {
    const data = await createUploadForm(uploadFile, groupDetail.id);
    setMessageFileContent(data.messageFileContent);
    setFormData(data.formData);
  };

  const handleSendMessage = () => {
    if (file) {
      AttachmentUtil.copyToUpload(file.path, attachmentData.attachmentId, file.name, file);
    }
    const content = {
      ...messageFileContent,
      attachment_id: attachmentData.attachmentId,
    };
    let mentionIds = [];

    if (caption) {
      content.content_message = caption.trim();
      mentionIds = groupDetail.groupMembers.reduce((results, item) => {
        if (content.content_message.includes(item.id)) {
          return [...results, item.id];
        } else {
          return results;
        }
      }, []);
    }

    saveMessageInQueue(attachmentData.sendType, JSON.stringify(content), null, mentionIds);
    handleCloseDialog();
  };

  useEffect(() => {
    if (file) {
      handleChangeFile(file);
    }
  }, [file]);

  useEffect(() => {
    if (attachmentData?.attachmentId) {
      handleSendMessage();
    }
  }, [attachmentData]);

  const isLoadingUpload = AppConstant.PROCESS_STATUS.calling === processStatus;

  return (
    <Dialog
      open={Boolean(file)}
      classes={{
        paper: classes.dialogPaper,
      }}
      onClose={handleCloseDialog}
    >
      {isUploadFile && <UploadDocument file={file} onConfirm={handleConfirm} onClose={handleCloseDialog} />}

      {isUploadMedia && <UploadMedia file={file} onConfirm={handleConfirm} onClose={handleCloseDialog} />}

      <Box className={isLoadingUpload ? classes.loadingBox : "hidden"}>
        <CircularProgress className={classes.circularIcon} />
      </Box>
    </Dialog>
  );
};

UploadFileDialog.propTypes = {
  isMediaType: PropTypes.bool,
  file: PropTypes.any.isRequired,

  onClose: PropTypes.func.isRequired,
};

export default memo(UploadFileDialog);

const getSendType = fileType => {
  if (isImage(fileType)) {
    return SystemConstant.SEND_TYPE.image;
  } else if (isAudio(fileType)) {
    return SystemConstant.SEND_TYPE.audio;
  } else if (isVideo(fileType)) {
    return SystemConstant.SEND_TYPE.video;
  } else {
    return SystemConstant.SEND_TYPE.file;
  }
};

const createUploadForm = async (uploadFile, groupId) => {
  if (uploadFile) {
    let messageMetaData = {
      file_name: uploadFile.name,
      size: `${uploadFile.size + 2}`,
      mime_type: uploadFile.type,
    };
    const result = {};
    const encryptContent = await CryptoUtil.encryptAES(uploadFile.path, uploadFile);
    result.messageFileContent = {
      aes_key_info: JSON.stringify({ iv: encryptContent.iv, key: encryptContent.key, authtag: encryptContent.authtag }),
      meta_data: JSON.stringify(messageMetaData),
    };

    const buffer = new ArrayBuffer(2);
    const addSize = appendBuffer(encryptContent.encryptedData, buffer);
    const formData = new FormData();
    formData.append("file", new Blob([addSize]));
    formData.append("group_id", groupId);
    formData.append("metadata", JSON.stringify(messageMetaData));
    formData.append("type", "1");
    result.formData = formData;

    return result;
  }

  return {};
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    padding: 30,
    width: "100%",
  },

  loadingBox: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: convertHex2rgba("#808080", 0.9),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: theme.zIndex.tooltip + 1,
  },
}));
