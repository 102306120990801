import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { isAudio, isImage, isVideo } from "utils";
import MessengerChatInput from "../MessengerChatInput/index";
import { ConversationSelectors } from "redux-store";

// Now, only accept value AppConstant.ACCEPTABLE_IMAGE_TYPES
const UploadMedia = ({ file, onConfirm }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const { processStatus } = useSelector(ConversationSelectors.getUploadingAttachment);

  const [previewUrl, setPreviewUrl] = useState("");
  const [mediaType, setMediaType] = useState();

  useEffect(() => {
    if (file) {
      switch (true) {
        case isImage(file.type):
          setMediaType(SystemConstant.MEDIA_SEND_TYPE.image);
          break;

        case isAudio(file.type):
          setMediaType(SystemConstant.MEDIA_SEND_TYPE.audio);
          break;

        case isVideo(file.type):
          setMediaType(SystemConstant.MEDIA_SEND_TYPE.video);
          break;

        default:
          setMediaType(SystemConstant.MEDIA_SEND_TYPE.file);
          break;
      }

      setPreviewUrl(URL.createObjectURL(file));
    }
  }, [file]);

  const isFailedUpload = AppConstant.PROCESS_STATUS.error === processStatus;

  return (
    <>
      <Typography className={classes.confirmTitle}>{getLabel(LangConstant.TXT_CONFIRM_SENDING_MEDIA)}</Typography>

      {previewUrl && (
        <Box className={classes.mediaPreview}>
          {mediaType === SystemConstant.MEDIA_SEND_TYPE.image && (
            <img src={previewUrl} alt={file.name} loading="lazy" />
          )}

          {mediaType === SystemConstant.MEDIA_SEND_TYPE.video && (
            <video controls className={classes.videoPreview} src={previewUrl} />
          )}
        </Box>
      )}

      {isFailedUpload && (
        <Typography className={clsx(classes.uploadFailed, "small-md-txt")}>
          {getLabel(LangConstant.TXT_UPLOAD_FAILED)}
        </Typography>
      )}

      <MessengerChatInput
        customStyle={classes.input}
        tagListStyle={classes.tagListClasses}
        placeholder={getLabel(LangConstant.TXT_INPUT_CAPTION)}
        isAllowAttach={false}
        onSendMessage={onConfirm}
      />
    </>
  );
};

UploadMedia.propTypes = {
  file: PropTypes.any.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default UploadMedia;

const useStyles = makeStyles({
  confirmTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 20,
  },

  mediaPreview: {
    maxHeight: "100%",
    height: 450,
    width: "100%",
    padding: 20,
    backgroundColor: "#F3F3F5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    "&>img": {
      maxHeight: "100%",
    },
  },

  videoPreview: {
    height: "100%",
    width: "100%",
    borderRadius: 8,
  },

  input: {
    padding: 0,
    marginTop: 15,
  },

  tagListClasses: {
    margin: 0,
  },

  audioPlayer: {
    minWidth: 400,
    minHeight: 80,
  },

  uploadFailed: {
    color: "red",
    marginTop: 10,
    width: "100%",
    textAlign: "end",
  },
});
