import { call, put } from "redux-saga/effects";
import { AttachmentService } from "services";
import { AttachmentUtil, StorageUtil, toSnake } from "utils";
import { ApiConstant, KeyConstant } from "const";
import { ProfileActions } from "redux-store";
import { remoteApiFactory } from "services/remote.service";
import { getInteractor } from "services/local.service";

export function* updateAccountStatus(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateAccount, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ProfileActions.profileSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

// Update avatar/ cover account
export function* changeProfileImage(action) {
  try {
    const { data, isAvatar } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const apiPayload = new FormData();
    apiPayload.append("file", data.upload, data.upload.path);

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).uploadFileAccount, apiPayload);
    if (response.status === ApiConstant.STT_OK) {
      const attachmentId = response.data.id;
      const fileName = attachmentId + ".png";

      const updateAccountParams = toSnake(isAvatar ? { avatarId: attachmentId } : { backgroundId: attachmentId });
      yield call(remoteApiFactory.getBranchApi(prefixKey).updateAccount, updateAccountParams);

      if (!AttachmentUtil.exitsLocalFile(attachmentId, fileName)) {
        let attachmentResponse = yield call(AttachmentService.getAttachment, toSnake({ attachmentId: attachmentId }));
        if (attachmentResponse.status === ApiConstant.STT_OK) {
          let unit8String = new Uint8Array(attachmentResponse.data);
          try {
            AttachmentUtil.savePublicFile(unit8String, attachmentId, fileName);
          } catch (error) {
            console.log({ error });
          }

          // Update database
          const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
          getInteractor(prefixKey).LocalAccountService.updateImage(accountId, attachmentId, isAvatar);

          // Dispatch result to view layer
          yield put(
            ProfileActions.profileSet({
              updatedProfile: new Date().getTime(),
            }),
          );
        }
      }
    }
  } catch (error) {
    console.log("change profile image fail: ", error);
  }
}

export function* updateProfileInfo(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateAccount, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ProfileActions.profileSuccess({
          isUpdateUserInfoSuccess: true,
        }),
      );
    } else {
      yield put(
        ProfileActions.profileFailure(response, {
          isUpdateUserInfoFailed: true,
        }),
      );
    }
  } catch (error) {
    yield put(ProfileActions.profileFailure(error));
  }
}
