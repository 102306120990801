import { KeyConstant, SystemConstant } from "const";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StorageUtil } from "utils";
import { toCamel } from "utils";
import { getInteractor } from "services/local.service";

const useDeviceList = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const fetchStatus = useSelector(state => state.deviceRedux.fetchStatus);

  const [masterDeviceList, setMasterDeviceList] = useState([]);
  const [slaveDeviceList, setSlaveDeviceList] = useState([]);
  const [isMaster, setIsMaster] = useState(false);

  const getDeviceList = () => {
    let deviceRecords = getInteractor().LocalDeviceService.getAllByAccount(accountId);

    deviceRecords = toCamel(deviceRecords.filter(s => s.state === 1));
    sortDeviceRecords(deviceRecords);
    setMasterDeviceList(deviceRecords.filter(item => item.masterF === 1));
    setSlaveDeviceList(deviceRecords.filter(item => item.masterF === 0));

    // Check current device is master or not
    const deviceInfo = getInteractor().LocalDeviceService.get(deviceId);
    if (Boolean(deviceInfo)) {
      setIsMaster(deviceInfo.master_f === SystemConstant.DEVICE_KEY_STATE.correct);
    }
  };

  const sortDeviceRecords = deviceRecords => {
    deviceRecords.sort((rec1, rec2) => {
      switch (true) {
        case rec1.state === SystemConstant.DEVICE_STATE.active && rec2.state === SystemConstant.DEVICE_STATE.inactive:
          return -1;
        case rec1.state === SystemConstant.DEVICE_STATE.inactive && rec2.state === SystemConstant.DEVICE_STATE.active:
          return 1;
        default:
          return rec2.lastSeen - rec1.lastSeen;
      }
    });
  };

  useEffect(() => {
    getDeviceList();
  }, []);

  useEffect(() => {
    if (fetchStatus === SystemConstant.REDUX_LIFECYCLE.success) {
      getDeviceList();
    }
  }, [fetchStatus]);

  return { masterDeviceList, slaveDeviceList, isMaster, getDeviceList };
};

export default useDeviceList;
