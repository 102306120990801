import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isJSONString, sortArrayObjectReserved, toCamel } from "utils";
import { getSavedServer } from "utils/view.utils";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { BranchSelectors, ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { ARR_NOTICE_NORMAL } from "sagas/saga.helper";

const useThreadInfo = () => {
  const dispatch = useDispatch();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);

  const [unreadThreads, setUnreadThreads] = useState([]);
  const [readThreads, setReadThreads] = useState([]);
  const [totalThreads, setTotalThreads] = useState([]);

  const getThreadsInfo = async () => {
    const branchId = getSavedServer().id;
    let threadList = getInteractor().LocalThreadService.getAllByBranchId(branchId);
    if (threadList && threadList.length > 0) {
      threadList = sortArrayObjectReserved(threadList, "modified");
      threadList = Promise.all(
        threadList.map(async item => {
          const threadInfo = toCamel(item);

          const senderParentMsg = getInteractor().LocalAccountService.get(threadInfo.threadAccountId);
          const deleteThreadMsgId = await getInteractor().LocalMessageService.get2NewestDeleteMsg(threadInfo.threadId);
          const deleteIds = deleteThreadMsgId.map(item => item.parent_id);
          const newestMsgInThread = await getInteractor().LocalMessageService.get2NewestThreadMsg(
            threadInfo.threadId,
            deleteIds,
          );

          let newestMessage = {};
          let secondMessage = {};

          // Get latest message
          if (newestMsgInThread && newestMsgInThread.length > 0) {
            const firstOriginMessage = newestMsgInThread[0];
            newestMessage = convertMessageContent(firstOriginMessage);
          }

          // Get second message
          if (newestMsgInThread && newestMsgInThread.length > 1) {
            const secondOriginMessage = newestMsgInThread[newestMsgInThread.length - 1];
            secondMessage = convertMessageContent(secondOriginMessage);
          }

          // Convert parent message content
          let parentMessage = getInteractor().LocalMessageService.find({
            source_id: threadInfo.threadId,
            send_type: ARR_NOTICE_NORMAL.concat(MEDIA_SEND_TYPE),
          })[0];
          parentMessage = convertMessageContent(parentMessage);

          return {
            ...threadInfo,
            secondMessage: toCamel(secondMessage),
            newestMessage: toCamel(newestMessage),
            parentMessage: toCamel(parentMessage),
            senderParentMsg: toCamel(senderParentMsg),
          };
        }),
      )
        .then(results => {
          const unreadThreads = results.filter(item => item.totalUnread > 0);
          const readThreads = results.filter(item => item.totalUnread === 0);

          setUnreadThreads(unreadThreads);
          setReadThreads(readThreads);
          setTotalThreads([...unreadThreads, ...readThreads]);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setUnreadThreads([]);
      setReadThreads([]);
      setTotalThreads([]);
    }
  };

  const convertMessageContent = message => {
    let convertMessage = { ...message };

    try {
      if (MEDIA_SEND_TYPE.includes(message.send_type) && isJSONString(message.content)) {
        const strMetadata = toCamel(JSON.parse(message.content)).metaData;
        if (false === isJSONString(strMetadata)) return;

        const metaData = JSON.parse(strMetadata);
        const mediaContent = metaData.mime_type.split("/")[0] + ": " + metaData.file_name;
        convertMessage = { ...message, content: mediaContent };
      } else {
        const lastEditMsg = getInteractor().LocalMessageService.getNewestEditMsg(message.source_id);
        if (lastEditMsg && Object.keys(lastEditMsg).length > 0) {
          convertMessage = { ...message, content: lastEditMsg.content };
        }
      }
    } catch (error) {
      console.error(error);
    }

    return convertMessage;
  };

  const updateThreadAndMessage = threads => {
    setUnreadThreads(unreadThreads.filter(item => !unreadThreads.includes(item)));

    let unreadMessageIdArr = [];
    threads
      .filter(item => unreadThreads.includes(item))
      .forEach(item => {
        getInteractor().LocalThreadService.update({ total_unread: 0 }, item.threadId);
        const messagesInThread = getInteractor().LocalMessageService.getThreadMessage(item.threadId);
        const unreadMessagesInThread = messagesInThread.filter(
          item => item.status !== SystemConstant.MESSAGE_STATUS.read && item.sender_id !== accountId,
        );
        const unreadId = unreadMessagesInThread.map(item => item.id);
        unreadMessageIdArr = [...unreadMessageIdArr, ...unreadId];
      });

    // Dispatch event update message status = read with chunk = 20;
    const chunkMessage = 20;
    let offset = 0;
    let countMessage = 0;
    while (offset === countMessage) {
      const subUnreadMsgIdArr = unreadMessageIdArr.slice(offset, offset + chunkMessage);
      if (subUnreadMsgIdArr && subUnreadMsgIdArr.length > 0) {
        dispatch(
          ConversationActions.updateMessageStatus({
            messageIds: [...subUnreadMsgIdArr],
            status: SystemConstant.MESSAGE_STATUS.read,
          }),
        );

        getInteractor().LocalMessageService.updateMessageStatusByMsgIdArr([...subUnreadMsgIdArr]);
      }
      offset += subUnreadMsgIdArr.length;
      countMessage += chunkMessage;
    }
  };

  useEffect(() => {
    const fetchDataTimeout = setTimeout(() => {
      getThreadsInfo();
    }, AppConstant.DEBOUNCE_TIME);

    return () => clearTimeout(fetchDataTimeout);
  }, [isUpdateViewMode, selectedServerRedux]);

  return { unreadThreads, readThreads, totalThreads, getThreadsInfo, updateThreadAndMessage };
};

const MEDIA_SEND_TYPE = [
  SystemConstant.SEND_TYPE.image,
  SystemConstant.SEND_TYPE.file,
  SystemConstant.SEND_TYPE.audio,
  SystemConstant.SEND_TYPE.video,
];

export default useThreadInfo;
