import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, CardMedia, CircularProgress, Stack, Typography } from "@mui/material";
import { getFirstLetter, isImage } from "utils";
import ChatTypography from "./ChatTypography";
import clsx from "clsx";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem.helper";
import { useMessageFile } from "hooks";

const ImagePreview = ({ message, isMine, onClick }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const isAvatar = Boolean(message.isAvatar);
  const { caption, mediaData } = useMessageFile(message);

  const hasSpacingClass = Boolean(caption);
  const msgCreatedTime = getCreatedTimeText(message);

  return isMine ? (
    <Stack
      direction="column"
      alignItems="center"
      className={clsx(
        messengerClasses.messengerRoot,
        messengerClasses.myMessage,
        hasSpacingClass && messengerClasses.spacing,
      )}
    >
      <MediaBox
        classes={classes}
        mediaSrc={mediaData.mediaPath}
        mediaType={mediaData.mimeType}
        onClick={onClick}
        caption={caption}
      />

      {caption && (
        <ChatTypography messageContent={caption} mentionList={message.mentionList} className={classes.caption} />
      )}
    </Stack>
  ) : (
    <Stack direction="row" spacing={2}>
      <Avatar className={clsx(!isAvatar && messengerClasses.hiddenAvatar)} alt="chatting-avatar" src={message.avatar}>
        {getFirstLetter(message?.senderName)}
      </Avatar>

      <Box>
        {msgCreatedTime && <Typography className={messengerClasses.createdTimeText}>{msgCreatedTime}</Typography>}

        <Box
          alignItems="center"
          className={clsx(messengerClasses.messengerRoot, hasSpacingClass && messengerClasses.spacing)}
        >
          <MediaBox mediaSrc={mediaData.mediaPath} mediaType={mediaData.mimeType} onClick={onClick} />

          {caption && (
            <ChatTypography messageContent={caption} mentionList={message.mentionList} className={classes.caption} />
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default memo(ImagePreview);

const MediaBox = ({ mediaSrc, mediaType, onClick }) => {
  const classes = useStyles();
  if (!mediaSrc)
    return (
      <Box className={classes.loadingBox}>
        <CircularProgress />
      </Box>
    );

  const isImageMedia = isImage(mediaType);

  return (
    <Box className={classes.previewMedia}>
      {isImageMedia ? (
        <CardMedia component="img" src={mediaSrc} onClick={onClick} />
      ) : (
        <video
          className={classes.cardVideo}
          controls
          onClick={event => {
            event.preventDefault();
            onClick();
          }}
          src={mediaSrc + "#t=1"}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  previewMedia: {
    width: "100%",
    maxWidth: 500,

    "& img": {
      maxHeight: 400,
    },

    "& video": {
      borderRadius: 20,
      maxHeight: 500,
    },
  },

  caption: {
    fontSize: 14,
    fontWeight: 600,
    borderTop: "1px dotted #a7a7a7",
    marginTop: 5,
    width: "100%",
    padding: "10px 0 5px",
  },
});
