import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, SystemConstant } from "const";
import { AvatarConversation, AvatarInfo } from "components";
import { getSavedServer } from "../../../../../utils/view.utils";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const GroupSearchPopup = ({ data, keyword, onClose, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onCreateNewHistoryKeyword = () => {
    const savedServer = getSavedServer();
    if (keyword) {
      let historyKeywords = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
      let historyKeywordsInServer = historyKeywords[savedServer?.id] || [];
      historyKeywordsInServer.unshift(keyword);
      StorageUtil.setItem(
        KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS,
        Object.assign(historyKeywords, { [savedServer?.id]: historyKeywordsInServer }),
      );
    }
  };

  const onClickSearchItem = group => {
    onCreateNewHistoryKeyword();
    dispatch(ConversationActions.setSelectGroupId({ selectedGroupId: group.id }));
    onClose();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.heading}>
        <Typography className={clsx(classes.title, "semiBold-sm-txt")}>{title}</Typography>
      </Box>
      <Box className={classes.scrollBox}>
        <Box>
          {data.map(group => (
            <Box key={group.id} className={classes.groupItem} onClick={() => onClickSearchItem(group)}>
              {group.groupType === SystemConstant.GROUP_CHAT_TYPE.group && group.groupMembers && !group.avatarId ? (
                <Box className={classes.customGroup}>
                  <Box className={classes.avatarGroup}>
                    <AvatarConversation memberArray={group.groupMembers} />
                  </Box>
                  <Typography className={classes.groupName}>{group.groupName}</Typography>
                </Box>
              ) : (
                <AvatarInfo
                  secondTitle={null}
                  title={group.groupName}
                  avatar={{ avatarId: group.avatarId, name: group.groupName, type: group.groupType }}
                  button={true}
                  itemProps={classes.avatarProps}
                  avatarRootProps={classes.avatarRootProps}
                  privateF={group.privateF}
                  className={classes.avatarInfoBox}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default GroupSearchPopup;

GroupSearchPopup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      avatarId: PropTypes.string,
      groupMembers: PropTypes.array,
    }),
  ),
  keyword: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.white,
    zIndex: 1,
  },

  title: {
    color: "#7F7F80",
    lineHeight: "16px",
  },

  groupItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  avatarGroup: {
    width: 64,
    height: 50,
    marginLeft: 12,
    marginRight: 20,
  },

  groupName: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.black,
    paddingRight: 12,
    width: "100%",
  },

  avatarProps: {
    paddingLeft: 0,
  },

  avatarRootProps: {
    marginRight: 22,
  },

  customGroup: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(1, 0),

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },

  avatarInfoBox: {
    paddingLeft: 12,
  },
}));
