import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { AvatarInfo, SearchBar } from "components";
import EmptyContact from "./EmptyContact";
import { AppConstant, SystemConstant } from "const";
import { Box, Divider, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddingChannelButton from "./AddingChannel/AddingChannelButton";
import { TabPanel } from "@mui/base";
import { ConversationActions } from "redux-store";
import { getInteractor } from "services/local.service";

const AddingChannelTab = ({ onCloseDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isShowEmptyImage, setIsShowEmptyImage] = useState(true);
  const [channelList, setChannelList] = useState([]);

  const handleSelectGroupFunc = useCallback(
    item => () => {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: item.id,
        }),
      );
      onCloseDrawer();
    },
    [],
  );

  const handleSearch = value => {
    getInteractor()
      .LocalGroupService.searchGroupByCondition(value, {
        group_type: SystemConstant.GROUP_CHAT_TYPE.channel,
      })
      .then(conversationList => {
        setChannelList(conversationList);
        setIsShowEmptyImage(conversationList.length === 0);
      });
  };

  return (
    <TabPanel value={AppConstant.CHATTING_TAB_MODE.channel}>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={handleSearch} />
      </Box>
      <AddingChannelButton onComplete={onCloseDrawer} />
      <List>
        {channelList.map((item, index) => (
          <Box key={`${item.avatarId}-${index}`}>
            <AvatarInfo
              avatar={{ avatarId: item.avatarId, name: item.groupName, type: item.groupType }}
              title={item.groupName}
              privateF={item.privateF}
              onClick={handleSelectGroupFunc(item)}
              button={true}
              titleProps={{ className: classes.channelName }}
            />
            <Divider variant="inset" component="li" />
          </Box>
        ))}
      </List>
      {isShowEmptyImage && <EmptyContact mode={AppConstant.CHATTING_TAB_MODE.channel} />}
    </TabPanel>
  );
};

AddingChannelTab.propTypes = {
  onCloseDrawer: PropTypes.func,
};

export default memo(AddingChannelTab);

const useStyles = makeStyles({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  channelName: {
    fontWeight: 400,
    color: "black",
  },
});
