import React, { useState, createContext, useContext, useEffect, useCallback, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import store, { ConversationActions, ConversationSelectors } from "redux-store";
import { StorageUtil, isArrayNotEquals, isJSONString, isObjectNotEqual, toCamel, uuid } from "utils";
import { getSavedServer, getSendType } from "utils/view.utils";
import { CarouselMedia } from "components";
import { getInteractor } from "services/local.service";
import { debounce } from "lodash";
import { AppConstant, KeyConstant, SystemConstant } from "const";

/* ------------- Initial State ------------- */
const DEFAULT_GROUP_DATA = {
  groupName: "",
  groupMembers: [],
};

const DEFAULT_STORE = {
  selectedMediaId: null, // Not select media
  isInactive: false, // in case personal group, isInactive is "true" when user is inactive
};

const INITIAL_STATE = {
  store: DEFAULT_STORE,
  groupDetail: DEFAULT_GROUP_DATA,

  saveConversation: store => {},
  clickMedia: message => {},
};

const ConversationContext = createContext(INITIAL_STATE);
export const useConversationContext = () => useContext(ConversationContext);

export const ConversationProvider = ({ children }) => {
  const groupRef = useRef({
    detail: {},
    threadId: null,
  });
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);
  const selectedThreadId = useSelector(ConversationSelectors.getThreadingId);
  groupRef.current.threadId = selectedThreadId;

  const [store, setStore] = useState(DEFAULT_STORE);
  const [groupDetail, setGroupDetail] = useState({
    groupName: "",
    groupMembers: [],
  });

  const handleUpdateGroup = group => {
    if (!group) group = { groupName: "", groupMembers: [] };
    else if (!Array.isArray(group.groupMembers)) group.groupMembers = [];

    // Checking receiver is active or not in case personal conversation
    const chattingMember = group.groupMembers.find(member => member.id !== accountId);
    const inactiveAccountIds = getInteractor()
      .LocalBranchAccountService.getAllInactiveAccount()
      .map(item => item.account_id);
    const isDisableChatting =
      group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
      chattingMember &&
      (chattingMember.state === SystemConstant.STATE.inactive || inactiveAccountIds.includes(chattingMember.id));
    saveConversation({
      isInactive: isDisableChatting,
    });

    groupRef.current.detail = group;
    setGroupDetail(() => group);
  };

  const handleGetGroupFromDB = useCallback(
    debounce(selectedGroupId => {
      if (!selectedGroupId) return;

      const groupInDB = toCamel(getInteractor().LocalGroupService.get(selectedGroupId));
      if (isObjectNotEqual(groupInDB, groupRef.current.detail)) handleUpdateGroup(groupInDB);
    }, AppConstant.DEBOUNCE_TIME),
    [],
  );

  const saveConversation = useCallback(newData => setStore(preState => ({ ...preState, ...newData })), []);

  const clickMedia = useCallback(message => {
    if ([SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video].includes(message.sendType)) {
      saveConversation({
        selectedMediaId: message.id,
      });
    }
  }, []);

  useEffect(() => {
    let handleGroupId = selectedGroupId;
    if (selectedThreadId) {
      const branchId = getSavedServer().id;
      const selectedThread = toCamel(getInteractor().LocalThreadService.getAllByBranchId(branchId)).find(
        item => item.threadId === selectedThreadId,
      );
      if (selectedThread?.groupId) handleGroupId = selectedThread.groupId;
    }

    if (handleGroupId) {
      handleGetGroupFromDB(handleGroupId);
    } else {
      handleUpdateGroup();
    }
  }, [selectedGroupId, selectedThreadId]);

  if (!groupDetail.id) return <Fragment />;

  return (
    <ConversationContext.Provider value={{ store, groupDetail, saveConversation, clickMedia }}>
      {children}

      <MediaCarouselDialog
        groupDetail={groupDetail}
        selectedMediaId={store.selectedMediaId}
        onClose={() => saveConversation({ selectedMediaId: null })}
      />
    </ConversationContext.Provider>
  );
};

const MediaCarouselDialog = ({ groupDetail, selectedMediaId, onClose }) => {
  const [mediaList, setMediaList] = useState([]);

  useEffect(() => {
    const isValid = groupDetail && groupDetail.id;
    if (!isValid) return;

    let handleMediaList = getInteractor().LocalMessageService.getMediaMessages(groupDetail.id, [
      SystemConstant.SEND_TYPE.image,
      SystemConstant.SEND_TYPE.video,
    ]);
    handleMediaList = toCamel(handleMediaList).map(media => {
      const content = isJSONString(media.content) ? toCamel(JSON.parse(media.content)) : {};
      const metaData = isJSONString(content.metaData) ? toCamel(JSON.parse(content.metaData)) : null;

      return { ...media, content: { ...content, metaData: metaData } };
    });

    if (isArrayNotEquals(mediaList, handleMediaList)) {
      setMediaList(handleMediaList);
    }
  }, [groupDetail, selectedMediaId]);

  const selectedMediaIndex = mediaList.findIndex(media => media.id === selectedMediaId);
  const isShowCarousel = selectedMediaIndex >= 0 && selectedMediaIndex < mediaList.length;

  return <CarouselMedia open={isShowCarousel} onClose={onClose} data={mediaList} initialIndex={selectedMediaIndex} />;
};

export const saveMessageInQueue = (sendType, content, parentId = null, mentionIds = []) => {
  try {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    const {
      conversationRedux: { selectedGroupId, threadingId },
    } = store.getState();

    const branchServerId = getSavedServer().id;
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
    const groupDetail = toCamel(getInteractor(prefixKey).LocalGroupService.get(selectedGroupId));
    const isValidGroup = Boolean(
      groupDetail && groupDetail.id && Array.isArray(groupDetail.groupMembers) && groupDetail.groupMembers.length > 0,
    );
    if (!content || content === "" || false === Boolean(branchServerId) || false === isValidGroup) return;

    const mentions = mentionIds && Array(mentionIds) ? JSON.stringify(mentionIds) : mentionIds;
    const options = JSON.stringify({
      encryption_f: groupDetail.encryptionF,
    });

    const saveMessage = {
      account_id: accountId,
      branch_id: branchServerId,
      content: content,
      created: Date.now(),
      device_id: deviceId,
      group_id: selectedGroupId,
      id: uuid(),
      mentions: mentions || "[]",
      modified: null,
      options: options,
      parent_id: parentId,
      send_type: getSendType(sendType, content, groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal),
      sender_device_id: deviceId,
      sender_id: accountId,
      source_id: uuid(),
      state: 1,
      status: 1,
      thread_id: threadingId,
      call_status: null,
    };

    store.dispatch(
      ConversationActions.sendMessage(
        {
          groupId: selectedGroupId,
          sendType: sendType,
          content: content,
          parentId: parentId,
          branchId: branchServerId,
          mentionIdsArr: mentionIds,
          threadId: threadingId,
          currentMessage: saveMessage,
        },
        StorageUtil.getCurrentPrefixKey(),
      ),
    );
  } catch (error) {
    console.error(error);
  }
};
