import React, { memo } from "react";
import PropTypes from "prop-types";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const Alert = ({ isShow, onClose, message, anchorOrigin, type, ...otherProps }) => {
  return (
    <Snackbar open={isShow} autoHideDuration={3000} onClose={onClose} anchorOrigin={anchorOrigin || ANCHOR_ORIGIN}>
      {Boolean(onClose) ? (
        <MuiAlert severity={type} onClose={onClose} variant="filled" {...otherProps}>
          {message}
        </MuiAlert>
      ) : (
        <MuiAlert severity={type} variant="filled" {...otherProps}>
          {message}
        </MuiAlert>
      )}
    </Snackbar>
  );
};

Alert.propTypes = {
  isShow: PropTypes.bool.isRequired,
  message: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,

  onClose: PropTypes.func,
};

Alert.defaultProps = {
  isShow: false,
  type: "info",
  message: "",
};

export default memo(Alert);

const ANCHOR_ORIGIN = {
  vertical: "top",
  horizontal: "center",
};
