import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Avatar, Box, Typography } from "@mui/material";
import clsx from "clsx";
import { formatSentTime } from "utils/date.utils";
import { AttachmentUtil, StorageUtil, getFirstLetter, isJSONString, toCamel } from "utils";
import { LangConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { ConversationActions } from "redux-store";
import { isGroupOrChannelType } from "pages/Call";
import { useStyles } from "./NotificationInviteItem";

const NotificationTextItem = ({ data, ...otherProps }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const [senderDetail, setSenderDetail] = useState({
    accountAvatar: null,
    accountName: "",
    groupName: "",
    groupId: "",
    groupType: "",
  });
  const [message, setMessage] = useState({});

  const handleClickNotification = () => {
    const group = getInteractor().LocalGroupService.get(senderDetail.groupId);
    if (group && group.id) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: senderDetail.groupId,
          threadingId: message.thread_id,
        }),
      );
    }

    setTimeout(() => {
      dispatch(
        ConversationActions.conversationSet({
          scrollToChildId: message.state === SystemConstant.STATE.active ? message.id : null,
        }),
      );
    }, 1000);
  };

  useEffect(() => {
    if (false === Boolean(data)) return;

    const queryParams = {
      ...data,
      options: isJSONString(data.options) ? toCamel(JSON.parse(data.options)) : {},
    };

    const noticeDetail = getInteractor(prefixKey).LocalNotificationService.getNormalNotificationDetail(queryParams);
    const sender = toCamel(getInteractor(prefixKey).LocalAccountService.get(data.fromAccountId));

    setSenderDetail({
      ...noticeDetail,
      accountAvatar: sender.avatarId ? AttachmentUtil.getAvatarRemoteUrl(sender.avatarId) : null,
    });
    setMessage({ ...noticeDetail.message });
  }, [data]);

  return (
    <Box className={classes.mentionNotificationItemRoot} onClick={handleClickNotification} {...otherProps}>
      <Box className={classes.detailContainer}>
        <Box className={classes.avatarBox}>
          <Avatar alt={data?.name} src={senderDetail.accountAvatar} className={classes.avatar}>
            {getFirstLetter(senderDetail.accountName)}
          </Avatar>
        </Box>
        <Box className={classes.notificationContentBox}>
          <Typography
            className={clsx(classes.messageContent, "regular-md-txt ellipsis")}
            dangerouslySetInnerHTML={{
              __html: getLabel(
                isGroupOrChannelType(senderDetail.groupType)
                  ? LangConstant.FM_MENTION_NOTIFICATION
                  : LangConstant.FM_MENTION_INBOX,
                {
                  accountName: senderDetail.accountName,
                  groupName: senderDetail.groupName,
                  message: message.content,
                },
              ),
            }}
          />
          <Typography className={classes.createdTimeNotification}>{formatSentTime(data.created)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationTextItem;
