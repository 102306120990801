import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import MessageThreadItem from "./MessageThreadItem";
import { useEffect, useState } from "react";
import { LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { People, Grid3x3, Lock } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { ConversationActions } from "redux-store";
import { getInteractor } from "services/local.service";
import { toCamel } from "utils";

const MessageThread = ({
  threadId,
  parentMessage,
  newestMessage,
  secondMessage,
  totalReply,
  groupId,
  senderParentMsg,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  let checkOnlineInterval = null;

  const [group, setGroup] = useState({});
  const [isOnline, setIsOnline] = useState(false);

  const onReply = () => {
    dispatch(
      ConversationActions.setSelectGroupId({
        threadingId: threadId,
      }),
    );
  };

  useEffect(() => {
    const group = getInteractor().LocalGroupService.get(groupId);
    if (group && group.id) {
      setGroup(toCamel(group));
    }
  }, []);

  useEffect(() => {
    if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal && senderParentMsg?.id) {
      checkOnlineInterval = setInterval(() => {
        setIsOnline(getInteractor().LocalDeviceService.isOnlineAccount(senderParentMsg.id));
      }, 1000);
    }

    return () => {
      clearInterval(checkOnlineInterval);
    };
  }, [senderParentMsg, group]);

  return (
    <Box className={classes.rootSpace}>
      <Box className={clsx(classes.threadBox)} onClick={onReply}>
        {group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal ? (
          <Stack direction="row" spacing={1} className={classes.threadTitle}>
            <Box className={isOnline ? classes.activeState : classes.inactiveStatus} />
            <Typography className="ellipsis">{senderParentMsg.name}</Typography>
          </Stack>
        ) : group.groupType === SystemConstant.GROUP_CHAT_TYPE.group ? (
          <Stack direction="row" spacing={1} className={classes.threadTitle}>
            <People className={classes.icon} />
            <Typography className="ellipsis">{group.groupName}</Typography>
          </Stack>
        ) : (
          <Stack direction="row" spacing={1} className={classes.threadTitle}>
            {group.privateF ? <Lock className={classes.icon} /> : <Grid3x3 className={classes.icon} />}
            <Typography className="ellipsis">{group.groupName}</Typography>
          </Stack>
        )}

        <MessageThreadItem data={{ ...parentMessage, groupMembers: group.groupMembers }} />
        <Box className={clsx(classes.divider, classes.rootSpace)}>
          <Typography className={classes.dividerText}>
            {getLabel(LangConstant.FM_NUMBER_THREAD_REPLY, { number: totalReply })}
          </Typography>
          <Divider variant="inset" />
        </Box>
        {secondMessage && <MessageThreadItem data={{ ...secondMessage, groupMembers: group.groupMembers }} />}
        <MessageThreadItem data={{ ...newestMessage, groupMembers: group.groupMembers }} />

        <Box className={clsx(classes.replyBox, classes.rootSpace)}>
          <Button
            variant="contained"
            className={classes.replyButton}
            classes={{ root: classes.buttonRoot }}
            onClick={onReply}
            title={getLabel(LangConstant.TXT_REPLY_THREAD_MESSAGE)}
          >
            {getLabel(LangConstant.TXT_REPLY)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageThread;

const useStyles = makeStyles(() => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  threadBox: {
    border: "solid",
    borderColor: "#e7e7e7",
    height: "fit-content",
    borderWidth: "thin",
    borderRadius: 5,
    width: "100%",
    marginBottom: 20,
  },

  threadTitle: {
    alignItems: "center",
    padding: 10,
  },

  activeState: {
    width: 10,
    height: 10,
    backgroundColor: "#0bd50b",
    borderRadius: "50%",
  },

  divider: {
    marginBottom: 10,
  },

  dividerText: {
    color: "#7F7F80",
    fontSize: 14,
  },

  replyBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 10,
  },

  buttonRoot: {
    textTransform: "none",
  },

  icon: {
    color: "gray",
  },

  inactiveStatus: {
    width: 10,
    height: 10,
    backgroundColor: "gray",
    borderRadius: "50%",
  },
}));
