import { all, call, put, select } from "redux-saga/effects";
import CallingAction from "redux-store/calling.redux";
import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import ConversationAction from "redux-store/conversation.redux";
import { AttachmentUtil, isObjectNotEqual, toCamel, toSnake } from "utils";
import { AttachmentService } from "services";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { getBranches } from "./branch.saga";

export function* checkCallingStatus(action) {
  try {
    const { data } = action;
    const { accountId: calleeAccountId } = data;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};
    const [callerAccountId, branchId] = [branchInfo.accountId, branchInfo.id];

    const {
      callingRedux: { callingGroupDetail, isReceiver, isOpenCallingDialog },
    } = yield select();

    const [callerResp, calleeResp] = yield all([
      call(remoteApiFactory.getBranchApi(prefixKey).getCallingStatus, {
        account_id: callerAccountId,
      }),
      call(remoteApiFactory.getBranchApi(prefixKey).getCallingStatus, {
        account_id: calleeAccountId,
      }),
    ]);

    let isInAnotherCall = false;
    let isCalleeInAnotherCall = false;

    if (callerResp.status === ApiConstant.STT_OK) {
      const data = toCamel(callerResp.data);
      isInAnotherCall =
        data.callingF === SystemConstant.CALL_CHECK.inCall || data.blockF === SystemConstant.ACCOUNT_CHECK.blocked;
      yield put(CallingAction.callingSet({ isInAnotherCall }));
    }

    if (calleeResp.status === ApiConstant.STT_OK) {
      const data = toCamel(calleeResp.data);
      isCalleeInAnotherCall =
        data.callingF === SystemConstant.CALL_CHECK.inCall || data.blockF === SystemConstant.ACCOUNT_CHECK.blocking;
      yield put(CallingAction.callingSet({ isCalleeInAnotherCall, isPingToCallee: true }));
    }

    if (false === isCalleeInAnotherCall && false === isInAnotherCall && false === Boolean(data.isEndCall)) {
      yield getBranches(prefixKey);

      const dataToToken = {
        branch_id: branchId,
        room_id: callingGroupDetail?.roomId,
        is_moderator: !Boolean(isReceiver),
      };
      const callingTokenRes = yield call(remoteApiFactory.getBranchApi(prefixKey).postMeetToken, dataToToken);
      if (callingTokenRes.status === ApiConstant.STT_OK) {
        const callInfo = toCamel(callingTokenRes.data);
        StorageUtil.setItem(KeyConstant.KEY_MEET_OBJ, callInfo, prefixKey);
        yield put(
          CallingAction.callingSet({
            isSendMeetInfoSuccess: true,
          }),
        );
      } else {
        yield put(
          CallingAction.callingSet({
            isSendMeetInfoSuccess: false,
          }),
        );
      }
    } else if (data.isEndCall) {
      StorageUtil.setItem(KeyConstant.KEY_MEET_OBJ, null, prefixKey);
    }

    if (isOpenCallingDialog === AppConstant.CALLING_STATUS.checking) {
      yield put(
        CallingAction.callingSet({
          isOpenCallingDialog: AppConstant.CALLING_STATUS.finishChecking,
        }),
      );
    }

    yield put(CallingAction.callingSet({ isFetching: false }));
  } catch (error) {
    yield put(CallingAction.callingSet({ error: error, isFetching: false }));
  }
}

export function* checkReceiversStatus(action) {
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    yield getBranches(prefixKey);
    const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};
    const calleeAccountId = branchInfo.accountId;

    const {
      callingRedux: { callingGroupDetail, isOpenCallingDialog },
    } = yield select();

    const [calleeResp] = yield all([
      call(remoteApiFactory.getBranchApi(prefixKey).getCallingStatus, {
        account_id: calleeAccountId,
      }),
    ]);

    if (calleeResp.status === ApiConstant.STT_OK) {
      let data = toCamel(calleeResp.data);
      let isCalleeInAnotherCall = data.callingF === SystemConstant.CALL_CHECK.inCall;
      yield put(
        CallingAction.callingSet({
          isCalleeInAnotherCall,
          isPingToCallee: true,
          isReceiving: true,
        }),
      );

      if (false === Boolean(isCalleeInAnotherCall)) {
        const dataToToken = {
          branch_id: branchInfo.id,
          room_id: callingGroupDetail?.roomId,
          is_moderator: false,
        };

        const callingTokenRes = yield call(remoteApiFactory.getBranchApi(prefixKey).postMeetToken, dataToToken);
        if (callingTokenRes.status === ApiConstant.STT_OK) {
          const callInfo = toCamel(callingTokenRes.data);
          StorageUtil.setItem(KeyConstant.KEY_MEET_OBJ, callInfo, prefixKey);
          yield put(
            CallingAction.callingSet({
              isSendMeetInfoSuccess: true,
            }),
          );
        } else {
          yield put(
            CallingAction.callingSet({
              isSendMeetInfoSuccess: false,
            }),
          );
        }
      }
    }

    if (isOpenCallingDialog === AppConstant.CALLING_STATUS.checking) {
      yield put(
        CallingAction.callingSet({
          isOpenCallingDialog: AppConstant.CALLING_STATUS.finishChecking,
        }),
      );
    }

    yield put(CallingAction.callingSet({ isFetching: false }));
  } catch (error) {
    yield put(CallingAction.callingSet({ error: error, isFetching: false }));
  }
}

export function* getCallHistory(action) {
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    let sinceTime = 0;
    const {
      callingRedux: { callingGroupDetail },
    } = yield select();

    try {
      let loginTime = StorageUtil.getItem(KeyConstant.KEY_LOGIN_TIME, prefixKey) || 0;
      let lastRecord = getInteractor(prefixKey).LocalCallHistoryService.getLastNotByMe();

      sinceTime = lastRecord?.created ? lastRecord?.created + 1 : Number(loginTime);
    } catch (error) {
      console.log({ error });
    }
    const data = {
      sinceTime: sinceTime,
    };
    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).getCallHistory, toSnake(data));
    if (response.status === ApiConstant.STT_OK && response.data.data.length > 0) {
      let responseData = response.data.data;
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
      getInteractor(prefixKey).LocalCallHistoryService.save(responseData);
      if (responseData.length > 0) {
        yield put(
          CallingAction.callingSet({
            isFetchHistorySuccess: true,
          }),
        );
      }
      if (responseData.find(item => item.sender_id === accountId)) {
        yield put(
          ConversationAction.conversationSet({
            isUpdateViewMode: Date.now(),
          }),
        );
      }

      let callHistory = responseData.find(item => item.room_id === callingGroupDetail?.roomId);
      if (callHistory) {
        let groupCallOptions = yield select(state => state.callingRedux.groupCallOptions);
        let newOptions = JSON.parse(callHistory.options);
        if (isObjectNotEqual(groupCallOptions, newOptions)) {
          yield put(CallingAction.callingSet({ groupCallOptions: newOptions }));
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateCallHistory(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const {
      callingRedux: { callingGroupDetail },
    } = yield select();
    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateCallHistory, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let optionsUpdate = toCamel(JSON.parse(response.data.options));

      yield put(
        CallingAction.callingSet({
          callingGroupDetail: {
            ...callingGroupDetail,
            avatarId: optionsUpdate.avatarUrl,
            callName: optionsUpdate.callName,
          },
        }),
      );
    } else {
      yield put(
        CallingAction.callingSet({
          isUpdateSuccess: false,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getImageIncomingCall(action) {
  try {
    const { avatarId } = action.data;
    let fileName = avatarId + ".png";
    if (!AttachmentUtil.exitsLocalFile(avatarId, fileName)) {
      let attachmentResponse = yield call(
        AttachmentService.getAttachment,
        toSnake({
          attachmentId: avatarId,
        }),
      );
      if (attachmentResponse.status === ApiConstant.STT_OK) {
        let unit8String = new Uint8Array(attachmentResponse.data);
        try {
          AttachmentUtil.savePublicFile(unit8String, avatarId, fileName);
        } catch (error) {
          console.log({ error });
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
