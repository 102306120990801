import { useState, memo, useRef } from "react";
import PropTypes from "prop-types";
import { AppConstant } from "const";
import { isVideo } from "utils";
import NoticeDialog from "./dialog/NoticeDialog";
import { useTranslation } from "react-i18next";

const ERR_UPLOADING_TYPE = {
  none: 0,
  notAccept: 1,
  notSupport: 2,
};

const HandleUploadingInput = ({ accept, onChange, ...otherProps }) => {
  const { t: getLabel } = useTranslation();

  const inputRef = useRef(null);

  const [errorType, setErrorType] = useState(ERR_UPLOADING_TYPE.none);

  const handleChangingFile = event => {
    const file = event.target.files[0];
    const fileType = (file?.type || "").split("/")[0];
    const isAccepted = Boolean(accept === "*" || (accept.includes(fileType) && fileType));
    const isNotSupportVideo =
      fileType && isVideo(fileType) && false === AppConstant.SUPPORT_VIDEO_TYPES.includes(file.type);

    if (!isAccepted) {
      setErrorType(ERR_UPLOADING_TYPE.notAccept);
    } else if (isNotSupportVideo) {
      setErrorType(ERR_UPLOADING_TYPE.notSupport);
    } else if (onChange) {
      onChange(event);
    }

    inputRef.current.value = null;
  };

  const isError = errorType !== ERR_UPLOADING_TYPE.none;
  const errorContent = getLabel(
    errorType === ERR_UPLOADING_TYPE.notSupport ? "ERR_UNSUPPORTED_EXTENSION" : "ERR_NOT_ACCEPTABLE",
  );

  return (
    <>
      <input ref={inputRef} accept={accept} onChange={handleChangingFile} {...otherProps} />
      <NoticeDialog
        open={isError}
        title={getLabel("ERR_TITLE")}
        content={errorContent}
        submitProps={{ onClick: () => setErrorType(ERR_UPLOADING_TYPE.none) }}
      />
    </>
  );
};

HandleUploadingInput.propTypes = {
  id: PropTypes.string,
  accept: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,

  onChange: PropTypes.func,
};

HandleUploadingInput.defaultProps = {};

export default memo(HandleUploadingInput);
