import { ApiConstant, FormatConstant, SystemConstant } from "const";
import { createApiWithoutToken } from "./config/api.config";
import StringFormat from "string-format";
import { toCamel } from "utils";

export const getBranchWithoutToken = async (domain, branchId) => {
  try {
    if (domain && branchId) {
      const path = StringFormat(ApiConstant.GET_BRANCH_INFO, branchId);
      const response = await createApiWithoutToken(domain).get(path);
      if (response.status === ApiConstant.STT_OK) {
        return toCamel(response.data);
      }
    }
  } catch (error) {
    console.log("Get branch info failed: ", error);
  }

  return {};
};

export const getBranchAvatarUrl = (domain, branchId) => {
  let url = StringFormat(FormatConstant.FM_BASE_URL, domain);
  if (false === Boolean(domain) && false === Boolean(branchId)) {
    url = ApiConstant.BASE_URL;
    branchId = SystemConstant.GLOBAL_BRANCH_ID;
  }

  return StringFormat(ApiConstant.GET_BRANCH_LOGO, url, branchId);
};
