import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Badge, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { ContactIcon } from "icons";
import ContactListTab from "./ContactListTab";
import { PrimaryTab, TabPanel } from "components";
import ChatTab from "./ChatTab";
import AddingChat from "../../AddingChat";
import { useSelector } from "react-redux";
import { useCreateConversation, useUnreadMessage } from "hooks";
import { useTranslation } from "react-i18next";
import { ChatBubbleOutline, VideocamOutlined } from "@mui/icons-material";
import AddingConferenceDrawer from "../../AddingChat/AddingConferenceDrawer";
import { BranchSelectors, ConversationActions } from "redux-store";

const ViewModeTabs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createWithPersonal } = useCreateConversation();

  const isCreateGroupSuccess = useSelector(state => state.groupInfoRedux.isCreateGroupSuccess);

  const [selectedViewMode, setSelectedViewMode] = useState(VIEW_MODE_TAB_INDEX.message);

  const onClickContact = contact => {
    if (contact.groupType) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: contact.id,
        }),
      );
    } else {
      createWithPersonal(contact);
    }

    setSelectedViewMode(VIEW_MODE_TAB_INDEX.message);
  };

  useEffect(() => {
    if (isCreateGroupSuccess) {
      setSelectedViewMode(VIEW_MODE_TAB_INDEX.message);
    }
  }, [isCreateGroupSuccess]);

  return (
    <Box className={classes.viewModeRoot}>
      <TabPanel value={selectedViewMode} index={VIEW_MODE_TAB_INDEX.message} className={classes.tabPanel}>
        <ChatTab />
        <AddingChat />
      </TabPanel>

      <TabPanel value={selectedViewMode} index={VIEW_MODE_TAB_INDEX.contact} className={classes.tabPanel}>
        <ContactListTab onClick={onClickContact} />
      </TabPanel>

      <TabList classes={classes} selectedTabIndex={selectedViewMode} onChange={setSelectedViewMode} />
    </Box>
  );
};

const VIEW_MODE_TAB_INDEX = {
  message: 0,
  contact: 1,
  conference: 2,
};

ViewModeTabs.propTypes = {};

export default ViewModeTabs;

const TabList = ({ classes, selectedTabIndex, onChange }) => {
  const tabArray = useTabArray(classes);

  const [isShowConference, setIsShowConference] = useState(false);

  const onChangeTabIndex = tabIndex => {
    if (tabIndex === VIEW_MODE_TAB_INDEX.conference) {
      setIsShowConference(true);
    } else {
      onChange(tabIndex);
    }
  };

  return (
    <>
      <Box className={classes.tabContainer}>
        <PrimaryTab
          tabArray={tabArray}
          onChange={onChangeTabIndex}
          TabIndicatorProps={{
            className: "hidden",
          }}
          classes={{
            tabLabel: "regular-sm-txt",
          }}
          selectedTabIndex={selectedTabIndex}
        />
      </Box>

      {isShowConference && <AddingConferenceDrawer open onClose={() => setIsShowConference(false)} />}
    </>
  );
};

const useTabArray = classes => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { t: getConversationLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { allUnreadNumber } = useUnreadMessage();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);

  const tabArray = useMemo(() => {
    const tabs = [
      {
        label: getLabel(LangConstant.TXT_CHATS),
        icon: (
          <Badge badgeContent={allUnreadNumber} color="primary" classes={{ root: classes.numNotify, badge: "badge" }}>
            <ChatBubbleOutline />
          </Badge>
        ),
      },
      {
        label: isBranchServer ? getConversationLabel(LangConstant.TXT_MEMBERS) : getLabel(LangConstant.TXT_CONTACT),
        icon: <ContactIcon />,
      },
    ];

    if (isBranchServer) {
      tabs.push({
        label: getLabel(LangConstant.TXT_CONFERENCE),
        icon: <VideocamOutlined />,
      });
    }

    return tabs;
  }, [isBranchServer, allUnreadNumber]);

  return tabArray;
};

const useStyles = makeStyles(theme => ({
  viewModeRoot: {
    height: "-webkit-fill-available",
    maxHeight: "calc(100% - 142px)",
  },

  tabContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    padding: 4,
    borderTop: "0.5px solid " + theme.palette.divider,
    background: "white",
  },

  tabLabel: {
    marginTop: 10,
  },

  tabPanel: {
    height: "100%",
    overflowY: "auto",
    paddingBottom: 66,
  },

  numNotify: {
    "& .badge": {
      top: 4,
      right: -5,
      backgroundColor: "#EE4F49",
      border: "2px solid white",
    },
  },
}));
