import { Avatar, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { useEffect, useState } from "react";
import { AttachmentUtil, getFirstLetter } from "utils";
import { isGroupOrChannelType } from "pages/Call";
import { useTranslation } from "react-i18next";
import { useConversationContext } from "../ConversationContext";

const SeenMessage = ({ data, isShow }) => {
  const classes = useStyles();
  const { groupDetail } = useConversationContext();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [seenMembersName, setSeenMembersName] = useState("");
  const [avatarSeenMemberList, setAvatarSeenMemberList] = useState([]);

  useEffect(() => {
    const seenMembers = data?.seenMembers || [];
    const membersToShowAvatar = data?.membersToShowAvatar || [];

    if (seenMembers.length > 0) {
      if (isGroupOrChannelType(groupDetail.groupType)) {
        const tmpSeenMembersName = (seenMembers.map(item => item.name) || []).join(", ");
        setSeenMembersName(
          getLabel(LangConstant.FM_SEEN_MEMBER, {
            memberNames: tmpSeenMembersName,
          }),
        );
        setAvatarSeenMemberList(membersToShowAvatar);
      } else {
        setSeenMembersName(getLabel(LangConstant.TXT_SEEN));
      }
    } else {
      setSeenMembersName("");
      setAvatarSeenMemberList([]);
    }
  }, [data]);

  return (
    <>
      {seenMembersName !== "" && (
        <Typography className={isShow ? classes.memberSeenName : "hidden"}>{seenMembersName}</Typography>
      )}

      {avatarSeenMemberList.length > 0 && (
        <Box className={classes.memberSeen}>
          {avatarSeenMemberList.map((item, index) => (
            <SeenMemberItem key={index} data={item} />
          ))}
        </Box>
      )}
    </>
  );
};

const SeenMemberItem = ({ data }) => {
  const classes = useStyles();
  const avatarUrl = AttachmentUtil.getAvatarRemoteUrl(data.avatarId);

  return (
    <Avatar key={data.id} src={avatarUrl} className={classes.avatarSeenMember}>
      {getFirstLetter(data.name)}
    </Avatar>
  );
};

export default SeenMessage;

const useStyles = makeStyles({
  memberSeen: {
    display: "flex",
    justifyContent: "end",
    paddingRight: "25px",
  },

  avatarSeenMember: {
    width: 15,
    height: 15,
    margin: 1,
    fontSize: 12,
  },

  memberSeenName: {
    display: "flex",
    justifyContent: "end",
    paddingRight: 25,
    fontSize: 9,
    height: 20,
  },
});
