import { KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { StorageUtil, convertJSONObject, removeDuplicatesWithKey, sortArrayObject, toCamel } from "utils";
import { findLastIndex } from "lodash";

export const DISPLAY_CALL_STATUS = [
  SystemConstant.MESSAGE_CALL_STATUS.end,
  SystemConstant.MESSAGE_CALL_STATUS.missed,
  SystemConstant.MESSAGE_CALL_STATUS.reject,
];

export const handleNewMessage = (newMessage, messageList, groupDetail) => {
  const isValid = newMessage && newMessage.id;
  if (!isValid) return; // Do nothing if data is invalid

  let newMessageList = [...messageList];
  let displayMsgItem = getDisplayMessage([newMessage], groupDetail)[0] || {};
  const existedIndex = newMessageList.findIndex(item => newMessage.id === item.id);
  const existedParentIndex = newMessageList.findIndex(item => item.sourceId === newMessage.parentId);

  switch (true) {
    case existedIndex >= 0:
      // 1. Update message/ delete / update message status message in view list
      if (
        newMessage.sendType === SystemConstant.SEND_TYPE.deleteMessage ||
        newMessage.state === SystemConstant.STATE.inactive
      ) {
        newMessageList = deleteMessage(newMessageList, existedIndex);
      } else if (newMessageList[existedIndex].modified < newMessage.modified) {
        newMessageList[existedIndex] = newMessage;
        newMessageList = refactorMessage2Show(newMessageList, groupDetail);
      }
      break;

    case existedParentIndex >= 0:
      // 2. New message is child message: reaction, edit, delete, calling .v.v
      if (SystemConstant.ARR_CALLING_TYPES.includes(newMessage.sendType)) {
        newMessageList = getDisplayMessage([...newMessageList, newMessage], groupDetail);
      } else if (
        newMessage.sendType === SystemConstant.SEND_TYPE.deleteMessage ||
        newMessage.state === SystemConstant.STATE.inactive
      ) {
        newMessageList = deleteMessage(newMessageList, existedParentIndex);
      } else {
        displayMsgItem = getDisplayMessage([newMessageList[existedParentIndex]], groupDetail)[0];

        if (displayMsgItem) {
          newMessageList[existedParentIndex] = displayMsgItem;
        }
      }
      break;

    case Boolean(displayMsgItem && displayMsgItem.id):
      // 3. Add new message
      displayMsgItem = refactorNewMessage(displayMsgItem, newMessageList, groupDetail);
      newMessageList.unshift(displayMsgItem);
      newMessageList = refactorMessage2Show(newMessageList, groupDetail);
      break;

    default:
      break;
  }

  return newMessageList;
};

export const getDisplayMessage = (messages, groupDetail) => {
  // Remove invalid message
  let showingList = messages.filter(messageItem => {
    const isDeletedMessage =
      getInteractor().LocalMessageService.getChildMessages(messageItem.sourceId, SystemConstant.SEND_TYPE.deleteMessage)
        .length > 0;
    const isBlockedMessage = messageItem.status === SystemConstant.MESSAGE_STATUS.block;

    return (
      (!isChildMessage(messageItem) || SystemConstant.ARR_CALLING_TYPES.includes(messageItem.sendType)) &&
      !isDeletedMessage &&
      !isBlockedMessage &&
      !Boolean(messageItem.threadId)
    );
  });

  const { callingMessageList, otherMessages } = showingList.reduce(
    (accumulator, messageItem) => {
      if (!Array.isArray(accumulator.callingMessageList)) accumulator.callingMessageList = [];
      if (!Array.isArray(accumulator.otherMessages)) accumulator.otherMessages = [];

      const isCalling = SystemConstant.ARR_CALLING_TYPES.includes(messageItem.sendType);
      if (isCalling) {
        accumulator.callingMessageList.push(messageItem);
      } else {
        accumulator.otherMessages.push(messageItem);
      }

      return accumulator;
    },
    { callingMessageList: [], otherMessages: [] },
  );

  showingList = getDisplayCallingMsg(
    callingMessageList,
    groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal,
  ).concat(otherMessages);

  return refactorMessage2Show(sortArrayObject(showingList).reverse(), groupDetail);
};

export const isChildMessage = mes => Boolean(mes.parentId);

export const getDisplayCallingMsg = (callingMessages, isPersonal) => {
  let displayMessageList = callingMessages.filter((messageItem, index) => {
    let isAllowedToShow = true;

    if (SystemConstant.ARR_CALLING_TYPES.includes(messageItem.sendType)) {
      if (isPersonal) {
        isAllowedToShow = DISPLAY_CALL_STATUS.includes(messageItem.callStatus) && isChildMessage(messageItem);
      } else if (messageItem.callStatus === SystemConstant.MESSAGE_CALL_STATUS.accept) {
        const nearestSameSender = callingMessages.find(
          (item, secondIndex) =>
            secondIndex > index &&
            item.senderId === messageItem.senderId &&
            (item.callingContent
              ? item.callingContent.roomId === messageItem.callingContent?.roomId
              : toCamel(convertJSONObject(item.content)).roomId === messageItem.callingContent?.roomId) &&
            item.sourceId !== messageItem.sourceId &&
            SystemConstant.ARR_CALLING_TYPES.includes(item.sendType),
        );

        const nearestBackward = [...callingMessages]
          .reverse()
          .find(
            (item, secondIndex) =>
              secondIndex > index &&
              item.senderId === messageItem.senderId &&
              item.callingContent?.roomId === messageItem.callingContent?.roomId &&
              item.sourceId !== messageItem.sourceId &&
              SystemConstant.ARR_CALLING_TYPES.includes(item.sendType),
          );

        if (
          (nearestSameSender && nearestSameSender.callStatus === messageItem.callStatus) ||
          (nearestBackward && !isChildMessage(nearestBackward))
        ) {
          isAllowedToShow = false;
        }
      }
    }

    return isAllowedToShow;
  });

  // Remove duplicate ended calling
  displayMessageList = displayMessageList.filter((message, index) => {
    const lastIndex = findLastIndex(
      displayMessageList,
      last => last.parentId === message.parentId && last.callStatus === message.callStatus,
    );

    const isDuplicateEndMessage = lastIndex !== index && message.callStatus === SystemConstant.MESSAGE_CALL_STATUS.end;

    return false === isDuplicateEndMessage;
  });

  return displayMessageList;
};

export const refactorMessage2Show = (messageList, groupDetail) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const groupMembers = Array.isArray(groupDetail.groupMembers) ? groupDetail.groupMembers : [];
  const isPersonal = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

  const newestMessage = messageList[0] || {};
  let listMemberIds = newestMessage.listSeenAvatar || groupMembers.map(memberItem => memberItem.id);
  const rewrite = messageList.map((messageItem, index) => {
    // Handle show seen member
    const refactorSeenMembers = {
      seenMembers: [],
      membersToShowAvatar: [],
    };

    if (messageItem.options && messageItem.options !== "") {
      const options = toCamel(convertJSONObject(messageItem.options));
      let optionSeenMembers = options.seenMembers;

      if (Array.isArray(optionSeenMembers)) {
        optionSeenMembers = optionSeenMembers.filter(item => item !== accountId);
        if (false === optionSeenMembers.includes(messageItem.senderId) && messageItem.senderId !== accountId) {
          optionSeenMembers.push(messageItem.senderId);
        }
        optionSeenMembers.forEach(seenMemberId => {
          const member = groupMembers.find(group => group.id === seenMemberId);
          if (member) {
            refactorSeenMembers.seenMembers.push(member);
            if (listMemberIds.includes(seenMemberId)) {
              refactorSeenMembers.membersToShowAvatar.push(member);
              listMemberIds = listMemberIds.filter(memberId => memberId !== seenMemberId);
            }
          }
        });
      }
    }

    // Check message can be display avatar and timeline
    let isAvatar = false;
    let isShowTime = false;
    if (messageItem.senderId !== accountId) {
      const maxTime = 24 * 60 * 60 * 1000; // After 1 days
      const msgCreatedTime = messageItem.created;
      const prevMsg = messageList[index + 1] ? messageList[index + 1] : null;

      isAvatar =
        (!prevMsg ||
          SystemConstant.ARR_CALLING_TYPES.includes(prevMsg.sendType) ||
          prevMsg.senderId !== messageItem.senderId ||
          msgCreatedTime - prevMsg.created >= maxTime) &&
        !SystemConstant.ARR_CALLING_TYPES.includes(messageItem.sendType);

      isShowTime =
        msgCreatedTime - prevMsg?.created >= maxTime &&
        false === Boolean(isPersonal && !DISPLAY_CALL_STATUS.includes(messageItem.callStatus));
    }

    return {
      ...messageItem,
      seenMembers: refactorSeenMembers,
      isShowTime: isShowTime,
      isAvatar: isAvatar,
      listSeenAvatar: listMemberIds,
    };
  });

  return removeDuplicatesWithKey(rewrite, "sourceId");
};

// Handling message when there are new message (Append new message to list  instead of refactoring list again)
export const refactorNewMessage = (newMessage, messageList, groupDetail) => {
  const isValid = groupDetail && groupDetail.id;
  if (!isValid) return;

  const previousMessage = messageList[1];
  if (
    previousMessage &&
    previousMessage.senderId === newMessage.senderId &&
    false === SystemConstant.ARR_CALLING_TYPES.includes(previousMessage.sendType)
  ) {
    return {
      ...newMessage,
      isShowTime: previousMessage.isShowTime ? false : newMessage.isShowTime,
      isAvatar: previousMessage.isAvatar ? false : newMessage.isAvatar,
    };
  }

  return newMessage;
};

export const deleteMessage = (messageList, messageIndex) => {
  const isValid = messageIndex >= 0;
  if (!isValid) return messageList;

  let newMessageList = [...messageList];
  switch (true) {
    case messageIndex === 0:
      // Delete first element
      newMessageList.shift();
      break;

    case messageIndex < messageList.length && messageIndex > 0:
      const currentMessage = newMessageList[messageIndex];
      const nextMsgIndex = messageIndex - 1;
      const nextMessage = newMessageList[nextMsgIndex];

      if (
        nextMessage &&
        nextMessage.senderId === currentMessage.senderId &&
        false === SystemConstant.ARR_CALLING_TYPES.includes(nextMessage.sendType)
      ) {
        newMessageList[nextMsgIndex] = {
          ...nextMessage,
          seenMembers: currentMessage.seenMembers,
          isShowTime: currentMessage.isShowTime,
          isAvatar: currentMessage.isAvatar,
        };
      }
      newMessageList.splice(messageIndex, 1);
      break;

    default:
      break;
  }

  return newMessageList;
};
