import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { AvatarInfo } from "components";
import clsx from "clsx";

const PeopleTagList = ({ isShow, data, onClick, offsetWidth, tagListStyle }) => {
  const classes = useStyles({
    rootWidth: offsetWidth,
  });

  return (
    <Box className={classes.tagListContainer}>
      <Box className={isShow ? clsx(classes.popover, tagListStyle) : "hidden"}>
        <Box className={classes.memberListBox}>
          {data.map(item => (
            <AvatarInfo
              key={item.id}
              className={classes.itemPeople}
              secondTitle={item.phone}
              title={item.name}
              avatar={{ avatarId: item.avatarId, name: item.name }}
              button={true}
              onClick={() => onClick(item)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PeopleTagList);

const useStyles = makeStyles(theme => ({
  tagListContainer: {
    width: "100%",
    position: "absolute",
    bottom: "calc(100% - 4px)",
    left: "50%",
    transform: "translate(-50%)",
    backgroundColor: theme.palette.white,
    zIndex: 1,
  },

  popover: {
    width: "auto",
    overflow: "hidden",
    boxShadow: "0px -3px 9px rgba(0, 0, 0, 0.15)",
    borderRadius: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 8,
  },

  memberListBox: {
    borderRadius: 4,
    overflow: "auto",
    maxHeight: 350,
  },

  memberAvatar: {},

  memberName: {
    marginLeft: 12,
  },

  itemPeople: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: 12,
    paddingBottom: 8,
    paddingTop: 8,
    borderRadius: 20,
  },
}));
