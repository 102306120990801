import { useEffect, useState } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DrawerLayout, InfiniteScroll } from "components";
import { ImageConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import MessageThread from "./MessageThread";

const PAGING_NUMBER = 4;
const ThreadListDrawer = ({ isOpen, onClose, totalThreads, updateThreadAndMessage }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const [displayThreads, setDisplayThreads] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const handleScrollToBottom = () => {
    const newDisplay = totalThreads.slice(displayThreads.length, displayThreads.length + PAGING_NUMBER);
    setDisplayThreads(state => [...state, ...newDisplay]);
    updateThreadAndMessage(newDisplay);
  };

  useEffect(() => {
    if (Boolean(isFirstLoad) && totalThreads.length > 0) {
      const newDisplay = totalThreads.slice(0, PAGING_NUMBER);
      setDisplayThreads(newDisplay);
      setIsFirstLoad(false);
      updateThreadAndMessage(newDisplay);
    }
  }, [totalThreads]);

  return (
    <DrawerLayout open={isOpen} onClose={onClose} title={getLabel(LangConstant.TXT_FLOW_MESSAGE)}>
      {displayThreads && displayThreads.length > 0 ? (
        <InfiniteScroll
          className={classes.infiniteScroll}
          onScrollToBottom={handleScrollToBottom}
          hasMore={displayThreads.length < totalThreads.length}
        >
          {displayThreads.map(item => (
            <MessageThread
              key={item.id}
              threadId={item.threadId}
              parentMessage={item.parentMessage}
              newestMessage={item.newestMessage}
              secondMessage={item.secondMessage}
              totalReply={item.totalReply}
              groupId={item.groupId}
              senderParentMsg={item.senderParentMsg}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <Box className={classes.emptyChatList}>
          <CardMedia component="img" src={ImageConstant.WelcomeImage} className={classes.emptyImage} />
          <Typography className={classes.emptyMessage}>{getLabel(LangConstant.TXT_NO_UNSEEN_THREAD)}</Typography>
        </Box>
      )}
    </DrawerLayout>
  );
};

export default ThreadListDrawer;

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  unseenText: {
    fontSize: 14,
    marginBottom: 10,
  },

  emptyImage: {
    padding: "10px 14px",
  },

  emptyMessage: {
    fontSize: 15,
    fontWeight: 600,
    marginTop: 16,
    lineHeight: "20px",
    textAlign: "center",
  },

  emptyChatList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 56px",
    borderTop: "0.5px solid " + theme.palette.divider,
  },

  infiniteScroll: {
    height: "100%",
    paddingTop: 5,
  },
}));
