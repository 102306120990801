import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { all, call, delay, put, select } from "redux-saga/effects";
import { getPrefixKey, isLoginBranch, toCamel, toSnake } from "utils";
import { LocalDbManagement, getInteractor } from "services/local.service";
import { ConversationActions, ConversationSelectors, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { DISPLAY_MSG_TYPES } from "./saga.helper";

const HANDLE_QUEUE_LIMIT = 10;
window.delaySyncMsec = 3000;

export function* executeQueue() {
  try {
    if (navigator.onLine) {
      // Get all active branches
      const activeBranchList = LocalDbManagement.find({ state: SystemConstant.STATE.active });

      // Handling queue on all active branch
      if (activeBranchList.length > 0) {
        yield all(activeBranchList.map(item => call(handleQueueTasks, getPrefixKey(item.account_id, item.branch_id))));
      }
    }
  } catch (error) {
    console.log(error);
  }

  // Delay before continue to execute
  yield delay(window.delaySyncMsec);
  yield executeQueue();
}

export function* handleQueueTasks(prefixKey) {
  if (false === isLoginBranch() || window.isStopSynchronize) return;
  const isCurrentBranch = StorageUtil.getCurrentPrefixKey() === prefixKey;

  try {
    const apiList = getInteractor(prefixKey).LocalApiCallService.getApiQueue(HANDLE_QUEUE_LIMIT);

    const apiByGroupId = apiList.reduce((apiListByGroup, apiItem) => {
      const groupId = apiItem.group_id || "group_id"; // Using "group_id" for older version

      if (false === Array.isArray(apiListByGroup[groupId])) {
        apiListByGroup[groupId] = [];
      }

      apiListByGroup[groupId].push(apiItem);
      return apiListByGroup;
    }, {});

    if (apiList.length > 0) {
      yield all(Object.keys(apiByGroupId).map(groupId => sendMessageList(prefixKey, apiByGroupId[groupId])));

      // Render view if current group need to be updated
      const selectedGroupId = yield select(ConversationSelectors.getSelectedGroupId);
      if (isCurrentBranch && selectedGroupId && Object.keys(apiByGroupId).includes(selectedGroupId)) {
        yield put(
          ConversationActions.conversationSet({
            isUpdateViewMode: Date.now(),
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* sendMessageList(prefixKey, apiList) {
  for (let index = 0; index < apiList.length; index++) {
    const item = apiList[index];
    const { data } = JSON.parse(item.content) || {};
    data["call_id"] = item.id;
    yield sendMessage2Server(prefixKey, data);
  }

  return true;
}

function* sendMessage2Server(prefixKey, data) {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  try {
    if (data.marks) delete data.marks;

    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).sendMessage, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      const local = getInteractor(prefixKey).LocalMessageService.getMessageBySourceId(data.sourceId)[0];
      if (local && Object.keys(local).length > 0 && local.created) {
        yield getInteractor(prefixKey).LocalMessageService.save([
          {
            ...local,
            modified: local.created,
          },
        ]);
      }

      const selectedGroupId = yield select(ConversationSelectors.getSelectedGroupId);
      if (local && local.group_id === selectedGroupId && DISPLAY_MSG_TYPES.includes(local.send_type)) {
        yield put(
          ConversationActions.conversationSet({
            sendingMessageLocal: toCamel({
              ...local,
              modified: local.created,
            }),
          }),
        );
      }

      if (data.sendType === SystemConstant.SEND_TYPE.leaveGroup && data.removingId === accountId) {
        const payload = {
          groupId: data.groupId,
        };

        if (data.adminId) {
          payload.adminId = data.adminId;
        }

        yield put(
          GroupInfoActions.deleteGroup({
            ...payload,
          }),
        );
      }

      getInteractor(prefixKey).LocalApiCallService.poll(data.call_id);
    } else {
      if (response.status === ApiConstant.STT_DUPLICATE_MESSAGE) {
        const local = getInteractor(prefixKey).LocalMessageService.getMessageBySourceId(data.sourceId)[0];
        if (local && Object.keys(local).length > 0 && local.created) {
          yield getInteractor(prefixKey).LocalMessageService.save([
            {
              ...local,
              modified: local.created,
            },
          ]);
        }

        getInteractor(prefixKey).LocalApiCallService.poll(data.call_id);
      } else if (
        response.status === ApiConstant.STT_UNAUTHORIZED ||
        response.status === ApiConstant.STT_FORBIDDEN ||
        response.status === ApiConstant.STT_MAINTAIN_1 ||
        response.status === ApiConstant.STT_MAINTAIN_2 ||
        response.status === ApiConstant.STT_MAINTAIN_3
      ) {
        // handler refresh token in generic
      } else {
        let apiCall = yield getInteractor(prefixKey).LocalApiCallService.get(data.call_id) || {};
        if (apiCall.retry <= ApiConstant.MAX_RETRY) {
          if (
            response.status === ApiConstant.STT_NOT_FOUND ||
            response.status === ApiConstant.STT_BAD_REQUEST ||
            response.status === ApiConstant.STT_INTERNAL_SERVER
          ) {
            yield getInteractor(prefixKey).LocalApiCallService.save([{ ...apiCall, retry: apiCall.retry + 1 }]);
          }
        } else {
          let deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
          if (data && data.messages && data.messages.length > 0) {
            data.messages.map(async message => {
              if (message.deviceId === deviceId) {
                const messageFromDB = getInteractor(prefixKey).LocalMessageService.get(message.messageId);
                if (messageFromDB) {
                  messageFromDB.state = SystemConstant.STATE.inactive;
                  getInteractor(prefixKey).LocalMessageService.save([messageFromDB]);
                }
              }
            });
          }
          getInteractor(prefixKey).LocalApiCallService.poll(data.call_id);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
