import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ButtonGroupInfo = ({ name, onClick, icon }) => {
  const classes = useStyles();

  return (
    <Button className={classes.btnGroupInfo} onClick={onClick}>
      <Typography className={classes.btnImage} variant="body1">
        {name}
      </Typography>
      <Box className={classes.iconGroupBtn}>{icon} </Box>
    </Button>
  );
};

ButtonGroupInfo.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

ButtonGroupInfo.defaultProps = {
  name: "",
  icon: {},
  onClick: () => {},
};

export default ButtonGroupInfo;

const useStyles = makeStyles({
  btnGroupInfo: {
    textTransform: "none",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  iconGroupBtn: {
    width: 30,
    height: 30,
    backgroundColor: "#C4C4C4",
    color: "#010101",
    borderRadius: "50%",
    paddingTop: 7,
  },
  iconGroupInfoBtn: {
    width: 15,
    height: 15,
  },
});
