import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { getExternalLinkFromString, isExternalLink } from "utils";
import { PREFIX_MENTION, SUFFIX_MENTION, replaceId2Name, replaceName2Id } from "utils/view.utils";

const ChatTypography = ({ messageContent, mentionList, ...otherProps }) => {
  const mentions = mentionList || [];
  const contentList = separateMessageContent(replaceName2Id(messageContent, mentions));

  return (
    <Box
      sx={{ whiteSpace: "pre-wrap", lineBreak: "normal", wordBreak: "break-word", color: "inherit" }}
      {...otherProps}
    >
      {contentList.map((item, index) =>
        item.isHtml ? (
          <Typography
            key={index}
            component="b"
            dangerouslySetInnerHTML={{ __html: replaceId2Name(item.content, mentions) }}
            color="inherit"
          />
        ) : (
          <Typography key={index} component="span" color="inherit">
            {item.content}
          </Typography>
        ),
      )}
    </Box>
  );
};

ChatTypography.propTypes = {
  messageContent: PropTypes.string.isRequired,
  mentionList: PropTypes.array,
};

export default ChatTypography;

const separateMentionContent = msgContent => {
  if (!msgContent || false === msgContent.includes(PREFIX_MENTION)) {
    return {
      rawText: msgContent,
      startMentionIndex: 0,
      endMentionIndex: 0,
    };
  }

  const startMentionIndex = msgContent.indexOf(PREFIX_MENTION);
  const rawText = msgContent.slice(0, startMentionIndex);
  const newMsgContent = msgContent.slice(startMentionIndex);
  const endMentionIndex = newMsgContent.indexOf(SUFFIX_MENTION) + 1;

  return {
    rawText,
    startMentionIndex,
    endMentionIndex,
    mentionContent: newMsgContent.slice(0, endMentionIndex),
    otherContent: newMsgContent.slice(endMentionIndex),
  };
};

const separateLinkContent = msgContent => {
  const hasLinkContent = msgContent && isExternalLink(msgContent);

  if (!hasLinkContent) {
    return [
      {
        content: msgContent,
        isHtml: false,
      },
    ];
  }

  let result = [];
  try {
    const url = getExternalLinkFromString(msgContent);
    if (url) {
      const startUrlIndex = msgContent.indexOf(url);
      result[0] = {
        content: msgContent.slice(0, startUrlIndex),
        isHtml: false,
      };
      result[1] = {
        content: `<a href="${url}">${url}</a>`,
        isHtml: true,
      };
      result[2] = {
        content: msgContent.slice(startUrlIndex + url.length, msgContent.length),
        isHtml: false,
      };
    }
  } catch (error) {
    console.error(error);
    console.warn({ msgContent });
  }
  return result;
};

const separateMessageContent = (msgContent = "") => {
  const isSpecialContent = Boolean(msgContent) && (isExternalLink(msgContent) || msgContent.includes(PREFIX_MENTION));

  if (!msgContent || !isSpecialContent) {
    return [
      {
        content: msgContent,
        isHtml: false,
      },
    ];
  }

  /**
   * Array object that structure like:
   * {
   *  content: <message content>
   *  isHtml: <true: display html text - false: raw text>
   * }
   */
  let result = [];
  const { rawText, mentionContent, otherContent } = separateMentionContent(msgContent);
  if (rawText) result = result.concat(separateLinkContent(rawText));

  if (mentionContent) {
    result.push({
      content: mentionContent,
      isHtml: true,
    });
  }

  if (otherContent) result = result.concat(separateMessageContent(otherContent));

  return result.filter(item => Boolean(item.content));
};
