import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { call, put } from "redux-saga/effects";
import { toSnake } from "utils";
import { getInteractor } from "services/local.service";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";
import { remoteApiFactory } from "services/remote.service";
import { checkCurrentBranchByPrefix } from "./saga.helper";

export function* uploadMessageFile(action) {
  let errorReason;

  try {
    const { upload, sendType } = action.data;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).sendMessageFile, upload);
    if (response && response.status === ApiConstant.STT_OK && response?.data?.id) {
      yield put(
        ConversationActions.conversationSuccess({
          uploadingAttachment: {
            processStatus: AppConstant.PROCESS_STATUS.success,
            attachmentId: response.data.id,
            sendType: sendType,
          },
        }),
      );
    } else {
      errorReason = response;
    }
  } catch (error) {
    errorReason = error;
  }

  if (errorReason)
    yield put(
      ConversationActions.conversationFailure(errorReason, {
        uploadingAttachment: {
          processStatus: AppConstant.PROCESS_STATUS.error,
        },
      }),
    );
}

export function* updateMessageStatus(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);

    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateMessageStatus, toSnake(data));
    if (isCurrentBranch && response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationActions.conversationSuccess({
          isUpdateViewMode: Date.now(),
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* markReadMessageInGroup(action) {
  const { selectedGroupId, threadingId } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    let unreadMessageList = [];

    if (threadingId) {
      unreadMessageList = getInteractor(prefixKey).LocalMessageService.getUnreadInThread(threadingId);
    } else if (selectedGroupId) {
      unreadMessageList = getInteractor(prefixKey).LocalMessageService.findUnreadOrderByCreatedDesc(
        accountId,
        selectedGroupId,
      );
    }

    if (Array.isArray(unreadMessageList) && unreadMessageList.length > 0) {
      const unreadMsgIds = unreadMessageList.map(item => item.id);
      yield updateMessageStatus({
        data: {
          messageIds: unreadMsgIds,
          status: SystemConstant.MESSAGE_STATUS.read,
        },
      });

      yield getInteractor(prefixKey).LocalMessageService.updateMessageStatusByMsgIdArr(unreadMsgIds);
    }
  } catch (e) {
    console.log(e);
  }
}
