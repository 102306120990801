import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Box, Button, List, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatStringWithKeyword, highlightString, sortArrayObject, toCamel } from "utils";
import { LangConstant, SystemConstant } from "const";
import { SearchBar } from "components";
import SearchPopup from "./SearchPopup";
import FlowMessageActive from "icons/FlowMessageActive";
import ThreadListDrawer from "./ThreadListDrawer";
import { FlowMessage } from "icons";
import { useAccountList, useContactList, useThreadInfo, useGroupByType } from "hooks";
import { getInteractor } from "services/local.service";
import { getSearchResult, replaceId2Name } from "utils/view.utils";
import { BranchSelectors } from "redux-store";
import ChatAllTab from "./ChatAllTab";

const ChatTab = () => {
  const classes = useStyles();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);
  return (
    <Box className={classes.chatTabRoot}>
      <SearchConversation isBranchServer={isBranchServer} />
      <Box className={classes.viewFullChat}>
        <ThreadList />

        <List>
          {isBranchServer && <ChannelList />}

          <GroupList />
          <PersonalMsgList />
        </List>
      </Box>
    </Box>
  );
};

export default ChatTab;
const SEARCH_INPUT_ANCHOR_ID = "search-input-anchor-id";

const SearchConversation = ({ isBranchServer }) => {
  const searchRef = useRef();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const classes = useStyles();

  const { accountList } = useAccountList();
  const { contactList } = useContactList();

  const [searchInputEl, setSearchInputEl] = useState(null);
  const [searchingResult, setSearchingResult] = useState({
    contacts: [],
    messages: [],
    groups: [],
    channels: [],
  });

  const handleClosePopup = () => setSearchInputEl(null);

  const handleShowPopup = () => {
    const inputEl = document.getElementById(SEARCH_INPUT_ANCHOR_ID);
    setSearchInputEl(inputEl);
  };

  const handleChangeInput = value => {
    searchRef.current = value;
    Promise.all([
      handleSearchMessage(value),
      getSearchResult(value, isBranchServer ? accountList : contactList, ["name", "contactName", "phone"]),
      getInteractor().LocalGroupService.searchGroupByCondition(value, {
        group_type: SystemConstant.GROUP_CHAT_TYPE.group,
      }),
      getInteractor().LocalGroupService.searchGroupByCondition(value, {
        group_type: SystemConstant.GROUP_CHAT_TYPE.channel,
      }),
    ]).then(([messages, contacts, groups, channels]) => {
      setSearchingResult({
        ...searchingResult,
        messages: messages.slice(0, 5),
        contacts: contacts.slice(0, 5),
        groups: groups.slice(0, 5),
        channels: channels.slice(0, 5),
      });
    });
  };

  const handleSearchMessage = searchValue => {
    if (searchValue) {
      const messageSearchResult = toCamel(getInteractor().LocalMessageService.searchMessageByCondition(searchValue));
      const handleData = messageSearchResult.map(message => {
        if (Object.values(SystemConstant.MEDIA_SEND_TYPE).includes(message.sendType)) {
          message.content = JSON.parse(message.content).content_message;
        }
        const group = getInteractor().LocalGroupService.get(message.groupId);
        if (group && group.id) {
          message.content = replaceId2Name(message.content, group.groupMembers);
        }
        message.content = formatStringWithKeyword(searchValue, message.content);
        message.content = highlightString(searchValue, message.content);

        return message;
      });

      return sortArrayObject(handleData);
    }

    return [];
  };

  return (
    <>
      <SearchBar
        placeholder={getLabel(LangConstant.TXT_CHAT_SEARCH_PLACE_HOLDER)}
        onFocus={handleShowPopup}
        onChange={handleChangeInput}
        classes={{
          root: clsx(classes.searchBarRoot, {
            [classes.searchBarRootActive]: Boolean(searchInputEl),
          }),
        }}
        componentsProps={{
          root: { id: SEARCH_INPUT_ANCHOR_ID },
        }}
      />

      {Boolean(searchInputEl) && (
        <SearchPopup
          anchorEL={searchInputEl}
          onClose={handleClosePopup}
          keyword={searchRef.current}
          onKeywordClick={handleChangeInput}
          data={searchingResult}
        />
      )}
    </>
  );
};

const ThreadList = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const classes = useStyles();
  const { unreadThreads, totalThreads, updateThreadAndMessage } = useThreadInfo();

  const [isShowThreadList, setIsThreadList] = useState(false);

  const handleShowThreadFlow = () => setIsThreadList(true);

  return (
    <>
      <Tooltip title={getLabel(LangConstant.TXT_VIEW_MESSAGE_THREAD)}>
        <Button
          className={classes.flowMessage}
          classes={{ root: unreadThreads.length === 0 && classes.buttonRoot }}
          variant="text"
          startIcon={
            unreadThreads.length > 0 ? (
              <FlowMessageActive className={classes.flowMessageBtn} />
            ) : (
              <FlowMessage className={classes.flowMessageBtn} />
            )
          }
          onClick={handleShowThreadFlow}
        >
          {unreadThreads.length > 0
            ? getLabel(LangConstant.FM_NUMBER_FLOW_MESSAGE, { number: unreadThreads.length })
            : getLabel(LangConstant.TXT_FLOW_MESSAGE)}
        </Button>
      </Tooltip>

      {isShowThreadList && (
        <ThreadListDrawer
          isOpen
          onClose={() => setIsThreadList(false)}
          unreadThreads={unreadThreads}
          totalThreads={totalThreads}
          updateThreadAndMessage={updateThreadAndMessage}
        />
      )}
    </>
  );
};

const ChannelList = () => {
  const { groupList } = useGroupByType([SystemConstant.GROUP_CHAT_TYPE.channel]);

  return <ChatAllTab conversations={groupList} type={SystemConstant.GROUP_CHAT_TYPE.channel} />;
};

const GroupList = () => {
  const { groupList } = useGroupByType([SystemConstant.GROUP_CHAT_TYPE.group]);

  return <ChatAllTab conversations={groupList} type={SystemConstant.GROUP_CHAT_TYPE.group} />;
};

const PersonalMsgList = () => {
  const { groupList } = useGroupByType([SystemConstant.GROUP_CHAT_TYPE.personal]);

  return <ChatAllTab conversations={groupList} type={SystemConstant.GROUP_CHAT_TYPE.personal} />;
};

const useStyles = makeStyles(theme => ({
  chatTabRoot: {
    height: "100%",
    paddingTop: 12,
  },

  chatList: {
    height: "100%",
  },

  searchBarRoot: {
    margin: "0 14px",
    width: "calc(100% - 28px)",
  },

  searchBarRootActive: {
    zIndex: theme.zIndex.snackbar,
  },

  viewFullChat: {
    height: "95%",
    overflowY: "auto",
    margin: "0 14px",
    width: "calc(100% - 28px)",
  },

  flowMessage: {
    marginTop: 10,
    textTransform: "none",
  },

  flowMessageBtn: {},

  buttonRoot: {
    color: "#8E8E8E",
  },
}));
