import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CardMedia, Typography, Box, Avatar, Grid, IconButton } from "@mui/material";
import { LangConstant, KeyConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { PersonAdd, Call, VideoCall, Message, Edit, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import ButtonGroupInfo from "../GroupInfo/ButtonGroupInfo";
import DialogConfirmLeave from "./DialogConfirmLeave";
import PopupChangeContactName from "./PopupChangeContactName";
import { DrawerLayout } from "components";
import { useCreateConversation } from "hooks";
import { getInteractor } from "services/local.service";
import { ContactActions } from "redux-store";
import { AttachmentUtil, StorageUtil, getFirstLetter } from "utils";

// TODO: Need to refactor
const AddingContactResultDrawer = ({ title, onClose, onBackToMain, ...drawerProps }) => {
  const classes = useStyles();
  const styles = createStyleObj();
  const dispatch = useDispatch();
  const { createWithPersonal } = useCreateConversation();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const contactObjContent = getLabel(LangConstant.OBJ_ADDING_CONTACT, { returnObjects: true });

  const existsInContactTable = useSelector(state => state.contactRedux.existsInContactTable);
  const addNewContactResponse = useSelector(state => state.contactRedux.addNewContactResponse);
  const nameAfterChange = useSelector(state => state.contactRedux.nameAfterChange);
  const idContactPhoneBook = useSelector(state => state.contactRedux.idContactPhoneBook);
  const notInContactTable = useSelector(state => state.contactRedux.notInContactTable);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);

  const [isMakingFriend, setIsMakingFriend] = useState(false);
  const [isShowChangingContactName, setIsShowChangingContactName] = useState(false);
  const [isConfirmDeleteContact, setIsConfirmDeleteContact] = useState(false);
  const [clipBoardChangedName, setClipBoardChangedName] = useState("");
  const [contactName, setContactName] = useState("");
  const [dataContact, setDataContact] = useState();
  const [userAvatar, setUserAvatar] = useState(null);
  const [userBackground, setUserBackground] = useState(null);
  const [isMyPhone, setIsMyPhone] = useState(false);

  const onMessenger = () => {
    createWithPersonal(dataContact);

    onClose(false);
    onBackToMain();
  };

  const handleAudioCall = () => {
    createWithPersonal(dataContact, { isDemandVoiceCall: true });

    onClose(false);
    onBackToMain();
  };

  const handleVideoCall = () => {
    createWithPersonal(dataContact, { isDemandVoiceCall: true });

    onClose(false);
    onBackToMain();
  };

  const onChangeContactName = () => {
    dispatch(
      ContactActions.updateContact({
        id: idContactPhoneBook,
        contactName: clipBoardChangedName,
      }),
    );
    setContactName(clipBoardChangedName);
    setIsShowChangingContactName(false);
  };

  const addNewContact = () => {
    dispatch(
      ContactActions.addNewContact({
        phoneNumber: dataContact.phone,
        contactName: dataContact.contactName ? dataContact.contactName : dataContact.name,
      }),
    );
  };

  const unfriendContact = () => {
    dispatch(
      ContactActions.unfriendContact({
        contactId: idContactPhoneBook,
      }),
    );
    setIsConfirmDeleteContact(false);
    setIsMakingFriend(false);
  };

  const closeSearch = () => {
    dispatch(
      ContactActions.contactSet({
        existsInContactTable: null,
        notInContactTable: null,
      }),
    );
    onClose(false);
  };

  useEffect(() => {
    if (existsInContactTable) {
      setIsMakingFriend(existsInContactTable.state === SystemConstant.STATE.active);
      setContactName(existsInContactTable.contactName);
      setDataContact(existsInContactTable);
    }
  }, [existsInContactTable]);

  useEffect(() => {
    if (notInContactTable) {
      setIsMakingFriend(false);
      setContactName(notInContactTable.name);
      setDataContact(notInContactTable);
    }
  }, [notInContactTable]);

  useEffect(() => {
    if (addNewContactResponse) {
      setIsMakingFriend(true);
    }
  }, [addNewContactResponse]);

  useEffect(() => {
    if (nameAfterChange) {
      setContactName(nameAfterChange);
    }
  }, [nameAfterChange]);

  useEffect(() => {
    const myAccount = getInteractor().LocalAccountService.get(accountId);
    if (dataContact && Object.keys(dataContact).length > 0) {
      if (dataContact.avatarId) {
        setUserAvatar(AttachmentUtil.getAvatarRemoteUrl(dataContact.avatarId));
      }
      if (dataContact.backgroundId) {
        setUserBackground(AttachmentUtil.getAvatarRemoteUrl(dataContact?.backgroundId));
      }

      if (myAccount && Object.keys(myAccount).length > 0) {
        const isMyPhone = myAccount.phone === dataContact.phone;
        setIsMyPhone(isMyPhone);
      }
    }
  }, [dataContact]);

  return (
    <DrawerLayout onClose={closeSearch} title={contactObjContent.title} {...drawerProps}>
      <Box className={classes.rootSearchResult}>
        {userBackground ? (
          <CardMedia component={"img"} alt={""} className={classes.cover} src={userBackground} />
        ) : (
          <Box className={classes.coverDefault}></Box>
        )}
        <Box className={classes.avatarBox}>
          <Avatar
            className={classes.avatarName}
            sx={dataContact?.avatarId ? styles.avatar : styles.avatarDefault}
            src={userAvatar}
          >
            {!dataContact?.avatarId && getFirstLetter(contactName)}
          </Avatar>
        </Box>
      </Box>

      <Box>
        <Typography className={classes.nameSearch}>
          {isMakingFriend || isMyPhone ? contactName : dataContact?.phone}
        </Typography>
        <Grid className={classes.gridSearchResultBtn} container>
          {!isMyPhone ? (
            <>
              {!isMakingFriend && (
                <CustomGrid className={classes.gridBtn} item onClick={addNewContact}>
                  <IconButton className={classes.iconBtn}>
                    <PersonAdd />
                  </IconButton>
                  <Typography variant="body2">{contactObjContent.addContact}</Typography>
                </CustomGrid>
              )}
              <CustomGrid className={classes.gridBtn} item>
                <IconButton className={classes.iconBtn} disabled={isInCall || !hasInternet} onClick={handleAudioCall}>
                  <Call />
                </IconButton>
                <Typography variant="body2">{contactObjContent.callContact}</Typography>
              </CustomGrid>
              <CustomGrid className={classes.gridBtn} item>
                <IconButton className={classes.iconBtn} disabled={isInCall || !hasInternet} onClick={handleVideoCall}>
                  <VideoCall />
                </IconButton>
                <Typography variant="body2">{contactObjContent.videoContact}</Typography>
              </CustomGrid>
              <CustomGrid
                className={classes.gridBtn}
                item
                onClick={() => {
                  onMessenger();
                  closeSearch();
                }}
              >
                <IconButton className={classes.iconBtn}>
                  <Message />
                </IconButton>
                <Typography variant="body2">{contactObjContent.mesContact}</Typography>
              </CustomGrid>
            </>
          ) : (
            <Typography className={classes.currentUser}>{contactObjContent.currentUser}</Typography>
          )}
        </Grid>
        {isMakingFriend && (
          <Box className={classes.body}>
            <ButtonGroupInfo
              name={contactObjContent.changeNickname}
              icon={<Edit className={classes.iconSearchInfoBtn} />}
              onClick={() => setIsShowChangingContactName(true)}
            />

            <ButtonGroupInfo
              name={contactObjContent.deleteContact}
              icon={<Delete className={classes.iconSearchInfoBtn} />}
              onClick={() => setIsConfirmDeleteContact(true)}
            />
          </Box>
        )}

        {isShowChangingContactName && (
          <PopupChangeContactName
            onChange={setClipBoardChangedName}
            open
            onClose={() => setIsShowChangingContactName(false)}
            onSubmit={onChangeContactName}
          />
        )}

        {isConfirmDeleteContact && (
          <DialogConfirmLeave
            open
            onClose={() => setIsConfirmDeleteContact(false)}
            title={contactObjContent.deleteContactPopup}
            content={getLabel(contactObjContent.deleteContactTitlePopup, { name: dataContact?.contactName })}
            submitProps={{ submitText: contactObjContent.deleteContact, onClick: unfriendContact }}
            cancelProps={{ onClick: () => setIsConfirmDeleteContact(false) }}
          />
        )}
      </Box>
    </DrawerLayout>
  );
};

AddingContactResultDrawer.propTypes = {
  ...DrawerLayout.propTypes,
  fullPhoneNumber: PropTypes.string,
};

export default AddingContactResultDrawer;

const CustomGrid = ({ isPersonal, children, ...props }) => (
  <Grid {...props} xs={isPersonal ? 4 : 3}>
    {children}
  </Grid>
);

const useStyles = makeStyles(theme => ({
  body: {
    width: "100%",
    display: "grid",
    padding: "0px 6px",
  },

  container: {
    padding: 16,
  },

  title: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
  },

  rootSearchResult: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 71.67%, rgba(0, 0, 0, 0.5) 100%)",
    marginBottom: 36,
  },

  coverDefault: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  avatarBox: {
    position: "absolute",
    bottom: -36,
    width: "100%",
  },

  nameSearch: {
    marginTop: 50,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "20px",
    textAlign: "center",
  },

  gridSearchResultBtn: {
    marginTop: 12,
    padding: "0 10px",
    display: "flex",
    justifyContent: "center",
  },

  gridBtn: {
    textAlign: "center",
  },

  iconBtn: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: 4,
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },

  iconSearchInfoBtn: {
    width: 18,
    height: 18,
  },

  avatarName: {
    fontSize: 48,
    fontWeight: 500,
  },

  currentUser: {
    fontSize: 15,
    fontWeight: 500,
  },
}));

const createStyleObj = () => ({
  avatar: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
  },
  avatarDefault: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 71.67%, rgba(0, 0, 0, 0.5) 100%)",
  },
  name: {
    fontWeight: 700,
    fontSize: 15,
  },
});
