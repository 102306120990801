import React, { memo } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { getFirstLetter } from "utils";
import { KeyConstant } from "const";
import { StorageUtil } from "utils";
import { useMessageFile } from "hooks";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem.helper";

const AudioChat = ({ message }) => {
  const { mediaData } = useMessageFile(message);
  const messengerClasses = useMessageStyle();

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isMine = message.senderId === accountId;
  const msgCreatedTime = getCreatedTimeText(message);

  return (
    <Stack direction="row" spacing={2}>
      {!isMine && (
        <Avatar
          className={clsx(!message.isAvatar && messengerClasses.hiddenAvatar)}
          alt="chatting-avatar"
          src={message.avatar}
        >
          {getFirstLetter(message?.senderName)}
        </Avatar>
      )}

      <Box>
        {msgCreatedTime && <Typography className={messengerClasses.createdTimeText}>{msgCreatedTime}</Typography>}

        <Box flex={1}>
          <audio controls preload="metadata" src={mediaData.mediaPath} />
        </Box>
      </Box>
    </Stack>
  );
};

export default memo(AudioChat, (prev, next) => JSON.stringify(prev.data) === JSON.stringify(next.data));
