import { Box, Drawer, Stack, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Sidebar, Welcome } from "./components";
import MessengerChat from "./components/MessengerChat";
import { AppConstant } from "const";
import { InactiveDialog } from "components";
import HandlingParallel from "./HandlingParallel";

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <HandlingParallel />

      <Box className={classes.root}>
        <Drawer
          variant="permanent"
          anchor="left"
          classes={{
            root: classes.sidebar,
            paper: classes.sidebar,
          }}
        >
          <Sidebar />
        </Drawer>
        <Box component="main" className={classes.main}>
          <Stack component={Paper} sx={{ height: "100%", borderRadius: 5 }} elevation={0}>
            <MessengerChat />
            <Welcome />
          </Stack>
        </Box>
      </Box>

      <InactiveDialog />
    </>
  );
};

export default Home;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  sidebar: {
    width: AppConstant.SIDEBAR_WIDTH.medium,
    flexShrink: 0,
    boxSizing: "border-box",
    maxHeight: "calc(100vh)",
    overflow: "hidden",
    [theme.breakpoints.up(1500)]: {
      width: AppConstant.SIDEBAR_WIDTH.large,
    },
  },
  main: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: "#F7F7F8",
    padding: 20,
    maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.medium})`,
    maxHeight: "100vh",

    [theme.breakpoints.up(1500)]: {
      maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.large}px)`,
    },
  },
}));
