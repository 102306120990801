import React, { memo } from "react";
import PropTypes from "prop-types";
import {AppConstant, ImageConstant, LangConstant} from "const";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

function EmptyContact({ mode }) {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });
  const displayText = AppConstant.CHATTING_TAB_MODE.message === mode ? chatObjContent.emptyMessage : chatObjContent.emptyChannel;

  return (
    <Box className={classes.root}>
      <img src={ImageConstant.EmptyContactImage} alt="empty contact" loading="lazy" />
      <Typography className={classes.description} variant="subtitle2">
        {displayText}
      </Typography>
    </Box>
  );
}

EmptyContact.propTypes = {
  mode: PropTypes.number,
};
EmptyContact.defaultProps = {
  mode: AppConstant.CHATTING_TAB_MODE.message,
};

export default memo(EmptyContact);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "calc(50vh - 250px)",
    textAlign: "center",

    "@media (min-height:780px)": {
      marginTop: 120,
    },

    "& img": {
      width: "80%",
    },
  },
  description: {
    color: "#1E272E",
    marginTop: 16,
  },
}));
